const en_US = {
  translations: {
    home: {
      message: "Hello World!",
    },
    page: {
      panels: {
        title: "Panels",
        edit: "To edit",
        search: "Search for a panel",
        add: "Add Painel",
        confirm: {
          title: "Delete panel?",
          description:
            "This will permanently delete the panel, its dashboards and all the data.",
        },
        name: "Name",
      },
      edit_panel: {
        title: "Panel",
        subtitle: "New panel ",
        name: "Name",
      },
      under_construction: {
        title: "Under construction",
        subtitle:
          "  This section is under construction, while it is not finished visit one of the other sections",
        button: " Visit Generics Register",
      },
      login: {
        login: "Login",
        accessing: "Accessing",
        welcome: "Welcome back",
        rememberme: "Remember me",
        forget_password: "Forgot password?",
        username: "CPF",
        password: "Senha",
      },
      not_found: {
        page_not_found: "Ooops! Page not found",
        server_not_responding: "Server not responding",
        try_again: "Try Again",
        back_to_begin: "Back to home",
        back_to_login: "Back to login",
        something_went_wrong: "Something went wrong",
      },
      dashboard: {
        dashboard: "Very Welcome!",
        account_balance: "Account balance",
        refresh_button: "Refresh",
        idle: "Idle",
        active: "Active",
      },
      register: {
        generics: {
          identifier: "Identifier",
          code: "Code",
          value: "Value",
          generics: "Generics",
          generic: "Generic",
          description: "Description",
          delete_generics: "Delete Generics",
          new_generic: "New Generic",
          search_identifier: "Searh for a identifier",
          edit_generic: "Edit Generic",
          confirm: {
            title: "Delete generic?",
            description: "This will permanently delete the generic",
            cancellationText: "Cancel",
            confirmationText: "Yes, delete",
          },
          errors: {
            identifier: "The identifier is mandatory",
            code: "The code is mandatory",
            value: "The value is mandatory",
          },
        },
        roles: {
          name: "Name",
          establishments: "Establishments",
          full_name: "Full name",
          privileges: "Privileges",
          roles: "Profiles",
          enable: "Enable",
          profiles: "Roles",
          roles_profile: "Roles that will have this profile linked",
          delete_role: "Delete profile",
          search_role: "Search by a profile",
          description: "Description",
          new_role: "New profile",
          software: "Software",
          select_software: "Select a software",
          config_access: "Profile access settings",
          confirm: {
            title: "Delete profile?",
            description: "This will permanently delete the profile",
          },
          errors: {
            name: "The name is mandatory",
            description: "The description is mandatory",
            establishments: "At least one establishment is mandatory",
            privilege: "At least one privilege is mandatory",
            software: "The software is mandatory",
          },
        },
        legalPerson: {
          new_legalPerson: "New Legal Entity",
          legalPersons: "Legal Entities",
          delete_legalPersons: "Delete legal entitys",
          search_legalPerson:
            "Search for document, corporate name or fantasy name",
          add_legalPerson: "Add legal entity",
          document: "Document",
          corporateName: "Company name",
          tradingName: "Fantasy name",
          contacts: "Contacts",
          //
          taxRegime: "Tax Regime",
          fiscalContributor: "Fiscal contributor",
          stateRegistration: "Registro de estado",
          municipalInscription: "Municipal inscription",
          foreigner: "Foreigner",
          country: "Country",
          //
          contactType: "Contact type",
          addContact: "Add Contact",
          //
          addresses: "Adresses",
          add_address: "Add address",
          addressType: "Address Type",
          zip_code: "Zip Code",
          city: "City:",
          province: "State:",
          addressDescription: "Street",
          establishmentId: "Establishment",
          neighborhood: "Neighborhood",
          number: "Number",
          complement: "Complement",
          establishment_tooltip: "See establishment",
          confirm: {
            title: "Delete legal entity?",
            description: "This will permanently delete the legal entity ",
            title_plural: "Delete legal entitys?",
            description_plural:
              "This will permanently delete the legal entitys",
          },
          errors: {
            document: {
              nomempty: "The document is mandatory",
              regex: "Invalid CNPJ Number",
            },
            corporateName: "The name of the company is mandatory",
            country: "The contry is mandatory for foreigners",
            establishment: "The establishment is mandatory",
            fiscal_contribuitor: "The fiscal contributor is mandatory",
            tax_regime: "The tax regime is mandatory",
            contact: {
              contactTypeId: "The type of contact is mandatory",
              phone: "Invalid phone number",
              email: "Invalid e-mail",
              site: "Invalid site",
              other: "The value is mandatory",
            },
            address: {
              zipCode: "Invalid CEP",
              addressTypeId: "Select a type",
              city: "The city is mandatory",
              province: "The state is mandatory",
              addressDescription: "The street is mandatory",
              neighborhood: "The neighborhood is mandatory",
              number: "Invalid number",
            },
          },
        },
        languages: {
          languages: "Languages",
          new_language: "New language",
          edit_language: "Edit language",
          search_language: "Search for a language",
          name: "Name",
          code: "Code",
          establishments: "Establishments",
          establishment_tooltip: "See establishments",
          confirm: {
            title: "Delete language?",
            description: "This will permanently delete the language",
          },
          errors: {
            name: "The name is mandatory",
            establishments: "The establishment is mandatory",
          },
        },
        users: {
          users: "Users",
          user: "User",
          new_user: "New user",
          username: "CPF",
          full_name: "Full name",
          user_profiles: "User profiles",
          user_roles: "User roles",
          name: "Name",
          enable: "Enable",
          document: "Document",
          email: "Email",
          password: "Password",
          individual: "Individual",
          resend_mail: "Resend email",
          email_confirmed_label: "Email status",
          email_confirmed_tag: "Confirmed",
          email_not_confirmed_tag: "Not confirmed",
          main_establishments:
            "Main establishment to which the user has a legal link",
          config: "User access settings",
          establishments_access: "Establishments that the user can access",
          establishments_tooltip: "See establishments",
          establishment_tooltip: "See establishment",
          search_user: "Search for a user",
          add_user: "Add user",
          roles_inherited:
            "Profiles that the user has inherited based on the roles they have",
          none_roles_inherited: "No inherited profile found",
          roles_tooltip: "See profiles",
          email_not_confirmed: "Email not confirmed by user",
          email_confirmation_date: "Email confirmation date",
          tooltip_main_estabishment:
            "By default, at the time of creation, the current establishment is defined as the main establishment",
          reset_password: {
            button: "Reset password",
            title:
              "Are you sure you want to reset the password for user {{username}}?",
            title_form: "Reset password for user {{username}}",
            site: "Site",
            site_error: "The site is mandatory",
            sending: "Sending",
            email_sent: "We send a change link of the sign to:",
            confirm: "Reset",
            cancel: "Cancel",
          },
          errors: {
            username: "The CPF is mandatory",
            password: "The password is mandatory",
            individual: "The individual is mandatory",
            email: "The email is mandatory",
            establishments: "Select at least one establishment",
            establishment_standard:
              "The main establishment to which the user has a legal link is mandatory",
            profiles: "At least one user role must be selected",
          },
          confirm: {
            title: "Delete user?",
            description: "This will permanently delete the user",
          },
        },
        establishmentParams: {
          establishmentParams: "General parameters",
          value: "Value",
          description: "Description",
          public: "Public",
          establishmentParamTypeId: "Param type",
          add_establishmentParam: "Add general parameter",
          search_param: "Search for a param",
          new_param: "New param",
          confirm: {
            title: "Delete general parameter?",
            description: "This will permanently delete the general parameter",
          },
          errors: {
            value: "The value is mandatory",
            description: "The description is mandatory",
            establishmentParamTypeId: "The param type is mandatory",
          },
        },
        individuals: {
          individuals: "Individuals",
          individual: "Individual",
          new_individual: "New Individual",
          search_individuals: "Search for an individual",
          add_individual: "Add Individual",
          foreigner: "Foreigner",
          identity_card: "Identity Card",
          name: "Name",
          document: "Document",
          birth_date: "Birth date",
          contacts: "Contacts",
          add_contact: "Add Contact",
          contact_type: "Contact Type",
          addresses: "Addresses",
          add_address: "Add Adress",
          address_type: "Addess Type",
          address_description: "Street",
          number: "Number",
          zip_code: "Zip Code",
          city: "City",
          province: "State",
          complement: "Complement",
          country: "Country",
          neighborhood: "District",
          gender: "Gender",
          family_group: "Family Group",
          add_family_group: "Add Family Group",
          family_individual: "Individual",
          kinship_degree: "Degree of Kinship",
          imports: "Imports",
          confirm: {
            title: "Delete individual?",
            description: "This will permanently delete the individual",
          },
          errors: {
            name: "The name is mandatory",
            document: "The document is mandatory",
            document_regex: "Should be a valid document",
            birth_date: "The birth date is mandatory",
            country: "The country is mandatory",
            gender: "The gender is mandatory",
            contact: {
              contactTypeId: "The type of contact is mandatory",
              phone: "Invalid phone number",
              email: "Invalid e-mail",
              site: "Invalid site",
              other: "The value is mandatory",
            },
            address: {
              addressTypeId: "The address type is mandatory",
              zipCode: "The zip code is mandatory",
              city: "The city is mandatory",
              provincy: "The state is mandatory",
              addressDescription: "The street is mandatory",
              neighborhood: "The neighborhood is mandatory",
              number: "The number is mandatory",
            },
            family_individual: "The individual is mandatory",
            kinship_degree: "The degree of kinship is mandatory",
          },
        },
        individualsImports: {
          imports: "Imports",
          import: "Import",
          search: "Search for an import",
          new_import: "New import",
          file_name: "File Name",
          normalized_file_name: "Normalized File Name",
          status: "Status",
          add_import: "Add import",
          view_individuals: "View Individuals",
          import_individuals: "Importing Individuals",
          import_successfully: "Imported successfully",
          download_model: "Download model",
          search_imports: "Search for an import",
        },
        importList: {
          imports_individuals: "Importing Individuals",
          name: "Name",
          document: "Document",
          status: "Status",
          birth_date: "Birth date",
          gender: "Gender",
          foreigner: "Foreigner",
          yes: "Yes",
          no: "No",
          cns: "CNS",
          address: "Address",
          number: "Number",
          neighborhood: "Neighborhood",
          complement: "Complement",
          city: "City",
          zip_code: "Zip Code",
          country: "Country",
          neighborhood_code: "Neighborhood Code",
          city_ibge_code: "City IBGE Code",
          identity_card: "Identity Card",
          email: "Email",
          phone: "Phone",
          view: "View more",
        },
        documents: {
          documents: "Terms and policies",
          new_document: "New document",
          version: "Version",
          date: "Date",
          document: "Document",
          add_document: "Add document",
          doc_type: "Document type",
          select_doc_type: "Select document type",
          no_docs_selected: "No document type was selected",
          no_docs_version: "Document type does not have any version",
          see_document: "See document",
          table_title: "Version history",
          export_csv: "Export Csv",
          docsVersion: {
            last_version: "Last version",
            new_version: "New version",
            edit_version: "Edit",
          },
          create_document: {
            new_document: "Document",
            add_new_language: "Add new language",
            version: "Version",
            version_date: "Version date",
            add_document: "Add document",
            language: "Language",
            name: "Name",
            content: "Content",
            enable: "Enable",
            language_tooltip: "See language",
          },
          document_modal: {
            version: "Version",
            version_date: "Version date",
            name: "Name",
            language: "Language",
            content: "Content",
          },
          errors: {
            version: "The version is mandatory",
            version_regex: "The version should be a number",
            language: "The language is mandatory",
            name: "The name is mandatory",
            content: "The content is mandatory",
          },
        },
        establishments: {
          establishments: "Establishments",
          establishment: "Establishment",
          new_establishment: "New establishment",
          name: "Name",
          ansCode: "Operator Code in ANS",
          integration: "Customer establishment code",
          domain_admin: "Admin panel domain",
          collector_host: "Collector host",
          parent_host: "Host collector cloud",
          real_time_host: "Real time host",
          search_establishment: "Search for a name",
          add_establishment: "Add establishment",
          company: "Company",
          enable: "Enable",
          config_on_premise: "On premise environment settings",
          languages: "Languages",
          sync: "Sync environment on premise",
          sync_beneficiaries: "Synchronize beneficiaries",
          see_establishments: "See establishment on premise",
          see_params: "See Parameters on premise",
          see_generics: "See Generics on premise",
          see_health: "Synchronized Health Plans",
          see_medic_specialties_on_premise: "See medical specialties",
          see_medical_practice_areas: "See areas of medical practice",
          see_provider_types: "See provider types",
          see_professional_councils: "See professional councils",
          see_extensions: "See extensions on premise",
          synced: "Synced successfully",
          jobs: "Jobs",
          crmDomain: "CRM domain",
          languages_tooltip: "See languages",
          extensions_on_premise: "Extensions on premise",
          generics_on_premise: "Generics on premise",
          params_on_premise: "Parameters on premise",
          establishment_on_premise: "Establishment on premise",
          health_on_premise: "Synchronized Health Plans",
          medic_specialties_on_premise: "Medical specialties",
          provider_types_on_premise: "Provider types",
          professional_councils_on_premise: "Professional councils",
          medical_practice_areas_on_premise: "Areas of medical practice",
          domain: "Domain",
          filemanager_host: "Filemanager host in cloud",
          softwares: "Applications",
          legal_entity: "Legal Entity",
          confirm: {
            title: "Delete establishment?",
            description: "This will permanently delete the establishment",
          },
          errors: {
            name: "The name is mandatory",
            company: "The company is mandatory",
            languages: "Select at least one language",
            integration:
              "The establishment code in the client must be greater than 1",
            domain_admin: "The admin panel domain is mandatory",
            collector_https:
              "The collector host must starts with 'http://' or 'https://'",
            parent_https:
              "The host collector cloud must starts with 'http://' or 'https://'",
            real_time_https:
              "The real time host must starts with 'http://' or 'https://'",
            domain_admin_https:
              "The admin panel domain must starts with 'http://' or 'https://'",
            filemanager_host_https:
              "The filemanager host in cloud must starts with 'http://' or 'https://'",
            ans_code: "The operator code in ANS is mandatory",
            filemanager_host: "The filemanager host in cloud is mandatory",
            parent_host: "The host collector cloud is mandatory",
            collector_host: "The collector host is mandatory",
            real_time_host: "The real time host is mandatory",
          },
        },
        countries: {
          countries: "Countries",
          country: "Country",
          search_country: "Search for a name",
          add_country: "Add country",
          new_country: "New country",
          name: "Name",
          code: "Code",
          enable: "Enable",
          ddi_code: "DDI code",
          phone_mask: "Phone mask",
          phone_mask_help:
            "The mask must be sent as per example: (00)000000000, where the 'zero' represents the telephone numbers",
          confirm: {
            title: "Delete country?",
            description: "This will permanently delete the country",
          },
          errors: {
            code: "The code is mandatory",
            name: "The name is mandatory",
          },
        },
        cities: {
          cities: "Cities",
          city: "City",
          new_city: "New city",
          name: "Name",
          provincy: "state",
          enable: "Enable",
          ibge_code: "IBGE Code",
          correio_code: "Post code",
          search_city: "Search for a city",
          add_city: "Add City",
          errors: {
            name: "The name is mandatory",
            ibge_code: "The IBGE Code is mandatory",
            correio_code: "The Post Code is mandatory",
            provincy: "The state is mandatory",
          },
        },
        neighborhoods: {
          neighborhoods: "Neighborhoods",
          neighborhood: "Neighborhood",
          new_neighborhood: "New neighborhood",
          search_neighborhoods: "Search for a neighborhood",
          add_neighborhood: "Add neighborhood",
          name: "Name",
          code: "Correios code",
          city: "City",
          enable: "Enable",
          errors: {
            name: "The name is mandatory",
            city: "The city is mandatory",
          },
        },
        companies: {
          companies: "Companies",
          company: "Company",
          search_company: "Search for a company",
          add_company: "Add company",
          new_company: "New company",
          name: "Name",
          code: "Code",
          enable: "Enable",
          company_group: "Company Group",
          confirm: {
            title: "Delete company?",
            description: "This will permanently delete the company",
          },
          errors: {
            code: "The code is mandatory",
            name: "The name is mandatory",
            company_group: "The company group is mandatory",
          },
        },
        provinces: {
          provinces: "States",
          province: "State",
          search_province: "Search for a state",
          add_province: "Add state",
          new_province: "New state",
          name: "Name",
          code: "Code",
          enable: "Enable",
          country: "Country",
          confirm: {
            title: "Delete state?",
            description: "This will permanently delete the state",
          },
          errors: {
            code: "The code is mandatory",
            name: "The name is mandatory",
            country: "The country is mandatory",
          },
        },
        banks: {
          banks: "Banks",
          bank: "Bank",
          search_bank: "Search for a name",
          add_bank: "Add bank",
          new_bank: "New bank",
          name: "Name",
          short_name: "Short Name",
          ispb_code: "ISPB Code",
          bacen_code: "Bacen Code",
          ibge_city_code: "IBGE City Code",
          confirm: {
            title: "Delete bank?",
            description: "This will permanently delete the bank",
          },
          errors: {
            name: "The name is mandatory",
            ispb_code: "The ISPB code is mandatory",
          },
        },
        applications: {
          applications: "Applications",
          application: "Application",
          new_application: "New application",
          add_application: "Add Application",
          search: "Search for a application",
          name: "Name",
          type: "Type",
          status: "Status",
          domains: "Domains",
          domain: "Domain",
          domain_type: "Domain type",
          add_domain: "Add domain",
          front_config: "Theme configuration JSON",
          confirm: {
            title: "Delete application?",
            description: "This will permanently delete the application",
          },
          errors: {
            name: "The name is mandatory",
            type: "The type is mandatory",
            status_type: "The status is mandatory",
            domain_type: "The domain type is mandatory",
            front_config: "The theme configuration JSON is mandatory",
            description: "The description is mandatory",
            file: "The file is mandatory",
            domain: "The domain is mandatory",
            domains: "At least one domain is mandatory",
          },
        },
        banners: {
          banners: "Banners",
          banner: "Banner",
          new_banner: "New banner",
          search: "Search for a banner",
          add_banner: "Add banner",
          name: "Name",
          title: "Title",
          alternative_text: "Alternative text",
          display_order: "Display order",
          language: "Language",
          site_apps: "Sites or Apps",
          banner_locals: "Locations where the banner will be displayed",
          active: "Active",
          inactive: "Inactive",
          enable: "Enable",
          status: "Status",
          media_file: "Media file",
          media_file_mobile: "Mobile Media File",
          application: "Application",
          banner_local: "Banner display location",
          targetingLink: "Redirect link",
          confirm: {
            title: "Delete banner?",
            description: "This will permanently delete the banner",
          },
          errors: {
            name: "The name is mandatory",
            targetingLink: "The link is invalid",
            title: "The title is mandatory",
            alternative_text: "The alternative text is mandatory",
            display_order: "The display order is mandatory",
            language: "The language is mandatory",
            site_apps: "At least one site or app is mandatory",
            banner_locals: "At least one location is mandatory",
            file: "The file is mandatory",
            display_order_negative: "Display order cannot be less than 0",
          },
          recommendedSize:
            "Recommended dimensions for desktop {{desktopWidth}}x{{desktopHeight}} pixels. Dimensions recommended for application/mobile {{appWidth}}x{{appHeight}} pixels.",
        },
        faq: {
          faqs: "FAQ areas",
          faq: "FAQ area",
          new_faq: "New FAQ area",
          add_faq: "Add FAQ area",
          search: "Search for a FAQ area",
          question_answer: "Questions and answers",
          active: "Active",
          inactive: "Inactive",
          status: "Status",
          name: "Name",
          enable: "Enable",
          whatsapp_phone_number: "Whatsapp phone number",
          mail: "Email",
          site_app: "Sites or Apps",
          questions_answers: "Questions and Answers",
          display_order: "Display order",
          confirm: {
            title: "Delete the FAQ?",
            description: "This will permanently delete the FAQ",
          },
          errors: {
            name: "The name is mandatory",
            site_app: "At least on site or app is mandatory",
            phone_email: "The whatsapp phone number or email is mandatory",
            mail: "Provide a valid email",
            display_order: "The display order is mandatory",
          },
        },
        questionsAnswers: {
          questions_answers: "Questions and Answers",
          question_answer: "Question and Answer",
          search: "Search by a question and answer",
          add_questions_answers: "Add a question and answer",
          new_question_answer: "New question and answer",
          display_order: "Display order",
          language: "Language",
          question: "Question",
          answer: "Answer",
          enable: "Enable",
          name: "Name",
          status: "Status",
          active: "Active",
          inactive: "Inactive",
          was_useful_count: "'Was useful' count",
          confirm: {
            title: "Delete the question and answer",
            description: "This will permanently delete the question and answer",
          },
          errors: {
            display_order: "The display order is mandatory",
            question: "The question is mandatory",
            answer: "The answer is mandatory",
            language: "The language is mandatory",
          },
        },
        news: {
          all_news: "News",
          news: "News",
          search: "Search for news",
          add_news: "Add news",
          new_news: "New news",
        },
        notifications: {
          notifications: "Notifications",
          notification: "Notification",
          add_notification: "Add notification",
          new_notification: "New notification",
          search: "Search for a notification",
          type: "Notification type",
          status: "Notification status",
          title: "Title",
          content: "Content",
          site_app: "Sites or Apps",
          language: "Language",
          language_tooltip: "View language",
          contents: "Notification batch contents",
          add_new_content: "Add content",
          filters: "Notification batch filters",
          add_new_filter: "Add filter",
          minimum_age: "Minimum age",
          maximum_age: "Maximum age",
          payer: "Payer",
          holder: "Holder",
          both: "Both",
          is_payer: "Is payer",
          is_not_payer: "Is not payer",
          is_holder: "Is holder",
          is_not_holder: "Is not holder",
          profiles: "Profiles",
          gender: "Gender",
          users: "Users",
          start_publish: "Start of publication",
          finish_publish: "End of publication",
          registered: "Registered",
          generating: "Generating",
          generated: "Generated",
          canceled: "Canceled",
          error: "Error generating",
          push_notification: "Generate PushNotification",
          rule_codes: "Rule codes",
          rule_codes_helper: "Enter rule codes separated by commas",
          date_start_rule: "Rule start date",
          content_info: "You can add the variables: {{variables}}",
          cleanFilters: {
            title: "Do you really want to change the notification type?",
            description:
              "When changing the notification type, all filters will be removed",
            confirm: "Yes, change",
            cancel: "Cancel",
          },
          confirm: {
            title: "Delete notification?",
            description: "This will permanently delete the notification",
          },
          errors: {
            type: "The notification type is mandatory",
            site_app: "At least one site or app is mandatory",
            contents: "At least one content is mandatory",
            language: "The language is mandatory",
            title: "The title is mandatory",
            content: "The content is mandatory",
            notification_batch_filter:
              "If the 'Payer' and 'Holder' fields have the value 'Both', at least one of the fields below is mandatory",
            variables:
              "A variable that does not exist in the list was added to one of the contents",
            rule_codes: "The rule codes are mandatory",
            date_start_rule: "The rule start date is mandatory",
          },
        },
        notifiedUsers: {
          notified_users: "Notified Users",
          search: "Search for a user",
          user: "User",
          read_date: "Read date",
          acceptance_date: "Acceptance date",
          acceptance_data: "Acceptance data",
        },
        extensions: {
          extensions: "Extensions",
          search_name: "Search for a name",
          extension: "Extension",
          new_extension: "New extension",
          presentation_order: "Presentation order",
          software: "Software",
          name: "Name",
          title: "Title",
          description: "Description",
          tags: "Tags",
          files: "Files",
          file: "File",
          media_files: "Media files",
          add_media_file: "Add file",
          media_type_code: "Media type",
          extension_dependencies: "Has dependency on extensions",
          extension_targets: "Available for application types",
          enable: "Enable",
          is_required_setup: "Requires setup for installation",
          status: "Status",
          active: "Active",
          inactive: "Inactive",
          actions: {
            default_settings: "Default settings",
            price_rules: "Price rules",
          },
          defaultSettings: {
            default_settings: "Default settings for: {{extension}}",
            default_setting: "Default setting",
            identifier: "Identifier",
            property: "Property",
            value: "Value",
            description: "Description",
            search_identifier: "Search for identifier",
            add_default_setting: "Add default setting",
            new_default_setting: "New default setting",
            is_public: "The default extension setting is public",
            errors: {
              identifier: "The identifier is mandatory",
              value: "The value is mandatory",
              property: "The property is mandatory",
              description: "The description is mandatory",
            },
            confirm: {
              title: "Delete price default configuration?",
              description:
                "This will permanently delete the default configuration",
            },
          },
          priceRules: {
            price_rules: "Price rules for: {{extension}}",
            price_rule: "Price rule",
            search_type: "Search for a type",
            add_price_rule: "Add Price Rule",
            type: "Type",
            initial_date: "Start of term",
            end_date: "End of validity",
            value: "Value",
            errors: {
              type: "The type is mandatory",
              initial_date: "The start of term is mandatory",
              end_date: "The end of validity is mandatory",
              value: "The value is mandatory",
            },
            confirm: {
              title: "Delete price rule?",
              description: "This will permanently delete the price rule",
            },
          },
          errors: {
            display_order: "The presentation order is mandatory",
            software: "The software is mandatory",
            name: "The name is mandatory",
            title: "The title is mandatory",
            tags: "At least one tag is mandatory",
            file: "The file is mandatory",
            file_type:
              "Only .jpg, .jpeg, .png and .webp are file types supported",
            file_size: "The allowed maximum size is 5MB",
            extension_targets: "At least one application is mandatory",
          },
        },
        cardsTemplates: {
          cards_templates: "Cards Templates",
          card_template: "Card Template",
          search: "Search for a template",
          new_card_template: "New Card Template",
          add_card_template: "Add Card Template",
          initial_date: "Start of term",
          end_date: "End of term",
          health_plan: "Health plan",
          enable: "Enable",
          establishment_param_type: "Forward establishment parameter type",
          establishment_param_type_back:
            "Establishment parameter type for back",
          confirm: {
            title: "Delete card template?",
            description: "This will permanently delete the card template",
          },
          errors: {
            initial_date: "The start of term is mandatory",
            end_date: "The end of term is mandatory",
            health_plan: "The health plan is mandatory",
            establishment_param_type:
              "The forward establishment parameter type is mandatory",
            establishment_param_type_back:
              "The establishment parameter type for back is mandatory",
          },
        },
        companyGroups: {
          company_groups: "Company Groups",
          company_group: "Company Group",
          new_company_group: "New company group",
          search_company_groups: "Search for a company group",
          add_company_group: "Add company group",
          name: "Name",
          code: "Code",
          is_main: "Main",
          enable: "Enable",
          filter_restriction_type: "Filter Restriction Type",
          confirm: {
            title: "Delete company group?",
            description: "This will permanently delete the company group",
          },
          errors: {
            name: "The name is mandatory",
            code: "The code is mandatory",
            filter_restriction_type: "The filter restriction type is mandatory",
          },
        },
        paramDefaultEstablishment: {
          param_default_establishment: "Standard establishment parameters",
          param_default: "Standard establishment parameter",
          new_param_default: "New establishment default parameter",
          search_param: "Search for a parameter",
          add_param: "Add default parameter",
          establishment_param_type: "Establishment parameter type",
          value: "Value",
          description: "Description",
          confirm: {
            title: "Delete standard establishment parameter",
            description:
              "This will permanently delete the standard establishment parameter",
          },
          errors: {
            param_type: "The establishment parameter type is mandatory",
            value: "The value is mandatory",
            description: "The description is mandatory",
          },
        },
        holidays: {
          holidays: "Holidays",
          holiday: "Holiday",
          new_holiday: "New holiday",
          search_holiday: "Search for a holiday",
          add_holiday: "Add holiday",
          name: "Name",
          description: "Description",
          holiday_type: "Holiday type",
          date: "Date",
          import_holidays: "Import national holidays",
          filter: "Filter",
          confirm: {
            title: "Delete holiday",
            description: "This will permanently delete the holiday",
          },
          errors: {
            name: "The name is mandatory",
            description: "The description is mandatory",
            holiday_type: "The holiday type is mandatory",
            date: "The date is mandatory",
          },
        },
        individualValidation: {
          individual_validations: "Registration Validation PF",
          search: "Search for a registration",
          individual: "Individual",
          name: "Name",
          document: "CPF",
          status: "Status",
          all: "All",
          approved: "Approved",
          disapproved: "Disapproved",
          pending: "Pending Approval",
          created: "Date created",
          photos_sent: "Photos Sent",
          approve_registrations: "Registration Approval",
          not_found: "Oops, registration not found!",
          no_registrations:
            "There are no more registrations to be validated, please try again later.",
          birth_date: "Birthdate",
          email: "Email",
          whatsapp_number: "WhatsApp number",
          approved_by: "Aproved by",
          approved_at: "Aproved at",
          disapproved_by: "Disapproved by",
          disapproved_at: "Disapproved at",
          copy: "Copy",
          copied_successfully: "Copied successfully",
          actions: {
            approved: "Registration approved successfully!",
            disapproved: "Registration disapproved successfully!",
            disapprove_modal: {
              title: "Do you really want to disapprove the registration?",
              description: "This will permanently disapprove the registration.",
              cancel: "Cancel",
              confirm: "Yes, disapprove",
            },
            disapprove: "Disapprove",
            approve: "Approve",
          },
          photo_item: {
            person: "Person",
            front_doc: "Front Document",
            back_doc: "Document Back",
            person_doc: "Person with the Document",
            error: "Oops, there was an error loading the photo!",
          },
        },
      },
      additionalSettings: {
        additionalSettings: "Additional settings",
        search: "Search for a setting",
      },
      extensionsStore: {
        title: "Explore the maximum potential of your plan",
        subtitle:
          "Check out the extensions available for your plan and get an even more complete solution for your business.",
        no_data: "No extensions found!",
        no_data_install:
          "Check your already installed extensions. There are no new extensions to display.",
        no_data_installed:
          "Check what's available in store. There are no extensions installed to display.",
        installed_successfully: "Extension installed successfully",
      },
      noAccess: {
        message:
          "Oops! It looks like you don't have permission to access this page!",
        button: "Back to home",
      },
      settings: {
        settings: "Settings",
        general: "General",
        cache: "Cache",
        "4uBus": {
          configs: {
            configs: "General settings",
            config: "General setting",
            new_config: "New general setting",
            search: "Search for a general setting",
            add_config: "Add general setting",
            host_4ubus: "4uBus host",
            host_aws: "AWS host",
            establishment: "Establishment",
            confirm: {
              title: "Delete general setting?",
              description: "This will permanently delete the general setting",
            },
            errors: {
              host_4ubus: "The 4uBus host is mandatory",
              host_aws: "The AWS host is mandatory",
              host_4ubus_https:
                "The 4uBus host must starts with 'http://' or 'https://'",
              host_aws_https:
                "The AWS host must starts with 'http://' or 'https://'",
            },
          },
          ipm: {
            generalSettings: {
              general_settings: "IPM general settings",
              general_setting: "IPM general setting",
              new_general_setting: "New IPM general setting",
              search: "Search for a general setting",
              add_general_setting: "Add general setting",
              host: "Host",
              establishment: "Establishment",
              ipm_user: "IPM user",
              ipm_password: "IPM password",
              token_4ubus: "4uBus token",
              token_copy: "Copy token",
              token_copied: "4uBus token copied successfully",
              itens_per_batch: "Itens per batch",
              total_batches: "Total batches",
              heart_beat_time_minutes: "Batch verification time in minutes",
              establishment_codes:
                "Code of sending establishments (separated by comma)",
              confirm: {
                title: "Delete general setting?",
                description: "This will permanently delete the general setting",
              },
              errors: {
                host: "The host is mandatory",
                ipm_user: "The IPM user is mandatory",
                ipm_password: "The IPM password is mandatory",
                token_4ubus: "The 4uBus token is mandatory",
                host_https: "The host must starts with 'http://' or 'https://'",
                itens_per_batch: "The itens per batch are mandatories",
                total_batches: "The total batches is mandatory",
                heart_beat_time_minutes:
                  "The batch verification time in minutes is mandatory",
                establishment_codes:
                  "The code of sending establishments is mandatory",
              },
            },
          },
          zScan: {
            examSendRule: {
              exam_send_rules: "Exam send rules",
              exam_send_rule: "Exam send rule",
              add_exam_send_rule: "Add exam send rule",
              new_exam_send_rule: "New exam send rule",
              target_machine: "Target machine",
              proc_classification_integration_id: "Procedure Classification Id",
              initial_date: "Initial date",
              end_date: "End date",
              enable: "Enable",
              status: "Status",
              active: "Active",
              inactive: "Inactive",
              errors: {
                target_machine: "The target machine is mandatory",
                proc_classification_integration_id:
                  "The procedure classification id is mandatory",
                initial_date: "The initial date is mandatory",
                end_date: "The end date is mandatory",
                initial_date_bigger:
                  "The initial date cannot be bigger than end",
              },
              confirm: {
                title: "Delete exam send rule?",
                description: "This will permanently delete the exam send rule",
              },
            },
          },
          externalExamPrescription: {
            rules: {
              rules: "External Exam Prescription Rules",
              rule: "External Exam Prescription Rule",
              new_rule: "New external exam prescription rule",
              add_rule: "Add external exam prescription rule",
              search: "Search for an external exam prescription rule",
              medical_procedure_type_code: "Medical Procedure Type Code",
              sector_code: "Sector Code",
              exec_physician_code: "Executing Physician Code",
              patient_insurance_code: "Patient Insurance Code",
              initial_date: "Initial Date",
              end_date: "End Date",
              is_exception: "Is Exception",
              yes: "Yes",
              no: "No",
              if: "If",
              then: "Then",
              exception: "Exception (Does not generate prescription)",
              errors: {
                initial_date: "The initial date is mandatory",
                end_date: "The end date is mandatory",
                initial_date_bigger:
                  "The initial date cannot be bigger than end",
                medical_procedure_type_code:
                  "The medical procedure type code is mandatory",
                sector_code: "The sector code is mandatory",
                execPhysicianCode: "The executing physician code is mandatory",
                max_medical_procedure_type_code:
                  "The medical procedure type code cannot be longer than 3 characters",
                max_sector_code:
                  "The sector code cannot be longer than 5 characters",
                max_exec_physician_code:
                  "The performing physician code cannot be longer than 10 characters",
                max_patient_insurance_code:
                  "The patient's health insurance code cannot be longer than 5 characters",
              },
              confirm: {
                title: "Delete external exam prescription rule?",
                description:
                  "This will permanently delete the external exam prescription rule",
              },
            },
          },
        },
        crm: {
          ticketAutoFinishRules: {
            ticket_auto_finish_rules: "Ticket (Card) Finalization Rules",
            ticket_auto_finish_rule: "Ticket (Card) Finalization Rule",
            add_ticket_auto_finish_rule: "Add ticket (card) finalization rule",
            new_ticket_auto_finish_rule: "New ticket (card) finalization rule",
            search: "Search for a ticket (card) finalization rule",
            message: "Message",
            minutes: "Minutes",
            initial_date: "Initial date1",
            end_date: "End date",
            finish_rule: "Finish",
            finish: "Finished",
            finished: "Yes",
            not_finished: "No",
            ticket_type: "Ticket (card) type",
            ticket_channel: "Board",
            ticket_channel_step: "Board list",
            ticket_workspace: "Workspace",
            message_helper:
              "The variable {{protocolNumber}} will be replaced by the protocol number assigned to the ticket (card)",
            confirm: {
              title: "Delete ticket (card) finalization rule?",
              description:
                "This will permanently delete the ticket (card) finalization rule",
            },
            errors: {
              minutes: "The minutes are mandatories",
              max_minutes: "The value could not be bigger than 720",
              ticket_type: "The ticket (card) type is mandatory",
              ticket_channel: "The board is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              end_date_less:
                "The end date cannot be less than the initial date",
              message: "The message is mandatory",
              ticket_workspace: "The workspace is mandatory",
              ticket_channel_step: "The board list is mandatory",
            },
          },
          protocolsDefault: {
            protocols_default: "Standard Guidance Protocols",
            protocol_default: "Standard Guidance Protocol",
            new_protocol_default: "New standard guidance protocol",
            add_protocol_default: "Add standard guidance protocol",
            search: "Search for a standard guidance protocol",
            title: "Title",
            message: "Message",
            workspaces: "Workspaces",
            file: "File",
            description: "Description",
            media_files: "Media files",
            add_media_file: "Add file",
            enable: "Enable",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete standard guidance protocol?",
              description:
                "This will permanently delete the standard guidance protocol",
            },
            errors: {
              title: "The title is mandatory",
              message: "The message is mandatory",
              workspaces: "At least one workspace is mandatory",
              description: "The description is mandatory",
              file: "The file is mandatory",
              media_files: "At least one file is mandatory",
              min_description: "The description is mandatory",
              max_description:
                "The description cannot be longer than 1000 characters",
            },
          },
          events: {
            events: "Events",
            event: "Event",
            new_event: "New event",
            add_event: "Add event",
            search: "Search for an event",
            name: "Name",
            event_type: "Event type",
            code: "Code",
            enable: "Enable",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            manage_event_types: "Manage event types",
            ticket_automations: "Automations",
            confirm: {
              title: "Delete event?",
              description: "This will permanently delete the event",
            },
            errors: {
              name: "The name is mandatory",
              event_type: "The event type is mandatory",
              code: "The code is mandatory",
            },
          },
          eventTypes: {
            event_types: "Event Types",
            event_type: "Event Type",
            new_event_type: "New event type",
            add_event_type: "Add event type",
            search: "Search for an event type",
            name: "Name",
            enable: "Enable",
            code: "Code",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete event type?",
              description: "This will permanently delete the event type",
            },
            errors: {
              name: "The name is mandatory",
            },
          },
          externalProcessType: {
            external_process_types: "Types of External Processes",
            external_process_type: "External Process Type",
            add_external_process_type: "Add external process type",
            new_external_process_type: "New external process type",
            search: "Search for an external process type",
            name: "Name",
            confirm: {
              title: "Delete external process type?",
              description:
                "This will permanently delete the external process type",
            },
            errors: {
              name: "The name is mandatory",
            },
          },
        },
        preventMedicine: {
          workGroupTypes: {
            work_group_types: "Work Group Types",
            work_group_type: "Work Group Type",
            new_work_group_type: "New work group type",
            add_work_group_type: "Add work group type",
            enable: "Enable",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            languages: "Languages",
            language: "Language",
            add_language: "Add language",
            name: "Name",
            description: "Description",
            search: "Search by a work group type",
            confirm: {
              title: "Delete work group type?",
              description: "This will permanently delete the work group type",
            },
            errors: {
              name: "The name is mandatory",
              language: "The language is mandatory",
            },
          },
          healthProgramTypes: {
            health_program_types: "Health Program Types",
            health_program_type: "Health Program Type",
            new_health_program_type: "New health program type",
            add_health_program_type: "Add Health Program Type",
            search: "Search for a health program type",
            name: "Name",
            language: "Language",
            description: "Description",
            enable: "Enable",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            characters: "characters",
            add_language: "Add language",
            confirm: {
              title: "Delete health program type?",
              description:
                "This will permanently delete the health program type",
            },
            errors: {
              name: "The name is mandatory",
              description: "Description cannot be longer than 500 characters",
            },
          },
        },
        generalSettings: {
          healthChecks: {
            health_checks: "4uHub Microservices Health Checks",
            health_check: "4uHub Microservices Health Check",
            new_health_check: "New 4uHub microservices health check",
            add_health_check: "Add 4uHub Microservices Health Check",
            search: "Search for a 4uHub microservices health check",
            name: "Name",
            uri: "URI",
            establishment: "Establishment",
            payload: "Failure payload",
            restored_payload: "Restore payload",
            webhook_url: "Webhook URL",
            enable: "Permitir",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete health check?",
              description: "This will permanently delete the health check",
            },
            errors: {
              name: "The name is mandatory",
              uri: "The URI is mandatory",
              payload: "The failure payload is mandatory",
              restored_payload: "The restore payload is mandatory",
              webhook_url: "The webhook url is mandatory",
              webhook_url_http:
                "The webhook url must starts with 'http://' or 'https://'",
              establishment: "The establishment is mandatory",
            },
          },
        },
        schedule: {
          scheduleCheckIn: {
            schedule_check_in: "Check In",
            add_schedule_check_in: "Add check in",
            new_schedule_check_in: "New check in",
            meters_approximation: "Approximation in meters",
            time: "Time in minutes",
            meters: "m.",
            min: "min.",
            latitude: "Latitude",
            longitude: "Longitude",
            help_time:
              "Enter the time in minutes before the appointment, when it will be possible to check in for an appointment",
            help_meters:
              "Please inform the approximate distance in meters before the appointment, and you will be able to check in for an appointment",
            confirm: {
              title: "Delete check in?",
              description: "This will permanently delete the check in",
            },
            errors: {
              meters_approximation: "The approximation in meters is mandatory",
              time: "The time in minutes is mandatory",
              latitude: "The latitude is mandatory",
              longitude: "The longitude is mandatory",
            },
          },
          scheduleConfirmation: {
            schedule_confirmation: "Schedule Confirmation",
            add_schedule_confirmation: "Add schedule confirmation",
            new_schedule_confirmation: "New schedule confirmation",
            time: "Time in minutes",
            min: "min.",
            help: "Enter the time in minutes before the appointment, which will allow you to confirm an appointment",
            confirm: {
              title: "Delete schedule confirmation?",
              description:
                "This will permanently delete the schedule confirmation",
            },
            errors: {
              time: "The time in minutes is mandatory",
            },
          },
        },
        system: {
          executeSql: {
            execute_sql: "Execute SQL",
            execute_successfully: "SQL executed successfully",
            clean: "Clean",
            execute: "Execute",
            errors: {
              sql: "The SQL code is mandatory",
            },
          },
        },
        siteApp: {
          siteAppCheckIn: {
            site_app_check_in: "Check-In of Digital PA Triage",
            add_site_app_check_in: "Add check in",
            new_site_app_check_in: "New check in",
            meters_approximation: "Meters approximation",
            patient_encounter_physician_code:
              "Physician code for the patient's encounter",
            sector_accommodation_type: "Sector accommodation type",
            patient_encounter_origin_code:
              "Origin code of the patient's encounter",
            time: "Time in minutes",
            meters: "m.",
            min: "min.",
            latitude: "Latitude",
            longitude: "Longitude",
            help_time:
              "Enter the time in minutes before the appointment, which will be allowed to check in for a schedule",
            help_meters:
              "Enter the meters approximation before the appointment, which will be allowed to check in for a schedule",
            standard_service_sector_code: "Standard Service Sector Code",
            standard_service_unit: "Standard Service Unit",
            confirm: {
              title: "Delete check in?",
              description: "This will permanently delete the check in",
            },
            errors: {
              meters_approximation: "Meters approximation is required",
              time: "Time in minutes is required",
              latitude: "Latitude is required",
              longitude: "Longitude is required",
              standard_service_sector_code:
                "The standard service sector code is required",
              standard_service_unit: "The standard service unit is required",
            },
          },
          siteAppConfirmation: {
            site_app_confirmation: "Schedule Confirmation",
            add_site_app_confirmation: "Add schedule confirmation",
            new_site_app_confirmation: "New schedule confirmation",
            time: "Time in minutes",
            min: "min.",
            help: "Enter the time in minutes before the appointment, which will be allowed to confirm a schedule",
            confirm: {
              title: "Delete schedule confirmation?",
              description:
                "This will permanently delete the schedule confirmation",
            },
            errors: {
              time: "Time in minutes is required",
            },
          },
        },
      },
      extensionSettings: {
        extension_settings:
          "Extension settings for '{{extension}}' in '{{siteApp}}'",
        add_extension_config: "Add configuration to extension",
        extension_config: "Extension configuration",
        new_extension_config: "New extension configuration",
        search_config: "Search for a configuration",
        identifier: "Identifier",
        property: "Property",
        value: "Value",
        description: "Description",
        is_public: "Extension configuration is public",
        save_in_establishment:
          "Save settings for all merchant websites and apps",
        confirm: {
          title: "Delete extension configuration?",
          description:
            "This will permanently delete the extension's configuration",
        },
        errors: {
          identifier: "The identifier is mandatory",
          property: "The property is mandatory",
          value: "The value is mandatory",
          description: "The description is mandatory",
        },
      },
      jobs: {
        title: "You have been redirected to the Jobs dashboard",
        subtitle: "To access again click the button below",
        button: "Access",
      },
      "4uHcp": {
        hcp: {
          healthPrograms: {
            health_programs: "Health Program",
            health_program: "Health Program",
            new_health_program: "New health program",
            search: "Search by a health program",
            add_health_program: "Add health program",
            languages: "Languages",
            health_program_type: "Type of health program",
            health_program_work_groups: "Work groups",
            applications: "Applications",
            work_groups: "Work groups",
            add_language: "Add language",
            name: "Name",
            language: "Language",
            description: "Description",
            participants: "Participants",
            imports: "Imports",
            fundraising: "Fundraising",
            confirm: {
              title: "Delete health program?",
              description: "This will permanently delete the health program",
            },
            errors: {
              health_program_type: "The health program type is mandatory",
              site_apps: "At least one application is mandatory",
              languages: "At least one language is mandatory",
              language: "The language is mandatory",
              name: "The name is mandatory",
            },
          },
          healthProgramsContentRules: {
            health_programs_content_rules: "Health program content rules",
            health_program_content_rule: "Health program content rule",
            new_health_program_content_rule: "New health program content rule",
            search: "Search by a health program content rule",
            add_health_program_content_rule: "Add health program content rule",
            content_view_type_hcp: "Content View Type",
            name: "Name",
            health_program: "Health program",
            initial_date: "Initial date",
            end_date: "End date",
            contents: "Rule contents",
            confirm: {
              title: "Delete health program content rule?",
              description:
                "This will permanently delete the health program content rule",
            },
            errors: {
              name: "The name is mandatory",
              health_program: "The health program is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              initial_date_greater:
                "The end date cannot be less than the initial date",
              content_view_type_hcp: "The content view type is mandatory",
            },
          },
          rulesContents: {
            rules_contents: "Rules Contents",
            rule_content: "Rule Content",
            new_rule_content: "New rule content",
            search: "Search by a rule content",
            add_rule_content: "Add rule content",
            title: "Title",
            publication_date: "Publication date",
            content_status: "Content status",
            published: "Published",
            not_published: "Not published",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete rule content?",
              description: "This will permanently delete the rule content rule",
            },
          },
          workGroups: {
            work_groups: "Work Groups",
            work_group: "Work Group",
            new_work_group: "New work group",
            add_work_group: "Add work group",
            search: "Search for a work group",
            name: "Name",
            languages: "Languages",
            add_language: "Add language",
            language: "Language",
            description: "Description",
            work_group_type: "Work group type",
            work_group_members: "Work group members",
            confirm: {
              title: "Delete work group?",
              description: "This will permanently delete the work group",
            },
            errors: {
              work_group_type: "The work group type is mandatory",
              languages: "At least one language is mandatory",
              name: "The name is mandatory",
              language: "The language is mandatory",
            },
          },
          participants: {
            participants: "Participants",
            participant: "Participant",
            new_participant: "New participant",
            add_participant: "Add participant",
            search: "Search for a participant",
            name: "Name",
            document: "CPF",
            status: "Status",
            history: "Historic",
            active: "Active",
            formerMember: "Former Member",
            membersHistory: {
              title: "Historic",
              initialDate: "entry date",
              endDate: "Exit date",
              noEndDate: "No end date",
            },
            confirm: {
              title: "Delete participant?",
              description: "This will permanently delete the participant",
            },
          },
          fundraising: {
            fundraising: "Fundraising",
            healthProgram: "Health program",
            captation: "Captation",
            new_captation: "New captation",
            search: "Search for a fundraising",
            name: "Name",
            document: "CPF",
            health_program: "Health program",
            captation_type: "Captation type",
            captation_status: "Captation status",
            approve_captation: "Approve captation",
            approved_successfully: "Captation approved successfully",
            disapprove_captation: "Disapprove captation",
            disapproved_successfully: "Captation disapproved successfully",
            denial_reason: "Reason (optional)",
            cancel: "Cancel",
            disapprove: "Disapprove",
          },
          healthProgramImports: {
            imports: "Imports",
            healthProgram: "Health program",
            import: "Import",
            add_import: "Add import",
            new_import: "New import",
            search: "Search for an import",
            file_name: "File name",
            import_person_name: "Import person name",
            origin_of_demand: "Origin of demand",
            error_message: "Error message",
            status: "Status",
            download_template: "Download template",
            health_program: "Health program",
            errors: {
              file: "The file is mandatory",
              import_person_name: "The import person name is mandatory",
              health_program: "The health program is mandatory",
            },
          },
        },
      },
      "4uBus": {
        register: {
          accessTokens: {
            access_tokens: "Access tokens",
            access_token: "Access token",
            new_access_token: "New access token",
            add_access_token: "Add access token",
            search: "Search for an access token",
            name: "Name",
            privileges: "Privileges",
            token_public: "Public token",
            copied_token: "Public token copied successfully",
            copy: "Copy public token",
            created: "Creation date",
            individual: "Individual",
            legal_entity: "Legal entity",
            software: "Software",
            justification: "Justification",
            description: "Description",
            is_integration: "Integration token",
            restriction_rule_profiles: "Restriction rule profiles",
            extension: "Extension",
            profiles: "Profiles",
            no_extensions_found:
              "It is not possible to register an Access Token, as it does not have any Bus endpoint extensions installed.",
            confirm: {
              title: "Delete access token?",
              description: "This will permanently delete the access token",
            },
            errors: {
              name: "The name is mandatory",
              privileges: "At least one privilege is mandatory",
            },
          },
          profileTasyBus: {
            profile_tasy_bus: "Profile - Tasy Bus",
            search: "Search for a profile",
            add_profile_tasy_bus: "Add profile",
            new_profile_tasy_bus: "New profile",
            name: "Name",
            extension: "Extension",
            is_global: "Global Profile",
            enable: "Enable",
            users: "Users",
            global: "Yes",
            not_global: "No",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete profile?",
              description:
                "This will permanently delete the profile, all restriction rules linked to that profile will be deleted and all linked users will be unlinked",
            },
            errors: {
              name: "The name is mandatory",
              extension: "The extension is mandatory",
            },
          },
          restrictionRules: {
            restriction_rules: "Restriction Rules",
            restriction_rule: "Restriction Rule",
            search: "Search for a restriction rule",
            add_restriction_rule: "Add restriction rule",
            new_restriction_rule: "New restriction rule",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            enable: "Enable",
            restriction_rule_type: "Restriction rule type",
            action_type: "Action type",
            value: "Value",
            description: "Description",
            confirm: {
              title: "Delete restriction rule?",
              description: "This will permanently delete the restriction rule",
            },
            errors: {
              restriction_rule_type: "The restriction rule type is mandatory",
              action_type: "The action type is mandatory",
              value: "The value is mandatory",
              description: "The description is mandatory",
            },
          },
        },
        relationships: {
          relationship: "Relationship",
          search: "Search for a realationship",
          add_relationship: "Add relationship",
          relationship_type: "Relationship type",
          direction_type: "Type of targeting",
          origin_name: "Origin name",
          origin_value: "Origin value",
          destiny_name: "Desitny name",
          destiny_value: "Desitiny value",
          enable: "Enable",
          default: "Default",
          status: "Status",
          active: "Active",
          inactive: "Inactive",
          is_default: "Yes",
          is_not_default: "No",
          confirm: {
            title: "Delete relationship?",
            description: "This will permanently delete the relationship",
          },
          errors: {
            relationship_type: "The relationship type is mandatory",
            direction_type: "The type of targeting is mandatory",
          },
        },
        restrictions: {
          schedule: {
            schedule: "Schedule",
            add_schedule: "Add schedule",
            search: "Search for a schedule",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            new_schedule: "New schedule",
            enable: "Enable",
            value: "Value",
            restriction_rule_type: "Restriction rule type",
            description: "Description",
            confirm: {
              title: "Delete schedule?",
              description: "This will permanently delete the schedule",
            },
            errors: {
              value: "The value is mandatory",
              restriction_rule_type: "The restriction rule type is mandatory",
              description: "The description is mandatory",
            },
          },
        },
        logs: {
          ipmXTasy: {
            ipmxtasy: "IPM - Receive - IPM x Tasy",
            status: "Status",
            message_status: "Message status",
            created: "Created date",
            tasy_individual_identifier: "Individual cd.",
            service_number_search: "Service number",
            service_number: "Service number (IPM)",
            service_number_tasy: "Service number (Tasy)",
            lab_exam_quantity: "Qty Exam Lab",
            exam_nlab: "Exam NLab",
            medicines: "Medicines",
            allergy: "Allergy",
            has_evolution: "Has evolution",
            evolution: "Yes",
            no_evolution: "No",
            search: "Search for status",
            view_logs: "View logs",
            logs: "Logs",
          },
          logTasyImp: {
            status: "status",
            message_status: "Status message",
            created: "Creation date",
            qtyEmrLabExamResult: "Laboratory test QTD",
            qtyEmrNonLaboratoryTests: "Laboratory test QTD",
            qtyEvolution: "Evolution QTD",
            qtyAllergy: "Allergies QTD",
            qtyMedicineInUse: "Medicines QTD",
            ipmtasy: "IPMxTasy",
            tasyipm: "TasyxIPM",
            TasyEncounterIntegrationId: "Tasy Customer Service Number",
            TasyIndividualIntegrationId: "Personal Code Tasy",
            IpmEncounterIntegrationId: "Ipm Customer Service Number",
            IpmIndividualIntegrationId: "Personal Code Ipm",
          },
          tasyXIpm: {
            tasyxipm: "IPM - Send - Tasy x IPM",
            status: "Status",
            message_status: "Message status",
            created: "Created date",
            individual_identifier: "Individual code (Tasy)",
            service_number_search: "Service number",
            service_number: "Service number (Tasy)",
            search: "Search for status",
            view_logs: "View logs",
            logs: "Logs",
            allergies: "Allergies",
            occurrences: "Occurrences",
            exams_lab: "Exams Lab",
            exams_n_lab: "Exams N Lab",
            medicines: "Medicines",
          },
          tasyCalendar: {
            title: "Tasy Calendar",
            type_code: "Code",
            created: "Created",
            identifier: "Identifier",
            calendar_identifier: "Calendar Identifier",
            calendar_date: "Calendar Date",
            status: "Status",
            individual_identifier: "Individual Identifier",
            last_modified: "Last Modified",
          },
          monitoring: {
            title: "Bus Server Monitoring",
            subtitle: "Bus On-Premise Server Statistics              ",
          },
        },
        sync: {
          sync: "Synchronization",
          sync_all: "Sync all",
          synced_all: "Synchronization completed successfully",
        },
        robots: {
          list: {
            list: "Robots List",
            search: "Search for a robot",
            name: "Name",
            description: "Description",
            code: "Code",
            started: "Started",
            last_success: "Last success",
            last_error: "Las error",
            last_error_message: "Last error message",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            executions_robots: "Executions Robots",
            executions: {
              executions_robots: "Executions Robots",
              job_name: "Job name",
              start_date: "Start date",
              end_date: "End date",
              execution_status: "Execution status",
            },
          },
        },
      },
      "4uSiteApp": {
        applications: {
          myApplications: {
            my_applications: "My applications",
            search: "Search for an application",
            name: "Name",
            type: "Type",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
          },
        },
      },
      "4uSalesContract": {
        registers: {
          grantors: {
            active: "Active",
            inactive: "Inactive",
            name: "Name",
            status: "Status",
            grantors: "Grantors",
            add_grantor: "Add Grantor",
            enabled: "Enabled",
            establishments: "Establishments",
            new_grantor: "New Grantor",
            legal_entity: "Legal Entity",
            search_grantors: "Search for a Grantor",
            confirm: {
              title: "Delete grantor?",
              description: "This will permanently delete the grantor",
            },
            errors: {
              name: "The name is mandatory",
              establishment: "At least one establishment is mandatory",
              legal_entity: "The legal entity is mandatory",
            },
          },
          salesContractAttachments: {
            title: "Attachment",
            description: "Description",
            search: "Search Attachments",
            add: "Add Attachment",
            title_form: "Title",
            approval_date: "Approval Date",
            approve: "Approve",
            attachment_date: "Attachment Date",
            new_attachment: "New Attachment",
            attachment_type: "Attachment Type",
            confirm: {
              title: "Delete Attachment?",
              description: "This will permanently delete the Attachment",
            },
            errors: {
              title: "The Title is mandatory",
              description: "The description is mandatory",
              attachment_type: "The attachment type is mandatory",
              attachment_date: "The attachment date is mandatory",
              file: "The file is mandatory",
            },
          },
          salesContractHistories: {
            title: "History",
            situation: "Situation",
            search: "Search Histories",
            add: "Add History",
            contract_title: "History",
            titleForm: "Title",
            name: "Name",
            approval_date: "Approval Date",
            approve: "Approve",
            history_date: "History Date",
            new_history: "New History",
            history: "History",
            confirm: {
              title: "Delete History?",
              description: "This will permanently delete the History",
            },
            errors: {
              title: "The Title is mandatory",
              history: "The History is mandatory",
              historyDate: "The History Date is mandatory",
            },
          },
          contractAttachmentType: {
            title: "Contract Attachment Type",
            search: "Search for a contract attachment type",
            add: "Add Contract Attachment Type",
            new: "New Contract Attachment Type",
            active: "Active",
            inactive: "Inactive",
            contract_type: "Contract Type",
            enabled: "Enable",
            name: "Name",
            status: "Status",
            confirm: {
              title: "Delete contract attachment type?",
              description:
                "This will permanently delete the contract attachment type",
            },
            errors: {
              name: "The name is mandatory",
              contract_type: "The contract type is mandatory",
            },
          },
          contractTerminationReason: {
            title: "Contract Termination Reason",
            search: "Search for a contract termination reason",
            add: "Add Contract Termination Reason",
            new: "New Contract Termination Reason",
            active: "Active",
            code: "Code",
            inactive: "Inactive",
            contract_type: "Contract Type",
            enabled: "Enable",
            name: "Name",
            status: "Status",
            confirm: {
              title: "Delete contract termination reason?",
              description:
                "This will permanently delete the contract termination reason",
            },
            errors: {
              name: "The name is mandatory",
              contract_type: "The contract type is mandatory",
            },
          },
          contract_classifications: {
            name: "Name",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            enabled: "Enabled",
            code: "Code",
            contract_type: "Contract Type",
            contract_classification: "Contract Classification",
            search_contract_classification:
              "Search for a Contract Classification",
            add_contract_classification: "Add Contract Classification",
            new_contract_classification: "New Contract Classification",
            confirm: {
              title: "Delete contract classification?",
              description:
                "This will permanently delete the contract classification",
            },
            errors: {
              name: "The name is mandatory",
              contract_type: "The contract type is mandatory",
            },
          },
          baseContracts: {
            title: "Benefits and Advantages Club",
            search: "Search Benefits and Advantages Club",
            add: "Add Benefits and Advantages Club",
            contratation_date: "Contract Date",
            termination_date: "Termination Date",
            situation_status: "Situation Status",
            contract_classification: "Contract Classification",
            integration_code: "Integration Code",
            name: "Name",
            code: "Code",
            status: "Status",
            approved: "Approved",
            canceled: "Canceled",
            pending: "Under Review",
            terminated: "Terminated",
            approve: "Approve",
            cancel: "Cancel",
            terminate: "Terminate",
            reactivation: "Reactivate",
            contractualObject: {
              substipulant: "Substipulant",
              product: "Product",
            },
            confirm: {
              title: "Delete the benefits and perks club?",
              description:
                "This will permanently delete the benefits and perks club",
            },
            advantagesAndBenefitsClub: {
              title: "Advantages and Benefits Club",
              advantages_and_benefits_club_contract:
                "Advantages and Benefits Club Contracts",
              grantor: "Grantor",
              contract_classification: "Contract Classification",
              contract_number: "Contract Number",
              integration_code: "Integration Code",
              contract_title: "Contract Title",
              renewal_period: "Renewal Period (months)",
              main_contract: "Main Contract",
              situation: "Situation",
              legal_entity: "Legal Entity",
              individual_person: "Individual Person",
              contratation_data: "Contracting Data",
              contract_type: "Contract Type",
              contratation_date: "Contract Date",
              termination_date: "Termination Date",
              approval_date: "Approval Date",
              cancelation_date: "Cancellation Date",
              reactivation_date: "Reactivation Date",
              code: "Code",
              benefit_administrators: "Benefit Administrators",
              additional_data:
                "Additional Data for Advantages and Benefits Club",
              tabs: {
                contractual_object: "Contractual Objects",
                history: "History",
                contract: "Contract",
                payer: "Payers",
                attachment: "Attachment",
                beneficiary: "Beneficiary",
              },
              errors: {
                contract_date: "The contract date is mandatory",
                contract_type: "Contract Type is mandatory",
                individual_person: "The individual person is mandatory",
                legal_entity: "The legal entity is mandatory",
                renew_months: "Renewal Period is mandatory",
                grantor: "The Grantor is mandatory",
                situation: "The situation is mandatory",
                contract_classification: "Contract Classification is mandatory",
                contract_title: "Contract Title is mandatory",
              },
            },
          },
          beneficiaryClassification: {
            title: "Beneficiary Classification",
            new: "New Beneficiary Classification",
            add: "Add Beneficiary Classification",
            search: "Search for Beneficiary Classification",
            name: "Name",
            status: "Status",
            enable: "Enable",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete beneficiary classification?",
              description:
                "This will permanently delete the beneficiary classification",
            },
            errors: {
              name: "The name is mandatory",
            },
          },
          contractProductClassifications: {
            title: "Product Classifications",
            contract_product_classification: "Product Classification",
            search: "Search for a product classifications",
            name: "Name",
            status: "Status",
            active: "Active",
            code: "Code",
            inactive: "Inactive",
            enabled: "Enable",
            add: "Add Product Classification",
            new: "New Product Classification",
            contract_type: "Contract Type",
            confirm: {
              title: "Delete product classification?",
              description:
                "This will permanently delete the product classification",
            },
            errors: {
              name: "Name is mandatory",
              contract_type: "Contract Type is mandatory",
            },
          },
          channelClassifications: {
            title: "Sales Channel Classifications",
            channel_classification: "Sales Channel Classification",
            search: "Search Sales Channel Classification",
            add: "Add Sales Channel Classification",
            new: "New Sales Channel Classification",
            name: "Name",
            code: "Code",
            enable: "Enable",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete Sales Channel Classification",
              description:
                "This will permanently delete the sales channel classification",
            },
            errors: {
              name: "Name is mandatory",
              status: "Status is mandatory",
            },
          },
          salesChannel: {
            title: "Sales Channels",
            sales_channel: "Sales Channel",
            search: "Search Sales Channel",
            add: "Add Sales Channel",
            new: "New Sales Channel",
            legal_entity: "Legal Entity",
            individual_person: "Individual Person",
            title_placeholder: "Title",
            name: "Name",
            code: "Code",
            active: "Active",
            inactive: "Inactive",
            suspension: "Suspend",
            inactivation: "Inactive",
            reactivation: "Reactivate",
            approve: "Approve",
            approved: "Approved",
            contract_type: "Contract Type",
            enabled: "Enable",
            reactivation_date: "Reactivation Date",
            inactivation_date: "Inactivation Date",
            suspension_date: "Suspension Date",
            approval_date: "Approval Date",
            situation: "Situation",
            classification: "Classification",
            sales_channel_type: "Sales Channel Type",
            tabs: {
              sales_channel: "Sales Channel",
              seller: "Sellers",
            },
            confirm: {
              title: "Delete sales channel?",
              description: "This will permanently delete the sales channel",
            },
            errors: {
              title: "The title is mandatory",
              situation: "The situation is mandatory",
              legal_entity: "The legal entity is mandatory",
              sales_channel_type: "The sales channel type is mandatory",
              individual_person: "The individual person is mandatory",
              sales_channel_classification: "The classification is mandatory",
            },
          },
          salesContractBeneficiary: {
            title: "Beneficiary",
            search: "Search for Beneficiary",
            add: "Add Beneficiary",
            new: "New Beneficiary",
            individual_person: "Individual Person",
            code: "Code",
            integration_code: "Integration Code",
            approve: "Approve",
            cancel: "Cancel",
            reestablish: "Reestablish",
            rescind: "Rescind",
            suspend: "Suspend",
            approved: "Approved",
            canceled: "Canceled",
            under_analysis: "Under Analysis",
            rescinded: "Rescinded",
            suspended: "Suspended",
            beneficiary_card: "Beneficiary Card",
            substipulant: "Substipulant",
            admission_date: "Admission Date",
            beneficiary_holder: "Primary Beneficiary",
            original_inclusion_date: "Original Inclusion Date",
            beneficiary_classification: "Beneficiary Classification",
            type_of_holder: "Type Of Holder",
            kinship_degree: "Degree of Kinship",
            sales_product: "Product",
            sales_price_list: "Price List",
            sales_payer: "Payer",
            sales_channel: "Sales Channel",
            sales_seller: "Seller",
            reactivation_date: "Reactivation Date",
            inactivation_date: "Inactivation Date",
            suspension_date: "Suspension Date",
            approval_date: "Approval Date",
            situation: "Situation",
            confirm: {
              title: "Delete Beneficiary?",
              description: "This will permanently delete the Beneficiary",
            },
            errors: {
              situation: "The situation is mandatory",
              individual_person: "The Individual Person is mandatory",
              beneficiary_classification:
                "The beneficiary classification is mandatory",
              sales_product: "The product is mandatory",
              sales_price_list: "The price list is mandatory",
              sales_channel: "The sales channel is mandatory",
              sales_payer: "The payer is mandatory",
              sales_seller: "The seller is mandatory",
            },
          },
          salesContractBeneficiaryCard: {
            title: "Beneficiary Card",
            add: "Add Beneficiary Card",
            card_identifier: "Card Identifier",
            initial_date: "Start Date",
            end_date: "End Date",
            integration_code: "Integration Code",
            confirm: {
              title: "Delete Beneficiary Card?",
              description: "This will permanently delete the Beneficiary Card",
            },
            errors: {
              card_identifier: "The card identifier is required",
              initial_date: "The start date is required",
              end_date: "The end date is required",
            },
          },
          salesContractPayerFinantial: {
            title: "Payer Finantial",
            search: "Search for Payer Finantial",
            add: "Add Payer Finantial",
            new: "New Payer Finantial",
            mail: "Mail",
            biling_method: "Biling Method",
            monthly_payment_due_date: "Payday",
            initial_date: "Initial Date",
            end_date: "End Date",
            phone: "Phone",
            whatsapp_number: "Whatsapp Number",
            observation: "Observation",
            confirm: {
              title: "Delete Payer?",
              description: "This will permanently delete the Payer",
            },
            errors: {
              biling_method: "The biling method is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              monthly_payment_due_date: "The payday is mandatory",
            },
          },
          salesContractPayer: {
            title: "Payer",
            search: "Search for Payer",
            add: "Add Payer",
            new: "New Payer",
            payer_finantial: "Payer Finantial",
            legal_entity: "Legal Entity",
            individual_person: "Individual Person",
            title_payer: "Title",
            payer: "Payer",
            address_type: "Address Type",
            address: "Address",
            payerType: "Type",
            integration_code: "Integration Code",
            code: "Code",
            active: "Active",
            inactive: "Inactive",
            suspension: "Suspend",
            inactivation: "Inactivate",
            reactivation: "Reactivate",
            approve: "Approve",
            reactivation_date: "Reactivation Date",
            inactivation_date: "Inactivation Date",
            suspension_date: "Suspension Date",
            approval_date: "Approval Date",
            situation: "Situation",
            confirm: {
              title: "Delete Payer?",
              description: "This will permanently delete the Payer",
            },
            errors: {
              situation: "The situation is mandatory",
              individual_person: "The individual person is mandatory",
              legal_entity: "The legal entity is mandatory",
              address_type: "The address type is mandatory",
              payer_title: "The title is mandatory",
            },
          },
          salesContractSubStipulant: {
            title: "Substipulant",
            search: "Search for substipulant",
            add: "Add Substipulant",
            new: "New Substipulant",
            legal_entity: "Legal Entity",
            individual_person: "Individual Person",
            code: "Code",
            active: "Active",
            inactive: "Inactive",
            suspension: "Suspend",
            inactivation: "Inactivate",
            reactivation: "Reactivate",
            approve: "Approve",
            reactivation_date: "Reactivation Date",
            inactivation_date: "Inactivation Date",
            suspension_date: "Suspension Date",
            approval_date: "Approval Date",
            situation: "Situation",
            confirm: {
              title: "Delete Substipulant?",
              description: "This will permanently delete the Substipulant",
            },
            errors: {
              situation: "The situation is mandatory",
              individual_person: "The Individual Person is mandatory",
              legal_entity: "The Legal Entity is mandatory",
            },
          },
          salesContractProducts: {
            title: "Product",
            add: "Add Product",
            new: "New Product",
            code: "Code",
            name: "Name",
            product: "Product",
            price_list: "Price List",
            price_list_product: "Price Lists",
            confirm: {
              title: "Delete Product?",
              description: "This will permanently delete the Product",
            },
            errors: {
              product: "The product is required",
              price_list: "The price list is required",
            },
          },
          salesContractsPriceList: {
            title: "Price Lists",
            price_list: "Price List",
            search: "Search for a price list",
            add: "Add Price List",
            name: "Name",
            initial_date: "Initial Date",
            end_date: "End Date",
            approval_date: "Approval Date",
            approve: "Approve",
            code: "Code",
            new_price_list: "New Price List",
            add_new_item: "Add New Item",
            degree_of_entitlement: "Degree Of Entitlement",
            items: "Items",
            price: "Price",
            initial_age: "Initial Age",
            end_age: "End Age",
            confirm: {
              title: "Delete Price List?",
              description: "This will permanently delete the Price List",
            },
            errors: {
              name: "The name is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              code: "The code is mandatory",
              price: "The price is mandatory",
              degree_of_entitlement: "The degree of entitlement is mandatory",
              sales_contract_price_list_items: "At least one item is mandatory",
            },
          },
          salesSeller: {
            title: "Seller",
            title_seller: "Seller Title",
            search: "Search Seller",
            add: "Add Seller",
            new: "New Seller",
            individual: "Individual",
            active: "Active",
            inactive: "Inactive",
            suspension: "Suspend",
            inactivation: "Inactive",
            code: "Code",
            reactivation: "Reactivate",
            approve: "Approve",
            suspended: "Suspended",
            approved: "Approved",
            reactivation_date: "Reactivation Date",
            inactivation_date: "Inactivation Date",
            suspension_date: "Suspension Date",
            approval_date: "Approval Date",
            situation: "Situation",
            confirm: {
              title: "Delete seller?",
              description: "This will permanently delete the seller",
            },
            errors: {
              title: "The title seller is mandatory",
              situation: "The situation is mandatory",
              individual: "The lindividual is mandatory",
            },
          },
          contractProducts: {
            tabs: {
              products: "Products",
              price: "Price",
              history: "History",
              priceList: "Price Lists",
            },
            products: {
              title: "Products",
              product: "Product",
              situation: "Situation",
              new_product: "New Procuct",
              search: "Search Products",
              add: "Add Product",
              contract_title: "Contract Title",
              name: "Name",
              active: "Active",
              inactive: "Inactive",
              approved: "Approved",
              canceled: "Canceled",
              suspended: "Suspended",
              reactivation_date: "Reactivation Date",
              inactivation_date: "Inactivation Date",
              suspension_date: "Suspension Date",
              approval_date: "Approval Date",
              cancelation_date: "Cancellation Date",
              suspension: "Suspend",
              inactivation: "Inactive",
              reactivation: "Reactivate",
              approve: "Approve",
              cancelation: "Cancelation",
              fantasy_name: "Fantasy Name",
              sales_contract_type: "Contract Type",
              integration_code: "Integration Code",
              sales_product_ctr_classification: "Product Classification",
              sales_grantors: "Grantors",
              code: "Code",
              confirm: {
                title: "Delete product?",
                description: "This will permanently delete the product",
              },
              errors: {
                name: "The name is mandatory",
                fantasy_name: "The fantasy name is mandatory",
                sales_grantors: "At least one grantor is mandatory",
                sales_product_ctr_classification:
                  "The product classification is mandatory",
                sales_contract_type: "The contract type is mandatory",
              },
            },
          },
          salesProductCtrPriceList: {
            title: "Price Lists",
            price_list: "Price List",
            search: "Search for a price list",
            add: "Add Price List",
            name: "Name",
            initial_date: "Initial Date",
            end_date: "End Date",
            approval_date: "Approval Date",
            approve: "Approve",
            code: "Code",
            new_price_list: "New Price List",
            add_new_item: "Add New Item",
            degree_of_entitlement: "Degree Of Entitlement",
            items: "Items",
            price: "Price",
            initial_age: "Initial Age",
            end_age: "End Age",
            confirm: {
              title: "Delete Price List?",
              description: "This will permanently delete the Price List",
            },
            errors: {
              name: "The name is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              code: "The code is mandatory",
              price: "The price is mandatory",
              degree_of_entitlement: "The degree of entitlement is mandatory",
              date: "The end date must be greater than the start date",
              age: "The final age must be greater than the initial age",
              sales_product_ctr_price_list_items:
                "At least one item is mandatory",
            },
          },
          salesProductCtrHistories: {
            title: "History",
            situation: "Situation",
            search: "Search Histories",
            add: "Add History",
            contract_title: "History",
            titleForm: "Title",
            name: "Name",
            approval_date: "Approval Date",
            approve: "Approve",
            history_date: "History Date",
            new_history: "New History",
            history: "History",
            confirm: {
              title: "Delete History?",
              description: "This will permanently delete the History",
            },
            errors: {
              title: "The Title is mandatory",
              history: "The History is mandatory",
              historyDate: "The History Date is mandatory",
            },
          },
          beneficiaryTerminationReasons: {
            title: "Beneficiary Termination Reason",
            search: "Search for Beneficiary Termination Reason",
            add: "Add Beneficiary Termination Reason",
            new: "New Beneficiary Termination Reason",
            name: "Name",
            status: "Status",
            active: "Active",
            code: "Code",
            inactive: "Inactive",
            enabled: "Enable",
            confirm: {
              title: "Delete beneficiary termination reason?",
              description:
                "This will permanently delete the beneficiary termination reason",
            },
            errors: {
              name: "The Name is mandatory",
            },
          },
          beneficiaryCancellationsReason: {
            title: "Beneficiary Cancellation Reason",
            search: "Search by Beneficiary Cancellation Reason",
            add: "Add Beneficiary Cancellation Reason",
            new: "New Beneficiary Cancellation Reason",
            name: "Name",
            status: "Status",
            code: "Code",
            inactive: "Inactive",
            active: "Active",
            enable: "Enable",
            confirm: {
              title: "Delete the beneficiary cancellation reason?",
              description:
                "This will permanently delete the beneficiary cancellation reason",
            },
            errors: {
              name: "The name is mandatory",
            },
          },
          beneficiarySuspensionsReason: {
            title: "Beneficiary Suspension Reason",
            search: "Search for Beneficiary Suspension Reason",
            add: "Add Beneficiary Suspension Reason",
            new: "New Beneficiary Suspension Reason",
            enable: "Enable",
            name: "Name",
            code: "Code",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            confirm: {
              title: "Delete the beneficiary suspension reason?",
              description:
                "This will permanently delete the beneficiary suspension reason",
            },
            errors: {
              name: "Name is mandatory",
            },
          },
        },
      },
      assessments: {
        questions: {
          questions: "Questions",
          question: "Question",
          new_question: "New question",
          add_question: "Add question",
          search: "Search for a question",
          question_evaluation_type: "Question evaluation type",
          integration_id: "Integration id",
          title: "Title",
          description: "Description",
          question_category: "Question category",
          created: "Created",
          required_justification: "Requires justification",
          required_justification_less_than: "Require justification below",
          required_justification_min_chars: "Minimum number of characters",
          required_justification_message: "Justification text",
          category: {
            label: "Category",
            no_options_text: "No categories selected",
            options_button_label: "Search category",
            drawer_props: {
              title: "Select category",
              search_placeholder: "Search categories",
              create_button_label: "Create category",
              apply_button_label: "Apply category",
              input_label: "Category name",
              delete_confirm: {
                title: "Delete category",
                message:
                  "This will delete the category and all related categories",
              },
            },
          },
          confirm: {
            title: "Delete question?",
            description: "This will permanently delete the question",
          },
          errors: {
            integration_id: "The integration id is mandatory",
            title: "The title is mandatory",
            description: "The description is mandatory",
            question_evaluation_type:
              "The question evaluation type is mandatory",
            question_category: "The question category is mandatory",
            required_justification_less_than:
              "The 'required justification below' is mandatory",
            required_justification_min_chars:
              "The minimum number of characters is mandatory",
            required_justification_message:
              "The justification text is mandatory",
          },
        },
      },
      logs: {
        healthChecks: {
          health_checks: "Health Checks",
          name: "Name",
          data: "Data",
          created: "Creation date",
          search: "Search for a health checks log",
        },
        logRegisterError: {
          errorMessage: "Error message",
          document: "Document",
          title: "Registration errors",
          created: "Date",
          confirm: {
            confirm: {
              title: "Delete error log?",
              description: "This will permanently delete the log",
            },
          },
          placeholder: "Search for some CPF",
        },
      },
      evaluation: {
        forms: {
          forms: "Forms",
          form: "Form",
          new_form: "New form",
          search: "Search for a form",
          add_form: "Add form",
          type_form: "Type Form",
          title: "Title",
          description: "Description",
          status: "Status",
          active: "Active",
          inactive: "Inactive",
          extension: "Application/Site/App",
          initial_date: "Start of term",
          end_date: "End of validity",
          domains: {
            domains: "Domains",
            tooltip: "Copy form sharing link for use in templates",
            copied_successfully: "Link copied successfully",
          },
          select_questions: {
            questions: "Questions",
            display_order: "Order",
            title: "Title",
            type: "Type",
            add_button: "Add questions",
            modal_title: "Select questions",
            modal_add_button: "Add questions",
            modal_remove_button: "Remove questions",
            search: "Search for a question",
          },
          confirm: {
            title: "Delete form?",
            description: "This will permanently delete the form",
          },
          errors: {
            type: "The type is mandatory",
            title: "The title is mandatory",
            site_app_extension: "The application/site/app is mandatory",
            initial_date: "The start of term date is mandatory",
            end_date: "The end of validity date is mandatory",
            bigger_date:
              "The start of term date cannot be greater than the end of validity date",
            description: "The description is mandatory",
            questions: "At least one question is mandatory",
            order: "The order must be greater than or equal to 1",
            duplicate_order: "Duplicate orders are not allowed",
            visual_description: "The visual description is mandatory",
          },
        },
        logs: {
          id: "Evaluation Id",
          title: "Integration logs",
          evaluationForm: "Form",
          ticket: "Ticket (card)",
          attempts: "Attempts",
          shippingDate: "Shipping Date",
          answerDate: "Response date",
          encounterIntegrationId: "Service number",
          errorMessage: "Error message",
          copy: "Copy",
          copied_successfully: "Evaluation ID copied successfully",
        },
      },
    },
    components: {
      content: {
        add_new_content: "Add content",
        add_new_video: "Add videos",
        contents: "Contents",
        content_type: "News type",
        content_status: "News status",
        enable: "Enable",
        reading_time: "Reading time",
        reading_time_info: "Time in minutes",
        site_app: "Applications",
        title: "Title",
        language: "Language",
        language_tooltip: "View language",
        summary: "Summary",
        publication_date: "Publication date",
        content_tags: "News tags",
        is_published: "Publish",
        status: "Status",
        published: "Published",
        not_published: "Not published",
        enabled: "Active",
        inactive: "Inactive",
        link: "Link",
        link_helper: "Add a Youtube link",
        videos: "Videos",
        highlight_end: "Highlight started at",
        highlight_start: "Highlight ended at",
        confirm: {
          title: "Delete news?",
          description: "This will permanently delete the news",
        },
        errors: {
          reading_time: "The reading time is mandatory",
          site_apps: "At least one application is mandatory",
          language: "The language is mandatory",
          link: "The link is invalid.Please add a Youtube link",
          title: "The title is mandatory",
          tags: "At least one news tag is mandatory",
          summary: "The summary is mandatory",
          content: "The content is mandatory",
          contents: "At least one content is mandatory",
          file: "The file is mandatory",
          highlight_end: "The end date cannot be greater than the start date",
        },
      },
      schedule: {
        selectAll: "Select all",
        seeMore: "View more {{quantity}}",
        day: "Day",
        week: "Week",
        month: "Month",
        today: "Today",
        newSchedule: {
          date: "Data",
          required: "Required field",
          hour: "Time",
          professional: "Professional",
          type: "Type of scheduling",
          duration: "Duration",
          button: "Schedule",
          title: "New scheduling",
          insurance: {
            add: "Add Agreement",
            edit: "Edit agreement",
            number: {
              label: "Card number (optional)",
              error: "The number is mandatory",
            },
            validity: {
              label: "Card Validity (optional)",
              error: "Validity is mandatory",
            },
            individual: {
              label: "Patient",
              error: "The patient is mandatory",
            },
            insurance: {
              label: "Health insurance",
              error: "The agreement is mandatory",
            },
          },
        },
        menu: {
          source: {
            both: "All",
          },
          exams: "Tests",
          subtitle: {
            title: "Legend",
            someFree: "There are free",
            full: "All busy",
            allFree: "All free",
          },
          period: "Period",
          situation: "Situation",
          both: "Both",
          specialitys: "Specialties",
          professionals: {
            label: "Professionals",
            noOptionsText: "No professionals found",
            loading: "Loading...",
          },
        },

        schedulePopover: {
          copy: "Copy identifier",
          copyCardNumber: "Copy card number",
          insurance: "Insurance",
          cancel: "Cancel Schedule",
          confirm: "Confirm cancellation",
          cancelButton: "Cancel",
          justification: {
            label: "Justification",
            placeholder: "Enter a justification",
            error: "The justification is mandatory",
          },
          reason: {
            label: "Reason",
            placeholder: "Select a reason",
            error: "The reason is mandatory",
          },
          cancelDescription:
            "Cancel query {{pacient}} with {{doctor}} at {{date}}",
        },
      },
      scheduleAssistant: {
        button: "Scheduling assistant",
        insurance: {
          title: "My Agreements",
          subtitle: "Manage your agreements and those of the family group",
          add: "To add",
          insuranceName: "Insurance",
          individual: "Individual",
          cardNumber: "Card number",
          cardValidity: "Validity",
          delete: {
            title: "Delete an agreement?",
            description: "This action is permanent!",
            confirmationText: "Yes, delete",
            cancellationText: "Cancel",
          },
          addInsurance: {
            edit: "Edit agreement",
            add: "Add Agreement",
            buttonSave: "To save",
            buttonEdit: "To edit",
            number: {
              label: "Card number (optional)",
              error: "The number is mandatory",
            },
            validity: {
              label: "Card Validity (optional)",
              error: "Validity is mandatory",
            },
            individual: {
              label: "Patient",
              error: "The patient is mandatory",
            },
            insurance: {
              label: "Health insurance",
              error: "The agreement is mandatory",
            },
          },
        },
        newSchedule: {
          success: "Successful registered scheduling",
          sched: "Schedule",
          notAvaiableModal: {
            button: "Ok",
            description:
              "The time chosen is no longer available, please select another time.",
          },
          next: "Next",
          back: "Back",
          finish: "Finish",
          steps: {
            patient: {
              title: "Select the patient",
              error: "The patient is mandatory",
            },
            insurance: {
              title: "Select the agreement",
              error: "The agreement is mandatory",
            },
            search: {
              title: "Search for an schedule",
              specialityError: "The specialty is mandatory",
              periodError: "The period is mandatory",
              examError: "The exam is mandatoryo",
            },
            schedule: {
              title: "Select a date and time",
              error: "The time is mandatory",
            },
            confirm: "Confirm data",
          },
          insurance: "Health insurance",
          addInsurance: "Add Agreement",
          addFamilyGroup: "Add familiar",
          search: {
            from: "From",
            examGroup: "Exam",
            professional: "Professional (optional)",
            speciality: "Specialty",
            type: "Type of scheduling",
            period: "Period",
          },
          schedule: {
            freeDays: "Days with available dates",
          },
          confirm: {
            descriptionSite: "Professional",
            patient: "Patient",
            insurance: "Health insurance",
            schedule: "Scheduling",
          },
        },
      },
      panel: {
        add_dashboard: "Add dashboard",
        select: "Select and order the components",
        helper_text: "Dashboard must have at least one component",
        no_data: "No data",
        not_found: "Panel not found",
        no_dashboards: "No dashboard found, add your first dashboard!",
        delete_dashboard: {
          delete: "Delete dashboard",
          title: "Delete dsahboard {{ name }}",
          description:
            "This will permanently delete your dashboards and your data.",
          cancel: "Cancel",
          confirm: "Yes, delete",
        },
        select_tooltip: "Selecionar e ordenar componentes",
        filter: {
          day: "Day",
          week: "Week",
          month: "Month",
          year: "Year",
        },
        helper: {
          title: "Dashboard data information",
          first:
            "Daily records are created at the end of each day, because of this on the day of creation of a dashboard no dashboard has data.",
          second:
            "Weekly records are created on Sunday, when the week is over.<1> On the first day of the week following the consulted. </1>",
          third:
            "Monthly records are created on the <1>first day of the month following the consulted.</1>",
          fourth:
            "Annual records are created on the <1>first day of the year following the consulted.</1>",
        },
      },
      selectBool: {
        true: "True",
        false: "False",
      },
      menu: {
        home: {
          home: "Home",
          begin: "Summary",
          dashboard: "Painel",
        },
        myDashboards: {
          myDashboards: "My panels",
          view: "Visualization",
          dashboards: "Panels",
        },
        people: {
          people: "People",
          individuals: "Physical Person",
          legalEntities: "Legal Entity",
          individualValidations: "Registration Validation PF",
        },
        registers: {
          beneficiaries: "Beneficiaries",
          doctors: "Doctors",
          userProfiles: "User Profiles",
          client: "Client",
          templates: "Templates",
          integrations: "Integrations",
          profile_roles: "Profile Roles",
          documents: "Terms and Policies",
          holidays: "Holidays",
        },
        admSystem: {
          admSystem: "Adm. System",
          users: "Users",
          establishmentParams: "General Parameters",
          roles: "Profile",
          establishments: "Establishments",
          aditionalSettings: {
            aditionalSettings: "Additional Settings",
            cardsTemplates: "Cards Templates",
          },
          companygroups: "Company Groups",
          companies: "Companies",
        },
        globals: {
          globals: "Global",
          languages: "Languages",
          menus: "Menus",
          generics: "Generic",
          countries: "Countries",
          extensions: "Extensions",
          cities: "Cities",
          neighborhoods: "Neighborhoods",
          provinces: "States",
          banks: "Banks",
          jobs: "Jobs",
          paramsDefaultEstablishment: "Standard Establishment Parameters",
        },
        menu_secondary_item: {
          modal_title: "In development",
          modal_content:
            "We are still working on the development of this screen, soon we will have news.",
        },
        secondary_menu: {
          suport: "Suport",
          configs: "Configs",
          extensions_store: "Extensions Store",
        },
        actions: {
          help: "Help",
          notifications: "Notifications",
          change_theme: "Change Theme",
          settings: "Settings",
        },
        applications: {
          applications: "Applications",
          register: "Register",
        },
        "4uChatBot": {
          templates: {
            templates: "Templates",
            whatsapp: "WhatsApp",
            internal: "CRM Internal",
          },
          createAutomations: {
            createAutomations: "Automations",
            automationRules: "Automation Rules",
          },
          fluxos: {
            fluxos: "Flows",
            serviceWhatsapp: "Service WhatsApp",
          },
        },
        evaluation: {
          evaluation: "Evaluations",
          questions: "Questions",
          evaluationForms: "Forms",
          evaluationLogs: "Integration logs",
        },
        chatBot: {
          register: "Register",
          ticket_service: "Tickets(Cards) Services",
          service: "Service",
          ticket_service_area: "Service Area",
          ticket_protocol_rule: "Protocol Generation Rules",
          ticket_service_day: "Opening Hours",
          ticket_service_message: "Messages",
        },
        logs: {
          logs: "Logs",
          healthcheck: "Health Checks",
          logRegisterError: "Register Errors",
        },
        settings: {
          system: {
            system: "System",
            generalSettings: {
              generalSettings: "Global settings",
              correios: "Correios",
              centralBank: "Banco Central",
              geolocalization: "Geolocalization",
              certillion: "Certillion",
            },
            cache: "Cache",
            paramsDefaultEstablishment: "Standard Establishment Parameters",
            executeSql: "Execute SQL",
          },
          generalSettings: {
            generalSettings: "General",
            holidays: "Holidays",
            establishmentParams: "Establishment Parameters",
            healthChecks: "4uHub Microservices Health Checks",
          },
          applications: {
            applications: "Applications",
            cardsTemplates: "Card Templates",
          },
          crm: {
            crm: "CRM",
            chatBot: {
              chatBot: "ChatBot",
              ticketServiceArea: "Service areas",
              ticketServiceMessage: "Messages",
            },
            ticketProtocolRule: "Protocol Generation Rules",
            ticketService: "Ticket(card) Service",
            ticketAutoFinishRule: "Ticket(card) Finalization Rules",
            ticketDefaultDocs: "Standard Guidance Protocols",
            events: "Events",
            externalProcessType: "Types of External Processes",
          },
          chatBot: {
            chatBot: "ChatBot",
            ticketServiceArea: "Service Areas",
            ticketServiceMessage: "Messages",
            ticketServiceDay: "Opening Hours",
          },
          hcp: {
            hcp: "4uHcP",
            healthProgramTypes: "Types of health programs",
            workGroupTypes: "Types of work groups",
          },
          "4uBus": {
            "4uBus": "4uBus",
            ipm: {
              ipm: "IPM",
              generalSettings: "General settings",
            },
            globals: {
              globals: "General settings",
              sync: "Synchronization",
            },
            zscan: {
              zscan: "ZScan",
              examSendRule: "Exam sending rule",
            },
            externalExamPrescription: {
              externalExamPrescription:
                "Generation of External Examination Request Prescription",
              rules: "Prescription Generation Rules",
            },
          },
          "4uSched": {
            "4uSched": "4uSched",
            tasyCalendar: {
              tasyCalendar: "Tasy - Consultations and exams",
              checkIn: "Check in",
              confirmation: "Schedule Confirmartion",
            },
          },
          "4uSiteApp": {
            "4uSiteApp": "4uSiteApp",
            digitalTriage: {
              digitalTriage: "Check-In of Digital PA Triage",
              checkIn: "Check in",
            },
          },
        },
        "4uSiteApp": {
          applications: {
            applications: "Applications",
            register: "Register",
            myApplications: "My applications",
          },
          communication: {
            communication: "Communication",
            banners: "Banners",
            notifications: "Notifications",
            news: "News",
          },
          privacyAndSecurity: {
            privacyAndSecurity: "Privacy and Security",
            termsAndPolicies: "Terms and policies",
          },
          sac: {
            sac: "SAC and Ombudsman",
            faq: "FAQ",
          },
        },
        "4uHcP": {
          structure: {
            structure: "Program structure",
            healthPrograms: "Health programs",
            workGroups: "Working groups",
          },
          content: {
            content: "Content",
            contentRules: "Content rules",
          },
          captation: {
            captation: "Capture",
            captations: "Captations",
            imports: "Import",
          },
        },
        "4ubus": {
          register: {
            register: "Register",
            accessTokens: "Access tokens",
            profileTasy: "Profile - Tasy Bus",
          },
          setRelationships: {
            setRelationships: "Relationships",
            ipm: "IPM x TASY",
            "4uHub": "Tasy - Call Center",
            zscanEvo: "ZScan Evo",
          },
          restriction: {
            restriction: "Restrictions",
            rule: "Schedule",
          },
          logs: {
            logs: "Logs",
            tasyCalendar: "Tasy Calendar",
            ipmxtasyinternal: "IPM - Receive - IPM x Tasy (Interno)",
            tasyxipminternal: "IPM - Send - Tasy x IPM (Interno)",
            ipmtasy: "IPM - Receive - IPM x Tasy",
            tasyipm: "IPM - Send - Tasy x IPM",
            monitoring: "Bus Server Monitoring",
          },
          robots: {
            robots: "Robots",
            list: "List",
          },
        },
        "4uSalesContract": {
          register: {
            register: "Registers",
            salesGrantors: "Grantors",
            salesContractAttachmentTypes: "Contract Attachment Type",
            salesContractClassifications: "Contract Classification",
            salesProductCtrClassifications: "Product Classifications",
            salesChannelClassifications: "Sales Channel Classifications",
            salesChannels: "Sales Channels",
            salesProductCtrs: "Product",
            salesContractTerminationReason: "Contract Termination Reason",
            beneficiaryContractReasonOfTerminations:
              "Beneficiary Contract Termination Reason",
            salesContractBeneficiaryClassifications:
              "Beneficiary Classification",
            beneficiaryContractReasonOfCancellations:
              "Beneficiary Cancellation Reason",
            beneficiaryContractReasonOfSuspensions:
              "Beneficiary Suspension Reason",
          },
        },
        "4uESign": {
          createAutomations: {
            createAutomations: "Automations",
            automationRules: "Automation Rules",
          },
        },
      },
      logs_accordions: {
        user: "User:",
        duration: "Duration",
      },
      logs_table: {
        name: "Name",
        original_value: "Original values",
        new_value: "New values",
        button_see_more: "See more",
      },
      logs_no_data: {
        message: "Data not found!",
      },
      roles: {
        title: "Profile",
        name: "Name",
        establishment: "Establishment",
        search_name: "Search for a name",
        new_role: "New profile",
      },
      roles_basic_data: {
        name: "Name",
        description: "Description",
      },
      roles_establishments: {
        title: "Establishments",
        add_establishments: "Add new establishment",
        name: "The name is mandatory",
        label: "Establishment(s) that may use this profile",
        establishmentsTypeId: "Select a establishment",
        establishments_tooltip: "See establishments",
      },
      roles_privileges: {
        title: "Privileges",
        add_privileges: "Add new privilege",
      },
      private: {
        permission: "Ooops! You don't have permission!",
      },
      forgetPasswordForm: {
        title: "Forgot password?",
        subtitle:
          "Enter your CPF below and we'll send you a code to your email to reset your password.",
        button: "Send",
        sending: "Sending",
        login: "CPF",
        login_required: "CPF is required!",
        notification_email_sent: "Email sent successfully!",
        back_login: "Back to login",
      },
      forgetPasswordModal: {
        title: "Instructions sent!",
        message:
          "We have sent password reset instructions to your email, please check all inboxes and spam folders.",
        close: "Close",
      },
      resetPasswordForm: {
        title: "New password",
        subtitle:
          "Enter the code sent to your email. If you don't find it, check your spam area.",
        password: "Password",
        confirm_password: "Confirm password",
        confirmation_code: "Confirmation code",
        sending: "Sending",
        send: "Send",
        new_password_required: "Password is required.",
        confirm_new_password_required: "Confirm password is required.",
        passwords_match: "Passwords do not match.",
        confirmation_code_required: "The confirmation code is mandatory",
        notification_password_success: "Password changed successfully!",
      },
      noRowsMessage: {
        no_permission: "You do not have permission to view the information",
        no_data: "You do not have registered information",
      },
      onPremiseModal: {
        establishment: {
          establishment_on_premise: "Establishment on premise",
          no_data: "No establishment found",
        },
        params: {
          params_on_premise: "Parameters on premise",
          no_data: "No parameter found",
        },
        generics: {
          generics_on_premise: "Generics on premise",
          no_data: "No generic found",
        },
        health: {
          health_on_premise: "Synchronized Health Plans",
          no_data: "No health plan found",
        },
        medicSpecialties: {
          no_data: "No medical specialties found",
        },
        providerTypes: {
          no_data: "No provider types found",
        },
        professionalCouncils: {
          no_data: "No professional advice found",
        },
        medicalPracticeAreas: {
          no_data: "No medical practice areas found",
        },
      },
      cardMenu: {
        button: "View more",
      },
      paramsFilter: {
        title: "Filters",
        clean_button: "Clean filter",
      },
      extensionsStoreHeader: {
        title: "Extensions store",
        search: "Search by an extension or tag",
        add_extensions: "Add extensions",
        my_extensions: "My extensions",
        back: "Back",
      },
      extensionsCard: {
        details: "Details",
        install: "Install",
        request_install: "Request installation",
        requested: "Installation requested",
        installing: "Installing",
        installed: "Installed",
        free: "Free",
        select: "Select a website",
        tooltip_install: "Installation",
        tooltip_month: "Monthly payment",
        site_app: "Sites or Apps",
        errors: {
          site_app: "Select at least on site or app to install",
        },
      },
      extensionsStoreDetail: {
        title: "Extension details",
        free: "Free",
        installed: "Installed",
        about: "About the extension",
        available_for: "Available for",
        install: "Install",
        request_install: "Request installation",
        requested: "Installation requested",
        settings: "Settings",
        configure: "Configure on",
        tooltip_install: "Install",
        tooltip_month: "Monthly payment",
        tooltip_uninstall: "Uninstall",
        tooltip_settings: "Settings",
        tags: "Tags",
        dependencies: "Dependencies",
      },
      extensionInstallPopper: {
        title: "Do you really want to install the extension?",
        request_title:
          "Do you really want to request the installation of the extension?",
        cancel: "Cancel",
        install: "Install",
        request: "Request",
      },
      extensionUninstallPopper: {
        title: "Do you really want to uninstall the extension?",
        cancel: "Cancel",
        uninstall: "Uninstall",
        uninstalling: "Uninstallling",
      },
      extensionSettings: {
        title: "Configure extension",
        identifier: "Identifier",
        property: "Property",
        value: "Value",
        description: "Description",
      },
      settingsCorreios: {
        url_token: "URL Token",
        user_token: "User Token",
        access_token: "Access Code Token",
        post_card: "Post Card",
      },
      settingsGeneral: {
        user_token: "correiosTokenUser",
        access_token: "correiosTokenAccessCode",
        post_card: "correiosTokenPostCard",
        correios_url: "Correios URL",
        correios_token_user: "correiosTokenUser",
        correios_authentication: "correiosAuthenticationUrl",
        correios_locations: "correiosLocationsUrl",
        correios_addresses: "correiosAddressesUrl",
        cep_cache_time: "cepCacheTime",
        correios_page_size: "correiosPageSize",
        bank_base: "bankBaseUrl",
        bank_cooperativas: "bankCooperativasUrl",
        bank_bancos: "bankBancosUrl",
        geolocation: "Geolocation",
        api_geocoding_key: "apiGeocodingKey",
        save: "Save",
        saving: "Saving",
        success: "General settings updated successfully",
        errors: {
          user_token: "The correiosTokenUser is mandatory",
          access_token: "The correiosTokenAccessCode is mandatory",
          post_card: "The correiosTokenPostCard is mandatory",
          correios_url: "The Correios URL is mandatory",
          correios_token_user: "The correiosTokenUser is mandatory",
          correios_authentication: "The correiosAuthenticationUrl is mandatory",
          correios_locations: "The correiosLocationsUrl is mandatory",
          correios_addresses: "The correiosAddressesUrl is mandatory",
          cep_cache_time: "The cepCacheTime is mandatory",
          bank_base: "The bankBaseUrl is mandatory",
          bank_cooperativas: "The bankCooperativasUrl is mandatory",
          bank_bancos: "The bankBancosUrl is mandatory",
          correios_page_size: "The correiosPageSize is mandatory",
          api_geocoding_key: "The apiGeocodingKey is mandatory",
        },
      },
      certillionSettings: {
        title: "Certillion Configs",
        base_url: "Base url",
        client_secret: "Client secret",
        seconds_to_find_signed_files: "Second to find signed files",
        token_path: "Token path",
        upload_file_path: "Upload file path",
        pscs_accounts_path: "Pscs accounts path",
        client_id: "Client id",
        default_minutes_signature_session: "Default minutes signature session",
        authorize_path: "Authorize path",
        signature_path: "Signature path",
        client_token_path: "Client token path",
        save: "Save",
        saving: "Saving",
        success: "Config updated",
        paths: "Paths",
        errors: {
          base_url: "Base url is mandatory",
          client_secret: "Client secret is mandatory",
          seconds_to_find_signed_files:
            "Second to find signed files is mandatory",
          token_path: "Token path is mandatory",
          upload_file_path: "Upload file path is mandatory",
          pscs_accounts_path: "Pscs accounts path is mandatory",
          client_id: "Client id is mandatory",
          default_minutes_signature_session:
            "Default minutes signature session is mandatory",
          authorize_path: "Authorize path is mandatory",
          signature_path: "Signature path is mandatory",
          client_token_path: "Client token path is mandatory",
        },
      },
      settingsCache: {
        settingsCacheTotal: {
          clean_all_cache: "Full Cache Clear (except Hangfire)",
          clean_all_description:
            "It cleans the platform's entire cache, with the exception of those managed by HangFire, our job manager.",
          clean_all_button: "Clean",
          clean_all_success: "Successfully cleared cache",
        },
        settingsCacheKey: {
          clean_key_cache: "Cache Clearing by Key (accepts partial key)",
          clean_key_description:
            "Clears the cache according to the key entered. It also accept to provide a partial key. Example: If EF_ is specified, it will clear the platform's entire Entity Framework cache. If hangfire* is informed, it will clear all cache related to our job manager.",
          clean_key: "Key",
          clean_key_button: "Clean",
          clean_key_success: "Successfully cleared cache for key '{{key}}'",
          errors: {
            cache_key: "The key is mandatory",
          },
        },
        searchCacheKey: {
          search_cache_key: "Search Cache by Key",
          search_cache_description:
            "Searches the cache according to the key entered. It also accept to provide a partial key. Example: If *EF_* is specified, it will search the platform's entire Entity Framework cache. If *HANGFIRE* is informed, it will search all cache related to our job manager.",
          search: "Search",
          search_key: "Key",
          results: "Search results for key",
          no_data: "No information found",
          errors: {
            cache_key: "The key is mandatory",
          },
        },
      },
      neighborhood: {
        neighborhood: "Neighborhood",
        other: "I didn't find my neighborhood and I want to enter a new one",
        cep: "Enter a zip code first",
      },
      addressContent: {
        cep_first: "Enter a zip code first",
      },
      appDomain: {
        domain: "Domain",
        title: "Copy domain",
      },
      nationalHolidays: {
        import_holidays: "Import national holidays",
        select_all: "Select all",
      },
      cepInput: {
        zip_code: "CEP",
        helper_text: "Enter a zip code to search",
        city: "City",
        province: "State",
      },
      daysOfWeek: {
        sunday: "Sunday",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
      },
      notificationInformations: {
        status: "Status",
        is_published: "Publication status",
        start_publish: "Start of publication",
        finish_publish: "End of publication",
        published: "Published",
        not_published: "Not published",
      },
      notificationBatchActions: {
        generate: "Generate batch",
        cancel: "Cancel batch",
        batch_generated: "Batch generated successfully",
        batch_canceled: "Batch canceled successfully",
        cancel_popover_title:
          "When canceling the batch, it will no longer be possible to generate batches for this notification.",
        users: "Notified users",
      },
      searchResults: {
        no_data: "No results found",
      },
      yearSelect: {
        label: "Filter by year",
      },
      syncSection: {
        sync: "Synchronize",
        view_data: "View integrated data",
      },
      syncEstablishment: {
        title: "Establishment",
        description:
          "Synchronize the Establishment’s data with the 4uBus environment",
        synced: "Establishment data synchronized successfully",
        modal_title: "Integrated establishment data",
      },
      syncGeneric: {
        title: "Generic",
        description:
          "Synchronize the Generic's data with the 4uBus environment",
        synced: "Generic data synchronized successfully",
        modal_title: "Integrated generic data",
      },
      syncUsers: {
        title: "User",
        description: "Synchronize the User's data with the 4uBus environment",
        synced: "User data synchronized successfully",
        modal_title: "Integrated user data",
      },
      syncPrivilege: {
        title: "Privilege",
        description:
          "Synchronize the Privilege's data with the 4uBus environment",
        synced: "Privilege data synchronized successfully",
        modal_title: "Integrated privilege data",
      },
      syncEstablishmentParam: {
        title: "Establishment param",
        description:
          "Synchronize the Establishment param's data with the 4uBus environment",
        synced: "Establishment param data synchronized successfully",
        modal_title: "Integrated establishment param data",
      },
      syncRelationshipType: {
        title: "Realationship type",
        description:
          "Synchronize the Realationship type's data with the 4uBus environment",
        synced: "Realationship type data synchronized successfully",
        modal_title: "Integrated relationship type data",
      },
      syncRelationship: {
        title: "Realationship",
        description:
          "Synchronize the Realationship's data with the 4uBus environment",
        synced: "Realationship data synchronized successfully",
        modal_title: "Integrated relationship data",
      },
      syncExtension: {
        title: "Extension",
        description:
          "Synchronize the Extension's data with the 4uBus environment",
        synced: "Extension data synchronized successfully",
        modal_title: "Integrated extension data",
      },
      integratedData: {
        no_data: "No integrated data found",
      },
      filtersActions: {
        filter: "Filter",
        clean: "Clean filters",
        refresh: "Refresh",
      },
      callToActionsButtons: {
        call_to_action: "Call to action",
        change_buttons_order: "Change the button grouping order",
      },
      quickAnswerButtons: {
        quick_aswer: "Quick answer",
        change_buttons_order: "Change the button grouping order",
      },
      buttonSetting: {
        action_type: "Action type",
      },
      accessSiteAction: {
        static: "Static",
        dynamic: "Dynamic",
        text: "Button text",
        url_type: "URL type",
        payload: "Site url",
        url_placeholder: "https://www.exemplo.com",
        url_tooltip:
          "Adding a variable creates a custom link for the customer to view their information. Only one variable can be added to the end of a URL.",
      },
      callAction: {
        text: "Button text",
        country: "Country",
        payload: "Phone",
      },
      copyOfferCodeAction: {
        text: "Button text",
        info: {
          title: "Add sample offer code",
          subtitle:
            "To help us analyze your message template, please add an example offer code.",
        },
        payload: "Offer code",
      },
      selectWhatsButtons: {
        tooltip_limit:
          "Button limit reached, to add another type of button, delete a button first",
        generate_automatically:
          "Buttons are automatically determined according to the selected automation",
        add_button: "Add a button",
        quick_answer_buttons: "Quick response buttons",
        call_to_action_buttons: "Call to action buttons",
        help: "If you add more than 3 buttons, they will appear in a list.",
      },
      whatsButtonsOptions: {
        personalized: {
          name: "Personalized",
        },
        access_site: {
          name: "Access site",
          description: "2 buttons maximum",
        },
        call: {
          name: "Call",
          description: "1 button maximum",
        },
        call_whats: {
          name: "Call on WhatsApp",
          description: "1 button maximum",
        },
        copy_offer_code: {
          name: "Copy offer code",
          description: "1 button maximum",
        },
      },
      customButton: {
        text: "Button text",
      },
      addVariablesButton: {
        variables: "Variables",
        variable: "Variable",
        cancel: "Cancel",
        add: "Add",
        add_variables: "Add variables",
      },
      variablesContainer: {
        title: "Example of body content",
        description:
          "To help us analyze your message template, please include an example of each variable in the body text. Do not use real customer information. The Cloud API hosted by Meta analyzes variable models and parameters to protect the security and integrity of our services.",
      },
      variableField: {
        label: "Insert content to ",
      },
      bodyInput: {
        error: "All variables must be valid",
      },
      timeRules: {
        add_time_rule: "Add time rule",
        time_classification: "Time Classification",
        type: "Type",
        value: "Value",
        search: "Search for a time rule",
        confirm: {
          title: "Delete time rule?",
          description: "This will permanently delete the time rule",
        },
        errors: {
          type: "The type is mandatory",
          time_classification: "The time classification is mandatory",
          value: "The value is mandatory",
        },
      },
      automationMessages: {
        automation_message: "Automation Message",
        new_automation_message: "New automation message",
        add_automation_message: "Add automation message",
        search: "Search for an automation message",
        automation_message_type: "Automation message type",
        message: "Message",
        confirm: {
          title: "Delete automation message?",
          description: "This will permanently delete the automation message",
        },
        errors: {
          automation_message_type: "The automation message type is mandatory",
          message: "The message is mandatory",
        },
      },
      messages: {
        message: "Message",
        new_message: "New message",
        add_message: "Add message",
        search: "Search for a message",
        name: "Name",
        enable: "Enable",
        exception: "Exception",
        send: "Send",
        initial_date: "Initial date",
        end_date: "End date",
        evaluation_form: "Form",
        rounded_schedules_date: "Date Rounding",
        establishment: "Establishment",
        schedule_records: "Schedules",
        schedule_classifications: "Schedule classifications",
        schedule_types: "Schedule type",
        schedule_health_insurances: "Health insurance",
        if: "If",
        then: "Then",
        move_ticket: "Move ticket(card) if person answer other than YES",
        warning:
          "When enabling the exception, at least one of the fields below is mandatory.",
        qty_pacient_sent: "Number of patients to send",
        confirm: {
          title: "Delete message?",
          description: "This will permanently delete the message",
        },
        errors: {
          name: "The name is mandatory",
          evaluation_form: "The form is mandatory",
          initial_date: "The initial date is mandatory",
          end_date: "The end date is mandatory",
          initial_date_bigger:
            "The initial date must be less than the end date",
          qty_pacient_sent: "The number of patients to send is mandatory",
        },
      },
      boardList: {
        board: "Board",
        list: "List",
      },
      ticketServiceAreaDays: {
        ticket_service_area_days: "Service days",
        ticket_service_area_day: "Service day",
        add_ticket_service_area_day: "Add service day",
        new_ticket_service_area_day: "New service day",
        edit_ticket_service_area_day: "Edit service day",
        day_of_week: "Day of week",
        initial_time: "Initial time",
        end_time: "End time",
        enable: "Enable",
        search: "Search for a service day",
        confirm: {
          title: "Delete service day?",
          description: "This will permanently delete the service day",
        },
        errors: {
          day_of_week: "The day of week is mandatory",
          initial_time: "The initial time is mandatory",
          end_time: "The end time is mandatory",
          initial_time_bigger:
            "The initial time must not be bigger than the end time",
        },
      },
      selectCategories: {
        label: "Select categories",
        no_options_text: "No categories selected",
        options_button_label: "Search categories",
        drawer_props: {
          title: "Select Categories",
          create_button_label: "Create new category",
          input_label: "Category name",
          apply_button_label: "Select categories",
        },
      },
    },
    chatBot: {
      page: {
        register: {
          ticketService: {
            id: "Service Id",
            ticket_service: "Ticket(card) service",
            new_ticket_service: "New ticket(card) service",
            enable: "Enable",
            name: "Name",
            ticket_service_type: "Ticket(card) service type",
            search: "Search for a ticket(card) service",
            add_ticket_service: "Add ticket(card) service",
            site_app_extensions: "Sites or Apps",
            ticket_channel_initial: "Initial board",
            whats_settings: "Whatsapp settings",
            typebot_settings: "TypeBot settings",
            ticket_message_received_rvent: "List for messages",
            config_title: "Settings",
            show_message_disable_area:
              "Display a message informing that some options are unavailable because it is outside business hours and requires an in-person attendant",
            show_badge_name_color:
              "Show badge with service name on ticket(card)",
            badge_background_name_color: "Select badge background color",
            badge_name_color: "Select badge text color",
            confirm: {
              title: "Delete ticket(card) service?",
              description:
                "This will permanently delete the ticket(card) service",
            },
            copied_successfully: "Copied successfully",
            errors: {
              name: "The name is mandatory",
              ticket_service_type: "The ticket(card) service type is mandatory",
              site_app_extensions: "At least one site or app is mandatory",
              ticket_channel_initial: "The initial board is mandatory",
              badge_background_name_color:
                "The badge background color is mandatory",
              badge_name_color: "The badge text color is mandatory",
            },
          },
          ticketServiceWhats: {
            ticket_service_whats: "Whatsapp Configuration",
            new_ticket_service_whats: "New Whatsapp Configuration",
            business_phone_number: "Whatsapp Business Phone Number Id",
            business_account: "Whatsapp Business Account Id",
            whatsapp_business: "Whatsapp Business App Id",
            access_token: "Access Token",
            webhook_link: "Webhook Link",
            webhook_link_tooltip: "Copy link",
            verify_token: "Verify Token",
            verify_token_tooltip: "Copy verify token",
            errors: {
              business_phone_number:
                "The Whatsapp Business phone number id is mandatory",
              business_account: "The Whatsapp Business account id is mandatory",
              whatsapp_business: "The Whatsapp Business App id is mandatory",
              access_token: "The access token is mandatory",
            },
          },
          ticketServiceTypebot: {
            ticket_service_typebot: "TypeBot Settings",
            new_ticket_service_typebot: "New TypeBot Configuration",
            typebot_viewer_host: "TypeBot Host Viewer",
            errors: {
              viewer_host: "The typeBot host viewer is mandatory",
            },
          },
        },
        service: {
          protocolGenerateRule: {
            protocol_generate_rules: "Protocol generate rules",
            protocol_generate_rule: "Protocol generate rule",
            new_protocol_generate_rule: "New protocol generate rule",
            name: "Rule name",
            enable: "Enable",
            initial_date: "Start of term",
            end_date: "End of validity",
            search: "Search for a protocol generate rule",
            add_protocol_generate_rule: "Add Protocol Generate Rule",
            protocol_rule_details: "Protocol generation rule details",
            add_protocol_rule_details: "Add protocol generation rule detail",
            order: "Order",
            field_type: "Type",
            fixed_code: "Fixed value",
            mask: "Mask",
            mask_information:
              "The mask is used to format the value of the '{{type}}' field in the protocol, in cases where the length is smaller than the accepted limit.",
            mask_no_information:
              "The mask is used to format the value of the 'type' field in the protocol, in cases where the length is smaller than the accepted limit.",
            confirm: {
              title: "Delete protocol generate rule?",
              description:
                "This will permanently delete the protocol generate rule",
            },
            errors: {
              name: "The rule name is mandatory",
              initial_date: "The start of term is mandatory",
              end_date: "The end of validity is mandatory",
              fixed_code: "The fixed value is mandatory",
              protocol_rule_details:
                "At least one detail of the protocol generation rule is mandatory",
            },
          },
          ticketServiceWhats: {
            ticket_service_whats: "Whatsapp Configuration",
            new_ticket_service_whats: "New Whatsapp Configuration",
            business_phone_number: "Whatsapp Business Phone Number Id",
            business_account: "Whatsapp Business Account Id",
            whatsapp_business: "Whatsapp Business Id",
            access_token: "Access Token",
            errors: {
              business_phone_number:
                "The Whatsapp Business phone number id is mandatory",
              business_account: "The Whatsapp Business account id is mandatory",
              whatsapp_business: "The Whatsapp Business id is mandatory",
              access_token: "The access token is mandatory",
            },
          },
          ticketServiceArea: {
            ticket_service_areas: "Service areas",
            ticket_service_area: "Service area",
            search: "Search for a service area",
            add_ticket_service_area: "Add service area",
            new_ticket_service_area: "New service area",
            ticket_service_area_parent: "Parent Service Area",
            ticket_default_doc: "Default Protocol",
            ticket_event: "Trigger Event",
            ticket_event_type: "Event Type",
            name: "Name",
            code: "Code",
            enable: "Enable",
            identification: "Requires identification",
            enable_all_the_time: "24/7 service",
            enable_special_time: "Special opening hours",
            display_order: "Display order",
            summary: "Summary",
            ticket_services: "Tickets(Cards) Service",
            ticket_channel: "Board to Transfer",
            ticket_channel_step: "List to Transfer",
            service_type: "Type of Service",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            yes: "Yes",
            no: "No",
            actions: "Actions",
            time: "Time",
            confirm: {
              title: "Delete service area?",
              description: "This will permanently delete the service area",
            },
            errors: {
              name: "The name is mandatory",
              display_order: "The display order is mandatory",
              code: "The code is mandatory",
              ticket_services: "The ticket(card) service is mandatory",
              summary: "The summary is mandatory",
              summary_characteres:
                "The summary cannot be longer than 72 characters",
              name_characteres: "The name cannot be longer than 24 characters",
            },
          },
          ticketServiceMessage: {
            ticket_service_messages: "Messages",
            ticket_service_message: "message",
            search: "Search for a message",
            message: "Mensage",
            message_type: "Message type",
            ticket_service: "Ticket(card) service",
            target: "Target",
            errors: {
              message: "The message is mandatory",
              message_type: "The message type is mandatory",
              ticket_service: "The ticket(card) service is mandatory",
            },
          },
          serviceDay: {
            service_days: "Opening hours",
            service_day: "Opening hours",
            new_service_day: "New opening hours",
            search: "Search by opening hours",
            add_service_day: "Add opening hours",
            day_of_week: "Day of week",
            start_time: "Start time",
            end_time: "End time",
            ticket_service: "Ticket(card) service",
            enable: "Enable",
            confirm: {
              title: "Delete opening hours?",
              description: "This will permanently delete the opening hours",
            },
            errors: {
              day_of_week: "The day of week is mandatory",
              ticket_service: "The ticket(card) service is mandatory",
              start_time: "The start time is mandatory",
              end_time: "The end time is mandatory",
            },
          },
        },
        templates: {
          whatsapp: {
            whatsapp_templates: "Whatsapp templates",
            whatsapp_template: "Whatsapp template",
            new_whatsapp_template: "New Whatsapp template",
            search: "Search for a Whatsapp template",
            add_whatsapp_template: "Add Whatsapp template",
            name: "Name",
            enable: "Enable",
            automation: "Automation",
            ticket_service: "Ticket(card) service",
            no_ticket_service:
              "You must have at least one ticket(card) service registered to be able to register a Whatsapp template",
            template_meta_name: "Template name in Meta",
            automation_status: "Automation status",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            message_preview: "Message preview",
            preview_button: "View template preview",
            optional: "Optional",
            header: "Header",
            internal_template: "Internal Template CRM",
            header_sub:
              "Add a title or choose which type of media you will use for this header.",
            title: "Title",
            body: "Body",
            body_sub: "Enter the text of your message.",
            body_placeholder: "Enter the body of your message...",
            footer: "Footer",
            footer_sub:
              "Add a small line of text at the bottom of your message template.",
            buttons: "Buttons",
            buttons_sub:
              "Create buttons for customers to respond to your message or take action.",
            resend: "Reesend",
            resend_success: "Template resent successfully",
            whatsappTemplateAutomation: {
              title: "Change automation?",
              description:
                "Changing the automation will cause all fields to be cleared",
              cancel: "Cancel",
              change: "Change",
            },
            confirm: {
              title: "Delete Whatsapp template?",
              description: "This will permanently delete the Whatsapp template",
            },
            errors: {
              name: "The name is mandatory",
              ticket_service: "Ticket(card) service is mandatory",
              ticket_automation: "The automation is mandatory",
              body: "The body must have at least 1 character",
              body_max: "The body must have a maximum of 1024 characters",
              custom_button_text: "Button text must have at least 1 character",
              custom_button_text_max:
                "Button text must have have a maximum of 25 characters",
              url_type: "URL type is mandatory",
              country: "The country is mandatory",
              text: "The button text must have at least 1 character",
              text_max: "The button text must have a maximum of 25 characters",
              payload: "Field mandatory",
              example: "The example is mandatory",
            },
          },
          internalCrm: {
            internal_crm: "CRM Internal",
            new_variable: "New Variable",
            new_category: "New Category",
            new_template: "New Template",
            search_category: "Search category",
            search_variable: "Search variable",
            search_template: "Search template",
            add_automation_rule: "Add automation rule",
            categories: "Categories",
            variables: "Variables",
            templates: "Templates",
            add_new_item: "Add new field",
            fields: "Fields",
            status: "Status",
            label: "Label",
            screen_order: "Screen Order",
            is_required: "Required",
            name: "Name",
            active: "Active",
            inactive: "Inactive",
            category: "Category",
            data_type: "Data type",
            enable: "Enable",
            code: "Code",
            confirm: {
              title_variable: "Delete variabble?",
              description_variable: "This will permanently delete the variable",
              title_category: "Delete category?",
              description_category: "This will permanently delete the category",
              title_template: "Delete template?",
              description_template: "This will permanently delete the template",
            },
            errors: {
              name: "The name is mandatory",
              label: "The label is mandatory",
              screen_order: "The screen order is mandatory",
              categories: "The category is mandatory",
              data_type: "The data type is mandatory",
              variable: "The variable is mandatory",
              fields: "At least one field is mandatory",
            },
          },
        },
        automations: {
          automationMessageTicketDefaulDoc: {
            title: "Default Document",
            new: "New default document",
            add: "Add default document",
            send_when: "Send When",
            ticket_default_doc: "Default Document",
            schedules: "Schedules",
            confirm: {
              title: "Delete the default document?",
              description: "This will permanently delete the default document",
            },
            errors: {
              ticket_default_doc: "The default document is mandatory",
              send_when: "Send when is mandatory",
            },
          },
          automationRules: {
            automation_rules: "Automation Rules",
            automation_rule: "Automation Rule",
            new_automation_rule: "New automation rule",
            search: "Search for an automation rule",
            add_automation_rule: "Add automation rule",
            name: "Name",
            automation: "Automation",
            created_by: "Created by",
            status: "Status",
            active: "Active",
            inactive: "Inactive",
            enable: "Enable",
            event: "Event",
            ticket_service: "Ticket(card) service",
            initial_date: "Initial date",
            end_date: "End date",
            whatsapp_template: "WhatsApp template",
            automation_message_ticket_defaul_doc: "Default Document",
            robot: "Robot",
            attendant: "Attendant",
            pendent: "Pending Submission",
            when_error: "When there is an error when send the template",
            board: "Board",
            list: "List",
            time_rule: "Time rule",
            automation_message: "Automation message",
            messages: "Messages",
            created_successfully: "Automation rule created successfully",
            confirm: {
              title: "Delete automation rule?",
              description: "This will permanently delete the automation rule",
            },
            errors: {
              name: "The name is mandatory",
              ticket_automation: "The automation is mandatory",
              ticket_event: "The event is mandatory",
              ticket_service: "The ticket(card) service is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              ticket_automation_template: "The WhatsApp template is mandatory",
              ticket_channel_step_robot: "The list for the robot is mandatory",
              ticket_channel_step_attendant:
                "The list for the attendant is mandatory",
              ticket_channel_step_pendent:
                "The list for the pending submission is mandatory",
              initial_date_bigger:
                "The initial date must be less than the end date",
            },
          },
        },
        flows: {
          serviceWhatsapp: {
            title: "You have been redirected to the stream manager",
            subtitle: "To access again click on the button below",
            button: "Access",
          },
        },
      },
    },
    "4uESign": {
      page: {
        automations: {
          automationRules: {
            automation_rules: "Automation Rules",
            automation_rule: "Automation Rule",
            search: "Search for an automation rule",
            name: "Name",
            created_by: "Created by",
            status: "Status",
            add_automation_rule: "Add automation rule",
            new_automation_rule: "New automation rule",
            active: "Active",
            inactive: "Inactive",
            initial_date: "Initial date",
            end_date: "End date",
            esign_automation: "Automation",
            enable: "Enable",
            observation: "Observation",
            file_type: "File type",
            header_pdf: "Header PDF",
            footer_pdf: "Footer PDF",
            file_generation: "From file generation and integration",
            created_successfully: "Automation rule created successfully",
            automation: "Automation",
            confirm: {
              title: "Delete automation rule?",
              description: "This will permanently delete the automation rule",
            },
            errors: {
              name: "The name is mandatory",
              automation: "The automation is mandatory",
              initial_date: "The initial date is mandatory",
              end_date: "The end date is mandatory",
              initial_date_bigger:
                "The initial date must be less than the end date",
            },
          },
        },
      },
    },
  },
};

export default en_US;
