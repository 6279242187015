import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MainApiService from "../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { IExtensionGet } from "../../../models/extension";
import { Chip } from "@mui/material";

const GENERICS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Extension";
const genericService = new MainApiService<IExtensionGet>(GENERICS_ROUTE);

const TRANSLATION_PATH = "page.register.extensions.";

const ExtensionsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IExtensionGet>[] = [
    {
      field: "name",
      headerName: t(TRANSLATION_PATH + "name") || "Name",
    },
    {
      field: "title",
      headerName: t(TRANSLATION_PATH + "title") || "Title",
    },
    {
      field: "enable",
      headerName: t(TRANSLATION_PATH + "status") || "Staus",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(TRANSLATION_PATH + "active")
                : t(TRANSLATION_PATH + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleRowClick = (params: GridRowParams<IExtensionGet>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IExtensionGet>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "extensions")}
      searchInput
      showButton={false}
      onRowClick={handleRowClick}
      showDeleteButton={false}
      rowActions={[
        {
          action: (params) =>
            navigate(`${params.id.toString()}/default-settings`),
          icon: <SettingsIcon />,
          text: `${t(TRANSLATION_PATH + "actions.default_settings")}`,
        },
        {
          action: (params) => navigate(`${params.id.toString()}/price-rules`),
          icon: <AttachMoneyIcon />,
          text: `${t(TRANSLATION_PATH + "actions.price_rules")}`,
        },
      ]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_name") || "Search for a name"
      }
      addButtonLabel={t(TRANSLATION_PATH + "new_extension") || "Add Extension"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ExtensionsPage;
