import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Stack } from "@mui/material";
import MainApi from "../../../../services/mainApi.service";
import { IForuBusLogIpmTasy } from "../model";
import { IFilterParams } from "./MacroFilter";
import MacroFilterIpmTasy from "./MacroFilterIpmTasy";

const translationPath = "page.4uBus.logs.logTasyImp.";

interface ITasyIpmProps {
  route: string;
  title: string;
}

const LogIpmTasy: React.FC<ITasyIpmProps> = ({ route, title }) => {
  const today = useMemo(() => new Date(), []);

  const [refresh, setRefresh] = useState(0);

  const ipmTasyService = useMemo(
    () => new MainApi<IForuBusLogIpmTasy>(route),
    [route]
  );

  const [filterParams, setFilterParams] = useState<IFilterParams>({
    CreatedInitial: today,
    Status: "",
    TasyIndividualIntegrationId: "",
    TasyEncounterIntegrationId: "",
    IpmEncounterIntegrationId: "",
    IpmIndividualIntegrationId: "",
  });

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IForuBusLogIpmTasy>[] = [
    {
      field: "status",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 250,
      renderCell: (params) => {
        return params.value ?? "-";
      },
    },
    {
      field: "statusMessage",
      headerName: t(translationPath + "message_status") || "Message status",
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            variant="subtitle2"
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      maxWidth: 150,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "qtyEmrLabExamResult",
      flex: 1,
      headerName:
        t(translationPath + "qtyEmrLabExamResult") || "Message status",
    },
    {
      field: "qtyEmrNonLaboratoryTests",
      flex: 1,
      headerName:
        t(translationPath + "qtyEmrNonLaboratoryTests") || "Message status",
    },
    {
      field: "qtyEvolution",
      flex: 1,
      headerName: t(translationPath + "qtyEvolution") || "Message status",
    },
    {
      field: "qtyAllergy",
      flex: 1,
      headerName: t(translationPath + "qtyAllergy") || "Message status",
    },
    {
      field: "qtyMedicineInUse",
      flex: 1,
      headerName: t(translationPath + "qtyMedicineInUse") || "Message status",
    },
    {
      field: "tasyEncounterIntegrationId",
      flex: 1,
      headerName:
        t(translationPath + "TasyEncounterIntegrationId") ||
        "Tasy Customer Service Number",
    },
    {
      field: "tasyIndividualIntegrationId",
      flex: 1,
      headerName:
        t(translationPath + "TasyIndividualIntegrationId") ||
        "Personal Code Tasy",
    },
    {
      field: "ipmEncounterIntegrationId",
      flex: 1,
      headerName:
        t(translationPath + "IpmEncounterIntegrationId") ||
        "IPM Customer Service Number",
    },
    {
      field: "ipmIndividualIntegrationId",
      flex: 1,
      headerName:
        t(translationPath + "IpmIndividualIntegrationId") ||
        "Personal Code IPM",
    },
  ];

  const CreatedInitial = filterParams?.CreatedInitial
    ? new Date(filterParams.CreatedInitial.setHours(15))
    : new Date();

  const handleRefresh = () => {
    setRefresh((p) => p + 1);
  };

  return (
    <Stack style={{ height: "100%" }}>
      <img
        src="/LGPD.png"
        width={"auto"}
        height={68}
        alt="LPGD guaranteed"
        style={{ alignSelf: "flex-end" }}
      />
      <Box sx={{ height: "calc(100% - 81.5px)" }}>
        <Table<IForuBusLogIpmTasy>
          title={title}
          columns={columns}
          service={ipmTasyService}
          showButton={false}
          showDeleteButton={false}
          defaultActions={false}
          onRowClick={() => {}}
          showDefaultMacroFilters={false}
          showDelete={false}
          serviceProps={{
            ...filterParams,
            refresh,
            CreatedInitial: CreatedInitial.toISOString().substring(0, 10),
          }}
          getRowId={(r) => r.created + r.tasyEncounterIntegrationId}
          macroFilters={(api) => (
            <MacroFilterIpmTasy
              filterParams={filterParams}
              onFilter={setFilterParams}
              onRefresh={handleRefresh}
            />
          )}
          searchInputPlaceHolder={""}
        />
      </Box>
    </Stack>
  );
};

export default memo(LogIpmTasy);
