import { z } from "zod";

const translationPath = "4uESign.page.automations.automationRules.errors.";

const esignAutomationSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
  },
  { invalid_type_error: translationPath + "automation" }
);

export const esignRuleSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, translationPath + "name"),
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
    enable: z.boolean(),
    observation: z.string().optional().nullish(),
    fileTypeCodeIntegrationId: z.string().optional().nullish(),
    headerPdf: z.string().optional().nullish(),
    footerPdf: z.string().optional().nullish(),
    signaturePdf: z.string().optional().nullish(),
    esignAutomation: esignAutomationSchema,
  })
  .superRefine((val, ctx) => {
    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "initial_date_bigger",
        path: ["initialDate"],
      });
    }
  });

export type TESignRuleForm = z.infer<typeof esignRuleSchema>;
