import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import { ISelectType, Table } from "@4uhub/lib4uhub";
import { Typography } from "@mui/material";

const COUNTRIES_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Country";
const TRANSLATION_PATH = "page.register.countries.";
const genericService = new MainApiService<ISelectType>(COUNTRIES_ROUTE);

const CountriesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<ISelectType>[] = [
    {
      field: "name",
      headerName: t(TRANSLATION_PATH + "name") || "Name",
    },
    {
      field: "code",
      headerName: t(TRANSLATION_PATH + "code") || "Code",
    },
    {
      field: "ddiCode",
      headerName: t(TRANSLATION_PATH + "ddi_code") || "DDI Code",
      renderCell: (params) =>
        params.value ? <Typography>+{params.value}</Typography> : "-",
    },
  ];

  const handleRowClick = (params: GridRowParams<ISelectType>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<ISelectType>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "countries")}
      searchInput
      onAdd={handleAdd}
      showDelete={false}
      showDeleteButton={false}
      defaultActions={false}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_country") || "Searh for a name"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_country") || "Add Country"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default CountriesPage;
