import { Table, useFetch } from "@4uhub/lib4uhub";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ISalesContractBeneficiary } from "../../../../models/sales-contract-beneficiary";
import MainApi from "../../../../services/mainApi.service";
import {
  approveSalesContractBeneficiary,
  cancelSalesContractBeneficiary,
  reestablishedSalesContractBeneficiary,
  rescendSalesContractBeneficiary,
  suspendSalesContractBeneficiary,
} from "../../../../services/salesContractBeneficiary.service";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import SituationChip from "./SituationChip";
const translationsPath =
  "page.4uSalesContract.registers.salesContractBeneficiary.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractBeneficiary/";
const service = new MainApi<ISalesContractBeneficiary>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractBeneficiary>;

export const SalesContractBeneficiarysPage = memo(() => {
  const {
    onOpen,
    selectPropId,
    onRefresh,
    selectTabName,
    onChangeAlreadyOpened,
    refresh,
    alreadyOpened,
  } = useSalesContract();

  const { sendRequest: approve } = useFetch(approveSalesContractBeneficiary);

  const { sendRequest: cancel } = useFetch(cancelSalesContractBeneficiary);

  const { sendRequest: reestablished } = useFetch(
    reestablishedSalesContractBeneficiary
  );
  const { sendRequest: rescend } = useFetch(rescendSalesContractBeneficiary);

  const { sendRequest: suspend } = useFetch(suspendSalesContractBeneficiary);

  const { id, beneficiaryId } = useParams();

  const { t } = useTranslation();

  const translate = (field: string) => t(translationsPath + field);

  const columns: GridColDef<ISalesContractBeneficiary>[] = [
    {
      field: "code",
      headerName: translate("code"),
      renderCell: (params) => (
        <Typography variant="body2">{params.row.code}</Typography>
      ),
    },
    {
      field: "individual",
      headerName: translate("individual_person"),
      renderCell: (params) => {
        if (params.row.individual) {
          return (
            <Typography variant="body2">
              {params.row.individual.individualName}
            </Typography>
          );
        }
        return <Typography variant="body2">-</Typography>;
      },
    },
    {
      field: "situation",
      headerName: translate("situation"),
      renderCell: ({ value }) => (
        <SituationChip name={value?.name} code={value?.code} />
      ),
    },
  ];

  const handleAdd = () => {
    onOpen();
    selectTabName("beneficiary");
  };

  const rowClickHandler = useCallback(
    (params: GridRowParams<ISalesContractBeneficiary>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("beneficiary");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const onApproveSalesContractBeneficiary = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        onRefresh();
      }
    },
    [approve, onRefresh]
  );
  const onCancelSalesContractBeneficiary = useCallback(
    async (id: string) => {
      const { success } = await cancel(id);
      if (success) {
        onRefresh();
      }
    },
    [cancel, onRefresh]
  );

  const onReestablishedSalesContractBeneficiary = useCallback(
    async (id: string) => {
      const { success } = await reestablished(id);
      if (success) {
        onRefresh();
      }
    },
    [reestablished, onRefresh]
  );

  const onRescendSalesContractBeneficiary = useCallback(
    async (id: string) => {
      const { success } = await rescend(id);
      if (success) {
        onRefresh();
      }
    },
    [rescend, onRefresh]
  );

  const onSuspendSalesContractBeneficiary = useCallback(
    async (id: string) => {
      const { success } = await suspend(id);
      if (success) {
        onRefresh();
      }
    },
    [suspend, onRefresh]
  );

  const handleOpenSalesContractBeneficiaryCard = useCallback(
    (params: GridRowParams<ISalesContractBeneficiary>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("salesContractBeneficiaryCard");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const openAutomaticBeneficiary = useCallback(() => {
    if (!beneficiaryId) return;
    if (alreadyOpened) return;
    selectPropId(beneficiaryId);
    onOpen();
    selectTabName("beneficiary");
    onChangeAlreadyOpened();
  }, [
    beneficiaryId,
    alreadyOpened,
    onOpen,
    selectPropId,
    selectTabName,
    onChangeAlreadyOpened,
  ]);

  const props = useMemo(() => {
    return { SalesContractId: id, refresh };
  }, [id, refresh]);

  useEffect(() => {
    openAutomaticBeneficiary();
  }, [openAutomaticBeneficiary]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box height={600}>
        <Table<ISalesContractBeneficiary>
          searchInput
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          onRowClick={rowClickHandler}
          serviceProps={props}
          title={translate("title")}
          addButtonLabel={translate("add")}
          searchInputPlaceHolder={translate("search")}
          rowActions={(params) => [
            {
              action: (params) => {
                handleOpenSalesContractBeneficiaryCard(params);
              },
              icon: <FormatListBulletedIcon fontSize="small" />,
              text: `${t(translationsPath + "beneficiary_card")}`,
            },
            params.row.situation.code === "3" &&
            params.row.approvalDate === null
              ? {
                  action: (params) => {
                    onApproveSalesContractBeneficiary(params.row.id);
                  },
                  icon: <CheckCircleIcon fontSize="small" />,
                  text: `${t(translationsPath + "approve")}`,
                }
              : null,
            params.row.cancelationDate === null &&
            (params.row.situation.code === "3" ||
              params.row.situation.code === "1")
              ? {
                  action: (params) => {
                    onCancelSalesContractBeneficiary(params.row.id);
                  },
                  icon: <DoDisturbOnIcon fontSize="small" />,
                  text: `${t(translationsPath + "cancel")}`,
                }
              : null,
            params.row.reestablishedDate === null &&
            (params.row.situation.code === "4" ||
              params.row.situation.code === "5")
              ? {
                  action: (params) => {
                    onReestablishedSalesContractBeneficiary(params.row.id);
                  },
                  icon: <DoDisturbOnIcon fontSize="small" />,
                  text: `${t(translationsPath + "reestablish")}`,
                }
              : null,
            params.row.rescendDate === null && params.row.situation.code === "1"
              ? {
                  action: (params) => {
                    onRescendSalesContractBeneficiary(params.row.id);
                  },
                  icon: <PauseCircleFilledIcon fontSize="small" />,
                  text: `${t(translationsPath + "rescind")}`,
                }
              : null,
            params.row.suspensionDate === null &&
            params.row.situation.code === "1"
              ? {
                  action: (params) => {
                    onSuspendSalesContractBeneficiary(params.row.id);
                  },
                  icon: <PauseCircleFilledIcon fontSize="small" />,
                  text: `${t(translationsPath + "suspend")}`,
                }
              : null,
          ]}
          confirmOptions={{
            title: translate("confirm.title"),
            description: translate("confirm.description"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      </Box>
    </Box>
  );
});
