import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import { IMetricsData } from "./model";
import GaugeChartBox from "./GaugeChartBox";
import { useTranslation } from "react-i18next";
import Monitoring4uBusService from "../../../../services/monitoring4uBus.service";
import NumberInfo from "./NumberInfo";
import { useFetch } from "@4uhub/lib4uhub";

const translationPath = "page.4uBus.logs.monitoring.";

const service = new Monitoring4uBusService();
// URLs individuais para cada métrica
const apiUrls: Record<keyof IMetricsData, string> = {
  cpu: "100 * (1 - avg(rate(node_cpu_seconds_total{mode='idle'}[5m])))",
  memory:
    "(1 - (node_memory_MemAvailable_bytes / node_memory_MemTotal_bytes)) * 100",
  cpuload:
    "scalar(node_load1) * 100 / count(count(node_cpu_seconds_total) by (cpu))",
  swap: "((node_memory_SwapTotal_bytes - node_memory_SwapFree_bytes) / (node_memory_SwapTotal_bytes)) * 100",
  disk: "100 - ((max(node_filesystem_avail_bytes) * 100) / max(node_filesystem_size_bytes))",
  cpuCount: "count(count(node_cpu_seconds_total) by (cpu))",
  uptime: "node_time_seconds - node_boot_time_seconds",
  rootFS: "max(node_filesystem_size_bytes)",
  ramSize: "node_memory_MemTotal_bytes",
  swapSize: "node_memory_SwapTotal_bytes",
};

// Componente principal do painel de métricas
const MetricsDashboard: React.FC = () => {
  const [data, setData] = useState<IMetricsData>({});
  const [updateInterval, setUpdateInterval] = useState<number>(5000);

  const { t } = useTranslation();

  // Função para buscar os dados de uma métrica específica
  const { sendRequest } = useFetch(service.getQuery);
  const fetchMetric = useCallback(
    async (metricKey: keyof IMetricsData, metricUrl: string) => {
      const { data, success } = await sendRequest(metricUrl);
      if (data && success && data.data && data.data.result.length > 0) {
        const value = parseFloat(data.data.result[0].value[1]);
        setData((prevData) => ({
          ...prevData,
          [metricKey]: parseFloat(value.toFixed(2)),
        }));
      }
    },
    [sendRequest]
  );

  // Função para atualizar todas as métricas
  const updateMetrics = useCallback(() => {
    Object.entries(apiUrls).forEach(([key, url]) => {
      fetchMetric(key as keyof IMetricsData, url);
    });
  }, [fetchMetric]);

  // Hook para atualizar os dados periodicamente
  useEffect(() => {
    updateMetrics();
    const interval = setInterval(updateMetrics, updateInterval);
    return () => clearInterval(interval);
  }, [updateMetrics, updateInterval]); // ✅ Agora está tudo correto!

  return (
    <Box width={"100%"}>
      <Box
        mt={5}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"end"}
      >
        <Grid container>
          <Grid container direction={"row"} gap={3}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {t(translationPath + "title")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction={"row"} gap={3}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t(translationPath + "subtitle")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction={"row"} gap={3}>
            <Grid item xs={12}>
              <Select
                labelId="updateInterval"
                id="updateInterval"
                value={updateInterval}
                onChange={(e) => setUpdateInterval(Number(e.target.value))}
              >
                <MenuItem value={3000}>3 segundos</MenuItem>
                <MenuItem value={5000}>10 segundos</MenuItem>
                <MenuItem value={30000}>30 segundos</MenuItem>
                <MenuItem value={60000}>1 minuto</MenuItem>
                <MenuItem value={60000 * 5}>5 minutos</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container direction={"row"} gap={3} marginTop={5}>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <GaugeChartBox title="CPU (%)" value={data.cpu || 0} />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <GaugeChartBox title="Memory (%)" value={data.memory || 0} />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <GaugeChartBox title="CPU Load" value={data.cpuload || 0} />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <GaugeChartBox title="Swap (%)" value={data.swap || 0} />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <GaugeChartBox title="Disk (%)" value={data.disk || 0} />
            </Grid>
          </Grid>
          <Grid container direction={"row"} gap={3} marginTop={5}>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <NumberInfo title="CPU Cores" value={data.cpuCount || 0} />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <NumberInfo
                title="Memory Size"
                value={
                  data.ramSize
                    ? (data.ramSize / 1024 / 1024 / 1024).toFixed(2) + "GB"
                    : "N/A"
                }
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <NumberInfo
                title="Uptime"
                value={
                  data.uptime
                    ? (data.uptime / 60 / 60 / 24).toFixed(0) +
                      " dia" +
                      (data.uptime / 60 / 60 / 24 > 1 ? "s" : "")
                    : 0
                }
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <NumberInfo
                title="Swap"
                value={
                  data.swapSize
                    ? (data.swapSize / 1024 / 1024 / 1024).toFixed(2) + "GB"
                    : "N/A"
                }
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <NumberInfo
                title="Disk Size"
                value={
                  data.rootFS
                    ? (data.rootFS / 1024 / 1024 / 1024).toFixed(2) + "GB"
                    : "N/A"
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MetricsDashboard;
