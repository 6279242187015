import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, ptBR } from "date-fns/locale";

import { memo, useCallback, useMemo, useState } from "react";
import MainApi from "../../../../services/mainApi.service";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
import { ISalesContractBeneficiaryCard } from "../../../../models/sales-contract-beneficiary-card";
import { SalesContractBeneficiaryCard } from "./SalesContractBeneficiaryCardForm/SalesContractBeneficiaryCard";
const translationsPath =
  "page.4uSalesContract.registers.salesContractBeneficiaryCard.";

const serviceURL =
  process.env.REACT_APP_MAIN + "/api/v1/SalesContractBeneficiaryCard/";
const service = new MainApi<ISalesContractBeneficiaryCard>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractBeneficiaryCard>;

export const SalesContractBeneficiaryCardPage = memo(
  ({ idBeneficiary }: { idBeneficiary: string | null }) => {
    const [
      salesContractBeneficiaryCardForm,
      setSalesContractBeneficiaryCardForm,
    ] = useState(false);
    const { onRefresh, refresh } = useSalesContract();

    const [selectedId, setSelectedId] = useState<string>();

    const {
      t,
      i18n: { language },
    } = useTranslation();
    const translate = (field: string) => t(translationsPath + field);

    const columns: GridColDef<ISalesContractBeneficiaryCard>[] = [
      {
        field: "initialDate",
        headerName: translate("initial_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "endDate",
        headerName: translate("end_date"),
        renderCell: (params) => {
          if (!params.value) return "-";
          const date = new Date(params.value);
          date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
          return format(date, "P", {
            locale: language === "pt-BR" ? ptBR : enUS,
          });
        },
      },
      {
        field: "cardIdentifier",
        headerName: translate("card_identifier"),
        renderCell: ({ value }) => (
          <Typography variant="body2">{value}</Typography>
        ),
      },
    ];

    const handleAdd = () => {
      setSalesContractBeneficiaryCardForm(true);
    };

    const handleRowClick = (
      params: GridRowParams<ISalesContractBeneficiaryCard>
    ) => {
      if (!params.row) return;
      setSalesContractBeneficiaryCardForm(true);
      setSelectedId(params.row.id);
    };

    const handleBack = useCallback(() => {
      setSelectedId(undefined);
      onRefresh();
      setSalesContractBeneficiaryCardForm(false);
    }, [onRefresh]);

    const props = useMemo(() => {
      return {
        salesContractBeneficiaryId: idBeneficiary,
        refresh,
      };
    }, [idBeneficiary, refresh]);

    return (
      <Box sx={{ height: 600, width: "100%" }}>
        {salesContractBeneficiaryCardForm ? (
          <Box
            sx={(t) => ({
              p: 1,
              px: 10,
              width: "100%",
              height: "100%",
              overflow: "auto",
              backgroundColor: t.palette.background.default,
              borderRadius: 1,
            })}
          >
            <SalesContractBeneficiaryCard
              id={selectedId}
              idBeneficiary={idBeneficiary || ""}
              onBackButton={handleBack}
            />
          </Box>
        ) : (
          <Box height={600}>
            <Table<ISalesContractBeneficiaryCard>
              searchInput={false}
              showDefaultMacroFilters={false}
              columns={columns}
              service={service}
              onAdd={handleAdd}
              onRowClick={handleRowClick}
              serviceProps={props}
              title={translate("title")}
              addButtonLabel={translate("add")}
              searchInputPlaceHolder={translate("search")}
              confirmOptions={{
                title: translate("confirm.title"),
                description: translate("confirm.description"),
                confirmationButtonProps: { variant: "contained" },
              }}
            />
          </Box>
        )}
      </Box>
    );
  }
);
