import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IExamsSendRules } from "../../../../../../models/exam-send-rule";
import MainApiService from "../../../../../../services/mainApi.service";

const IPM_GENERAL_SETTINGS_ROUTE =
  process.env.REACT_APP_4UBUS +
  "/api/v1/ExtensionEstablismentTasySendZscanEvo/";
const ipmGeneralSettingsService = new MainApiService<IExamsSendRules>(
  IPM_GENERAL_SETTINGS_ROUTE
);

const translationPath = "page.settings.4uBus.zScan.examSendRule.";

const ExamSendRulePage = () => {
  const navigate = useNavigate();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IExamsSendRules>[] = [
    {
      field: "targetMachine",
      headerName: t(translationPath + "target_machine") || "Target Machine",
      flex: 1,
    },
    {
      field: "procClassificationIntegrationId",
      headerName:
        t(translationPath + "proc_classification_integration_id") ||
        "Internal Process Classification Id",
      flex: 1,
    },
    {
      field: "initialDate",
      headerName: t(translationPath + "initial_date") || "Initial Date",
      maxWidth: 180,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        });
      },
    },
    {
      field: "endDate",
      headerName: t(translationPath + "end_date") || "End Date",
      maxWidth: 180,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        });
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IExamsSendRules>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IExamsSendRules>
      showDefaultMacroFilters={false}
      service={ipmGeneralSettingsService}
      columns={columns}
      title={t(translationPath + "exam_send_rules")}
      onAdd={handleAdd}
      searchInput={false}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={""}
      addButtonLabel={
        t(translationPath + "add_exam_send_rule") || "Add Exam Send Rule"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ExamSendRulePage;
