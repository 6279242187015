import { GlobalAxios } from "../axios/axios-config";
import {
  IEditESignRule,
  IESignRule,
  INewESignRule,
} from "../models/esign-rule";

const ESIGN_RULE_ROUTE = process.env.REACT_APP_4UESIGN + "/api/v1/ESignRule/";

export const getESignRuleById = (id: string) => {
  return GlobalAxios.get<IESignRule>(ESIGN_RULE_ROUTE + id);
};

export const createESignRule = (item: INewESignRule) => {
  return GlobalAxios.post(ESIGN_RULE_ROUTE, item);
};

export const updateESignRuleById = ({
  item,
  id,
}: {
  item: IEditESignRule;
  id: string;
}) => {
  return GlobalAxios.put(ESIGN_RULE_ROUTE + id, item);
};
