import { MainApiService, Table, useRole } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import GroupIcon from "@mui/icons-material/Group";
import { GlobalAxios } from "../../../axios/axios-config";
import { IIndividual } from "../../../models/individuals";
import { CPFMask } from "../../../utils/maks";

const INDIVIDUALS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Individual/";
const TRANSLATION_PATH = "page.register.individuals.";

const genericService = new MainApiService<IIndividual>(
  INDIVIDUALS_ROUTE,
  GlobalAxios
);

const IndividualsPage = () => {
  const navigate = useNavigate();
  const createPermission = useRole(["Create"]);

  const { t } = useTranslation();

  const columns: GridColDef<IIndividual>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(TRANSLATION_PATH + "name") || "Name",
      },
      {
        field: "document",
        headerName: t(TRANSLATION_PATH + "document") || "Document",
        renderCell: (params) => {
          return params.value ? CPFMask.resolve(params.value) : "";
        },
      },
      {
        field: "birthDate",
        headerName: t(TRANSLATION_PATH + "birth_date") || "Birth date",
        renderCell: (params) => {
          if (params.value) {
            const dateParts = params.value.split("-");
            const newDate =
              dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
            return newDate;
          }
          return "-";
        },
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IIndividual>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  const importsPeopleHandler = () => {
    navigate("imports");
  };

  return (
    <Table<IIndividual>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "individuals") || "Individuals"}
      searchInput
      onAdd={handleAdd}
      showButton={createPermission}
      defaultActions={false}
      showDeleteButton={false}
      onRowClick={handleRowClick}
      tableActionsMenu={[
        {
          icon: <GroupIcon />,
          text: t(`${TRANSLATION_PATH}imports`),
          action: importsPeopleHandler,
        },
      ]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_individuals") || "Searh for an individual"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_individual") || "Add Individual"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default IndividualsPage;
