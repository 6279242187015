import { RouteObject } from "react-router-dom";
import WelcomePage from "../pages/welcomePage";
import { DomainProvider } from "@4uhub/lib4uhub";
import AutomationRulePage from "../pages/4uESign/Automations/AutomationRules/AutomationRulesPage";
import AutomationRule from "../pages/4uESign/Automations/AutomationRules/AutomationRuleForm/AutomationRuleForm";

const esign: RouteObject[] = [
  {
    index: true,
    element: <WelcomePage />,
  },
  {
    path: "createAutomations/automationRules",
    element: (
      <DomainProvider value={{ domainName: "esignrule" }}>
        <AutomationRulePage />
      </DomainProvider>
    ),
  },
  {
    path: "createAutomations/automationRules/new",
    element: (
      <DomainProvider value={{ domainName: "esignrule" }} actions={["Create"]}>
        <AutomationRule />
      </DomainProvider>
    ),
  },
  {
    path: "createAutomations/automationRules/:id/edit",
    element: (
      <DomainProvider value={{ domainName: "esignrule" }}>
        <AutomationRule />
      </DomainProvider>
    ),
  },
];

export default esign;
