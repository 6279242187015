import { ptBR, enUS } from "date-fns/locale";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { format } from "date-fns";
import { Table } from "@4uhub/lib4uhub";
import { IGeneric } from "../../../../../models/generics";

const GENERICS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/EstablishmentParamModelCard/";
const genericService = new MainApiService<IGeneric>(GENERICS_ROUTE);

const TRANSLATION_PATH = "page.register.cardsTemplates.";

const CardsTemplatesPage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IGeneric>[] = [
    {
      field: "initialDate",
      headerName: t(TRANSLATION_PATH + "initial_date") || "Initial date",
      renderCell: (params) => {
        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
      maxWidth: 200,
    },
    {
      field: "endDate",
      headerName: t(TRANSLATION_PATH + "end_date") || "End date",
      renderCell: (params) => {
        const endDate = new Date(params.value);
        return format(endDate, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
      maxWidth: 200,
    },
    {
      field: "healthPlan",
      headerName: t(TRANSLATION_PATH + "health_plan") || "Health plan",
      renderCell: (params) => params.value.name,
    },
  ];

  const handleRowClick = (params: GridRowParams<IGeneric>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IGeneric>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "cards_templates")}
      searchInput
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      onRowClick={handleRowClick}
      onAdd={handleAdd}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search") || "Searh for a template"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_card_template") || "Add Card Template"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default CardsTemplatesPage;
