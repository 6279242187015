import {
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFetch } from "@4uhub/lib4uhub";
import SearchResults from "../../components/Settings/SearchResults";
import SettingsSearch from "../../components/Settings/SettingsSearch";
import { ITabProps, TabsComponent } from "../../components/TabsComponent";
import { IMenuRoute } from "../../services/menuRoute.service";
import SettingsService from "../../services/settings.service";
import { settingsGeneralSliceActions } from "../../store/slices/settings-general";
import { useAppDispatch, useAppSelector } from "../../store/store";
import SettingsMenu from "./SettingsMenu";

const translationPath = "page.settings.";

const service = new SettingsService();

const SettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [tabsMenu, setTabsMenu] = useState<IMenuRoute[]>([]);
  const menus = useAppSelector((state) => state.menu.items);

  const loadTabMenus = useCallback(() => {
    const mainMenus = menus.filter((menu) =>
      menu.parent ? menu.parent.description === "Configurações" : null
    );

    menus.filter((menu) =>
      mainMenus.some((main) => {
        if (menu.parent) {
          if (menu.parent.description === main.description) {
            setTabsMenu((oldState) => {
              if (oldState.find((o) => o.id === main.id)) {
                return oldState;
              }
              return [...oldState, main];
            });
            return main;
          }
          return null;
        }
        return null;
      })
    );
  }, [menus]);

  useEffect(() => {
    loadTabMenus();
  }, [loadTabMenus]);

  const tabs: ITabProps[] = tabsMenu
    .sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
    .map((menu, index) => ({
      index: index,
      label: `${t(`${menu.name}`)}`,
      description: menu.description,
    }));

  const { sendRequest, loading } = useFetch(service.getSettingsGeneral);

  const onLoadSettingsGeneral = useCallback(async () => {
    const { data } = await sendRequest(undefined);
    if (data) {
      dispatch(settingsGeneralSliceActions.setSettingsGeneral(data));
    }
  }, [sendRequest, dispatch]);

  useEffect(() => {
    onLoadSettingsGeneral();
  }, [onLoadSettingsGeneral]);

  const hasSearchText = search && search.length !== 0;

  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
        marginRight: matches ? 0 : 30,
        overflow: "hidden",
      }}
    >
      <Stack mt={10} mb={2}>
        <Typography variant="h5">{t(translationPath + "settings")}</Typography>
      </Stack>
      <SettingsSearch onSearch={setSearch} />
      {loading && (
        <Stack flexDirection={"row"} justifyContent={"center"} mt={5}>
          <CircularProgress />
        </Stack>
      )}
      {!loading && (
        <>
          {hasSearchText && (
            <SearchResults
              search={search}
              parentMenus={[
                "Sistema",
                "Configurações Gerais",
                "Applications",
                "CRM",
              ]}
            />
          )}
          {!hasSearchText && (
            <>
              <TabsComponent onChange={setValue} tabs={tabs} value={value} />
              <SettingsMenu
                value={value}
                index={
                  tabs.find((tab) => tab.description === "Sistema")?.index!
                }
                description="Sistema"
                submenuDescription={["Configurações Gerais do Sistema"]}
              />
              <SettingsMenu
                value={value}
                index={
                  tabs.find((tab) => tab.description === "Configurações Gerais")
                    ?.index!
                }
                description="Configurações Gerais"
              />
              <SettingsMenu
                value={value}
                index={
                  tabs.find((tab) => tab.description === "Aplicações")?.index!
                }
                description="Aplicações"
              />
              <SettingsMenu
                value={value}
                index={tabs.find((tab) => tab.description === "CRM")?.index!}
                description="CRM"
              />
              <SettingsMenu
                value={value}
                index={
                  tabs.find((tab) => tab.description === "ChatBot")?.index!
                }
                description="ChatBot"
              />
              <SettingsMenu
                value={value}
                index={tabs.find((tab) => tab.description === "4uHcP")?.index!}
                description="4uHcP"
              />
              <SettingsMenu
                value={value}
                index={tabs.find((tab) => tab.description === "4uBus")?.index!}
                description="4uBus"
                submenuDescription={[
                  "IPM",
                  "ZScan",
                  "Geração de prescrição do pedido de exame externo",
                ]}
              />
              <SettingsMenu
                value={value}
                index={
                  tabs.find((tab) => tab.description === "4uSched")?.index!
                }
                description="4uSched"
                submenuDescription={["Agenda Tasy"]}
              />
              <SettingsMenu
                value={value}
                index={
                  tabs.find((tab) => tab.description === "4uSiteApp")?.index!
                }
                description="4uSiteApp"
                submenuDescription={["Check In da Triagem PA Digital"]}
              />
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default SettingsPage;
