import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { Table } from "@4uhub/lib4uhub";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import MainApiService from "../../../../services/mainApi.service";
import { IGetFaqs } from "../../../../models/faqs";

const FAQS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Faq/";
const faqsService = new MainApiService<IGetFaqs>(FAQS_ROUTE);

const translationPath = "page.register.faq.";

const FaqPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: GridColDef<IGetFaqs>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "displayOrder",
      headerName: t(translationPath + "display_order") || "Display order",
      maxWidth: 150,
    },
    {
      field: "language",
      headerName: t(translationPath + "language") || "Language",
      renderCell: (params) => params.value.name,
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.value ? "success" : "error"}
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
        />
      ),
    },
    {
      field: "redirect",
      headerName: "",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Tooltip title={t(translationPath + "questions_answers")}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${params.row.id}/questions-answers`);
              }}
            >
              <HelpCenterIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetFaqs>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGetFaqs>
      showDefaultMacroFilters={false}
      service={faqsService}
      columns={columns}
      title={t(translationPath + "faqs")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a FAQ"
      }
      addButtonLabel={t(translationPath + "add_faq") || "Add FAQ"}
      rowActions={[
        {
          action: (params) => navigate(`${params.row.id}/questions-answers`),
          icon: <HelpCenterIcon fontSize="small" />,
          text: t(translationPath + "question_answer"),
        },
      ]}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default FaqPage;
