import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  IActionMenuItems,
  Table,
  useFetch,
  UsePrivileges,
} from "@4uhub/lib4uhub";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import MainApiService from "../../../../../services/mainApi.service";
import { ITicketsService } from "../../../../../models/tickets-service";
import TicketServWhatsService from "../../../../../services/ticketServiceWhats.service";
import { getTypebotConfigByTicketId } from "../../../../../services/ticketServiceTypeBot.service";
import TicketServiceId from "./TicketServiceId";

const SITE_APP_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServices/";
const siteAppService = new MainApiService<ITicketsService>(SITE_APP_ROUTE);

const ticketServWhatsService = new TicketServWhatsService();

const translationPath = "chatBot.page.register.ticketService.";

const SiteAppPage = () => {
  const { eI } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { privileges } = UsePrivileges();

  const { sendRequest: getTypebotConfig, loading: isFetchingTypebot } =
    useFetch(getTypebotConfigByTicketId);
  const { sendRequest: getWhatsConfig, loading: isFetchingWhatsapp } = useFetch(
    ticketServWhatsService.getWhatsConfig
  );

  const isSysAdmin = useMemo(
    () => privileges.find(({ name }) => name === "platform-all"),
    [privileges]
  );

  const columns: GridColDef<ITicketsService>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "id",
      headerName: t(translationPath + "id") || "Id do Serviço",
      renderCell: (params) => <TicketServiceId data={params.value} />,
    },
    {
      field: "ticketServiceType",
      renderCell: ({ value: { name } }) => name,
      headerName:
        t(translationPath + "ticket_service_type") || "Ticket Service Type",
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<ITicketsService>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const navigateToSettings = useCallback(
    async (
      ticketServiceId: string,
      service: "whatsapp" | "typebot" = "whatsapp"
    ) => {
      const request = service === "typebot" ? getTypebotConfig : getWhatsConfig;

      const { data: { id } = {} as any, success } = await request(
        ticketServiceId
      );

      if (id && success) {
        navigate(`${service}Config/${ticketServiceId}/${id}/edit`);
      } else {
        navigate(`${service}Config/${ticketServiceId}/new`);
      }
    },
    [getWhatsConfig, navigate, getTypebotConfig]
  );

  const rowActions = useCallback(
    ({
      row: { ticketServiceType } = {} as any,
    }: GridRowParams<ITicketsService>) => {
      let actions: IActionMenuItems<ITicketsService>[] = [];

      if (ticketServiceType.code === "1") {
        actions = actions.concat([
          {
            action: ({
              row: { id } = {} as ITicketsService,
            }: GridRowParams<ITicketsService>) => navigateToSettings(id),
            text: t(translationPath + "whats_settings"),
            icon: <WhatsAppIcon fontSize="small" />,
          },
        ]);

        if (isSysAdmin) {
          actions = actions.concat([
            {
              action: ({
                row: { id } = {} as ITicketsService,
              }: GridRowParams<ITicketsService>) =>
                navigateToSettings(id, "typebot"),
              text: t(translationPath + "typebot_settings"),
              icon: <SettingsSuggestIcon fontSize="small" />,
            },
          ]);
        }
      }

      return actions;
    },
    [isSysAdmin, navigateToSettings, t]
  );

  return (
    <Table<ITicketsService>
      showDefaultMacroFilters={false}
      service={siteAppService}
      columns={columns}
      title={t(translationPath + "ticket_service")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      showDeleteButton={false}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      syncing={isFetchingWhatsapp || isFetchingTypebot}
      rowActions={rowActions}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a ticket service"
      }
      addButtonLabel={
        t(translationPath + "add_ticket_service") || "Add Ticket Service"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default SiteAppPage;
