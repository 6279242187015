import { useTranslation } from "react-i18next";

import { Table } from "@4uhub/lib4uhub";
import { Box, Chip, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { memo, useCallback, useState } from "react";
import { ITemplateInternalTemplates } from "../../../../../models/internal-templates";
import MainApi from "../../../../../services/mainApi.service";
import Templates from "./TemplateForm/Template";
const translationsPath = "chatBot.page.templates.internalCrm.";

const serviceURL =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplate/";
const service = new MainApi<ITemplateInternalTemplates>(serviceURL);

export const TemplatePage = memo(() => {
  const [templateInternalForm, setTemplateInternalForm] = useState(false);

  const [selectedId, setSelectedId] = useState<string>();
  const { t } = useTranslation();
  const translate = (field: string) => t(translationsPath + field);

  const columns: GridColDef<ITemplateInternalTemplates>[] = [
    {
      field: "name",
      headerName: t(translationsPath + "name") || "Name",
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "enable",
      headerName: t(translationsPath + "status") || "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationsPath + "active")
              : t(translationsPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    setTemplateInternalForm(true);
  };

  const handleRowClick = (
    params: GridRowParams<ITemplateInternalTemplates>
  ) => {
    if (!params.row) return;
    setTemplateInternalForm(true);
    setSelectedId(params.row.id);
  };

  const handleBack = useCallback(() => {
    setSelectedId(undefined);
    setTemplateInternalForm(false);
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {templateInternalForm && (
        <Box
          sx={(t) => ({
            p: 1,
            px: 10,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: t.palette.background.default,
            borderRadius: 1,
          })}
        >
          <Templates id={selectedId} onBackButton={handleBack} />
        </Box>
      )}
      {!templateInternalForm && (
        <Table<ITemplateInternalTemplates>
          searchInput
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          onRowClick={handleRowClick}
          title={translate("templates")}
          addButtonLabel={translate("new_template")}
          searchInputPlaceHolder={translate("search_template")}
          confirmOptions={{
            title: translate("confirm.title_template"),
            description: translate("confirm.description_template"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      )}
    </Box>
  );
});
