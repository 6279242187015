import {
  AutoComplete,
  DateInput,
  FormInput,
  ISelectType,
  SwitchInput,
} from "@4uhub/lib4uhub";
import { memo } from "react";
import MainApi from "../../../../../../services/mainApi.service";
import { useTranslation } from "react-i18next";
import { Divider, Grid, Typography } from "@mui/material";
import FormTextEditor from "../../../../../../components/UI/TextEditor/FormTextEditor";

const ESIGN_AUTOMATION_ROUTE =
  process.env.REACT_APP_4UESIGN + "/api/v1/ESignAutomation/";
const esignAutomationService = new MainApi<ISelectType>(ESIGN_AUTOMATION_ROUTE);

const translationPath = "4uESign.page.automations.automationRules.";

export const BasicData = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={12} textAlign={"end"}>
        <SwitchInput name="enable" label={t(translationPath + "enable")} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormInput
          name="name"
          label={t(translationPath + "name")}
          size="small"
          fullWidth
          autoFocus
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AutoComplete
          name="esignAutomation"
          label={t(translationPath + "esign_automation")}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          clearIcon={false}
          request={esignAutomationService.list}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput
          name="initialDate"
          label={t(translationPath + "initial_date")}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput name="endDate" label={t(translationPath + "end_date")} />
      </Grid>

      <Grid item xs={12}>
        <FormInput
          size="small"
          multiline
          name="observation"
          fullWidth
          minRows={4}
          label={t(translationPath + "observation") || "Observation"}
        />
      </Grid>

      <Grid item xs={12} sm={12} mt={2}>
        <Divider sx={{ width: "100%", marginBottom: 2 }} />
        <Typography variant="h6">
          {t(translationPath + "file_generation")}
        </Typography>
      </Grid>

      <Grid item sm={12}>
        <FormInput
          name="fileTypeCodeIntegrationId"
          label={t(translationPath + "file_type")}
          size="small"
          fullWidth
        />
      </Grid>

      <Grid
        item
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography>{t(translationPath + "header_pdf")}</Typography>
        <FormTextEditor name="headerPdf" />
      </Grid>

      <Grid
        item
        sm={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography>{t(translationPath + "footer_pdf")}</Typography>
        <FormTextEditor name="footerPdf" />
      </Grid>
    </>
  );
});
