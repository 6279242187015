import { z } from "zod";

const translationPath = "page.evaluation.forms.errors.";
const typeFormSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "typeForm" }
);
const typeSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "type" }
);

const questionSchema = z.object({
  id: z.string(),
  title: z.string().min(1, translationPath + "title"),
  type: typeSchema.optional(),
  displayOrder: z.string(),
});

const siteAppExtensionSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "site_app_extension" }
);

export const formSchema = z
  .object({
    id: z.string().optional(),
    enable: z.boolean(),
    title: z.string().min(1, translationPath + "title"),
    integrationId: z.string().optional(),
    description: z.string().min(1, translationPath + "description"),
    typeForm: typeFormSchema,
    visualDescription: z
      .string()
      .min(1, translationPath + "visual_description"),
    evaluationFormQuestions: z
      .array(questionSchema)
      .min(1, translationPath + "questions"),
    siteAppExtension: siteAppExtensionSchema,
    initialDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date({ invalid_type_error: translationPath + "end_date" }),
  })
  .superRefine((val, ctx) => {
    if (val.evaluationFormQuestions.some((v) => !v.displayOrder)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "order",
        path: ["evaluationFormQuestions"],
      });
    }
    const displayOrderCounts = val.evaluationFormQuestions.reduce(
      (acc: any, curr) => {
        acc[curr.displayOrder] = (acc[curr.displayOrder] || 0) + 1;
        return acc;
      },
      {}
    );

    const hasDuplicates = Object.values(displayOrderCounts).some(
      (count: any) => count > 1
    );

    if (hasDuplicates) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "duplicate_order",
        path: ["evaluationFormQuestions"],
      });
    }

    if (val.initialDate > val.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationPath + "bigger_date",
        path: ["initialDate"],
      });
    }
  });

export type TFormsForm = z.infer<typeof formSchema>;
