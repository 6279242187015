import { z } from "zod";

const translationPath = "components.certillionSettings.errors.";

export const settingsCertillionConfigSchema = z.object({
  baseUrl: z.string().min(1, translationPath + "base_url"),
  clientId: z.string().min(1, translationPath + "client_id"),
  clientSecret: z.string().min(1, translationPath + "client_secret"),
  defaulMinutesSignatureSession: z
    .string()
    .min(1, translationPath + "default_minutes_signature_session"),
  secondsToFindSignedFiles: z
    .string()
    .min(1, translationPath + "seconds_to_find_signed_files"),
  authorizePath: z.string().min(1, translationPath + "authorize_path"),
  tokenPath: z.string().min(1, translationPath + "token_path"),
  signaturePath: z.string().min(1, translationPath + "signature_path"),
  uploadFilePath: z.string().min(1, translationPath + "upload_file_path"),
  clientTokenPath: z.string().min(1, translationPath + "client_token_path"),
  pscsAccountsPath: z.string().min(1, translationPath + "pscs_accounts_path"),
});

export type TSettingsCertillionConfigForm = z.infer<
  typeof settingsCertillionConfigSchema
>;
