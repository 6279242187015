import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import { Chip } from "@mui/material";
import { Table } from "@4uhub/lib4uhub";
import { IGetSitesApps } from "../../../models/site-app";

const SITE_APP_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/SiteApp/";
const siteAppService = new MainApiService<IGetSitesApps>(SITE_APP_ROUTE);

const translationPath = "page.register.applications.";

const RegisterPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IGetSitesApps>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "siteAppType",
      headerName: t(translationPath + "type") || "Type",
      renderCell: (params) => params.value.name,
    },
    {
      field: "siteAppStatus",
      headerName: t(translationPath + "status") || "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          color={
            params.value.name === "Publicado"
              ? "success"
              : params.value.name === "Inativo"
              ? "error"
              : "default"
          }
          label={params.value.name}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetSitesApps>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGetSitesApps>
      showDefaultMacroFilters={false}
      service={siteAppService}
      columns={columns}
      title={t(translationPath + "applications")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Searh for an application"
      }
      addButtonLabel={
        t(translationPath + "add_application") || "Add Application"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default RegisterPage;
