import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Chip, Typography } from "@mui/material";
import { Table } from "@4uhub/lib4uhub";

import {
  salesContractService,
  buildTPath,
} from "./contract-classifications.utils";
import { ISalesContractClassification } from "../../../../models/sales-contract-classification";

const ContractClassificationsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<ISalesContractClassification>[] = [
    {
      field: "code",
      headerName: t(buildTPath("code"))!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "name",
      headerName: t(buildTPath("name"))!,
    },
    {
      field: "enable",
      maxWidth: 100,
      headerName: t(buildTPath("status"))!,
      renderCell: ({ value }) => (
        <Chip
          size="small"
          color={value ? "success" : "error"}
          label={value ? t(buildTPath("active")) : t(buildTPath("inactive"))}
        />
      ),
    },
  ];

  const handleRowClick = ({
    id,
  }: GridRowParams<ISalesContractClassification>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<ISalesContractClassification>
      searchInput
      showDefaultMacroFilters={false}
      columns={columns}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      service={salesContractService}
      title={t(buildTPath("contract_classification"))}
      addButtonLabel={t(buildTPath("add_contract_classification"))!}
      searchInputPlaceHolder={t(buildTPath("search_contract_classification"))}
      confirmOptions={{
        title: t(buildTPath("confirm.title")),
        description: t(buildTPath("confirm.description")),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ContractClassificationsPage;
