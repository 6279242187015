import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { ptBR, enUS } from "date-fns/locale";
import { format } from "date-fns";
import { IProtocolGenerate } from "../../../../../models/protocol-generate";

const PROTOCOL_GENERATE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketProtocolRules/";
const protocolGenerateService = new MainApiService<IProtocolGenerate>(
  PROTOCOL_GENERATE_ROUTE
);

const translationPath = "chatBot.page.service.protocolGenerateRule.";

const ProtocolGeneratePage = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IProtocolGenerate>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "initialDate",
      headerName: t(translationPath + "initial_date") || "Start of term",
      renderCell: (params) => {
        if (!params.value) return "-";
        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
    {
      field: "endDate",
      headerName: t(translationPath + "end_date") || "End of validity",
      renderCell: (params) => {
        if (!params.value) return "-";
        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale: language === "pt-BR" ? ptBR : enUS,
        });
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IProtocolGenerate>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IProtocolGenerate>
      showDefaultMacroFilters={false}
      service={protocolGenerateService}
      columns={columns}
      title={t(translationPath + "protocol_generate_rules")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a protocol generate rule"
      }
      addButtonLabel={
        t(translationPath + "add_protocol_generate_rule") ||
        "Add Protocol Generate Rule"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ProtocolGeneratePage;
