import { useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

// Tipo das props do GaugeChart
interface GaugeChartProps {
  title: string;
  value: number;
}
// Componente para um gráfico individual (gauge)
const GaugeChart: React.FC<GaugeChartProps> = ({ title, value }) => {
  const theme = useTheme();
  const options: ApexOptions = {
    chart: { type: "radialBar", height: 250 },
    series: [value],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: { background: "#e9ecef", strokeWidth: "100%" },
        hollow: { size: "50%" },
        dataLabels: {
          name: { show: true, offsetY: -20 },
          value: {
            fontSize: "22px",
            show: true,
            offsetY: 0,
            color: theme.palette.mode === "light" ? "#000" : "#fff",
          },
        },
      },
    },
    colors: [value > 70 ? "#dc3545" : value > 50 ? "#ffc107" : "#28a745"],
    labels: [title],
  };

  return (
    <div className="gauge-box">
      <Chart options={options} series={[value]} type="radialBar" height={250} />
    </div>
  );
};

export default GaugeChart;
