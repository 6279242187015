import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IHealthPrograms } from "../../../../models/health-programs";

import GroupIcon from "@mui/icons-material/Group";
import DownloadIcon from "@mui/icons-material/Download";
import RuleIcon from "@mui/icons-material/Rule";

const HEALTH_PROGRAMS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgram/";
const healthProgramsService = new MainApiService<IHealthPrograms>(
  HEALTH_PROGRAMS_ROUTE
);

const translationPath = "page.4uHcp.hcp.healthPrograms.";

const HealthProgramsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IHealthPrograms>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "languages",
      headerName: t(translationPath + "languages") || "Languages",
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IHealthPrograms>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IHealthPrograms>
      showDefaultMacroFilters={false}
      service={healthProgramsService}
      columns={columns}
      title={t(translationPath + "health_programs")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      rowActions={[
        {
          text: t(translationPath + "participants"),
          action: (params) => navigate(`${params.row.id}/participants`),
          icon: <GroupIcon />,
        },
        {
          text: t(translationPath + "imports"),
          action: (params) =>
            navigate(`/e/${eI}/4uHcp/captation/imports/${params.row.id}`),
          icon: <DownloadIcon />,
        },
        {
          text: t(translationPath + "fundraising"),
          action: (params) =>
            navigate(`/e/${eI}/4uHcp/captation/captations/${params.row.id}`),
          icon: <RuleIcon />,
        },
      ]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a health program"
      }
      addButtonLabel={
        t(translationPath + "add_health_program") || "Add Health Program"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HealthProgramsPage;
