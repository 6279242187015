import { FormContainer, Treturn, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import {
  createESignRule,
  getESignRuleById,
  updateESignRuleById,
} from "../../../../../services/esignRule.service";
import { esignRuleSchema, TESignRuleForm } from "./esignRuleSchema";
import { Grid } from "@mui/material";
import { BasicData } from "./BasicData/BasicData";

const translationPath = "4uESign.page.automations.automationRules.";

const AutomationRule = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { eI } = useParams();

  const { sendRequest: save, loading: sLoading } = useFetch(createESignRule);

  const { sendRequest: update, loading: uLoading } =
    useFetch(updateESignRuleById);

  const { sendRequest: get, loading: gLoading } = useFetch(getESignRuleById);

  const saveHandler = (v: TESignRuleForm) => {
    return save({
      ...v,
      esignAutomationId: v.esignAutomation?.id ?? "",
      initialDate: v.initialDate?.toISOString().substring(0, 10),
      endDate: v.endDate?.toISOString().substring(0, 10),
    });
  };
  const updateHandler = (v: TESignRuleForm, id: string) => {
    return update({
      item: {
        ...v,
        esignAutomationId: v.esignAutomation?.id ?? "",
        initialDate: v.initialDate?.toISOString().substring(0, 10),
        endDate: v.endDate?.toISOString().substring(0, 10),
      },
      id: id,
    });
  };

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TESignRuleForm>> => {
      const { data, success } = await get(id);

      if (data) {
        const newData: TESignRuleForm = {
          ...data,
          initialDate: new Date(data.initialDate),
          endDate: new Date(data.endDate),
          headerPdf: data.headerPdf ?? "",
          footerPdf: data.footerPdf ?? "",
        };

        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const redirectOnSuccess = useCallback(() => {
    navigate(`/e/${eI}/4uESign/createAutomations/automationRules/`);
  }, [navigate, eI]);

  const loading = sLoading || uLoading || gLoading;

  return (
    <>
      <FormContainer<TESignRuleForm>
        saveFunction={saveHandler}
        updateFunction={updateHandler}
        getFunction={getHandler}
        loading={loading}
        title={t(translationPath + "automation_rule")}
        schema={esignRuleSchema}
        subtitle={t(translationPath + "new_automation_rule")}
        subtitleWatchField={"name"}
        backUrl={`/e/${eI}/4uESign/createAutomations/automationRules/`}
        redirectOnSave={false}
        onSuccess={redirectOnSuccess}
        marginBottom={5}
        fields={() => (
          <Grid container spacing={2}>
            <BasicData />
          </Grid>
        )}
      />
    </>
  );
};

export default AutomationRule;
