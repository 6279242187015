import { GridColDef } from "@mui/x-data-grid";
import { Table } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { MainApi } from "../../services/mainApi.service";
import { CPFMask } from "../../utils/maks";
import { format } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";

const LOG_REGISTER_ERRORS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Logs/GetLogRegisterError";

interface IRegisterError {
  id: string;
  establishmentId: string;
  document: string;
  errorMessage: string;
  created: string;
}

const logRegisterErrorService = new MainApi<IRegisterError>(
  LOG_REGISTER_ERRORS_ROUTE
);

const translationPath = "page.logs.logRegisterError.";

const LogRegisterErrors = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<IRegisterError>[] = [
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "Pp", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "errorMessage",
      headerName: t(translationPath + "errorMessage") || "Error Message",
      flex: 1,
    },
    {
      field: "document",
      headerName: t(translationPath + "document") || "Document",
      maxWidth: 300,
      valueFormatter(params) {
        return CPFMask.resolve(params.value);
      },
    },
  ];

  return (
    <Table<IRegisterError>
      showDefaultMacroFilters={false}
      service={logRegisterErrorService}
      columns={columns}
      title={t(translationPath + "title")}
      searchInput
      showButton={false}
      showDelete={false}
      showDeleteButton={false}
      defaultActions={false}
      searchInputPlaceHolder={t(translationPath + "placeholder")}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default LogRegisterErrors;
