import { Table, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import GroupsIcon from "@mui/icons-material/Groups";
import { IIndividualImport } from "../../../../models/individuals";
import { importIndividuals } from "../../../../services/individualImports.service";
import MainApi from "../../../../services/mainApi.service";
import { DownloadModel } from "./DownloadModel/DownloadModel";

const INDIVIDUALS_IMPORTS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/IndividualImports";

const individualsImportsService = new MainApi<IIndividualImport>(
  INDIVIDUALS_IMPORTS_ROUTE
);

const TRANSLATION_PATH = "page.register.individualsImports.";

const ImportsPeoplePage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const { sendRequest, loading } = useFetch(importIndividuals);

  const columns: GridColDef<IIndividualImport>[] = useMemo(
    () => [
      {
        field: "fileName",
        headerName: t(TRANSLATION_PATH + "file_name") || "File Name",
      },
      {
        field: "normalizedFileName",
        headerName:
          t(TRANSLATION_PATH + "normalized_file_name") ||
          "Normalized File Name",
      },
      {
        field: "status",
        headerName: t(TRANSLATION_PATH + "status") || "Status",
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IIndividualImport>) => {
    navigate(`${params.id.toString()}`);
  };

  const importIndividualsHandler = useCallback(
    async (params: GridRowParams<IIndividualImport>) => {
      if (!params.row) return;
      const { data, success } = await sendRequest({
        IndividualImportId: params.row.id,
      });
      if (data && success) {
        setMessage({
          message: t(TRANSLATION_PATH + "import_successfully"),
          type: "success",
        });
      }
    },
    [sendRequest, setMessage, t]
  );

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IIndividualImport>
      showDefaultMacroFilters={false}
      service={individualsImportsService}
      columns={columns}
      title={t(TRANSLATION_PATH + "imports") || "Imports"}
      searchInput
      onAdd={handleAdd}
      defaultActions={true}
      showDeleteButton={false}
      onRowClick={handleRowClick}
      syncing={loading}
      backButton
      macroFilters={() => <DownloadModel />}
      rowActions={[
        {
          text: t(TRANSLATION_PATH + "view_individuals") || "View Individuals",
          icon: <GroupsIcon />,
          action: handleRowClick,
        },
        {
          text: t(TRANSLATION_PATH + "import_individuals") || "Import",
          icon: <BrowserUpdatedIcon />,
          action: importIndividualsHandler,
        },
      ]}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_imports") || "Searh for an import"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_import") || "Add Import"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ImportsPeoplePage;
