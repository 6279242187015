import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table, useNotificationContext } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IAccessTokens } from "../../../../models/access-token";
import { Stack } from "@mui/system";
import { IconButton, Tooltip, Typography } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ACCESS_TOKEN_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/4uBusUsers/";
const accessTokenService = new MainApiService<IAccessTokens>(
  ACCESS_TOKEN_ROUTE
);

const translationPath = "page.4uBus.register.accessTokens.";

const AccessTokenPage = () => {
  const navigate = useNavigate();

  const { setMessage } = useNotificationContext();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const copyTokenHandler = (
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    text: string
  ) => {
    event?.stopPropagation();
    navigator.clipboard.writeText(text);
    setMessage({
      message: t(translationPath + "copied_token"),
      type: "success",
    });
  };

  const columns: GridColDef<IAccessTokens>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "tokenPublic",
      headerName: t(translationPath + "token_public") || "Public Token",
      renderCell: (params) => {
        if (params.value.length === 0 || !params.value) {
          return "-";
        }
        return (
          <Stack width={"100%"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={params.value}>
              <Typography
                variant="subtitle2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {params.value}
              </Typography>
            </Tooltip>
            <Tooltip title={t(translationPath + "copy")}>
              <IconButton
                size="small"
                onClick={(e) => copyTokenHandler(e, params.value)}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      maxWidth: 200,
      renderCell: (params) => {
        if (params.value.length === 0 || !params.value) {
          return "-";
        }

        return new Date(params.value)
          .toLocaleString(language)
          .replaceAll(",", "")
          .slice(0, -3);
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IAccessTokens>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IAccessTokens>
      showDefaultMacroFilters={false}
      service={accessTokenService}
      columns={columns}
      title={t(translationPath + "access_tokens")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for an access token"
      }
      addButtonLabel={
        t(translationPath + "add_access_token") || "Add Access Token"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default AccessTokenPage;
