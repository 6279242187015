import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../services/mainApi.service";
import { IProvinces } from "../../../models/provinces";
import { Table } from "@4uhub/lib4uhub";

const TRANSLATION_PATH = "page.register.provinces.";

const PROVINCES_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Province";
const provincesService = new MainApiService<IProvinces>(PROVINCES_ROUTE);

const ProvincesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IProvinces>[] = [
    {
      field: "code",
      headerName: t(TRANSLATION_PATH + "code") || "Code",
      maxWidth: 100,
    },
    {
      field: "name",
      headerName: t(TRANSLATION_PATH + "name") || "Name",
    },
  ];

  const handleRowClick = (params: GridRowParams<IProvinces>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IProvinces>
      showDefaultMacroFilters={false}
      service={provincesService}
      columns={columns}
      title={t(TRANSLATION_PATH + "provinces")}
      searchInput
      onAdd={handleAdd}
      showDelete={false}
      defaultActions={false}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_province") || "Searh for a state"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_province") || "Add State"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ProvincesPage;
