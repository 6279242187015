import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { IBusConfigs } from "../../../../../models/bus-configs";

const BUS_CONFIGS_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/4uBusConfigs/";
const busConfigsService = new MainApiService<IBusConfigs>(BUS_CONFIGS_ROUTE);

const translationPath = "page.settings.4uBus.configs.";

const ConfigsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IBusConfigs>[] = [
    {
      field: "host4uBus",
      headerName: t(translationPath + "host_4ubus") || "4uBus Host",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "hostAws",
      headerName: t(translationPath + "host_aws") || "AWS Host",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "establishment",
      headerName: t(translationPath + "establishment") || "Establishment",
      renderCell: (params) => (params.value ? params.value.name : "-"),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IBusConfigs>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IBusConfigs>
      showDefaultMacroFilters={false}
      service={busConfigsService}
      columns={columns}
      title={t(translationPath + "configs")}
      searchInput={false}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a 4uBus general setting"
      }
      addButtonLabel={
        t(translationPath + "add_config") || "Add General Setting"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ConfigsPage;
