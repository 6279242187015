import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IExtensionGet, IExtensionSettings } from "../../../models/extension";
import { useCallback, useMemo, useState } from "react";
import MainApiService from "../../../services/mainApi.service";
import { Table, useRole } from "@4uhub/lib4uhub";

const EXTENSION_SETTINGS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/ExtensionConfig/";

const extensionSettingsService = new MainApiService<IExtensionSettings>(
  EXTENSION_SETTINGS_ROUTE
);

const TRANSLATION_PATH = "page.extensionSettings.";

const ExtensionSettings = () => {
  const navigate = useNavigate();
  const createPermission = useRole(["Create"]);
  const deletePermission = useRole(["Delete"]);
  const { t } = useTranslation();
  const { eI, siteAppId, extensionId } = useParams();
  const [extensionSettings, setExtensionSettings] = useState<
    IExtensionSettings[]
  >([]);

  const columns: GridColDef<IExtensionSettings>[] = useMemo(
    () => [
      {
        field: "identifier",
        headerName: t(TRANSLATION_PATH + "identifier") || "Identifier",
      },
      {
        field: "property",
        headerName: t(TRANSLATION_PATH + "property") || "Property",
      },
      {
        field: "value",
        headerName: t(TRANSLATION_PATH + "value") || "Value",
      },
      {
        field: "description",
        headerName: t(TRANSLATION_PATH + "description") || "Description",
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IExtensionGet>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate(
      `/e/${eI}/dashboard/extension-settings/${siteAppId}/${extensionId}/new`
    );
  };

  const props = useMemo(
    () => ({
      SiteAppId: siteAppId,
      ExtensionId: extensionId,
    }),
    [siteAppId, extensionId]
  );

  const onLoadSiteAppData = useCallback((data: IExtensionSettings[]) => {
    setExtensionSettings(data);
  }, []);

  return (
    <Table<IExtensionSettings>
      onFetchData={onLoadSiteAppData}
      showDefaultMacroFilters={false}
      columns={columns}
      service={extensionSettingsService}
      serviceProps={props}
      title={t(
        TRANSLATION_PATH + "extension_settings",
        extensionSettings.length !== 0
          ? {
              extension: extensionSettings[0].extension
                ? extensionSettings[0].extension.name
                : "-",
              siteApp: extensionSettings[0].siteApp
                ? extensionSettings[0].siteApp.name
                : "-",
            }
          : {}
      )}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      showButton={createPermission}
      defaultActions={deletePermission}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_config") || "Search for a configuration"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_extension_config") ||
        "Add Extension Configuration"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ExtensionSettings;
