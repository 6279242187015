import { Table } from "@4uhub/lib4uhub";
import { GridColDef } from "@mui/x-data-grid";
import { add } from "date-fns";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useDate from "../../../hooks/useDate";
import MainApi from "../../../services/mainApi.service";
import Form from "../EvaluationLogs/Form";
import Ticket from "../EvaluationLogs/Ticket";
import { IEvaluationLog } from "../EvaluationLogs/model";
import EvaluationId from "../EvaluationLogs/EvaluationId";
import ErrorMessage from "../EvaluationLogs/ErrorMessage";

const translationPath = "page.evaluation.logs.";

const ROUTE = process.env.REACT_APP_MAIN + "/api/v1/EvalMovTicket";

const logRegisterErrorService = new MainApi<IEvaluationLog>(ROUTE);

const EvaluationLogsTicket = () => {
  const { t } = useTranslation();

  const { localeFormat } = useDate();

  const columns: GridColDef<IEvaluationLog>[] = useMemo(
    () => [
      {
        field: "evaluationForm",
        headerName: t(translationPath + "evaluationForm") || "Form",
        renderCell: (params) => <Form data={params.value} />,
      },
      {
        field: "ticket",
        headerName: t(translationPath + "ticket") || "Ticket",
        renderCell: (params) => <Ticket data={params.value} />,
      },
      {
        field: "answerDate",
        headerName: t(translationPath + "answerDate") || "Answer Date",
        valueFormatter(params) {
          return params.value
            ? localeFormat(add(new Date(params.value), { hours: 3 }), "P pp")
            : "-";
        },
      },
      {
        field: "id",
        headerName: t(translationPath + "id") || "Id da Pesquisa",
        renderCell: (params) => <EvaluationId data={params.value} />,
      },
      {
        field: "errorMessage",
        headerName: t(translationPath + "errorMessage") || "Error Message",
        renderCell: (params) => <ErrorMessage>{params.value}</ErrorMessage>,
        maxWidth: 150,
      },
    ],
    [t, localeFormat]
  );

  return (
    <Table<IEvaluationLog>
      showDefaultMacroFilters={false}
      service={logRegisterErrorService}
      columns={columns}
      title={t(translationPath + "title")}
      searchInput={false}
      showButton={false}
      showDelete={false}
      showDeleteButton={false}
      defaultActions={false}
      defaultPageSize={20}
      hideLog
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={t(translationPath + "placeholder")}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default memo(EvaluationLogsTicket);
