import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Table } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MainApi from "../../../../services/mainApi.service";
import { memo, useCallback, useMemo } from "react";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
import { ISalesContractProduct } from "../../../../models/sales-contract-product";
const translationsPath =
  "page.4uSalesContract.registers.salesContractProducts.";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesContractProduct/";
const service = new MainApi<ISalesContractProduct>(serviceURL);

export type TRowClick = GridRowParams<ISalesContractProduct>;

export const SalesContractProductPage = memo(() => {
  const { onOpen, selectTabName, selectPropId, refresh } = useSalesContract();

  const { id } = useParams();
  const { t } = useTranslation();
  const translate = (field: string) => t(translationsPath + field);

  const columns: GridColDef<ISalesContractProduct>[] = [
    {
      field: "code",
      headerName: translate("code"),
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.salesProductCtr?.code}
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: translate("product"),
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.salesProductCtr?.name}
        </Typography>
      ),
    },
  ];

  const handleAdd = () => {
    onOpen();
    selectTabName("product");
  };
  const handleOpenPayerFinantial = useCallback(
    (params: GridRowParams<ISalesContractProduct>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("contractPriceList");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const props = useMemo(() => {
    return { SalesContractId: id, refresh };
  }, [id, refresh]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box height={600}>
        <Table<ISalesContractProduct>
          searchInput={false}
          showDefaultMacroFilters={false}
          columns={columns}
          service={service}
          onAdd={handleAdd}
          serviceProps={props}
          title={translate("title")}
          addButtonLabel={translate("add")}
          searchInputPlaceHolder={""}
          rowActions={(params) => [
            {
              action: (params) => {
                handleOpenPayerFinantial(params);
              },
              icon: <FormatListBulletedIcon fontSize="small" />,
              text: `${t(translationsPath + "price_list_product")}`,
            },
          ]}
          confirmOptions={{
            title: translate("confirm.title"),
            description: translate("confirm.description"),
            confirmationButtonProps: { variant: "contained" },
          }}
        />
      </Box>
    </Box>
  );
});
