import {
  DisplayOrder,
  EditOrder,
  ITreeNode,
  OverflowTooltip,
  SearchInput,
  TableSelect,
  useDebounce,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQuestionList } from "../../../../../models/questions";
import { listQuestions } from "../../../../../services/question.service";
import { TFormsForm } from "../formSchema";
import { SelectCategories } from "../SelectCategories/SelectCategories";
import { ISelectQuestionsProps } from "./models";

const translationPath = "page.evaluation.forms.select_questions.";

export const SelectQuestions = memo(
  ({ defaultQuestions }: ISelectQuestionsProps) => {
    const { t } = useTranslation();

    const [categories, setCategories] = useState<ITreeNode[]>([]);

    const [text, setText] = useState<string>("");

    const { getValues } = useFormContext<TFormsForm>();

    const typeForm = getValues("typeForm");

    const searchBy = useDebounce(text, 500);

    const columns: GridColDef<IQuestionList>[] = useMemo(
      () => [
        {
          field: "displayOrder",
          headerName: `${t(translationPath + "display_order")}`,
          flex: 1,
          maxWidth: 100,
          editable: true,
          type: "number",
          renderCell: (params) => <DisplayOrder params={params} />,
          renderEditCell: (params) => <EditOrder params={params} />,
        },
        {
          field: "title",
          headerName: `${t(translationPath + "title")}`,
          flex: 1,
          renderCell: (params) => {
            return (
              <OverflowTooltip
                text={params.value}
                tooltip={params.value}
                textTransform="none"
                variant="body2"
              />
            );
          },
        },
        {
          field: "questionEvaluationType",
          headerName: `${t(translationPath + "type")}`,
          flex: 1,
          maxWidth: 400,
          renderCell: (params) => {
            return (
              <OverflowTooltip
                text={params.value.name}
                tooltip={params.value.name}
                textTransform="none"
                variant="body2"
              />
            );
          },
        },
      ],
      [t]
    );

    const getNodes = useCallback((value: ITreeNode[]) => {
      setCategories(value);
    }, []);

    const props = useMemo(() => {
      return {
        TypeFormId: typeForm ? typeForm.id : undefined,
        QuestionCategoryIds:
          categories.length > 0
            ? categories.map((category) => category.id)
            : undefined,
        SearchBy: searchBy.length > 0 ? searchBy : undefined,
      };
    }, [categories, searchBy, typeForm]);

    return (
      <TableSelect<IQuestionList>
        name="evaluationFormQuestions"
        initialSelectedItems={defaultQuestions}
        requestOptions={listQuestions}
        columns={columns}
        tableInputProps={{
          label: t(translationPath + "questions"),
          addButtonLabel: t(translationPath + "add_button"),
          modalTitle: t(translationPath + "modal_title"),
          disable: !!typeForm,
        }}
        tableSelectActionsProps={{
          addButtonLabel: t(translationPath + "modal_add_button"),
          removeButtonLabel: t(translationPath + "modal_remove_button"),
        }}
        serviceProps={props}
        filters={
          <>
            <SelectCategories onGetNodes={getNodes} />
            <Grid item xs={12} sm={6}>
              <SearchInput
                onSearch={setText}
                placeholder={t(translationPath + "search")}
                focus={false}
              />
            </Grid>
          </>
        }
      />
    );
  }
);
