import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";

import { Table } from "@4uhub/lib4uhub";
import { IGeneric } from "../../../../../models/generics";

const GENERICS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/EstablishmentParam/";

const TRANSLATION_PATH = "page.register.establishmentParams.";
const genericService = new MainApiService<IGeneric>(GENERICS_ROUTE);

const EstablishmentParamsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IGeneric>[] = useMemo(
    () => [
      {
        field: "establishmentParamType",
        headerName:
          t(TRANSLATION_PATH + "establishmentParamTypeId") || "Param type",
        renderCell: (params) => {
          return params.formattedValue.name;
        },
      },
      {
        field: "description",
        headerName: t(TRANSLATION_PATH + "description") || "Description",
      },
      {
        field: "value",
        headerName: t(TRANSLATION_PATH + "value") || "Value",
      },
      {
        field: "isPublic",
        headerName: t(TRANSLATION_PATH + "public") || "Public",
        renderCell: (params) => {
          if (params.value === true) {
            return t("components.selectBool.true");
          }
          return t("components.selectBool.false");
        },
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IGeneric>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IGeneric>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={
        t(TRANSLATION_PATH + "establishmentParams") || "Establishment params"
      }
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_param") || "Search for a param"
      }
      addButtonLabel={
        t(TRANSLATION_PATH + "add_establishmentParam") ||
        "Add Establishment Param"
      }
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default EstablishmentParamsPage;
