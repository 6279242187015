import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import { Table } from "@4uhub/lib4uhub";
import { useMemo } from "react";

import MainApiService from "../../../../../services/mainApi.service";
import { IGetSitesApps } from "../../../../../models/site-app";
import { IGetQuestionsAnswers } from "../../../../../models/faqs";

const FAQS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/FaqQuestion/";
const faqsService = new MainApiService<IGetQuestionsAnswers>(FAQS_ROUTE);

const translationPath = "page.register.questionsAnswers.";

const QuestionsAnswersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { faqId } = useParams();

  const columns: GridColDef<IGetQuestionsAnswers>[] = [
    {
      field: "name",
      headerName: t(translationPath + "question") || "Question",
      renderCell: (params) => {
        return params.row.question;
      },
    },
    {
      field: "wasUsefulCount",
      headerName: t(translationPath + "was_useful_count") || "Was useful count",
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "status",
      headerName: t(translationPath + "status") || "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.faq.enable ? "success" : "error"}
          label={
            params.row.faq.enable
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetSitesApps>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return { FaqId: faqId };
  }, [faqId]);

  return (
    <Table<IGetQuestionsAnswers>
      showDefaultMacroFilters={false}
      service={faqsService}
      columns={columns}
      title={t(translationPath + "questions_answers")}
      serviceProps={props}
      searchInput
      onAdd={handleAdd}
      backButton
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a question and answer"
      }
      addButtonLabel={
        t(translationPath + "add_questions_answers") ||
        "Add a question and answer"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default QuestionsAnswersPage;
