import { useTranslation } from "react-i18next";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

import { Table, useFetch } from "@4uhub/lib4uhub";

import { useCallback, useMemo } from "react";
import MainApi from "../../../../services/mainApi.service";
import {
  approveSalesSeller,
  inactivateSalesSeller,
  reactivateSalesSeller,
  suspendSalesSeller,
} from "../../../../services/salesSeller.service";
import SituationChip from "./SituationChip";
import { ISaleSeller } from "../../../../models/sales-seller";
import { Box, Typography } from "@mui/material";
import { useSalesContract } from "../../../../store/contexts/SalesContractContext";
import { useParams } from "react-router-dom";

const serviceURL = process.env.REACT_APP_MAIN + "/api/v1/SalesSeller/";
export const salesSellerService = new MainApi<ISaleSeller>(serviceURL);

const translationsPath = "page.4uSalesContract.registers.salesSeller.";

const SalesSellersPage = () => {
  const { onOpen, selectPropId, onRefresh, selectTabName, refresh } =
    useSalesContract();

  const { id } = useParams();

  const { t } = useTranslation();

  const translate = (field: string) => t(translationsPath + field);

  const { sendRequest: approve } = useFetch(approveSalesSeller);
  const { sendRequest: inactivate } = useFetch(inactivateSalesSeller);
  const { sendRequest: reactivate } = useFetch(reactivateSalesSeller);
  const { sendRequest: suspend } = useFetch(suspendSalesSeller);

  const columns: GridColDef<ISaleSeller>[] = [
    {
      field: "code",
      headerName: translate("code"),
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "titleSeller",
      headerName: translate("title_seller"),
    },
    {
      field: "approvalDate",
      headerName: translate("approval_date"),
      renderCell: (params) => {
        if (params.value) {
          const dateParts = params.value.split("-");
          const newDate =
            dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
          return newDate;
        }
        return "-";
      },
    },
    {
      field: "situation",
      maxWidth: 100,
      headerName: translate("situation"),
      renderCell: ({ value }) => (
        <SituationChip name={value?.name} code={value?.code} />
      ),
    },
  ];

  const handleAdd = () => {
    onOpen();
    selectTabName("seller");
  };

  const rowClickHandler = useCallback(
    (params: GridRowParams<ISaleSeller>) => {
      selectPropId(params.row.id);
      onOpen();
      selectTabName("seller");
    },
    [selectPropId, onOpen, selectTabName]
  );

  const onApproveSalesSeller = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        onRefresh();
      }
    },
    [approve, onRefresh]
  );
  const onInactivateSalesSeller = useCallback(
    async (id: string) => {
      const { success } = await inactivate(id);
      if (success) {
        onRefresh();
      }
    },
    [inactivate, onRefresh]
  );
  const onReactivationSalesSeller = useCallback(
    async (id: string) => {
      const { success } = await reactivate(id);
      if (success) {
        onRefresh();
      }
    },
    [reactivate, onRefresh]
  );
  const onSuspensionSalesSeller = useCallback(
    async (id: string) => {
      const { success } = await suspend(id);
      if (success) {
        onRefresh();
      }
    },
    [suspend, onRefresh]
  );

  const props = useMemo(() => {
    return { SalesChannelId: id, refresh };
  }, [id, refresh]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box height={600}>
        <Table<ISaleSeller>
          showDefaultMacroFilters={false}
          service={salesSellerService}
          columns={columns}
          serviceProps={props}
          title={translate("title")}
          searchInput
          onAdd={handleAdd}
          onRowClick={rowClickHandler}
          searchInputPlaceHolder={t(translate("search"))}
          addButtonLabel={translate("add")!}
          rowActions={(params) => [
            params.row.situation.code === "1" &&
            params.row.approvalDate === null
              ? {
                  action: (params) => {
                    onApproveSalesSeller(params.row.id);
                  },
                  icon: <CheckCircleIcon fontSize="small" />,
                  text: `${t(translationsPath + "approve")}`,
                }
              : null,
            params.row.inactivationDate === null &&
            (params.row.situation.code === "1" ||
              params.row.situation.code === "4")
              ? {
                  action: (params) => {
                    onInactivateSalesSeller(params.row.id);
                  },
                  icon: <DoDisturbOnIcon fontSize="small" />,
                  text: `${t(translationsPath + "inactivation")}`,
                }
              : null,
            params.row.situation.code === "3" &&
            params.row.reactivationDate == null
              ? {
                  action: (params) => {
                    onReactivationSalesSeller(params.row.id);
                  },
                  icon: <PlayCircleFilledIcon fontSize="small" />,
                  text: `${t(translationsPath + "reactivation")}`,
                }
              : null,
            params.row.suspensionDate === null &&
            (params.row.situation.code === "1" ||
              params.row.situation.code === "4")
              ? {
                  action: (params) => {
                    onSuspensionSalesSeller(params.row.id);
                  },
                  icon: <PauseCircleFilledIcon fontSize="small" />,
                  text: `${t(translationsPath + "suspension")}`,
                }
              : null,
          ]}
          confirmOptions={{
            title: translate("confirm.title"),
            description: translate("confirm.description"),
            confirmationButtonProps: {
              variant: "contained",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default SalesSellersPage;
