import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../../services/mainApi.service";
import { IWorkGroups } from "../../../../models/work-group";

const WORK_GROUPS_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramWorkGroup/";
const workGroupsService = new MainApiService<IWorkGroups>(WORK_GROUPS_ROUTE);

const translationPath = "page.4uHcp.hcp.workGroups.";

const WorkGroupsPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: GridColDef<IWorkGroups>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "languages",
      headerName: t(translationPath + "languages") || "Languages",
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IWorkGroups>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IWorkGroups>
      showDefaultMacroFilters={false}
      service={workGroupsService}
      columns={columns}
      title={t(translationPath + "work_groups")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a Work Group"
      }
      addButtonLabel={t(translationPath + "add_work_group") || "Add Work Group"}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default WorkGroupsPage;
