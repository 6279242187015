import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Modal, Table } from "@4uhub/lib4uhub";
import { useMemo, useState } from "react";

import MainApiService from "../../../../../services/mainApi.service";

import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { IHolidays } from "../../../../../models/holidays";
import NationalHolidays from "../../../../../components/NationalHolidays/NationalHolidays";
import YearSelect from "../../../../../components/Holidays/YearSelect";

const HOLIDAYS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Holidays/";

const holidaysService = new MainApiService<IHolidays>(HOLIDAYS_ROUTE);

const translationPath = "page.register.holidays.";

const HolidaysPage = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { eI } = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedYear, setSelectedYear] = useState("");

  const [open, setOpen] = useState(false);
  const [openYearModal, setOpenYearModal] = useState(false);

  const [render, setRender] = useState(0);

  const columns: GridColDef<IHolidays>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "description",
      headerName: t(translationPath + "description") || "Description",
    },
    {
      field: "date",
      headerName: t(translationPath + "date") || "Date",
      maxWidth: 200,
      renderCell: (params) => {
        return new Date(params.value).toLocaleDateString(i18n.language);
      },
    },
  ];

  const handleRowClick = (params: GridRowParams<IHolidays>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  const onOpenHandler = () => setOpen(true);
  const onCloseHandler = () => {
    setOpen(false);
  };

  const onOpenYearHandler = () => setOpenYearModal(true);
  const onCloseYearHandler = () => {
    setOpenYearModal(false);
  };

  const onRefreshHandler = () => {
    setRender((prevState) => (prevState = prevState + 1));
    setOpen(false);
  };

  const params = useMemo(() => {
    return {
      Year: selectedYear,
      render: render,
    };
  }, [selectedYear, render]);

  return (
    <>
      <Modal open={open} onClose={onCloseHandler}>
        <NationalHolidays onClose={onRefreshHandler} />
      </Modal>
      <Modal
        open={openYearModal}
        onClose={onCloseYearHandler}
        title={`${t(translationPath + "filter")}`}
      >
        <YearSelect onSelectYear={setSelectedYear} maxWidth={"100%"} />
      </Modal>
      <Table<IHolidays>
        service={holidaysService}
        showDefaultMacroFilters={false}
        columns={columns}
        title={t(translationPath + "holidays")}
        searchInput
        onAdd={handleAdd}
        backButton
        backButtonUrl={`/e/${eI}/dashboard/settings`}
        serviceProps={params}
        onRowClick={handleRowClick}
        searchInputPlaceHolder={
          t(translationPath + "search_holiday") || "Search for a holiday"
        }
        addButtonLabel={t(translationPath + "add_holiday") || "Add Holiday"}
        confirmOptions={{
          title: t(translationPath + "confirm.title"),
          description: t(translationPath + "confirm.description"),
          confirmationButtonProps: {
            variant: "contained",
          },
        }}
        macroFilters={() => {
          return (
            <>
              {!matches && (
                <Stack
                  width={"100%"}
                  minWidth={390}
                  flexDirection={"row"}
                  gap={1}
                >
                  <YearSelect onSelectYear={setSelectedYear} />
                  <Button
                    variant="outlined"
                    startIcon={<SystemUpdateAltIcon />}
                    onClick={onOpenHandler}
                  >
                    {t(translationPath + "import_holidays")}
                  </Button>
                </Stack>
              )}
              {matches && (
                <Stack flexDirection={"row"}>
                  <IconButton onClick={onOpenYearHandler}>
                    <CalendarMonthIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={onOpenHandler}>
                    <SystemUpdateAltIcon color="primary" />
                  </IconButton>
                </Stack>
              )}
            </>
          );
        }}
      />
    </>
  );
};

export default HolidaysPage;
