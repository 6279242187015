import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import { IHealthProgramTypes } from "../../../../../models/health-programs";

const HEALTH_PROGRAM_TYPES_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/HealthProgramType";
const healthProgramTypesService = new MainApiService<IHealthProgramTypes>(
  HEALTH_PROGRAM_TYPES_ROUTE
);

const translationPath = "page.settings.preventMedicine.healthProgramTypes.";

const HealthProgramTypesPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IHealthProgramTypes>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IHealthProgramTypes>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IHealthProgramTypes>
      showDefaultMacroFilters={false}
      service={healthProgramTypesService}
      columns={columns}
      title={t(translationPath + "health_program_types")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      defaultActions={false}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a health program type"
      }
      addButtonLabel={
        t(translationPath + "add_health_program_type") ||
        "Add Health Program Type"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HealthProgramTypesPage;
