import { FC } from "react";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BlockIcon from "@mui/icons-material/Block";
import BuildIcon from "@mui/icons-material/Build";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import BusinessIcon from "@mui/icons-material/Business";
import CachedIcon from "@mui/icons-material/Cached";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DataObjectIcon from "@mui/icons-material/DataObject";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import DomainIcon from "@mui/icons-material/Domain";
import DownloadIcon from "@mui/icons-material/Download";
import DvrIcon from "@mui/icons-material/Dvr";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ExtensionIcon from "@mui/icons-material/Extension";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import ForumIcon from "@mui/icons-material/Forum";
import GradingIcon from "@mui/icons-material/Grading";
import GroupsIcon from "@mui/icons-material/Groups";
import Groups2Icon from "@mui/icons-material/Groups2";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import HomeIcon from "@mui/icons-material/Home";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PeopleIcon from "@mui/icons-material/HowToReg";
import TaskIcon from "@mui/icons-material/Task";
import ImageIcon from "@mui/icons-material/Image";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import KeyIcon from "@mui/icons-material/Key";
import LanguageIcon from "@mui/icons-material/Language";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import ListIcon from "@mui/icons-material/List";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import MessageIcon from "@mui/icons-material/Message";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import NotesIcon from "@mui/icons-material/Notes";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaidIcon from "@mui/icons-material/Paid";
import PaymentIcon from "@mui/icons-material/Payment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PersonIcon from "@mui/icons-material/Person";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import PublicIcon from "@mui/icons-material/Public";
import QuizIcon from "@mui/icons-material/Quiz";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import RuleIcon from "@mui/icons-material/Rule";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import SensorsIcon from "@mui/icons-material/Sensors";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SpaIcon from "@mui/icons-material/Spa";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import SyncIcon from "@mui/icons-material/Sync";
import TerminalIcon from "@mui/icons-material/Terminal";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TodayIcon from "@mui/icons-material/Today";
import TuneIcon from "@mui/icons-material/Tune";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import WebIcon from "@mui/icons-material/Web";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MonitorIcon from "@mui/icons-material/Monitor";

import { SvgIcon, SvgIconProps } from "@mui/material";

import {
  PersonalInjuryIcon,
  PersonCancelIcon,
  PersonCheckIcon,
  PersonManageIcon,
  StethoscopeIcon,
  Sun,
  WeatherNight,
  WeatherSunsetDown,
  WeatherSunsetUp,
} from "./CustomIcons";
interface IDynamicIcon {
  icon?: string | null;
}

const DynamicIcon: FC<SvgIconProps & IDynamicIcon> = ({ icon }, props) => {
  switch (icon) {
    case "ListIcon":
      return <ListIcon {...props} />;
    case "ListAltIcon":
      return <ListAltIcon {...props} />;
    case "HomeIcon":
      return <HomeIcon {...props} />;
    case "FormatListNumberedIcon":
      return <FormatListNumberedIcon {...props} />;
    case "PersonIcon":
      return <PersonIcon {...props} />;
    case "BusinessIcon":
      return <BusinessIcon {...props} />;
    case "HistoryEduIcon":
      return <HistoryEduIcon {...props} />;
    case "ContactPageIcon":
      return <ContactPageIcon {...props} />;
    case "AccountBoxIcon":
      return <AccountBoxIcon {...props} />;
    case "ApartmentIcon":
      return <ApartmentIcon {...props} />;
    case "TuneIcon":
      return <TuneIcon {...props} />;
    case "LanguageIcon":
      return <LanguageIcon {...props} />;
    case "DataObjectIcon":
      return <DataObjectIcon {...props} />;
    case "PublicIcon":
      return <PublicIcon {...props} />;
    case "WebIcon":
      return <WebIcon {...props} />;
    case "SsidChartIcon":
      return <SsidChartIcon {...props} />;
    case "AccountBalanceIcon":
      return <AccountBalanceIcon {...props} />;
    case "ExtensionIcon":
      return <ExtensionIcon {...props} />;
    case "SettingsSuggestIcon":
      return <SettingsSuggestIcon {...props} />;
    case "LocationCityIcon":
      return <LocationCityIcon {...props} />;
    case "CorporateFareIcon":
      return <CorporateFareIcon {...props} />;
    case "FlagCircleIcon":
      return <FlagCircleIcon {...props} />;
    case "MapsHomeWorkIcon":
      return <MapsHomeWorkIcon {...props} />;
    case "HolidayVillageIcon":
      return <HolidayVillageIcon {...props} />;
    case "PhonelinkIcon":
      return <PhonelinkIcon {...props} />;
    case "DisplaySettingsIcon":
      return <DisplaySettingsIcon {...props} />;
    case "DomainIcon":
      return <DomainIcon {...props} />;
    case "EventIcon":
      return <EventIcon {...props} />;
    case "SupportAgentIcon":
      return <SupportAgentIcon {...props} />;
    case "RuleIcon":
      return <RuleIcon {...props} />;
    case "HelpOutlineIcon":
      return <HelpOutlineIcon {...props} />;
    case "ConfirmationNumberIcon":
      return <ConfirmationNumberIcon {...props} />;
    case "ForumIcon":
      return <ForumIcon {...props} />;
    case "PermPhoneMsgIcon":
      return <PermPhoneMsgIcon {...props} />;
    case "PendingActionsIcon":
      return <PendingActionsIcon {...props} />;
    case "NewspaperIcon":
      return <NewspaperIcon {...props} />;
    case "NotificationsIcon":
      return <NotificationsIcon {...props} />;
    case "ImageIcon":
      return <ImageIcon {...props} />;
    case "PaymentIcon":
      return <PaymentIcon {...props} />;
    case "SmartToyIcon":
      return <SmartToyIcon {...props} />;
    case "MarkAsUnreadIcon":
      return <MarkAsUnreadIcon {...props} />;
    case "CachedIcon":
      return <CachedIcon {...props} />;
    case "LocalShippingIcon":
      return <LocalShippingIcon {...props} />;
    case "PaidIcon":
      return <PaidIcon {...props} />;
    case "ShareLocationIcon":
      return <ShareLocationIcon {...props} />;
    case "ImportantDevicesIcon":
      return <ImportantDevicesIcon {...props} />;
    case "VolunteerActivismIcon":
      return <VolunteerActivismIcon {...props} />;
    case "TextSnippetIcon":
      return <TextSnippetIcon {...props} />;
    case "Diversity3Icon":
      return <Diversity3Icon {...props} />;
    case "GroupsIcon":
      return <GroupsIcon {...props} />;
    case "BuildCircleIcon":
      return <BuildCircleIcon {...props} />;
    case "DvrIcon":
      return <DvrIcon {...props} />;
    case "BuildIcon":
      return <BuildIcon {...props} />;
    case "KeyIcon":
      return <KeyIcon {...props} />;
    case "SyncIcon":
      return <SyncIcon {...props} />;
    case "HomeWorkIcon":
      return <HomeWorkIcon {...props} />;
    case "PersonalInjury":
      return <PersonalInjuryIcon />;
    case "Stethoscope":
      return <StethoscopeIcon />;
    case "PersonManage":
      return <PersonManageIcon />;
    case "PersonCheck":
      return <PersonCheckIcon />;
    case "PersonCancel":
      return <PersonCancelIcon />;
    case "LeakAddIcon":
      return <LeakAddIcon {...props} />;
    case "FavoriteIcon":
      return <FavoriteIcon {...props} />;
    case "SpaIcon":
      return <SpaIcon {...props} />;
    case "Groups2Icon":
      return <Groups2Icon {...props} />;
    case "Diversity2Icon":
      return <Diversity2Icon {...props} />;
    case "ContentPasteIcon":
      return <ContentPasteIcon {...props} />;
    case "BlockIcon":
      return <BlockIcon {...props} />;
    case "NotesIcon":
      return <NotesIcon {...props} />;
    case "FormatAlignLeftIcon":
      return <FormatAlignLeftIcon {...props} />;
    case "FactCheckIcon":
      return <FactCheckIcon {...props} />;
    case "ChecklistIcon":
      return <ChecklistIcon {...props} />;
    case "MonitorHeartIcon":
      return <MonitorHeartIcon {...props} />;
    case "GradingIcon":
      return <GradingIcon {...props} />;
    case "CalendarMonthIcon":
      return <CalendarMonthIcon {...props} />;
    case "DateRangeIcon":
      return <DateRangeIcon {...props} />;
    case "EventAvailableIcon":
      return <EventAvailableIcon {...props} />;
    case "AssignmentTurnedInIcon":
      return <AssignmentTurnedInIcon {...props} />;
    case "QuizIcon":
      return <QuizIcon {...props} />;
    case "ArticleIcon":
      return <ArticleIcon {...props} />;
    case "RecentActorsIcon":
      return <RecentActorsIcon {...props} />;
    case "TodayIcon":
      return <TodayIcon {...props} />;
    case "EventNoteIcon":
      return <EventNoteIcon {...props} />;
    case "ViewHeadlineIcon":
      return <ViewHeadlineIcon {...props} />;
    case "PrecisionManufacturingIcon":
      return <PrecisionManufacturingIcon {...props} />;
    case "SensorsIcon":
      return <SensorsIcon {...props} />;
    case "WhatsAppIcon":
      return <WhatsAppIcon {...props} />;
    case "CalendarTodayIcon":
      return <CalendarTodayIcon {...props} />;
    case "WeatherSunsetDown":
      return (
        <SvgIcon component={WeatherSunsetDown} inheritViewBox {...props} />
      );
    case "WeatherSunsetUp":
      return <SvgIcon component={WeatherSunsetUp} inheritViewBox {...props} />;
    case "WeatherNight":
      return <SvgIcon component={WeatherNight} inheritViewBox {...props} />;
    case "Sun":
      return <SvgIcon component={Sun} inheritViewBox {...props} />;
    case "DownloadIcon":
      return <DownloadIcon {...props} />;
    case "TerminalIcon":
      return <TerminalIcon {...props} />;
    case "RssFeedIcon":
      return <RssFeedIcon {...props} />;
    case "LensBlurIcon":
      return <LensBlurIcon {...props} />;
    case "RuleFolderIcon":
      return <RuleFolderIcon {...props} />;
    case "RoomPreferencesIcon":
      return <RoomPreferencesIcon {...props} />;
    case "ReceiptLongIcon":
      return <ReceiptLongIcon {...props} />;
    case "ChecklistRtlIcon":
      return <ChecklistRtlIcon {...props} />;
    case "SwitchAccountIcon":
      return <SwitchAccountIcon {...props} />;
    case "CancelPresentationIcon":
      return <CancelPresentationIcon {...props} />;
    case "FilePresentIcon":
      return <FilePresentIcon {...props} />;
    case "FormatListNumberedRtlIcon":
      return <FormatListNumberedRtlIcon {...props} />;
    case "CardGiftcardIcon":
      return <CardGiftcardIcon {...props} />;
    case "AssignmentIndIcon":
      return <AssignmentTurnedInIcon {...props} />;
    case "AllInboxIcon":
      return <AllInboxIcon {...props} />;
    case "CampaignIcon":
      return <CampaignIcon {...props} />;
    case "SupervisedUserCircleIcon":
      return <SupervisedUserCircleIcon {...props} />;
    case "RequestQuoteIcon":
      return <RequestQuoteIcon {...props} />;
    case "CheckCircleIcon":
      return <CheckCircleIcon {...props} />;
    case "CancelIcon":
      return <CancelIcon {...props} />;
    case "DisabledByDefaultIcon":
      return <DisabledByDefaultIcon {...props} />;
    case "MessageIcon":
      return <MessageIcon {...props} />;
    case "DocumentScannerIcon":
      return <DocumentScannerIcon {...props} />;
    case "HowToRegIcon":
      return <HowToRegIcon {...props} />;
    case "BorderColorIcon":
      return <BorderColorIcon {...props} />;
    case "PeopleIcon":
      return <PeopleIcon {...props} />;
    case "TaskIcon":
      return <TaskIcon {...props} />;
    case "FormatListBulletedIcon":
      return <FormatListBulletedIcon {...props} />;
    case "MonitorIcon":
      return <MonitorIcon {...props} />;
    default:
      return null;
  }
};

export default DynamicIcon;
