import { Table } from "@4uhub/lib4uhub";
import { Chip } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IRules } from "../../../../../../models/external-exam-prescription";
import MainApiService from "../../../../../../services/mainApi.service";

const IPM_GENERAL_SETTINGS_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/ForuBusRuleRequestExtPresProc/";
const ipmGeneralSettingsService = new MainApiService<IRules>(
  IPM_GENERAL_SETTINGS_ROUTE
);

const translationPath = "page.settings.4uBus.externalExamPrescription.rules.";

const RulesPage = () => {
  const navigate = useNavigate();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IRules>[] = [
    {
      field: "medicalProcedureTypeCode",
      flex: 1,
      headerName:
        t(translationPath + "medical_procedure_type_code") ||
        "Medical Procedure Type Code",
    },
    {
      field: "sectorCode",
      maxWidth: 130,
      headerName: t(translationPath + "sector_code") || "Sector Code",
    },
    {
      field: "execPhysicianCode",
      flex: 1,
      headerName:
        t(translationPath + "exec_physician_code") ||
        "Executing Physician Code",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "patientInsuranceCode",
      flex: 1,
      headerName:
        t(translationPath + "patient_insurance_code") ||
        "Patient Insurance Code",
      renderCell: (params) => params.value ?? "-",
    },
    {
      field: "initialDate",
      flex: 1,
      maxWidth: 150,
      headerName: t(translationPath + "initial_date") || "Initial Date",
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "endDate",
      flex: 1,
      maxWidth: 150,
      headerName: t(translationPath + "end_date") || "End Date",
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(params.value);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "isExceptions",
      flex: 1,
      maxWidth: 130,
      headerName: t(translationPath + "is_exception") || "Is Exception",
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.value
                ? t(translationPath + "yes")
                : t(translationPath + "no")
            }
            color={params.value ? "success" : "error"}
            size="small"
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IRules>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IRules>
      showDefaultMacroFilters={false}
      service={ipmGeneralSettingsService}
      columns={columns}
      title={t(translationPath + "rules")}
      searchInput={false}
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      getRowId={(row) => row.identifier}
      rowKey="identifier"
      searchInputPlaceHolder={
        t(translationPath + "search") ||
        "Search for a external exam prescription rule"
      }
      addButtonLabel={
        t(translationPath + "add_rule") || "Add prescription generation rule"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default RulesPage;
