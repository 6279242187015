import { IPrometheusResponse } from "../pages/4uBus/Logs/Monitoring/model";
import { GlobalAxios } from "./../axios/axios-config";

const BUS_ROUTE = process.env.REACT_APP_4UBUS + "/api/v1/Monitoring/Query";

export class Monitoring4uBusService {
  getQuery = (query: string) => {
    return GlobalAxios.get<IPrometheusResponse>(BUS_ROUTE + "?query=" + query);
  };
}

export default Monitoring4uBusService;
