import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainApiService from "../../../../../services/mainApi.service";
import { Table } from "@4uhub/lib4uhub";
import { IGetTicketsServiceArea } from "../../../../../models/ticket-service-area";
import { Chip } from "@mui/material";

const SITE_APP_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketServiceAreas/";
const siteAppService = new MainApiService<IGetTicketsServiceArea>(
  SITE_APP_ROUTE
);

const translationPath = "chatBot.page.service.ticketServiceArea.";

const TicketServiceAreaPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eI } = useParams();

  const columns: GridColDef<IGetTicketsServiceArea>[] = [
    {
      field: "displayOrder",
      headerName: t(translationPath + "display_order") || "Display order",
      maxWidth: 150,
    },
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "code",
      headerName: t(translationPath + "code") || "Code",
    },
    {
      field: "enableAllTheTime",
      headerName: t(translationPath + "service_type") || "Service Type",
      renderCell: (params) =>
        params.row.enableAllTheTime
          ? t(translationPath + "enable_all_the_time")
          : params.row.enableSpecialTime
          ? t(translationPath + "enable_special_time")
          : "-",
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.value ? "success" : "error"}
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
        />
      ),
    },
    {
      field: "isIdentification",
      headerName: t(translationPath + "identification") || "Is Identification",
      maxWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.value ? "success" : "error"}
          label={
            params.value
              ? t(translationPath + "yes")
              : t(translationPath + "no")
          }
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IGetTicketsServiceArea>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IGetTicketsServiceArea>
      showDefaultMacroFilters={false}
      service={siteAppService}
      columns={columns}
      title={t(translationPath + "ticket_service_areas")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a ticket service"
      }
      addButtonLabel={
        t(translationPath + "add_ticket_service_area") ||
        "Add Ticket Service Area"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default TicketServiceAreaPage;
