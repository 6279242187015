import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../../../services/mainApi.service";

import { Box, Chip, Divider, Grid } from "@mui/material";
import { IRestrictionRules } from "../../../../../models/restriction-rules";
import { useMemo } from "react";

const RESTRICTION_RULE_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/RestrictionRule/";
const restrictionRuleService = new MainApiService<IRestrictionRules>(
  RESTRICTION_RULE_ROUTE
);

const translationPath = "page.4uBus.register.restrictionRules.";

interface IRestrictionRuleTableProps {
  extensionCode: string;
}

const RestrictionRuleTable = ({
  extensionCode,
}: IRestrictionRuleTableProps) => {
  const navigate = useNavigate();

  const { id, eI } = useParams();

  const { t } = useTranslation();

  const columns: GridColDef<IRestrictionRules>[] = [
    {
      field: "restrictionRuleType",
      headerName:
        t(translationPath + "restriction_rule_type") || "Restriction Rule Type",
      renderCell: (params) => (params.value ? params.value.name : "-"),
    },
    {
      field: "value",
      headerName: t(translationPath + "value") || "Value",
      renderCell: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate(
      `/e/${eI}/4uBus/register/restrictionRule/${id}/${extensionCode}/new`
    );
  };

  const handleRowClick = (params: GridRowParams<IRestrictionRules>) => {
    navigate(
      `/e/${eI}/4uBus/register/restrictionRule/${id}/${extensionCode}/${params.row.id}/edit`
    );
  };

  const props = useMemo(() => {
    return {
      RestrictionRuleProfileId: id,
      ExtensionCode: extensionCode,
    };
  }, [id, extensionCode]);

  return (
    <Grid mt={2} item container sm={12}>
      <Divider sx={{ width: "100%", mb: 4 }} />
      <Box width={"100%"} height={540}>
        <Table<IRestrictionRules>
          showDefaultMacroFilters={false}
          service={restrictionRuleService}
          columns={columns}
          title={
            t(translationPath + "restriction_rules") || "Restriction Rules"
          }
          searchInput
          onAdd={handleAdd}
          onRowClick={handleRowClick}
          searchInputAutoFocus={false}
          serviceProps={props}
          searchInputPlaceHolder={
            t(translationPath + "search") || "Search for a restriction rule"
          }
          addButtonLabel={
            t(translationPath + "add_restriction_rule") ||
            "Add Restriction Rule"
          }
          confirmOptions={{
            title: t(translationPath + "confirm.title"),
            description: t(translationPath + "confirm.description"),
            confirmationButtonProps: {
              variant: "contained",
            },
          }}
        />
      </Box>
    </Grid>
  );
};

export default RestrictionRuleTable;
