import { z } from "zod";

const translationPath = "page.settings.siteApp.siteAppCheckIn.errors.";

export const siteAppCheckInSchema = z.object({
  id: z.string().optional(),
  time: z.string().min(1, translationPath + "time"),
  metersApproximation: z
    .string()
    .min(1, translationPath + "meters_approximation"),
  latitude: z.string().min(1, translationPath + "latitude"),
  longitude: z.string().min(1, translationPath + "longitude"),
  sectorCode: z
    .string()
    .min(1, translationPath + "standard_service_sector_code"),
  unityCode: z.string().min(1, translationPath + "standard_service_unit"),
  patientEncounterOriginCode: z
    .string()
    .min(1, translationPath + "standard_service_unit"),
  sectorAccommodationType: z
    .string()
    .min(1, translationPath + "standard_service_unit"),
  patientEncounterPhysicianCode: z
    .string()
    .min(1, translationPath + "standard_service_unit"),
});

export type TSiteAppCheckInForm = z.infer<typeof siteAppCheckInSchema>;
