import { GlobalAxios } from "../axios/axios-config";
import { INewSiteAppCheckIn, ISiteAppCheckIn } from "../models/site-app-check-in";

const SITE_APP_CONFIRMATION_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/TasyTriageCheckInRule";

export const createSiteAppCheckIn = (item: INewSiteAppCheckIn) => {
  return GlobalAxios.post(SITE_APP_CONFIRMATION_ROUTE, item);
};

export const getSiteAppCheckInById = (id: string) => {
  return GlobalAxios.get<ISiteAppCheckIn>(
    SITE_APP_CONFIRMATION_ROUTE + "/" + id
  );
};

export const updateSiteAppCheckInById = ({
  item,
  id,
}: {
  item: INewSiteAppCheckIn;
  id: string;
}) => {
  return GlobalAxios.put(SITE_APP_CONFIRMATION_ROUTE + "/" + id, item);
};
