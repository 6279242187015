import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import { useMemo, useState } from "react";
import { Chip } from "@mui/material";

import MainApiService from "../../../services/mainApi.service";

import { IIpmTasyRelationships } from "../../../models/ipm-tasy";
import { useAppSelector } from "../../../store/store";
import CellData from "../../../components/UI/CellData/CellData";
import RelationShipFilter from "./RelationShipFilter";

const ACCESS_TOKEN_ROUTE =
  process.env.REACT_APP_4UBUS + "/api/v1/EstablishmentSetRelationships/";

const accessTokenService = new MainApiService<IIpmTasyRelationships>(
  ACCESS_TOKEN_ROUTE
);

const translationPath = "page.4uBus.relationships.";

const RelationShipPage = () => {
  const [filterParams, setFilterParams] = useState<
    Record<string, any> | undefined
  >();

  const currentPage = useAppSelector((state) => state.menu.actualPage?.name);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { extension } = useParams();

  const columns: GridColDef<IIpmTasyRelationships>[] = [
    {
      field: "setRelationshipType",
      headerName:
        t(translationPath + "relationship_type") || "Relationship Type",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value.name} />;
        }
      },
    },
    {
      field: "directionType",
      headerName: t(translationPath + "direction_type") || "Direction Type",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value.name} />;
        }
      },
    },
    {
      field: "originName",
      headerName: t(translationPath + "origin_name") || "Origin name",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "originValue",
      headerName: t(translationPath + "origin_value") || "Origin value",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "destinyName",
      headerName: t(translationPath + "destiny_name") || "Destiny name",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "destinyValue",
      headerName: t(translationPath + "destiny_value") || "Destiny value",
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        } else {
          return <CellData value={params.value} />;
        }
      },
    },
    {
      field: "default",
      headerName: t(translationPath + "default") || "Default",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "is_default")
                : t(translationPath + "is_not_default")
            }
            color={params.value ? "success" : "default"}
          />
        );
      },
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        );
      },
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IIpmTasyRelationships>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const props = useMemo(() => {
    return {
      ExtensionCode: extension,
      ...filterParams,
    };
  }, [extension, filterParams]);

  return (
    <Table<IIpmTasyRelationships>
      showDefaultMacroFilters={false}
      service={accessTokenService}
      columns={columns}
      title={currentPage ? t(currentPage) : t(translationPath + "relationship")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      serviceProps={props}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a relationship"
      }
      addButtonLabel={
        t(translationPath + "add_relationship") || "Add Relationship"
      }
      macroFilters={() => (
        <RelationShipFilter
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
      )}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default RelationShipPage;
