const pt_BR = {
  translations: {
    home: {
      message: "Olá Mundo",
    },
    page: {
      panels: {
        title: "Painéis",
        edit: "Editar",
        search: "Procure por um painel",
        add: "Adicionar painel",
        confirm: {
          title: "Deletar painel?",
          description:
            "Isso vai permanentemente deletar o painel,seus dashboards e todos os dados.",
        },
        name: "Nome",
      },
      edit_panel: {
        title: "Painel",
        subtitle: "Novo painel",
        name: "Nome",
      },
      under_construction: {
        title: "Em construção",
        subtitle:
          "Esta seção está em construção, enquanto não terminada, visite uma das outras seções",
        button: "Visite Registro de Genéricos",
      },
      login: {
        login: "Acessar",
        accessing: "Acessando",
        welcome: "Bem-vindo de volta",
        rememberme: "Lembre de mim",
        forget_password: "Esqueceu a senha?",
        username: "CPF",
        password: "Senha",
      },
      not_found: {
        page_not_found: "Ooops! Página não encontrada",
        back_to_begin: "Voltar ao início",
        server_not_responding: "O servidor não está respondendo",
        try_again: "Tente novamante",
        back_to_login: "Voltar ao login",
        something_went_wrong: "Algo deu errado :(",
      },
      dashboard: {
        dashboard: "Seja bem-vindo!",
        account_balance: "Saldo da conta",
        refresh_button: "Recarregar",
        idle: "Parado",
        active: "Ativo",
      },
      register: {
        generics: {
          identifier: "Identificador",
          code: "Código",
          value: "Valor",
          generics: "Genéricos",
          generic: "Genérico",
          delete_generics: "Excluir Genéricos",
          search_identifier: "Procure por um identificador",
          edit_generic: "Editar Genérico",
          description: "Descrição",
          new_generic: "Novo Genérico",
          confirm: {
            title: "Deletar genérico?",
            description: "Isso vai permanentemente deletar o genérico",
          },
          errors: {
            identifier: "O identificador é obrigatório",
            code: "O código é obrigatório",
            value: "O valor é obrigatório",
          },
        },
        roles: {
          name: "Nome",
          establishments: "Estabelecimentos",
          full_name: "Nome completo",
          privileges: "Privilégios",
          roles: "Perfil",
          enable: "Habilitar",
          profiles: "Papéis",
          roles_profile: "Papéis que terão este perfil vinculado",
          delete_role: "Excluir perfil",
          search_role: "Procure por um perfil",
          description: "Descrição",
          new_role: "Novo Perfil",
          software: "Software",
          select_software: "Selecione um software",
          config_access: "Configurações de acesso ao perfil",
          confirm: {
            title: "Deletar perfil?",
            description: "Isso vai permanentemente deletar o perfil",
          },
          errors: {
            name: "O nome é obrigatório",
            description: "A descrição é obrigatória",
            establishments: "Ao menos um estabelecimento é obrigatório",
            privilege: "Ao menos um privilégio é obrigatório",
            software: "O software é obrigatório",
          },
        },
        legalPerson: {
          new_legalPerson: "Nova pessoa Jurídica",
          legalPersons: "Pessoas Jurídicas",
          delete_legalPersons: "Deletar Pessoas Jurídicas",
          search_legalPerson:
            "Procure por Documento, Razão Social ou Nome Fantasia",
          add_legalPerson: "Adicionar Pessoa Jurídica",
          //
          document: "CNPJ",
          corporateName: "Razão social",
          tradingName: "Nome fantasia",
          taxRegime: "Regime tributário",
          fiscalContributor: "Contribuinte fiscal",
          stateRegistration: "Inscrição estadual",
          municipalInscription: "Inscrição municipal",
          foreigner: "Estrangeira",
          country: "País",
          //
          contactType: "Tipo de contato",
          addContact: "Adicionar contato",
          //
          addresses: "Endereços",
          add_address: "Adicionar endereço",
          addressType: "Tipo de endereço",
          zip_code: "CEP",
          city: "Cidade:",
          province: "Estado:",
          addressDescription: "Rua",
          establishmentId: "Estabelecimento",
          neighborhood: "Bairro",
          number: "Número",
          complement: "Complemento",
          //
          contacts: "Contatos",
          establishment_tooltip: "Ver estabelecimento",
          confirm: {
            title: "Deletar pessoa jurídica?",
            description: "Isso vai permanentemente deletar a pessoa jurídica",
            title_plural: "Deletar pessoas jurídicas?",
            description_plural:
              "Isso vai permanentemente deletar as pessoas jurídicas",
          },
          //
          errors: {
            document: {
              nomempty: "O documento é obrigatório",
              regex: "Número de CNPJ inválido",
            },
            corporateName: "O nome da empresa é obrigatório",
            country: "O País obrigatório",
            establishment: "O estabelecimento é obrigatório",
            fiscal_contribuitor: "O contribuinte fiscal é obrigatório",
            tax_regime: "O regime tributário é obrigatório",
            contact: {
              contactTypeId: "O tipo de contato é obrigatório",
              value: {
                phone: "Número de telefone inválido",
                email: "Email inválido",
                site: "Site inválido",
                other: "O valor é obrigatório",
              },
            },
            address: {
              zipCode: "Cep inválido",
              addressTypeId: "Selecione um tipo",
              city: "A cidade é obrigatória",
              province: "O estado é obrigatório",
              addressDescription: "A rua é obrigatória",
              neighborhood: "O bairro é obrigatório",
              number: "Número inválido",
            },
          },
        },
        languages: {
          languages: "Idiomas",
          new_language: "Novo idioma",
          edit_language: "Editar idioma",
          search_language: "Pesquise por um idioma",
          name: "Nome",
          code: "Código",
          establishments: "Estabelecimentos",
          establishment_tooltip: "Ver estabelecimentos",
          confirm: {
            title: "Deletar idioma?",
            description: "Isso vai permanentemente deletar o idioma",
          },
          errors: {
            name: "O nome é obrigatório",
            establishments: "O estabelecimento é obrigatório",
          },
        },
        users: {
          users: "Usuários",
          user: "Usuário",
          new_user: "Novo usuário",
          full_name: "Nome completo",
          username: "CPF",
          user_profiles: "Perfis do usuário",
          user_roles: "Papéis do usuário",
          name: "Nome",
          enable: "Habilitar",
          document: "Documento",
          email: "E-mail",
          password: "Senha",
          individual: "Pessoa física",
          resend_mail: "Reenviar e-mail",
          email_confirmed_label: "Status do e-mail",
          email_confirmed_tag: "Confirmado",
          email_not_confirmed_tag: "Não confirmado",
          main_establishments:
            "Principal estabelecimento que o usuário possui vínculo legal",
          config: "Configurações de acesso do usuário",
          establishments_access:
            "Estabelecimentos que o usuário poderá acessar",
          establishments_tooltip: "Ver estabelecimentos",
          establishment_tooltip: "Ver estabelecimento",
          search_user: "Pesquise por um usuário",
          add_user: "Adicionar usuário",
          roles_inherited:
            "Perfis que o usuário herdou com base nos papéis que possui",
          none_roles_inherited: "Nenhum perfil herdado encontrado",
          roles_tooltip: "Ver perfis",
          email_not_confirmed: "Email não confirmado pelo usuário",
          email_confirmation_date: "Data da confirmação do e-mail",
          tooltip_main_estabishment:
            "Por padrão no momento da criação é definido o estabelecimento atual como principal estabelecimento",
          reset_password: {
            button: "Resetar senha",
            title:
              "Você tem tem certeza que deseja resetar a senha do usuário {{username}}?",
            title_form: "Resetar senha do usuário {{username}}",
            site: "Site",
            site_error: "O site é obrigatório",
            sending: "Enviando",
            email_sent: "Enviamos um link de alteração da senha para:",
            confirm: "Resetar",
            cancel: "Cancelar",
          },
          errors: {
            username: "A CPF é obrigatória",
            password: "A senha é obrigatória",
            individual: "A Pessoa física é obrigatório",
            email: "O email é obrigatório",
            establishments: "Selecione ao menos um estabelecimento",
            establishment_standard:
              "O principal estabelecimento que o usuário possui vínculo legal é obrigatório",
            profiles: "Pelo menos um papel do usuário deve ser selecionado",
          },
          confirm: {
            title: "Deletar usuário?",
            description: "Isso vai permanentemente deletar o usuário",
          },
        },
        establishmentParams: {
          establishmentParams: "Parâmetros Gerais",
          value: "Valor",
          description: "Descrição",
          public: "Público",
          establishmentParamTypeId: "Tipo de parâmetro",
          add_establishmentParam: "Adicionar parâmetro geral",
          search_param: "Pesquise por um parâmetro",
          new_param: "Novo parâmetro",
          confirm: {
            title: "Deletar parâmetro geral?",
            description: "Isso vai permanentemente deletar o parâmetro geral",
          },
          errors: {
            value: "O valor é obrigatório",
            description: "A descrição é obrigatória",
            establishmentParamTypeId: "O tipo de parâmetro é obrigatório",
          },
        },
        individuals: {
          individuals: "Pessoa Física",
          individual: "Pessoa Física",
          new_individual: "Nova Pessoa Física",
          search_individuals: "Pesquise por uma pessoa física",
          add_individual: "Adicionar Pessoa Física",
          foreigner: "Estrangeiro",
          identity_card: "RG",
          name: "Nome",
          document: "CPF",
          birth_date: "Data de nascimento",
          contacts: "Contatos",
          add_contact: "Adicionar Contato",
          contact_type: "Tipo de Contato",
          addresses: "Endereços",
          add_address: "Adicionar Endereço",
          address_type: "Tipo de Endereço",
          zip_code: "CEP",
          city: "Cidade",
          province: "Estado",
          country: "País",
          address_description: "Rua",
          neighborhood: "Bairro",
          number: "Número",
          complement: "Complemento",
          gender: "Gênero",
          family_group: "Grupo familiar",
          add_family_group: "Adicionar Grupo Familiar",
          family_individual: "Pessoa Física",
          kinship_degree: "Grau de Parentesco",
          imports: "Importações",
          confirm: {
            title: "Deletar pessoa física?",
            description: "Isso vai permanentemente deletar a pessoa física",
          },
          errors: {
            name: "O nome é obrigatório",
            document: "O documento é obrigatório",
            document_regex: "Precisa ser um CPF válido",
            birth_date: "A data de nascimento é obrigatória",
            country: "O país é obrigatório",
            gender: "O gênero é obrigatório",
            contact: {
              contactTypeId: "O tipo de contato é obrigatório",
              phone: "O número de telefone é obrigatório",
              email: "O e-mail é obrigatório",
              site: "O site é obrigatório",
              other: "O campo é obrigatório",
            },
            address: {
              addressTypeId: "O tipo de endereço é obrigatório",
              zipCode: "O CEP é obrigatório",
              city: "A cidade é obrigatória",
              provincy: "O estado é obrigatório",
              addressDescription: "A rua é obrigatória",
              neighborhood: "O bairro é obrigatório",
              number: "O número é obrigatório",
            },
            family_individual: "A pessoa física é obrigatória",
            kinship_degree: "O grau de parentesco é obrigatório",
          },
          establishments: {
            establishments: "Estabelecimentos",
          },
        },
        individualsImports: {
          imports: "Importações",
          import: "Importação",
          search: "Pesquise por uma importação",
          new_import: "Nova importação",
          file_name: "Nome do arquivo",
          normalized_file_name: "Nome do arquivo normalizado",
          status: "Status",
          add_import: "Adicionar importação",
          view_individuals: "Ver Pessoas Físicas",
          import_individuals: "Importar Pessoas Físicas",
          import_successfully: "Importação realizada com sucesso",
          download_model: "Baixar modelo",
          search_imports: "Procure por uma importação",
        },
        importList: {
          imports_individuals: "Pessoas Físicas da Importação",
          name: "Nome",
          document: "Documento",
          status: "Status",
          birth_date: "Data de nascimento",
          gender: "Gênero",
          foreigner: "Estrangeiro",
          yes: "Sim",
          no: "Não",
          cns: "CNS",
          address: "Endereço",
          number: "Número",
          neighborhood: "Bairro",
          complement: "Complemento",
          city: "Cidade",
          zip_code: "CEP",
          country: "País",
          neighborhood_code: "Código do bairro",
          city_ibge_code: "Código IBGE da cidade",
          identity_card: "RG",
          email: "E-mail",
          phone: "Telefone",
          view: "Ver mais",
        },
        documents: {
          documents: "Termos e políticas",
          version: "Versão",
          date: "Data",
          document: "Documento",
          add_document: "Adicionar documento",
          doc_type: "Tipo de documento",
          select_doc_type: "Selecione o tipo de documento",
          no_docs_selected: "Nenhum tipo de documento foi selecionado",
          no_docs_version: "Tipo de documento não possui uma versão",
          see_document: "Ver documento",
          table_title: "Histórico de versões",
          export_csv: "Exportar Csv",
          docsVersion: {
            last_version: "Última versão",
            new_version: "Nova versão",
            edit_version: "Editar",
          },
          create_document: {
            new_document: "Documento",
            add_new_language: "Adicionar novo idioma",
            version: "Versão",
            version_date: "Data da versão",
            add_document: "Adicionar documento",
            language: "Idioma",
            name: "Nome",
            content: "Conteúdo",
            enable: "Habilitar",
            language_tooltip: "Ver idioma",
          },
          document_modal: {
            version: "Versão",
            version_date: "Data da versão",
            name: "Nome",
            language: "Idioma",
            content: "Conteúdo",
          },
          errors: {
            version: "A versão é obrigatória",
            version_regex: "A versão deve ser um número",
            language: "O idioma é obrigatório",
            name: "O nome é obrigatório",
            content: "O conteúdo é obrigatório",
          },
        },
        establishments: {
          establishments: "Estabelecimentos",
          establishment: "Estabelecimento",
          new_establishment: "Novo estabelecimento",
          name: "Nome",
          ansCode: "Código da Operadora na ANS",
          integration: "Código de estabelecimento no cliente",
          domain_admin: "Domínio do painel administrativo",
          collector_host: "Host coletor",
          parent_host: "Host coletor da nuvem",
          real_time_host: "Host em tempo real",
          search_establishment: "Pesquise por um nome",
          add_establishment: "Adicionar estabelecimento",
          company: "Empresa",
          enable: "Habilitado",
          config_on_premise: "Configurações do ambiente on premise",
          languages: "Idiomas",
          sync: "Sincronizar ambiente on premise",
          sync_beneficiaries: "Sincronizar beneficiários",
          see_establishments: "Ver Estabelecimento on premise",
          see_params: "Ver Parâmetros on premise",
          see_generics: "Ver Genéricos on premise",
          see_health: "Planos de Saúde sincronizados",
          see_medic_specialties_on_premise: "Ver especialidades médicas",
          see_medical_practice_areas: "Ver áreas de atuação médica",
          see_provider_types: "Ver tipos de prestadores",
          see_professional_councils: "Ver conselhos profissionais",
          see_extensions: "Ver extensões on premise",
          synced: "Sincronizado com sucesso",
          jobs: "Jobs",
          crmDomain: "Domínio do CRM",
          languages_tooltip: "Ver idiomas",
          extensions_on_premise: "Extensões on premise",
          generics_on_premise: "Genéricos on premise",
          params_on_premise: "Parâmetros on premise",
          establishment_on_premise: "Estabelecimento on premise",
          health_on_premise: "Planos de Saúde sincronizados",
          provider_types_on_premise: "Tipos de prestadores",
          medic_specialties_on_premise: "Especialidades médicas",
          professional_councils_on_premise: "Conselhos profissionais",
          medical_practice_areas_on_premise: "Áreas de atuação médica",
          domain: "Domínio",
          filemanager_host: "Host do filemanager na nuvem",
          softwares: "Aplicações",
          legal_entity: "Pessoa Jurídica",
          confirm: {
            title: "Deletar estabelecimento?",
            description: "Isso vai permanentemente deletar o estabelecimento",
          },
          errors: {
            name: "O nome é obrigatório",
            company: "A empresa é obrigatória",
            languages: "Selecione ao menos um idioma",
            integration:
              "O código de estabelecimento no cliente deve ser maior que 1",
            domain_admin: "O domínio do painel administrativo é obrigatório",
            collector_https:
              "O host coletor precisa começar com 'http://' ou 'https://'",
            parent_https:
              "O host coletor da nuvem precisa começar com 'http://' ou 'https://'",
            real_time_https:
              "O host em tempo real precisa começar com 'http://' ou 'https://'",
            domain_admin_https:
              "O domínio do painel administrativo precisa começar com 'http://' ou 'https://'",
            ans_code: "O código da operadora na ANS é obrigatório",
            filemanager_host: "O host do filemanager na nuvem é obrigatório",
            filemanager_host_https:
              "O host do filemanager na nuvem precisa começar com 'http://' ou 'https://'",
            parent_host: "O host coletor da nuvem é obrigatório",
            collector_host: "O host coletor é obrigatório",
            real_time_host: "O host em tempo real é obrigatório",
          },
        },
        countries: {
          countries: "Países",
          country: "País",
          search_country: "Procure por um nome",
          add_country: "Adicionar país",
          new_country: "Novo país",
          name: "Nome",
          code: "Código",
          enable: "Habilitar",
          ddi_code: "Código DDI",
          phone_mask: "Máscara de telefone",
          phone_mask_help:
            "A máscara deve ser enviada conforme exemplo: (00)000000000, em que o 'zero' representa os números do telefone",
          confirm: {
            title: "Deletar país?",
            description: "Isso vai permanentemente deletar o país",
          },
          errors: {
            code: "O código é obrigatório",
            name: "O nome é obrigatório",
          },
        },
        cities: {
          cities: "Cidades",
          city: "Cidade",
          new_city: "Nova cidade",
          name: "Nome",
          provincy: "Estado",
          enable: "Habilitar",
          ibge_code: "Código do IBGE",
          correio_code: "Código dos Correios",
          search_city: "Procure por uma cidade",
          add_city: "Adicionar cidade",
          errors: {
            name: "O nome é obrigatório",
            ibge_code: "O código do IBGE é obrigatório",
            correio_code: "O código dos Correios é obrigatório",
            provincy: "O estado é obrigatório",
          },
        },
        neighborhoods: {
          neighborhoods: "Bairros",
          neighborhood: "Bairro",
          new_neighborhood: "Novo bairro",
          search_neighborhoods: "Procure por um bairro",
          add_neighborhood: "Adicionar bairro",
          name: "Nome",
          code: "Código dos Correios",
          city: "Cidade",
          enable: "Habilitar",
          errors: {
            name: "O nome é obrigatório",
            city: "A cidade é obrigatória",
          },
        },
        companies: {
          companies: "Empresas",
          company: "Empresa",
          search_company: "Pesquise por uma empresa",
          add_company: "Adicionar empresa",
          new_company: "Nova empresa",
          name: "Nome",
          code: "Código",
          enable: "Habilitar",
          company_group: "Grupo Empresarial",
          confirm: {
            title: "Deletar empresa?",
            description: "Isso vai permanentemente deletar a empresa",
          },
          errors: {
            name: "O nome é obrigatório",
            code: "O código é obrigatório",
            company_group: "O grupo empresarial é obrigatório",
          },
        },
        provinces: {
          provinces: "Estados",
          province: "Estado",
          search_province: "Procure por um estado",
          add_province: "Adicionar estado",
          new_province: "Novo estado",
          name: "Nome",
          code: "Código",
          enable: "Habilitar",
          country: "País",
          confirm: {
            title: "Deletar estado?",
            description: "Isso vai permanentemente deletar o estado",
          },
          errors: {
            code: "O código é obrigatório",
            name: "O nome é obrigatório",
            company_group: "O grupo empresarial é obrigatório",
            country: "O país é obrigatório",
          },
        },
        banks: {
          banks: "Bancos",
          bank: "Banco",
          search_bank: "Procure por um banco",
          add_bank: "Adicionar banco",
          new_bank: "Novo banco",
          name: "Nome",
          short_name: "Nome curto",
          ispb_code: "Código ISPB",
          bacen_code: "Código Bacen",
          ibge_city_code: "Código IBGE da Cidade",
          confirm: {
            title: "Deletar banco?",
            description: "Isso vai permanentemente deletar o banco",
          },
          errors: {
            name: "O nome é obrigatório",
            ispb_code: "O código ISPB é obrigatório",
          },
        },
        applications: {
          applications: "Aplicações",
          application: "Aplicação",
          new_application: "Nova aplicação",
          add_application: "Adicionar aplicação",
          search: "Pesquise por uma aplicação",
          name: "Nome",
          type: "Tipo",
          status: "Status",
          domains: "Domínios",
          domain: "Domínio",
          domain_type: "Tipo de domínio",
          add_domain: "Adicionar domínio",
          front_config: "JSON de configuração do tema",
          confirm: {
            title: "Deletar a aplicação?",
            description: "Isso vai permanentemente deletar a aplicação",
          },
          errors: {
            name: "O nome é obrigatório",
            type: "O tipo é obrigatório",
            status_type: "O status é obrigatório",
            domain_type: "O tipo de domínio é obrigatório",
            front_config: "O JSON de configuração do tema é obrigatório",
            description: "A descrição é obrigatória",
            file: "O arquivo é obrigatório",
            domain: "O domínio é obrigatório",
            domains: "Ao menos um domínio é obrigatório",
          },
        },
        banners: {
          banners: "Banners",
          banner: "Banner",
          new_banner: "Novo banner",
          search: "Pesquise por um banner",
          add_banner: "Adicionar banner",
          name: "Nome",
          title: "Título",
          alternative_text: "Texto alternativo",
          display_order: "Ordem de exibição",
          language: "Idioma",
          site_apps: "Sites ou Apps",
          banner_locals: "Locais onde o banner será exibido",
          active: "Ativo",
          inactive: "Inativo",
          enable: "Habilitar",
          status: "Status",
          media_file: "Arquivo de mídia",
          media_file_mobile: "Arquivo de mídia mobile",
          application: "Aplicação",
          banner_local: "Local de exibição do banner",
          targetingLink: "Link de redirecionamento",
          confirm: {
            title: "Deletar o banner?",
            description: "Isso vai permanentemente deletar o banner",
          },
          errors: {
            name: "O nome é obrigatório",
            title: "O título é obrigatório",
            alternative_text: "O texto alternativo é obrigatório",
            display_order: "A ordem de exibição é obrigatória",
            language: "O idioma é obrigatório",
            site_apps: "Ao menos um site ou app é obrigatório",
            banner_locals: "Ao menos um local é obrigatório",
            file: "O arquivo é obrigatório",
            targetingLink: "O link é inválido",
            display_order_negative:
              "A ordem de exibição não pode ser menor que 0",
          },
          recommendedSize:
            "Dimensões recomendadas para Desktop {{desktopWidth}}x{{desktopHeight}} pixels. Dimensões recomendas para Aplicativo/Mobile {{appWidth}}x{{appHeight}} pixels.",
        },
        faq: {
          faqs: "Áreas do FAQ",
          faq: "Área do FAQ",
          new_faq: "Nova área do FAQ",
          add_faq: "Adicionar área do FAQ",
          search: "Pesquise por uma área do FAQ",
          question_answer: "Perguntas e respostas",
          active: "Ativo",
          inactive: "Inativo",
          status: "Status",
          name: "Nome",
          enable: "Habilitar",
          whatsapp_phone_number: "Número de telefone do Whatsapp",
          language: "Idioma",
          mail: "E-mail",
          site_app: "Sites ou Apps",
          questions_answers: "Perguntas e Respostas",
          display_order: "Ordem de exibição",
          confirm: {
            title: "Deletar o FAQ?",
            description: "Isso vai permanentemente deletar o FAQ",
          },
          errors: {
            name: "O nome é obrigatório",
            site_app: "Ao menos um site ou app é obrigatório",
            phone_email:
              "O número de telefone do Whatsapp ou o e-mail deve ser informado",
            mail: "Informe um e-mail válido",
            language: "O idioma é obrigatório",
            display_order: "A ordem de exibição é obrigatória",
          },
        },
        questionsAnswers: {
          questions_answers: "Perguntas e Respostas",
          question_answer: "Pergunta e Resposta",
          search: "Pesquise por uma pergunta e resposta",
          add_questions_answers: "Adicione uma pergunta e resposta",
          new_question_answer: "Nova pergunta e resposta",
          display_order: "Ordem de exibição",
          question: "Pergunta",
          answer: "Resposta",
          enable: "Habilitar",
          name: "Nome",
          status: "Status",
          active: "Ativo",
          inactive: "Inativo",
          was_useful_count: "Contagem do 'Foi útil'",
          confirm: {
            title: "Deletar a pergunta e resposta",
            description:
              "Isso vai permanentemente deletar a pergunta e resposta",
          },
          errors: {
            display_order: "A ordem de exibição é obrigatória",
            question: "A pergunta é obrigatória",
            answer: "A resposta é obrigatória",
          },
        },
        news: {
          all_news: "Notícias",
          news: "Notícia",
          search: "Pesquise por uma notícia",
          add_news: "Adicionar notícia",
          new_news: "Nova notícia",
        },
        notifications: {
          notifications: "Notificações",
          notification: "Notificação",
          add_notification: "Adicionar notificação",
          new_notification: "Nova notificação",
          search: "Pesquise por uma notificação",
          type: "Tipo de notificação",
          status: "Status da notificação",
          title: "Título",
          content: "Conteúdo",
          site_app: "Sites ou Apps",
          language: "Idioma",
          language_tooltip: "Ver idioma",
          contents: "Conteúdos do lote de notificação",
          add_new_content: "Adicionar conteúdo",
          filters: "Filtros do lote de notificação",
          add_new_filter: "Adicionar filtro",
          minimum_age: "Idade mínima",
          maximum_age: "Idade máxima",
          payer: "Pagador",
          holder: "Titular",
          both: "Ambos",
          is_payer: "É pagador",
          is_not_payer: "Não é pagador",
          is_holder: "É titular",
          is_not_holder: "Não é titular",
          profiles: "Perfis",
          gender: "Gênero",
          users: "Usuários",
          published_status: "Status da publicação",
          published: "Publicado",
          not_published: "Não publicado",
          start_publish: "Início da publicação",
          finish_publish: "Fim da publicação",
          registered: "Cadastrado",
          generating: "Gerando",
          generated: "Gerado",
          canceled: "Cancelado",
          error: "Erro ao gerar",
          push_notification: "Gerar PushNotification",
          rule_codes: "Códigos de regras",
          rule_codes_helper: "Insira códigos de regras separados por vírgula",
          date_start_rule: "Data de início da regra",
          content_info: "Você pode adicionar as variáveis: {{variables}}",
          cleanFilters: {
            title: "Deseja realmente alterar o tipo de notificação?",
            description:
              "Ao alterar o tipo de notificação, todos os filtros serão removidos",
            confirm: "Sim, alterar",
            cancel: "Cancelar",
          },
          confirm: {
            title: "Deletar a Notificação?",
            description: "Isso vai permanentemente deletar a notificação",
          },
          errors: {
            type: "O tipo de notificação é obrigatório",
            site_app: "Ao menos um site ou app é obrigatório",
            contents: "Ao menos um conteúdo é obrigatório",
            language: "O idioma é obrigatório",
            title: "O título é obrigatório",
            content: "O conteúdo é obrigatório",
            notification_batch_filter:
              "Se os campos 'Pagador' e 'Titular' tiverem o valor 'Ambos', ao menos um dos campos abaixo é obrigatório",
            variables:
              "Uma variável que não existe na lista foi adicionada a um dos conteúdos",
            rule_codes: "Os códigos de regras são obrigatórios",
            date_start_rule: "A data de início da regra é obrigatória",
          },
        },
        notifiedUsers: {
          notified_users: "Usuários Notificados",
          search: "Pesquise por um usuário",
          user: "Usuário",
          read_date: "Data de leitura",
          acceptance_date: "Data de aceitação",
          acceptance_data: "Dados da aceitação",
        },
        extensions: {
          extensions: "Extensões",
          search_name: "Pesquise por um nome",
          extension: "Extensão",
          new_extension: "Nova extensão",
          presentation_order: "Ordem de apresentação",
          software: "Software",
          name: "Nome",
          title: "Título",
          description: "Descrição",
          tags: "Tags",
          files: "Arquivos",
          file: "Arquivo",
          media_files: "Arquivos de mídia",
          add_media_file: "Adicionar arquivo",
          media_type_code: "Tipo de mídia",
          extension_dependencies: "Possui dependência das extensões",
          extension_targets: "Disponível para os tipos de aplicação",
          enable: "Habilitar",
          is_required_setup: "Requer setup para instalação",
          status: "Status",
          active: "Ativo",
          inactive: "Inativo",
          actions: {
            default_settings: "Configurações padrão",
            price_rules: "Regras de preço",
          },
          defaultSettings: {
            default_settings: "Configurações padrão para: {{extension}}",
            default_setting: "Configuração padrão",
            identifier: "Identificador",
            property: "Propriedade",
            value: "Valor",
            description: "Descrição",
            search_identifier: "Pesquise por um identificador",
            add_default_setting: "Adicionar configuração padrão",
            new_default_setting: "Nova configuração padrão",
            is_public: "A configuração padrão da extensão é pública",
            errors: {
              identifier: "O identificador é obrigatório",
              value: "O valor é obrigatório",
              property: "A propriedade é obrigatória",
              description: "A descrição é obrigatória",
            },
            confirm: {
              title: "Deletar configuração padrão?",
              description:
                "Isso vai permanentemente deletar a configuração padrão",
            },
          },
          priceRules: {
            price_rules: "Regras de Preço para: {{extension}}",
            price_rule: "Regra de Preço",
            search_type: "Pesquise por um tipo",
            edit_price_rule: "Editar regra de preço",
            add_price_rule: "Adicionar Regra de Preço",
            type: "Tipo",
            initial_date: "Início vigência",
            end_date: "Fim vigênica",
            value: "Valor",
            errors: {
              type: "O tipo é obrigatório",
              initial_date: "O início vigência é obrigatório",
              end_date: "O fim vigência é obrigatório",
              value: "O valor é obrigatório",
            },
            confirm: {
              title: "Deletar regra de preço?",
              description: "Isso vai permanentemente deletar a regra de preço",
            },
          },
          errors: {
            display_order: "A ordem de apresentação é obrigatória",
            software: "O software é obrigatório",
            name: "O nome é obrigatório",
            title: "O título é obrigatório",
            tags: "Ao menos uma tag é obrigatória",
            file: "O Arquivo é obrigatório",
            file_type:
              "Apenas .jpg, .jpeg, .png and .webp são formatos suportados",
            file_size: "O tamanho máximo permitido é de 5MB",
            extension_targets: "Ao menos uma aplicação é obrigatória",
          },
        },
        cardsTemplates: {
          cards_templates: "Modelos de Carteirinhas",
          card_template: "Modelo de Carteirinha",
          search: "Pesquise por um modelo",
          new_card_template: "Novo Modelo de Carteirinha",
          add_card_template: "Adicionar Modelo de Carteirinha",
          initial_date: "Início vigência",
          end_date: "Fim vigência",
          health_plan: "Plano de saúde",
          enable: "Habilitar",
          establishment_param_type:
            "Tipo de parâmetro do estabelecimento para a frente",
          establishment_param_type_back:
            "Tipo de parâmetro do estabelecimento para o verso",
          confirm: {
            title: "Deletar modelo de carteirinha?",
            description:
              "Isso vai permanentemente deletar o modelo de carteirinha",
          },
          errors: {
            initial_date: "O início vigência é obrigatória",
            end_date: "O fim vigência é obrigatório",
            health_plan: "O nome do plano é obrigatório",
            establishment_param_type:
              "O tipo de parâmetro do estabelecimento para a frente é obrigatório",
            establishment_param_type_back:
              "O tipo de parâmetro do estabelecimento para o verso é obrigatório",
          },
        },
        companyGroups: {
          company_groups: "Grupos Empresariais",
          company_group: "Grupo Empresarial",
          new_company_group: "Novo grupo empresarial",
          search_company_groups: "Procure por grupo empresarial",
          add_company_group: "Adicionar grupo empresarial",
          name: "Nome",
          code: "Código",
          is_main: "Principal",
          enable: "Habilitar",
          filter_restriction_type: "Tipo de Restrição de Filtro",
          confirm: {
            title: "Deletar grupo empresarial?",
            description: "Isso vai permanentemente deletar o grupo empresarial",
          },
          errors: {
            name: "O nome é obrigatório",
            code: "O código é obrigatório",
            filter_restriction_type:
              "O tipo de restrição de filtro é obrigatório",
          },
        },
        paramDefaultEstablishment: {
          param_default_establishment: "Parâmetros padrão do estabelecimento",
          param_default: "Parâmetro padrão do estabelecimento",
          new_param_default: "Novo parâmetro padrão do estabelecimento",
          search_param: "Pesquise por um parâmetro",
          add_param: "Adicionar parâmetro padrão",
          establishment_param_type: "Tipo de parâmetro do estabelecimento",
          value: "Valor",
          description: "Descrição",
          confirm: {
            title: "Deletar parâmetro padrão do estabelecimento?",
            description:
              "Isso vai permanentemente deletar o parâmetro padrão do estabelecimento",
          },
          errors: {
            param_type: "O tipo de parâmetro do estabelecimento é obrigatório",
            value: "O valor é obrigatório",
            description: "A descrição é obrigatória",
          },
        },
        holidays: {
          holidays: "Feriados",
          holiday: "Feriado",
          new_holiday: "Novo feriado",
          search_holiday: "Pesquise por um feriado",
          add_holiday: "Adicionar feriado",
          name: "Nome",
          description: "Descrição",
          holiday_type: "Tipo de feriado",
          date: "Data",
          import_holidays: "Importar feriados nacionais",
          filter: "Filtro",
          confirm: {
            title: "Deletar feriado?",
            description: "Isso vai permanentemente deletar o feriado",
          },
          errors: {
            name: "O nome é obrigatório",
            description: "A descrição é obrigatória",
            holiday_type: "O tipo de feriado é obrigatório",
            date: "A data é obrigatória",
          },
        },
        individualValidation: {
          individual_validations: "Validação Cadastro PF",
          individual_validation: "Validação de Cadastro PF",
          search: "Pesquise por um cadastro",
          individual: "Pessoa Física",
          name: "Nome",
          document: "CPF",
          status: "Status",
          all: "Todos",
          approved: "Aprovado",
          disapproved: "Reprovado",
          pending: "Pendente de Aprovação",
          created: "Data de criação",
          photos_sent: "Fotos Enviadas",
          approve_registrations: "Aprovação de Cadastros",
          not_found: "Ops, cadastro não encontrado!",
          no_registrations:
            "Não há mais cadastros a serem validados, por favor, tente novamente mais tarde",
          birth_date: "Data de Nascimento",
          email: "E-mail",
          whatsapp_number: "Número do WhatsApp",
          approved_by: "Aprovado por",
          approved_at: "Aprovado em",
          disapproved_by: "Reprovado por",
          disapproved_at: "Reprovado em",
          copy: "Copiar",
          copied_successfully: "Copiado com sucesso",
          actions: {
            approved: "Cadastro aprovado com sucesso!",
            disapproved: "Cadastro reprovado com sucesso!",
            disapprove_modal: {
              title: "Deseja realmente reprovar o cadastro?",
              description: "Isso irá permanentemente reprovar o cadastro.",
              cancel: "Cancelar",
              confirm: "Sim, reprovar",
            },
            disapprove: "Reprovar",
            approve: "Aprovar",
          },
          photo_item: {
            person: "Pessoa",
            front_doc: "Documento Frente",
            back_doc: "Documento Verso",
            person_doc: "Pessoa com o Documento",
            error: "Ops, ocorreu um erro ao carregar a foto!",
          },
        },
      },
      additionalSettings: {
        additionalSettings: "Configurações adicionais",
        search: "Pesquise por uma configuração",
      },
      extensionsStore: {
        title: "Explore o máximo potencial do seu plano",
        subtitle:
          "Confira as extensões disponíveis para o seu plano e obtenha uma solução ainda mais completa para o seu negócio.",
        no_data: "Nenhuma extensão encontrada!",
        no_data_install:
          "Verifique as suas extensões já instaladas. Não há novas extensões para exibir.",
        no_data_installed:
          "Verifique as disponíveis na loja. Não há extensões instaladas para exibir.",
        installed_successfully: "Extensão instalada com sucesso",
      },
      noAccess: {
        message:
          "Ops! Parece que você não possui permissão para acessar essa página!",
        button: "Voltar para o início",
      },
      settings: {
        settings: "Configurações",
        general: "Gerais",
        cache: "Cache",
        "4uBus": {
          configs: {
            configs: "Configurações gerais",
            config: "Configuração geral",
            new_config: "Nova configuração geral",
            search: "Pesquise por uma configuração geral",
            add_config: "Adicionar configuração geral",
            host_4ubus: "Host do 4uBus",
            host_aws: "Host da AWS",
            establishment: "Estabelecimento",
            confirm: {
              title: "Deletar configuração geral?",
              description:
                "Isso vai permanentemente deletar a configuração geral",
            },
            errors: {
              host_4ubus: "O host do 4uBus é obrigatório",
              host_aws: "O host do AWS é obrigatório",
              host_4ubus_https:
                "O host do 4uBus precisa começar com 'http://' ou 'https://'",
              host_aws_https:
                "O host da AWS precisa começar com 'http://' ou 'https://'",
            },
          },
          ipm: {
            generalSettings: {
              general_settings: "Configurações gerais IPM",
              general_setting: "Configuração geral IPM",
              new_general_setting: "Nova configuração geral IPM",
              search: "Pesquise por uma configuração geral",
              add_general_setting: "Adicionar configuração geral",
              host: "Host",
              establishment: "Estabelecimento",
              ipm_user: "Usuário IPM",
              ipm_password: "Senha IPM",
              token_4ubus: "Token do 4uBus",
              token_copy: "Copiar token",
              token_copied: "Token do 4uBus copiado com sucesso",
              itens_per_batch: "Itens por lote",
              total_batches: "Total de lotes",
              heart_beat_time_minutes:
                "Tempo de verificação de lote em minutos",
              establishment_codes:
                "Código dos estabelecimentos de envio (separados por vírgula)",
              confirm: {
                title: "Deletar configuração geral?",
                description:
                  "Isso vai permanentemente deletar a configuração geral",
              },
              errors: {
                host: "O host é obrigatório",
                ipm_user: "O usuário IPM é obrigatório",
                ipm_password: "A senha IPM é obrigatória",
                token_4ubus: "O token do 4uBus é obrigatório",
                host_https:
                  "O host precisa começar com 'http://' ou 'https://'",
                itens_per_batch: "Os itens por lote são obrigatórios",
                total_batches: "O total de lotes é obrigatório",
                heart_beat_time_minutes:
                  "O tempo de verificação de lote em minutos é obrigatório",
                establishment_codes:
                  "Código dos estabelecimentos de envio é obrigatório",
              },
            },
          },
          zScan: {
            examSendRule: {
              exam_send_rules: "Regras de envio de exames",
              exam_send_rule: "Regra de envio de exames",
              add_exam_send_rule: "Adicionar regra de envio de exames",
              new_exam_send_rule: "Nova regra de envio de exames",
              target_machine: "Máquina de destino",
              proc_classification_integration_id:
                "Id de Classificação do Procedimento",
              initial_date: "Data de início",
              end_date: "Data de fim",
              enable: "Ativar",
              status: "Status",
              active: "Ativo",
              inactive: "Inativo",
              errors: {
                target_machine: "A máquina de destino é obrigatória",
                proc_classification_integration_id:
                  "O id de classificação do procedimento é obrigatório",
                initial_date: "A data de início é obrigatória",
                end_date: "A data de fim é obrigatória",
                initial_date_bigger:
                  "A data de início não pode ser maior que a de fim",
              },
              confirm: {
                title: "Deletar regra de envio de exames?",
                description:
                  "Isso vai permanentemente deletar a regra de envio de exames",
              },
            },
          },
          externalExamPrescription: {
            rules: {
              rules: "Regras de Geração de Prescrição",
              rule: "Regra de Geração de Prescrição",
              new_rule: "Nova regra de geração de prescrição",
              add_rule: "Adicionar regra de geração de prescrição",
              search: "Pesquise por uma regra de geração de prescrição",
              medical_procedure_type_code:
                "Código do Tipo de Procedimento Médico",
              sector_code: "Código do Setor",
              exec_physician_code: "Código do Médico Executor",
              patient_insurance_code: "Código do Convênio do Paciente",
              initial_date: "Data de Início",
              end_date: "Data de Fim",
              is_exception: "É Exceção",
              yes: "Sim",
              no: "Não",
              if: "Se",
              then: "Então",
              exception: "Exceção (Não gera prescrição)",
              errors: {
                initial_date: "A data de início é obrigatória",
                end_date: "A data de fim é obrigatória",
                initial_date_bigger:
                  "A data de início não pode ser maior que a de fim",
                medical_procedure_type_code:
                  "O código do tipo de procedimento é obrigatório",
                sector_code: "O código do setor é obrigatório",
                execPhysicianCode: "O código do médico executor é obrigatório",
                max_medical_procedure_type_code:
                  "O código do tipo de procedimento médico não pode ter mais de 3 caracteres",
                max_sector_code:
                  "O código do setor não pode ter mais de 5 caracteres",
                max_exec_physician_code:
                  "O código do médico executor não pode ter mais de 10 caracteres",
                max_patient_insurance_code:
                  "O código do convênio do paciente não pode ter mais de 5 caracteres",
              },
              confirm: {
                title: "Deletar regra de geração de prescrição?",
                description:
                  "Isso vai permanentemente deletar a regra de geração de prescrição",
              },
            },
          },
        },
        crm: {
          ticketAutoFinishRules: {
            ticket_auto_finish_rules: "Regras de Finalização de Ticket(Card)",
            ticket_auto_finish_rule: "Regra de Finalização de Ticket(Card)",
            add_ticket_auto_finish_rule:
              "Adicionar regra de finalização de ticket(card)",
            new_ticket_auto_finish_rule:
              "Nova regra de finalização de ticket(card)",
            search: "Pesquise por uma regra de finalização de ticket(card)",
            message: "Mensagem",
            minutes: "Minutos",
            initial_date: "Data de início",
            end_date: "Data de fim",
            finish_rule: "Finalizar",
            finish: "Finalizado",
            finished: "Sim",
            not_finished: "Não",
            ticket_type: "Tipo de ticket(card)",
            ticket_channel: "Quadro",
            ticket_channel_step: "Lista do quadro",
            ticket_workspace: "Área de trabalho",
            message_helper:
              "A variável {{protocolNumber}} será substituída pelo número do protocolo atribuído ao ticket(card)",
            confirm: {
              title: "Deletar regra de finalização de ticket(card)?",
              description:
                "Isso vai permanentemente deletar a regra de finalização de ticket(card)",
            },
            errors: {
              minutes: "Os minutos são obrigatórios",
              max_minutes: "O valor não pode ser maior que 720",
              ticket_type: "O tipo de ticket(card) é obrigatório",
              ticket_channel: "O quadro é obrigatório",
              initial_date: "A data de início é obrigatória",
              end_date: "A data de fim é obrigatória",
              end_date_less: "A data de fim não pode ser menor que a de início",
              message: "A mensagem é obrigatória",
              ticket_workspace: "A área de trabalho é obrigatória",
              ticket_channel_step: "A lista do quadro é obrigatória",
            },
          },
          protocolsDefault: {
            protocols_default: "Protocolos de Orientações Padrão",
            protocol_default: "Protocolo de Orientações Padrão",
            new_protocol_default: "Novo protocolo de orientações padrão",
            add_protocol_default: "Adicionar protocolo de orientações padrão",
            search: "Pesquise por um protocolo de orientações padrão",
            title: "Título",
            message: "Mensagem",
            workspaces: "Áreas de trabalho",
            file: "Arquivo",
            description: "Descrição",
            media_files: "Arquivos de mídia",
            add_media_file: "Adicionar arquivo",
            enable: "Ativar",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar o protocolo de orientações padrão?",
              description:
                "Isso vai permanentemente deletar o protocolo de orientações padrão",
            },
            errors: {
              title: "O título é obrigatório",
              message: "A mensagem é obrigatória",
              workspaces: "Ao menos uma área de trabalho é obrigatória",
              description: "A descrição é obrigatória",
              file: "O arquivo é obrigatório",
              media_files: "Ao menos um arquivo de mídia é obrigatório",
              min_description: "A descrição é obrigatória",
              max_description:
                "A descrição não pode ter mais de 1000 caracteres",
            },
          },
          events: {
            events: "Eventos",
            event: "Evento",
            new_event: "Novo evento",
            add_event: "Adicionar evento",
            search: "Pesquise por um evento",
            name: "Nome",
            event_type: "Tipo de evento",
            code: "Código",
            enable: "Habilitar",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            manage_event_types: "Gerenciar tipos de evento",
            ticket_automations: "Automações",
            confirm: {
              title: "Deletar evento?",
              description: "Isso vai permanentemente deletar o evento",
            },
            errors: {
              name: "O nome é obrigatório",
              event_type: "O tipo de evento é obrigatório",
              code: "O código é obrigatório",
            },
          },
          eventTypes: {
            event_types: "Tipos de Evento",
            event_type: "Tipo de Evento",
            new_event_type: "Novo tipo de evento",
            add_event_type: "Adicionar tipo de evento",
            search: "Pesquise por um tipo de evento",
            name: "Nome",
            enable: "Habilitar",
            code: "Código",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar tipo de evento?",
              description: "Isso vai permanentemente deletar o tipo de evento",
            },
            errors: {
              name: "O nome é obrigatório",
            },
          },
          externalProcessType: {
            external_process_types: "Tipos de Processos Externos",
            external_process_type: "Tipo de Processo Externo",
            add_external_process_type: "Adicionar tipo de processo externo",
            new_external_process_type: "Novo tipo de processo externo",
            search: "Pesquise por um tipo de processo externo",
            name: "Nome",
            confirm: {
              title: "Deletar o tipo de processo externo?",
              description:
                "Isso vai permanentemente deletar o tipo de processo externo",
            },
            errors: {
              name: "O nome é obrigatório",
            },
          },
        },
        preventMedicine: {
          workGroupTypes: {
            work_group_types: "Tipos de Grupo de Trabalho",
            work_group_type: "Tipo de Grupo de Trabalho",
            new_work_group_type: "Novo tipo de grupo de trabalho",
            add_work_group_type: "Adicionar tipo de grupo de trabalho",
            enable: "Habilitar",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            languages: "Idiomas",
            language: "Idioma",
            add_language: "Adicionar idioma",
            name: "Nome",
            description: "Descrição",
            search: "Pesquise por um grupo de trabalho",
            confirm: {
              title: "Deletar tipo de grupo de trabalho?",
              description:
                "Isso vai permanentemente deletar o tipo de grupo de trabalho",
            },
            errors: {
              name: "O nome é obrigatório",
              language: "O idioma é obrigatório",
            },
          },
          healthProgramTypes: {
            health_program_types: "Tipos de Programas de Saúde",
            health_program_type: "Tipo de Programa de Saúde",
            new_health_program_type: "Novo tipo de programa de saúde",
            add_health_program_type: "Adicionar Tipo de Programa de Saúde",
            search: "Pesquise por um tipo de programa de saúde",
            name: "Nome",
            language: "Idioma",
            description: "Descrição",
            enable: "Habilitar",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            characters: "caracteres",
            add_language: "Adicionar idioma",
            confirm: {
              title: "Deletar tipo de programa de saúde?",
              description:
                "Isso vai permanentemente deletar o tipo de programa de saúde",
            },
            errors: {
              name: "O nome é obrigatório",
              description: "A descrição não pode ter mais de 500 caracteres",
            },
          },
        },
        generalSettings: {
          healthChecks: {
            health_checks: "Health Checks de Microsserviços 4uHub",
            health_check: "Health Check de Microsserviços 4uHub",
            new_health_check: "Novo health check de microsserviços 4uHub",
            add_health_check: "Adicionar Health Check de Microsserviços 4uHub",
            search: "Pesquise por um health check de microsserviços 4uHub",
            name: "Nome",
            uri: "URI",
            establishment: "Estabelecimento",
            payload: "Payload de falha",
            restored_payload: "Payload de restauração",
            webhook_url: "URL do Webhook",
            enable: "Habilitar",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar health check de microsserviços 4uHub?",
              description:
                "Isso vai permanentemente deletar o health check de microsserviços 4uHub",
            },
            errors: {
              name: "O nome é obrigatório",
              uri: "O URI é obrigatório",
              payload: "O payload de falha é obrigatório",
              restored_payload: "O payload de restauração é obrigatório",
              webhook_url: "A URL do Webhook é obrigatória",
              webhook_url_http:
                "A URL do Webhook precisa começar com 'http://' ou 'https://'",
              establishment: "O estabelecimento é obrigatório",
            },
          },
        },
        schedule: {
          scheduleCheckIn: {
            schedule_check_in: "Check In",
            add_schedule_check_in: "Adicionar check in",
            new_schedule_check_in: "Novo check in",
            meters_approximation: "Aproximação em metros",
            time: "Tempo em minutos",
            meters: "m.",
            min: "min.",
            latitude: "Latitude",
            longitude: "Longitude",
            help_time:
              "Informe o tempo em minutos antes da consulta, que será permitido realizar o check in de um agendamento",
            help_meters:
              "Informe a aproximação em metros antes da consulta, que será permitido realizar o check in de um agendamento",
            confirm: {
              title: "Deletar check in?",
              description: "Isso vai permanentemente deletar o check in",
            },
            errors: {
              meters_approximation: "A aproximação em metros é obrigatória",
              time: "O tempo em minutos é obrigatório",
              latitude: "A latitude é obrigatória",
              longitude: "A longitude é obrigatória",
            },
          },
          scheduleConfirmation: {
            schedule_confirmation: "Confirmação de Agenda",
            add_schedule_confirmation: "Adicionar confirmação de agenda",
            new_schedule_confirmation: "Nova confirmação de agenda",
            time: "Tempo em minutos",
            min: "min.",
            help: "Informe o tempo em minutos antes da consulta, que será permitido realizar a confirmação de um agendamento",
            confirm: {
              title: "Deletar confirmação de agenda?",
              description:
                "Isso vai permanentemente deletar a confirmação de agenda",
            },
            errors: {
              time: "O tempo em minutos é obrigatório",
            },
          },
        },
        system: {
          executeSql: {
            execute_sql: "Executar SQL",
            execute_successfully: "SQL executado com sucesso",
            clean: "Limpar",
            execute: "Executar",
            errors: {
              sql: "O código SQL é obrigatório",
            },
          },
        },
        siteApp: {
          siteAppCheckIn: {
            site_app_check_in: "Check In da Triagem PA Digital",
            add_site_app_check_in: "Adicionar check in",
            new_site_app_check_in: "Novo check in",
            meters_approximation: "Aproximação em metros",
            patient_encounter_physician_code:
              "Código do médico do atendimento do paciente",
            sector_accommodation_type: "Tipo de acomodação do setor",
            patient_encounter_origin_code:
              "Código de origem do atendimento do paciente",
            time: "Tempo em minutos",
            meters: "m.",
            min: "min.",
            latitude: "Latitude",
            longitude: "Longitude",
            help_time:
              "Informe o tempo em minutos antes da consulta, que será permitido realizar o check in de um agendamento",
            help_meters:
              "Informe a aproximação em metros antes da consulta, que será permitido realizar o check in de um agendamento",
            standard_service_sector_code: "Código Setor Atendimento Padrão",
            standard_service_unit: "Unidade atendimento padrão",
            confirm: {
              title: "Deletar check in?",
              description: "Isso vai permanentemente deletar o check in",
            },
            errors: {
              meters_approximation: "A aproximação em metros é obrigatória",
              time: "O tempo em minutos é obrigatório",
              latitude: "A latitude é obrigatória",
              longitude: "A longitude é obrigatória",
              standard_service_sector_code:
                "O código setor atendimento padrão é obrigatório",
              standard_service_unit:
                "A unidade atendimento padrão é obrigatório",
            },
          },
          siteAppConfirmation: {
            site_app_confirmation: "Confirmação de Agenda",
            add_site_app_confirmation: "Adicionar confirmação de agenda",
            new_site_app_confirmation: "Nova confirmação de agenda",
            time: "Tempo em minutos",
            min: "min.",
            help: "Informe o tempo em minutos antes da consulta, que será permitido realizar a confirmação de um agendamento",
            confirm: {
              title: "Deletar confirmação de agenda?",
              description:
                "Isso vai permanentemente deletar a confirmação de agenda",
            },
            errors: {
              time: "O tempo em minutos é obrigatório",
            },
          },
        },
      },
      extensionSettings: {
        extension_settings:
          "Configurações da extensão para '{{extension}}' em '{{siteApp}}'",
        add_extension_config: "Adicionar configuração à extensão",
        extension_config: "Configuração da extensão",
        new_extension_config: "Nova configuração da extensão",
        search_config: "Pesquise por uma configuração",
        identifier: "Identificador",
        property: "Propriedade",
        value: "Valor",
        description: "Descrição",
        is_public: "A configuração da extensão é pública",
        save_in_establishment:
          "Salvar configurações para todos os sites e apps do estabelecimento",
        confirm: {
          title: "Deletar configuração da extensão?",
          description:
            "Isso vai permanentemente deletar configuração da extensão",
        },
        errors: {
          identifier: "O identificador é obrigatório",
          property: "A propriedade é obrigatória",
          value: "O valor é obrigatório",
          description: "A descrição é obrigatória",
        },
      },
      jobs: {
        title: "Você foi redirecionado para o painel de Jobs",
        subtitle: "Para acessar novamente clique no botão abaixo",
        button: "Acessar",
      },
      "4uHcp": {
        hcp: {
          healthPrograms: {
            health_programs: "Programas de Saúde",
            health_program: "Programa de Saúde",
            new_health_program: "Novo programa de saúde",
            search: "Pesquise por um programa de saúde",
            add_health_program: "Adicionar programa de saúde",
            languages: "Idiomas",
            health_program_type: "Tipo de programa de saúde",
            health_program_work_groups: "Grupos de trabalho",
            health_program_content_rules: "Regras de conteúdo",
            add_health_program_content_rule: "Adicionar regra de conteúdo",
            applications: "Aplicações",
            work_groups: "Grupos de trabalho",
            add_language: "Adicionar idioma",
            name: "Nome",
            language: "Idioma",
            description: "Descrição",
            participants: "Participantes",
            imports: "Importações",
            fundraising: "Captações",
            confirm: {
              title: "Deletar programa de saúde?",
              description:
                "Isso vai permanentemente deletar o programa de saúde",
            },
            errors: {
              health_program_type: "O tipo de programa de saúde é obrigatório",
              site_apps: "Ao menos uma aplicação é obrigatória",
              languages: "Ao menos um idioma é obrigatório",
              language: "O idioma é obrigatório",
              name: "O nome é obrigatório",
            },
          },
          healthProgramsContentRules: {
            health_programs_content_rules:
              "Regras de Conteúdo dos Programas de Saúde",
            health_program_content_rule:
              "Regra de Conteúdo do Programa de Saúde",
            new_health_program_content_rule:
              "Nova regra de conteúdo do programa de saúde",
            search: "Pesquise por uma regra de conteúdo do programa de saúde",
            add_health_program_content_rule:
              "Adicionar regra de conteúdo do programa de saúde",
            content_view_type_hcp: "Tipo de Visualização do Conteúdo",
            name: "Nome",
            health_program: "Programa de saúde",
            initial_date: "Data de início",
            end_date: "Data de fim",
            contents: "Conteúdos da regra",
            confirm: {
              title: "Deletar regra de conteúdo do programa de saúde?",
              description:
                "Isso vai permanentemente deletar a regra de conteúdo do programa de saúde",
            },
            errors: {
              name: "O nome é obrigatório",
              health_program: "O programa de saúde é obrigatório",
              initial_date: "A data de início é obrigatória",
              end_date: "A data de fim é obrigatória",
              initial_date_greater:
                "A data de de fim não pode ser menor que a de início",
              content_view_type_hcp: "O tipo de visualização do conteúdo",
            },
          },
          rulesContents: {
            rules_contents: "Conteúdos da Regra",
            rule_content: "Conteúdo da Regra",
            new_rule_content: "Novo conteúdo da regra",
            search: "Pesquise por um conteúdo da regra",
            add_rule_content: "Adicionar conteúdo da regra",
            title: "Título",
            publication_date: "Data de publicação",
            content_status: "Status do conteúdo",
            published: "Publicado",
            not_published: "Não publicado",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar conteúdo da regra?",
              description:
                "Isso vai permanentemente deletar o conteúdo da regra",
            },
          },
          workGroups: {
            work_groups: "Grupos de Trabalho",
            work_group: "Grupo de Trabalho",
            new_work_group: "Novo grupo de trabalho",
            add_work_group: "Adicionar grupo de trabalho",
            search: "Pesquise por um grupo de trabalho",
            name: "Nome",
            languages: "Idiomas",
            add_language: "Adicionar idioma",
            language: "Idioma",
            description: "Descrição",
            work_group_type: "Tipo de grupo de trabalho",
            work_group_members: "Membros do grupo de trabalho",
            confirm: {
              title: "Deletar grupo de trabalho?",
              description:
                "Isso vai permanentemente deletar o grupo de trabalho",
            },
            errors: {
              work_group_type: "O tipo de grupo de trabalho é obrigatório",
              languages: "Ao menos um idioma é obrigatório",
              name: "O nome é obrigatório",
              language: "O idioma é obrigatório",
            },
          },
          participants: {
            participants: "Participantes",
            participant: "Participante",
            new_participant: "Novo participante",
            add_participant: "Adicionar participante",
            search: "Pesquise por um participante",
            name: "Nome",
            document: "CPF",
            status: "Status",
            history: "Histórico",
            active: "Ativo",
            formerMember: "Ex-Membro",
            membersHistory: {
              title: "Histórico",
              initialDate: "Data de entrada",
              endDate: "Data de saída",
              noEndDate: "Sem data de fim",
            },
            confirm: {
              title: "Deletar participante?",
              description: "Isso vai permanentemente deletar o participante",
            },
          },
          fundraising: {
            fundraising: "Captações",
            healthProgram: "Programa de saúde",
            captation: "Captação",
            new_captation: "Nova captação",
            search: "Pesquise por uma captação",
            name: "Nome",
            document: "CPF",
            health_program: "Programa de saúde",
            captation_type: "Tipo de captação",
            captation_status: "Status da captação",
            approve_captation: "Aprovar captação",
            approved_successfully: "Captação aprovada com sucesso",
            disapprove_captation: "Desaprovar captação",
            disapproved_successfully: "Captação desaprovada com sucesso",
            denial_reason: "Motivo (opcional)",
            cancel: "Cancelar",
            disapprove: "Desaprovar",
          },
          healthProgramImports: {
            imports: "Importações",
            healthProgram: "Programa de saúde",
            import: "Importação",
            add_import: "Adicionar importação",
            new_import: "Nova importação",
            search: "Pesquise por uma importação",
            file_name: "Nome do arquivo",
            import_person_name: "Nome do importador",
            origin_of_demand: "Origem da demanda",
            error_message: "Mensagem de erro",
            status: "Status",
            download_template: "Baixar modelo",
            health_program: "Programa de saúde",
            errors: {
              file: "O arquivo é obrigatório",
              import_person_name: "O nome do importador é obrigatório",
              health_program: "O programa de saúde é obrigatório",
            },
          },
        },
      },
      "4uBus": {
        register: {
          accessTokens: {
            access_tokens: "Tokens de acesso",
            access_token: "Token de acesso",
            new_access_token: "Novo token de acesso",
            add_access_token: "Adicionar token de acesso",
            search: "Pesquise por um token de acesso",
            name: "Nome",
            privileges: "Privilégios",
            token_public: "Token público",
            copied_token: "Token público copiado com sucesso",
            copy: "Copiar token público",
            created: "Data de criação",
            individual: "Pessoa física",
            legal_entity: "Pessoa jurídica",
            software: "Software",
            justification: "Justificativa",
            description: "Descrição",
            is_integration: "Token de integração",
            restriction_rule_profiles: "Perfis de regras de restrição",
            extension: "Extensão",
            profiles: "Perfis",
            no_extensions_found:
              "Não é possível cadastrar Token de Acesso, pois não possui nenhuma extensão de endpoints de Barramento Instalada.",
            confirm: {
              title: "Deletar token de acesso?",
              description: "Isso vai permanentemente deletar o token de acesso",
            },
            errors: {
              name: "O nome é obrigatório",
              privileges: "Ao menos um privilégio é obrigatório",
            },
          },
          profileTasyBus: {
            profile_tasy_bus: "Perfil - Barramento Tasy",
            search: "Pesquise por um perfil",
            add_profile_tasy_bus: "Adicionar perfil",
            new_profile_tasy_bus: "Novo perfil",
            name: "Nome",
            extension: "Extensão",
            is_global: "Perfil Global",
            enable: "Habilitar",
            users: "Usuários",
            global: "Sim",
            not_global: "Não",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar perfil?",
              description:
                "Isso vai permanentemente deletar o perfil, todas as regras de restrição vinculadas a esse perfil serão excluídas e todos os usuários vinculados serão desvinculados",
            },
            errors: {
              name: "O nome é obrigatório",
              extension: "A extensão é obrigatória",
            },
          },
          restrictionRules: {
            restriction_rules: "Regras de Restrição",
            restriction_rule: "Regra de Restrição",
            search: "Pesquise por uma regra de restrição",
            add_restriction_rule: "Adicionar regra de restrição",
            new_restriction_rule: "Nova regra de restrição",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            enable: "Habilitar",
            restriction_rule_type: "Tipo de regra de restrição",
            action_type: "Tipo de ação",
            value: "Valor",
            description: "Descrição",
            confirm: {
              title: "Deletar regra de restrição?",
              description:
                "Isso vai permanentemente deletar a regra de restrição",
            },
            errors: {
              restriction_rule_type:
                "O tipo de regra de restrição é obrigatório",
              action_type: "O tipo de ação é obrigatório",
              value: "O valor é obrigatório",
              description: "A descrição é obrigatória",
            },
          },
        },
        relationships: {
          relationship: "Relacionamento",
          ipm_tasy_relationship: "Relacionamento IPM x Tasy",
          new_relationship: "Novo relacionamento",
          search: "Pesquise por um relacionamento",
          add_relationship: "Adicionar relacionamento",
          relationship_type: "Tipo de relacionamento",
          direction_type: "Tipo de direcionamento",
          origin_name: "Nome de origem",
          origin_value: "Valor de origem",
          destiny_name: "Nome do destino",
          destiny_value: "Valor do destino",
          enable: "Ativo",
          default: "Padrão",
          status: "Status",
          active: "Ativo",
          inactive: "Inativo",
          is_default: "Sim",
          is_not_default: "Não",
          confirm: {
            title: "Deletar relacionamento?",
            description: "Isso vai permanentemente deletar o relacionamento",
          },
          errors: {
            relationship_type: "O tipo de relacionamento é obrigatório",
            direction_type: "O tipo de direcionamento é obrigatório",
          },
        },
        restrictions: {
          schedule: {
            schedule: "Agenda",
            add_schedule: "Adicionar agenda",
            search: "Pesquise por uma agenda",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            new_schedule: "Nova agenda",
            enable: "Habilitar",
            value: "Valor",
            restriction_rule_type: "Tipo de agenda de restrição",
            description: "Descrição",
            confirm: {
              title: "Deletar agenda?",
              description: "Isso vai permanentemente deletar a agenda",
            },
            errors: {
              value: "O valor é obrigatório",
              restriction_rule_type:
                "O tipo de regra de restrição é obrigatório",
              description: "A descrição é obrigatória",
            },
          },
        },
        logs: {
          ipmXTasy: {
            ipmxtasy: "IPM - Receber - IPM x Tasy",
            status: "Status",
            message_status: "Mensagem do status",
            created: "Data de criação",
            tasy_individual_identifier: "Cd. pessoa",
            service_number_search: "Nr. atendimento",
            service_number: "Nr. atendimento (IPM)",
            service_number_tasy: "Nr. atendimento (Tasy)",
            lab_exam_quantity: "Qt Exame Lab",
            exam_nlab: "Exame NLab",
            medicines: "Medicamentos",
            allergy: "Alergia",
            has_evolution: "Possui evolução",
            evolution: "Sim",
            no_evolution: "Não",
            search: "Pesquisar por status",
            view_logs: "Ver logs",
            logs: "Logs",
          },
          logTasyImp: {
            status: "Status",
            message_status: "Mensagem do status",
            created: "Data de criação",
            qtyEmrLabExamResult: "Qtd de Exames Laboratoriais",
            qtyEmrNonLaboratoryTests: "Qtd de Exames Laboratoriais",
            qtyEvolution: "Qtd de Evoluções",
            qtyAllergy: "Qtd de Alergias",
            qtyMedicineInUse: "Qtd de Medicamentos",
            ipmtasy: "IPMxTasy",
            tasyipm: "TasyxIPM",
            TasyEncounterIntegrationId: "Nº Atendimento Tasy",
            TasyIndividualIntegrationId: "Cod. Pessoa Tasy",
            IpmEncounterIntegrationId: "Nº Atendimento IPM",
            IpmIndividualIntegrationId: "Cod. Pessoa IPM",
          },
          tasyXIpm: {
            tasyxipm: "IPM - Enviar - Tasy x IPM",
            status: "Status",
            message_status: "Mensagem do status",
            created: "Data de criação",
            individual_identifier: "Código da pessoa (Tasy)",
            service_number_search: "Número do atendimento",
            service_number: "Número do atendimento (Tasy)",
            search: "Pesquisar por status",
            view_logs: "Ver logs",
            logs: "Logs",
            allergies: "Alergias",
            occurrences: "Ocorrências",
            exams_lab: "Exames Lab",
            exams_n_lab: "Exames N Lab",
            medicines: "Medicamentos",
          },
          tasyCalendar: {
            title: "Agendamentos Tasy",
            type_code: "Código",
            created: "Criado em",
            identifier: "Identificador",
            calendar_identifier: "Identificador do agendamento",
            calendar_date: "Data do agendamento",
            status: "Status",
            individual_identifier: "Identificador do individuo",
            last_modified: "Última modificação",
          },
          monitoring: {
            title: "Monitoramento Servidor Barramento",
            subtitle: "Estatísticas do servidor On-Premise do Barramento",
          },
        },
        sync: {
          sync: "Sincronização",
          sync_all: "Sincronizar tudo",
          synced_all: "Sincronização realizada com sucesso",
        },
        robots: {
          list: {
            list: "Lista de Robôs",
            search: "Pesquise por um robô",
            name: "Nome",
            description: "Descrição",
            code: "Código",
            started: "Iniciou",
            last_success: "Último sucesso",
            last_error: "Último erro",
            last_error_message: "Mensagem do último erro",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            executions_robots: "Execuções do Robô",
            executions: {
              executions_robots: "Execuções do Robô",
              job_name: "Nome da Job",
              start_date: "Data de início",
              end_date: "Data de fim",
              execution_status: "Status da execução",
            },
          },
        },
      },
      "4uSiteApp": {
        applications: {
          myApplications: {
            my_applications: "Minhas aplicações",
            search: "Pesquise por uma aplicação",
            name: "Nome",
            type: "Tipo",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
          },
        },
      },
      assessments: {
        questions: {
          questions: "Questões",
          question: "Questão",
          new_question: "Nova questão",
          add_question: "Adicionar questão",
          search: "Pesquise por uma questão",
          question_evaluation_type: "Tipo de avaliação da questão",
          integration_id: "Id de integração",
          title: "Título",
          description: "Descrição",
          question_category: "Categoria da questão",
          created: "Data de criação",
          required_justification: "Requer justificativa",
          required_justification_less_than: "Obrigar justificativa abaixo de",
          required_justification_min_chars: "Quantidade mínima de caracteres",
          required_justification_message: "Texto justificativa",
          category: {
            label: "Categoria",
            no_options_text: "Nenhuma categoria selecionada",
            options_button_label: "Buscar categoria",
            drawer_props: {
              title: "Selecionar categoria",
              search_placeholder: "Buscar categorias",
              create_button_label: "Criar categoria",
              apply_button_label: "Aplicar categoria",
              input_label: "Nome da categoria",
              delete_confirm: {
                title: "Deletar categoria",
                message:
                  "Isso irá excluir a categoria e todas as categorias relacionadas",
              },
            },
          },
          confirm: {
            title: "Deletar questão?",
            description: "Isso vai permanentemente deletar a questão",
          },
          errors: {
            integration_id: "O id de integração é obrigatório",
            title: "O título é obrigatório",
            description: "A descrição é obrigatória",
            question_evaluation_type:
              "O tipo de avaliação da questão é obrigatório",
            question_category: "A categoria da questão é obrigatória",
            required_justification_less_than:
              "O 'obrigar justificativa abaixo de' é obrigatório",
            required_justification_min_chars:
              "A quantidade mínima de caracteres é obrigatória",
            required_justification_message:
              "O texto justificativa é obrigatório",
          },
        },
      },
      logs: {
        healthChecks: {
          health_checks: "Health Checks",
          name: "Nome",
          data: "Dados",
          created: "Data de criação",
          search: "Pesquisar por um log do health checks",
        },
        logRegisterError: {
          errorMessage: "Mensagem de erro",
          document: "Documento",
          title: "Erros de Cadastro",
          created: "Data",
          confirm: {
            confirm: {
              title: "Deletar log de erro?",
              description: "Isso vai permanentemente deletar o log",
            },
          },
          placeholder: "Pesquise por algum CPF",
        },
      },
      evaluation: {
        forms: {
          forms: "Formulários",
          form: "Formulário",
          new_form: "Novo formulário",
          search: "Pesquise por um formulário",
          type_form: "Tipo de Formulário",
          add_form: "Adicionar formulário",
          title: "Título",
          description: "Descrição",
          status: "Status",
          active: "Ativo",
          inactive: "Inativo",
          enable: "Ativar",
          integration_id: "Código de integração",
          visual_description: "Descrição visual de apresentação:",
          extension: "Aplicação/Site/App",
          initial_date: "Início vigência",
          end_date: "Fim vigência",
          domains: {
            domains: "Domínios",
            tooltip:
              "Copiar link de compartilhamento do formulário para uso em templates",
            copied_successfully: "Link copiado com sucesso",
          },
          select_questions: {
            questions: "Questões",
            display_order: "Ordem",
            title: "Título",
            type: "Tipo",
            add_button: "Adicionar questões",
            modal_title: "Selecionar questões",
            modal_add_button: "Adicionar questões",
            modal_remove_button: "Remover questões",
            search: "Pesquise por uma questão",
          },
          confirm: {
            title: "Deletar formulário?",
            description: "Isso vai permanentemente deletar o formulário",
          },
          errors: {
            type: "O tipo é obrigatório",
            title: "O título é obrigatório",
            site_app_extension: "A aplicação/site/app é obrigatória",
            initial_date: "A data de início vigência é obrigatória",
            end_date: "A data de fim vigência é obrigatória",
            bigger_date:
              "A data de início vigência não pode ser maior que a data de fim vigência",
            description: "A descrição é obrigatória",
            questions: "Ao menos uma questão é obrigatória",
            order: "A ordem precisa ser maior ou igual a 1",
            duplicate_order: "Não é permitido ordens duplicadas",
            visual_description: "A descrição visual é obrigatória",
          },
        },
        logs: {
          id: "Id da pesquisa",
          title: "Logs de Integração",
          evaluationForm: "Formulário",
          ticket: "Ticket (card)",
          attempts: "Tentativas",
          shippingDate: "Data de envio",
          answerDate: "Data da resposta",
          encounterIntegrationId: "Numero do atendimento",
          errorMessage: "Mensagem de erro",
          copy: "Copiar",
          copied_successfully: "Id da pesquisa copiado com sucesso",
        },
      },
      "4uSalesContract": {
        registers: {
          grantors: {
            active: "Ativo",
            inactive: "Inativo",
            name: "Nome",
            status: "Status",
            grantors: "Outorgantes",
            add_grantor: "Adicionar Outorgante",
            enabled: "Habilitar",
            establishments: "Estabelecimentos",
            new_grantor: "Novo Outorgante",
            legal_entity: "Pessoa Jurídica",
            search_grantors: "Pesquisar por Outorgante",
            confirm: {
              title: "Deletar outorgante?",
              description: "Isso vai permanentemente deletar o outorgante",
            },
            errors: {
              name: "O nome é obrigatório",
              establishment: "Ao menos um estabelecimento é obrigatório",
              legal_entity: "A Pessoa Jurídica é obrigatório",
            },
          },
          salesContractAttachments: {
            title: "Anexo",
            description: "Descrição",
            search: "Buscar Anexos",
            add: "Adicionar Anexo",
            title_form: "Título",
            approval_date: "Data de Aprovação",
            approve: "Aprovar",
            attachment_date: "Data do Anexo",
            new_attachment: "Novo Anexo",
            attachment_type: "Tipo de Anexo",
            confirm: {
              title: "Excluir Anexo?",
              description: "Isso excluirá permanentemente o Anexo",
            },
            errors: {
              title: "O Título é obrigatório",
              description: "A descrição é obrigatória",
              attachment_type: "O tipo do anexo é obrigatório",
              attachment_date: "A data do anexo é obrigatória",
              file: "O arquivo é obrigatório",
            },
          },
          salesContractHistories: {
            title: "Histórico",
            search: "Pesquisa Histórico",
            add: "Adicionar Histórico",
            contract_title: "Histórico",
            new_history: "Nova Histórico",
            titleForm: "Título",
            history: "Histórico",
            approval_date: "Data Aprovação",
            history_date: "Data Histórico",
            approve: "Aprovar",
            confirm: {
              title: "Deletar histórico?",
              description: "Isso vai permanentemente deletar o histórico",
            },
            errors: {
              title: "O título é obrigatório",
              history: "O histórico é obrigatório",
              historyDate: "A data do histórico é obrigatória",
            },
          },
          contractAttachmentType: {
            title: "Tipo de Anexo de Contrato",
            search: "Pesquisar por tipo de anexo de contrato",
            add: "Adicionar Tipo de Anexo de Contrato",
            new: "Novo Tipo de Anexo de Contrato",
            active: "Ativo",
            inactive: "Inativo",
            contract_type: "Tipo de Contrato",
            enabled: "Habilitar",
            name: "Nome",
            status: "Status",
            confirm: {
              title: "Deletar tipo de anexo de contrato?",
              description:
                "Isso vai permanentemente deletar o tipo de anexo de contrato",
            },
            errors: {
              name: "O nome é obrigatório",
              contract_type: "O tipo de contrato é obrigatório",
            },
          },
          contractTerminationReason: {
            title: "Motivo de Cancelamento Contrato",
            search: "Pesquisar por Motivo de Cancelamento Contrato",
            add: "Adicionar Motivo de Cancelamento Contrato",
            new: "Novo Motivo de Cancelamento Contrato",
            active: "Ativo",
            inactive: "Inativo",
            code: "Código",
            contract_type: "Tipo de Contrato",
            enabled: "Habilitar",
            name: "Nome",
            status: "Status",
            confirm: {
              title: "Deletar motivo de cancelamento contrato?",
              description:
                "Isso vai permanentemente deletar o motivo de cancelamento contrato",
            },
            errors: {
              name: "O nome é obrigatório",
              contract_type: "O tipo de contrato é obrigatório",
            },
          },
          contract_classifications: {
            name: "Nome",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            enabled: "Habilitar",
            code: "Código",
            contract_type: "Tipo de Contrato",
            contract_classification: "Classificação de Contrato",
            search_contract_classification:
              "Pesquisar por Classificação de Contrato",
            add_contract_classification: "Adicionar Classificação de Contrato",
            new_contract_classification: "Nova Classificação de Contrato",
            confirm: {
              title: "Deletar classificação de contrato?",
              description:
                "Isso vai permanentemente deletar a classificação de contrato",
            },
            errors: {
              name: "O nome é obrigatório",
              contract_type: "O tipo de contrato é obrigatório",
            },
          },
          baseContracts: {
            title: "Clube de Vantagens e Benefícios",
            search: "Pesquisar por Clube de Vantagens e Benefícios",
            add: "Adicionar Clube de Vantagens e Benefícios",
            contratation_date: "Data da Contratação",
            termination_date: "Data da Finalização",
            situation_status: "Status da Situação",
            contract_classification: "Classificação do Contrato",
            integration_code: "Código de Integração",
            name: "Nome",
            status: "Status",
            approved: "Aprovado",
            code: "Código",
            canceled: "Cancelado",
            pending: "Em Análise",
            terminated: "Rescindido",
            approve: "Aprovar",
            cancel: "Cancelar",
            terminate: "Rescindir",
            reactivation: "Reativar",
            contractualObject: {
              substipulant: "Subestipulante",
              product: "Produto",
            },
            confirm: {
              title: "Deletar o clube de vantagens e benefícios?",
              description:
                "Isso vai permanentemente deletar o clube de vantagens e benefícios",
            },
            advantagesAndBenefitsClub: {
              title: "Clube de Vantagens e Benefícios",
              advantages_and_benefits_club_contract:
                "Contratos de Clube de Vantagens e Benefícios",
              grantor: "Outorgante",
              new: "Novo Clube de Vantagens e Benefícios",
              contract_classification: "Classificação de Contrato",
              contract_number: "Número de contrato",
              integration_code: "Código de Integração",
              contract_title: "Título do Contrato",
              renewal_period: "Período Renovação (meses)",
              main_contract: "Contrato Principal",
              situation: "Situação",
              legal_entity: "Pessoa Jurídica",
              individual_person: "Pessoa Física",
              contratation_data: "Dados da Contratação",
              contract_type: "Tipo do Contrato",
              contratation_date: "Data da Contratação",
              termination_date: "Data da Finalização",
              approval_date: "Data da Aprovação",
              cancelation_date: "Data da Cancelamento",
              reactivation_date: "Data da Reativação",
              code: "Código",
              benefit_administrators: "Administradores do Benefício",
              additional_data:
                "Dados Adicionais para Clube de Vantagens e Benefícios",
              tabs: {
                contractual_object: "Objetos Contratuais",
                history: "Histórico",
                contract: "Contrato",
                payer: "Pagadores",
                attachment: "Anexos",
                beneficiary: "Beneficiário",
              },
              errors: {
                contract_date: "A Data de contratação é obrigatória",
                contract_type: "O Tipo de contrato é obrigatório",
                individual_person: "A pessoa física é obrigatória",
                legal_entity: "A pessoa jurídica é obrigatória",
                renew_months: "Perídio de Renovação é obrigatório",
                grantor: "O Outorgante é obrigatório",
                situation: "A situação é obrigatória",
                contract_classification:
                  "A Classificação de Contrato é obrigatória",
                contract_title: "O Título do contrato é obrigatório",
              },
            },
          },
          beneficiaryClassification: {
            title: "Classificação de Beneficiários",
            new: "Nova Classificação de Beneficiários",
            add: "Adicionar Classificação de Beneficiário",
            search: "Pesquisar por Classificação de Beneficiário",
            name: "Nome",
            code: "Código",
            status: "Status",
            enable: "Habilitar",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar classificação de beneficiário?",
              description:
                "Isso vai permanentemente deletar a classificação de beneficiário",
            },
            errors: {
              name: "O Nome é obrigatório",
            },
          },
          contractProductClassifications: {
            title: "Classificação de Produtos",
            contract_product_classification: "Classificação de Produto",
            search: "Pesquisar por uma Classificação de Produtos",
            name: "Nome",
            status: "Status",
            active: "Ativo",
            code: "Código",
            inactive: "Inativo",
            enabled: "Habilitar",
            add: "Adicionar Classificação de Produto",
            new: "Nova Classificação de Produto",
            contract_type: "Tipo de Contrato",
            confirm: {
              title: "Deletar classificação de produto?",
              description:
                "Isso vai permanentemente deletar a classificação de produto",
            },
            errors: {
              name: "O nome é obrigatório",
              contract_type: "O Tipo do contrato é obrigatório",
            },
          },
          channelClassifications: {
            title: "Classificações de Canal de Vendas",
            channel_classification: "Classificação de Canal de Vendas",
            search: "Pesquisar por Classificação de Canal de Vendas",
            add: "Adicionar Classificação de Canal de Vendas",
            new: "Nova Classificação de Canal de Vendas",
            name: "Nome",
            code: "Código",
            status: "Status",
            enable: "Habilitar",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar classificação de canal de vendas",
              description:
                "Isso vai permanentemente deletar a classificação de canal de vendas",
            },
            errors: {
              name: "O nome é obrigatório",
              status: "O Status é obrigatório",
            },
          },
          salesChannel: {
            title: "Canais de Vendas",
            sales_channel: "Canal de Vendas",
            search: "Pesquisar por Canal de Vendas",
            add: "Adicionar Canal de Vendas",
            new: "Novo Canal de Vendas",
            legal_entity: "Pessoa Jurídica",
            individual_person: "Pessoa Física",
            title_placeholder: "Título",
            name: "Nome",
            code: "Código",
            active: "Ativo",
            inactive: "Inativo",
            suspension: "Suspender",
            inactivation: "Inativar",
            reactivation: "Reativar",
            approve: "Aprovar",
            approved: "Aprovado",
            contract_type: "Tipo Contrato",
            enabled: "Habilitar",
            reactivation_date: "Data de Reativação",
            inactivation_date: "Data de Inativação",
            suspension_date: "Data de Suspensão",
            approval_date: "Data de Aprovação",
            situation: "Situação",
            classification: "Classificação",
            sales_channel_type: "Tipo de Canal de Vendas",
            tabs: {
              sales_channel: "Canal de Vendas",
              seller: "Vendedores",
            },
            confirm: {
              title: "Deletar canal de vendas?",
              description: "Isso vai permanentemente deletar o canal de vendas",
            },
            errors: {
              title: "O título é obrigatório",
              situation: "A situação é obrigatória",
              sales_channel_classification: "A classificação é obrigatória",
              sales_channel_type: "O canal de vendas é obrigatório",
              individual_person: "A pessoa física é obrigatória",
              legal_entity: "A pessoa jurídica é obrigatória",
            },
          },
          salesContractBeneficiary: {
            title: "Beneficiário",
            search: "Pesquisar por Beneficiário",
            add: "Adicionar Beneficiário",
            new: "Novo Beneficiário",
            individual_person: "Pessoa Física",
            code: "Código",
            integration_code: "Código de Integração",
            active: "Ativo",
            approve: "Aprovar",
            cancel: "Cancelar",
            reestablish: "Reestabelecer",
            rescind: "Rescindir",
            suspend: "Suspender",
            approved: "Aprovado",
            canceled: "Cancelado",
            under_analysis: "Em Análise",
            rescinded: "Rescindido",
            suspended: "Suspenso",
            beneficiary_card: "Cartão do Beneficiário",
            admission_date: "Data de Admissão",
            original_inclusion_date: "Data de Original de Inclusão",
            beneficiary_classification: "Classificação do Beneficiário",
            kinship_degree: "Grau de Parentesco",
            beneficiary_holder: "Beneficiário Titular",
            type_of_holder: "Tipo de Titularidade",
            sales_product: "Produto",
            sales_price_list: "Lista de Preço",
            sales_payer: "Pagador",
            substipulant: "Subestipulante",
            sales_channel: "Canal de Vendas",
            sales_seller: "Vendedor",
            approval_date: "Data de Aprovação",
            situation: "Situação",
            confirm: {
              title: "Deletar Beneficiário?",
              description: "Isso vai permanentemente deletar o Beneficiário",
            },
            errors: {
              situation: "A situação é obrigatória",
              individual_person: "A Pessoa Física é obrigatória",
              beneficiary_classification:
                " A classificação do beneficiário é obrigatória",
              sales_product: "O produto é obrigatório",
              sales_price_list: "A lista de preço é obrigatória",
              sales_channel: "O canal de vendas é obrigatório",
              sales_payer: "O pagador é obrigatório",
              sales_seller: "O vendedor é obrigatório",
            },
          },
          salesContractBeneficiaryCard: {
            title: "Cartão do Beneficiário",
            add: "Adicionar Cartão do Beneficiário",
            card_identifier: "Identificação do Cartão",
            initial_date: "Data Inicial",
            end_date: "Data Final",
            integration_code: "Código de Integração",
            confirm: {
              title: "Deletar Cartão do Beneficiário?",
              description:
                "Isso vai permanentemente deletar a Cartão do Beneficiário",
            },
            errors: {
              card_identifier: "A identificação do cartão é obrigatória",
              initial_date: "A data inicial é obrigatória",
              end_date: "A data final é obrigatória",
            },
          },
          salesContractPayerFinantial: {
            title: "Financeiro do Pagador",
            search: "Buscar Financeiro do Pagador",
            add: "Adicionar Financeiro do Pagador",
            new: "Novo Financeiro do Pagador",
            mail: "E-mail",
            biling_method: "Método de Cobrança",
            monthly_payment_due_date: "Dia do Pagamento",
            initial_date: "Data Inicial",
            end_date: "Data Final",
            phone: "Telefone",
            whatsapp_number: "Número do WhatsApp",
            observation: "Observação",
            confirm: {
              title: "Excluir Pagador?",
              description: "Isso excluirá permanentemente o Pagador",
            },
            errors: {
              biling_method: "O método de cobrança é obrigatório",
              initial_date: "A data inicial é obrigatória",
              end_date: "A data final é obrigatória",
              monthly_payment_due_date: "O dia do pagamento é obrigatória",
            },
          },
          salesContractPayer: {
            title: "Pagador",
            search: "Buscar Pagador",
            add: "Adicionar Pagador",
            new: "Novo Pagador",
            payer_finantial: "Financeiro do Pagador",
            legal_entity: "Pessoa Jurídica",
            individual_person: "Pessoa Física",
            payer: "Pagador",
            title_payer: "Título",
            address_type: "Tipo de Endereço",
            address: "Endereço",
            payerType: "Tipo",
            integration_code: "Código de Integração",
            code: "Código",
            active: "Ativo",
            inactive: "Inativo",
            suspension: "Suspender",
            inactivation: "Inativar",
            reactivation: "Reativar",
            approve: "Aprovar",
            reactivation_date: "Data de Reativação",
            inactivation_date: "Data de Inativação",
            suspension_date: "Data de Suspensão",
            approval_date: "Data de Aprovação",
            situation: "Situação",
            confirm: {
              title: "Excluir Pagador?",
              description: "Isso irá excluir permanentemente o Pagador",
            },
            errors: {
              situation: "A situação é obrigatória",
              individual_person: "A pessoa física é obrigatória",
              legal_entity: "A pessoa jurídica é obrigatória",
              address_type: "O tipo de endereço é obrigatório",
              payer_title: "O título é obrigatório",
            },
          },
          salesContractSubStipulant: {
            title: "Subestipulante",
            search: "Pesquisar por Subestipulante",
            add: "Adicionar Subestipulante",
            new: "Novo Subestipulante",
            legal_entity: "Pessoa Jurídica",
            individual_person: "Pessoa Física",
            code: "Código",
            integration_code: "Código de Integração",
            active: "Ativo",
            inactive: "Inativo",
            suspension: "Suspender",
            inactivation: "Inativar",
            reactivation: "Reativar",
            approve: "Aprovar",
            reactivation_date: "Data de Reativação",
            inactivation_date: "Data de Inativação",
            suspension_date: "Data de Suspensão",
            approval_date: "Data de Aprovação",
            situation: "Situação",
            confirm: {
              title: "Deletar Subestipulante?",
              description: "Isso vai permanentemente deletar o Subestipulante",
            },
            errors: {
              situation: "A situação é obrigatória",
              individual_person: "A Pessoa Física é obrigatória",
              legal_entity: "A Pessoa Jurídica é obrigatória",
            },
          },
          salesContractProducts: {
            title: "Produto",
            add: "Adicionar Produto",
            new: "Novo Produto",
            code: "Code",
            name: "Nome",
            product: "Produto",
            price_list: "Lista de Preço",
            price_list_product: "Listas de Preço",
            confirm: {
              title: "Deletar Produto?",
              description: "Isso vai permanentemente deletar o Produto",
            },
            errors: {
              product: "O produto é obrigatório",
              price_list: "A lista de preço é obrigatória",
            },
          },
          salesContractPriceList: {
            title: "Listas de Preços",
            price_list: "Lista de Preço",
            search: "Pesquisar por uma lista de preços",
            add: "Adicionar Lista de Preços",
            name: "Nome",
            initial_date: "Data Inicial",
            end_date: "Data Final",
            approval_date: "Data Aprovação",
            approve: "Aprovar",
            code: "Código",
            new_price_list: "Nova Lista de Preços",
            add_new_item: "Adicionar Novo Item",
            degree_of_entitlement: "Grau de Titularidade",
            initial_age: "Idade Inicial",
            end_age: "Idade Final",
            items: "Itens",
            price: "Preço",
            confirm: {
              title: "Deletar Lista de Preços?",
              description: "Isso vai permanentemente deletar a Lista de Preços",
            },
            errors: {
              name: "O nome é obrigatório",
              initial_date: "A data inicial é obrigatória",
              end_date: "A data final é obrigatória",
              code: "O código é obrigatório",
              price: "O preço é obrigatório",
              date: "A data final deve ser maior que a data inicial",
              age: "A idade final deve ser maior que a idade inicial",
              degree_of_entitlement: "O grau de elegibilidade é obrigatório",
              sales_contract_price_list_items: "Ao menos um item é obrigatório",
            },
          },
          salesSeller: {
            title: "Vendedor",
            title_seller: "Título do Vendedor",
            search: "Buscar Vendedor",
            add: "Adicionar Vendedor",
            new: "Novo Vendedor",
            individual: "Individual",
            active: "Ativo",
            inactive: "Inativo",
            suspension: "Suspender",
            inactivation: "Inativar",
            code: "Código",
            reactivation: "Reativar",
            approve: "Aprovar",
            approved: "Aprovado",
            suspended: "Suspenso",
            reactivation_date: "Data de Reativação",
            inactivation_date: "Data de Inativação",
            suspension_date: "Data de Suspensão",
            approval_date: "Data de Aprovação",
            situation: "Situação",
            confirm: {
              title: "Excluir vendedor?",
              description: "Isso excluirá permanentemente o vendedor",
            },
            errors: {
              title: "O título do vendedor é obrigatório",
              situation: "A situação é obrigatória",
              individual: "A pessoa física é obrigatória",
            },
          },
          contractProducts: {
            tabs: {
              products: "Produtos",
              price: "Preço",
              history: "Histórico",
              priceList: "Listas de Preços",
            },
            products: {
              title: "Produtos",
              product: "Produto",
              situation: "Situação",
              new_product: "Novo Produto",
              search: "Pesquisar por Produtos",
              add: "Adicionar Produto",
              contract_title: "Título do Contrato",
              name: "Nome",
              active: "Ativo",
              inactive: "Inativo",
              approved: "Aprovado",
              canceled: "Cancelado",
              suspended: "Suspenso",
              reactivation_date: "Data de Reativação",
              inactivation_date: "Data de Inativação",
              suspension_date: "Data de Suspensão",
              approval_date: "Data de Aprovação",
              cancelation_date: "Data de Cancelação",
              suspension: "Suspender",
              inactivation: "Inativar",
              reactivation: "Reativar",
              approve: "Aprovar",
              cancelation: "Cancelar",
              fantasy_name: "Nome Fantasia",
              sales_contract_type: "Tipo de Contrato",
              integration_code: "Código de Integração",
              sales_product_ctr_classification: "Classificação de Produto",
              sales_grantors: "Outorgantes",
              code: "Código",
              confirm: {
                title: "Deletar produto?",
                description: "Isso vai permanentemente deletar o produto",
              },
              errors: {
                name: "O nome é obrigatório",
                fantasy_name: "O nome fantasia é obrigatório",
                sales_grantors: "Ao menos um outorgante é obrigatório",
                sales_product_ctr_classification:
                  "A classificação de produto é obrigatória",
                sales_contract_type: "O tipo de contrato é obrigatório",
              },
            },
          },
          salesProductCtrPriceList: {
            title: "Listas de Preços",
            price_list: "Lista de Preço",
            search: "Pesquisar por uma lista de preços",
            add: "Adicionar Lista de Preços",
            name: "Nome",
            initial_date: "Data Inicial",
            end_date: "Data Final",
            approval_date: "Data Aprovação",
            approve: "Aprovar",
            code: "Código",
            new_price_list: "Nova Lista de Preços",
            add_new_item: "Adicionar Novo Item",
            degree_of_entitlement: "Grau de Titularidade",
            initial_age: "Idade Inicial",
            end_age: "Idade Final",
            items: "Itens",
            price: "Preço",
            confirm: {
              title: "Deletar Lista de Preços?",
              description: "Isso vai permanentemente deletar a Lista de Preços",
            },
            errors: {
              name: "O nome é obrigatório",
              initial_date: "A data inicial é obrigatória",
              end_date: "A data final é obrigatória",
              code: "O código é obrigatório",
              price: "O preço é obrigatório",
              degree_of_entitlement: "O grau de elegibilidade é obrigatório",
              date: "A data final deve ser maior que a data inicial",
              age: "A idade final deve ser maior que a idade inicial",
              sales_product_ctr_price_list_items:
                "Ao menos um item é obrigatório",
            },
          },
          salesProductCtrHistories: {
            title: "Histórico",
            search: "Pesquisa Histórico",
            add: "Adicionar Histórico",
            contract_title: "Histórico",
            new_history: "Novo Histórico",
            titleForm: "Título",
            history: "Histórico",
            approval_date: "Data Aprovação",
            history_date: "Data Histórico",
            approve: "Aprovar",
            confirm: {
              title: "Deletar histórico?",
              description: "Isso vai permanentemente deletar o histórico",
            },
            errors: {
              title: "O título é obrigatório",
              history: "O histórico é obrigatório",
              historyDate: "A data do histórico é obrigatória",
            },
          },
          beneficiaryTerminationReasons: {
            title: "Motivo de Termino Beneficiários",
            search: "Pesquisar por Motivo de Termino Beneficiário",
            add: "Adicionar Motivo de Termino Beneficiário",
            new: "Novo Motivo de Termino Beneficiário",
            name: "Nome",
            status: "Status",
            code: "Código",
            active: "Ativo",
            inactive: "Inativo",
            enabled: "Habilitar",
            confirm: {
              title: "Deletar motivo de término beneficiário?",
              description:
                "Isso vai permanentemente deletar o motivo de término beneficiário",
            },
            errors: {
              name: "O Nome é obrigatório",
            },
          },
          beneficiaryCancellationsReason: {
            title: "Motivo de Cancelamento Beneficiário",
            search: "Pesquisar por de Motivo de Cancelamento Beneficiário",
            add: "Adicionar Motivo de Cancelamento Beneficiário",
            new: "Novo Motivo de Cancelamento Beneficiário",
            name: "Nome",
            status: "Status",
            inactive: "Inativo",
            code: "Código",
            active: "Ativo",
            enable: "Habilitar",
            confirm: {
              title: "Deletar o motivo de cancelamento beneficiário?",
              description:
                "Isso vai permanentemente deletar o motivo de cancelamento beneficiário",
            },
            errors: {
              name: "O nome é obrigatório",
            },
          },
          beneficiarySuspensionsReason: {
            title: "Motivo de Suspensão Beneficiário",
            search: "Pesquisar por Motivo de Suspensão Beneficiário",
            add: "Adicionar Motivo de Suspensão Beneficiário",
            new: "Novo Motivo de Suspensão Beneficiário",
            enable: "Habilitar",
            name: "Nome",
            code: "Código",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            confirm: {
              title: "Deletar o motivo de suspensão beneficiário?",
              description:
                "Isso vai permanentemente deletar o motivo de suspensão beneficiário",
            },
            errors: {
              name: "O nome é obrigatório",
            },
          },
        },
      },
    },
    components: {
      content: {
        contents: "Conteúdos",
        add_new_content: "Adicionar conteúdo",
        add_new_video: "Adicionar vídeo",
        content_type: "Tipo de notícia",
        content_status: "Status da notícia",
        enable: "Habilitar",
        reading_time: "Tempo de leitura",
        reading_time_info: "Tempo em minutos",
        site_app: "Aplicações",
        title: "Título",
        language: "Idioma",
        language_tooltip: "Ver idioma",
        summary: "Resumo",
        publication_date: "Data de publicação",
        content_tags: "Tags da notícia",
        is_published: "Publicar",
        status: "Status",
        published: "Publicada",
        link: "Link do vídeo",
        link_helper: "Adicione um link do youtube",
        not_published: "Não publicada",
        enabled: "Ativo",
        inactive: "Inativo",
        videos: "Vídeos",
        highlight_end: "Destaque encerrado em",
        highlight_start: "Destaque iniciado em",
        confirm: {
          title: "Deletar o conteúdo?",
          description: "Isso vai permanentemente deletar o conteúdo",
        },
        errors: {
          reading_time: "O tempo de leitura é obrigatório",
          site_apps: "Ao menos uma aplicação é obrigatório",
          language: "O idioma é obrigatório",
          title: "O título é obrigatório",
          tags: "Ao menos uma tag da notícia é obrigatória",
          summary: "O resumo é obrigatório",
          content: "O conteúdo é obrigatório",
          contents: "Ao menos um conteúdo é obrigatório",
          file: "O arquivo é obrigatório",
          link: "O link é inválido. Por favor adicione um link do Youtube",
          highlight_end: "A data de fim não pode ser maior que a de início",
        },
      },
      schedule: {
        selectAll: "Selecionar todos",
        seeMore: "Ver mais {{quantity}}",
        day: "Dia",
        week: "Semana",
        month: "Mês",
        today: "Hoje",
        newSchedule: {
          button: "Agendar",
          title: "Novo agendamento",
          date: "Data",
          required: "Campo obrigatório",
          hour: "Horário",
          professional: "Profissional",
          type: "Tipo de agendamento",
          duration: "Duração",
          insurance: {
            add: "Adicionar Convênio",
            edit: "Editar Convênio",
            validate: "Validade da carteirinha",
            code: "Número da carteirinha",
            number: {
              label: "Número da carteirinha (opcional)",
              error: "O número é obrigatório",
            },
            validity: {
              label: "Validade da carteirinha (opcional)",
              error: "A validade é obrigatória",
            },
            individual: {
              label: "Paciente",
              error: "O paciente é obrigatório",
            },
            insurance: {
              label: "Convênio",
              error: "O convênio é obrigatório",
            },
          },
        },
        menu: {
          source: {
            both: "Todos",
          },
          exams: "Exames",
          subtitle: {
            title: "Legenda",
            someFree: "Existem livres",
            full: "Todos ocupados",
            allFree: "Todos livres",
          },

          period: "Período",
          situation: "Situação",
          both: "Ambas",
          specialitys: "Especialidades",
          professionals: {
            label: "Profissionais",
            noOptionsText: "Nenhum profissional encontrado",
            loading: "Carregando...",
          },
        },
        schedulePopover: {
          copy: "Copiar identificador",
          copyCardNumber: "Copiar número da carteirinha",
          cancel: "Cancelar agendamento",
          insurance: "Convênio",
          confirm: "Confirmar cancelamento",
          cancelButton: "Cancelar",
          justification: {
            label: "Justificativa",
            placeholder: "Digite uma justificativa",
            error: "A justificativa é obrigatória",
          },
          reason: {
            label: "Razão",
            placeholder: "Selecione uma razão",
            error: "A razão é obrigatória",
          },
          cancelDescription:
            "Cancelar consulta de {{pacient}} com {{doctor}} em {{date}}",
        },
      },
      scheduleAssistant: {
        button: "Assistente de Agendamento",
        insurance: {
          title: "Meus Convênios",
          subtitle: "Gerencie os seus convênios e os do grupo familiar",
          add: "Adicionar",
          insuranceName: "Convênio",
          individual: "Indivíduo",
          cardNumber: "Número do cartão",
          cardValidity: "Validade",
          delete: {
            title: "Deletar convênio?",
            description: "Essa ação é permanente!",
            confirmationText: "Sim, deletar",
            cancellationText: "Cancelar",
          },
          addInsurance: {
            edit: "Editar convênio",
            add: "Adicionar convênio",
            buttonSave: "Salvar",
            buttonEdit: "Editar",
            number: {
              label: "Número da carteirinha (opcional)",
              error: "O número é obrigatório",
            },
            validity: {
              label: "Validade da carteirinha (opcional)",
              error: "A validade é obrigatória",
            },
            individual: {
              label: "Paciente",
              error: "O paciente é obrigatório",
            },
            insurance: {
              label: "Convênio",
              error: "O convênio é obrigatório",
            },
          },
        },
        newSchedule: {
          success: "Agendamento cadastrado com sucesso",
          sched: "Agendar",
          next: "Próximo",
          back: "Voltar",
          finish: "Finalizar",
          notAvaiableModal: {
            button: "Ok",
            description:
              "O horário escolhido não está mais disponível, por favor selecione outro horário.",
          },
          steps: {
            patient: {
              title: "Selecione o paciente",
              error: "O paciente é obrigatório",
            },
            insurance: {
              title: "Selecione o convênio",
              error: "O convênio é obrigatório",
            },
            search: {
              title: "Procure por uma agenda",
              specialityError: "A especialidade é obrigatória",
              periodError: "O período é obrigatório",
              examError: "O exame é obrigatório",
            },
            schedule: {
              title: "Selecione uma data e um horário",
              error: "O horário é obrigatório",
            },
            confirm: "Confirmar dados",
          },
          insurance: "Convênio",
          addInsurance: "Adicionar convênio",
          addFamilyGroup: "Adicionar familiar",
          search: {
            from: "A partir de",
            examGroup: "Exame",
            professional: "Profissional (opcional)",
            speciality: "Especialidade",
            type: "Tipo de agendamento",
            period: "Período",
          },
          schedule: {
            freeDays: "Dias com datas disponíveis",
          },
          confirm: {
            descriptionSite: "Profissional",
            patient: "Paciente",
            insurance: "Convênio",
            schedule: "Agendamento",
          },
        },
      },
      panel: {
        add_dashboard: "Adicionar Dashboard",
        select: "Selecione e ordene os componentes",
        helper_text: "O Dashboard deve ter ao menos um componente",
        no_data: "Sem dados",
        not_found: "Painel não encontrado",
        filter: {
          day: "Dia",
          week: "Semana",
          month: "Mês",
          year: "Ano",
        },
        no_dashboards:
          "Nenhum dashboard encontrado, adicione seu primeiro dashboard!",
        select_tooltip: "Selecionar e ordenar componentes",
        delete_dashboard: {
          delete: "Deletar dashboard",
          title: "Deletar dashboard {{ name }}",
          description:
            "Isso vai permanentemente deletar o seu dashboards e seus dados.",
          cancel: "Cancelar",
          confirm: "Sim, deletar",
        },
        helper: {
          title: "Informações sobre os dados dos Dashboards",
          first:
            "Os registros diários são criados no final de cada dia, por conta disso no dia de criação de um painel nenhum dashboard possui dados.",
          second:
            "Os registros semanais são criados no domingo, ao acabar asemana. No <1> primeiro dia da semana seguinte à consultada.</1>",
          third:
            "Os registros mensais são criados no<1> primeiro dia do mês seguinte ao consultado.</1>",
          fourth:
            "Os registros anuais são criados no<1> primeiro dia do ano seguinte ao consultado.</1>",
        },
      },
      selectBool: {
        true: "Verdadeiro",
        false: "Falso",
      },
      menu: {
        home: {
          home: "início",
          begin: "resumo",
          dashboard: "painel",
        },
        "4uSched": {
          calendar: {
            calendar: "Calendário",
            tasyCalendar: "Tasy - Consultas e Exames",
          },
        },
        myDashboards: {
          myDashboards: "Meus painéis",
          view: "Visualização",
          dashboards: "Painéis",
        },
        people: {
          people: "Pessoas",
          individuals: "pessoa física",
          legalEntities: "pessoa jurídica",
          individualValidations: "Validação Cadastro PF",
        },
        registers: {
          beneficiaries: "beneficiários",
          doctors: "médicos",
          userProfiles: "perfis de usuário",
          client: "cliente",
          templates: "modelos",
          integrations: "integrações",
          documents: "Termos e políticas",
          holidays: "Feriados",
        },
        admSystem: {
          admSystem: "Adm. Sistema",
          users: "usuários",
          establishmentParams: "Parâmetros Gerais",
          roles: "Perfil",
          establishments: "Estabelecimentos",
          aditionalSettings: {
            aditionalSettings: "Configurações adicionais",
            cardsTemplates: "Modelos de carteirinhas",
          },
          companygroups: "Grupos Empresariais",
          companies: "Empresas",
        },
        globals: {
          globals: "Globais",
          languages: "idiomas",
          menus: "menus",
          generics: "genéricos",
          countries: "países",
          extensions: "extensões",
          cities: "cidades",
          neighborhoods: "bairros",
          provinces: "estados",
          banks: "bancos",
          jobs: "jobs",
          paramsDefaultEstablishment: "Parâmetros padrão do estabelecimento",
        },
        menu_secondary_item: {
          modal_title: "Em desenvolvimento",
          modal_content:
            "Ainda estamos trabalhando no desenvolvimento desta tela, em breve traremos novidades.",
        },
        secondary_menu: {
          suport: "Suporte",
          configs: "Configurações",
          extensions_store: "Loja de extensões",
        },
        applications: {
          applications: "Aplicações",
          register: "Cadastro",
        },
        "4uChatBot": {
          templates: {
            templates: "Templates",
            whatsapp: "WhatsApp",
            internal: "Interno CRM",
          },
          createAutomations: {
            createAutomations: "Automações",
            automationRules: "Regras de Automação",
          },
          fluxos: {
            fluxos: "Fluxos",
            serviceWhatsapp: "Atendimento WhatsApp",
          },
        },
        evaluation: {
          evaluation: "Avaliações",
          questions: "Questões",
          evaluationForms: "Formulários",
          evaluationLogs: "Logs de Integração",
          evaluationLogsTicket: "Logs Pesquisa Satisfação Ticket",
        },
        chatBot: {
          register: "Cadastro",
          ticket_service: "Serviços de tickets(cards)",
          service: "Atendimento",
          ticket_service_area: "Área de atendimento",
          ticket_protocol_rule: "Regras de geração de protocolo",
          ticket_service_day: "Horário de atendimento",
          ticket_service_message: "Mensagens",
        },
        logs: {
          logs: "Logs",
          healthcheck: "Health Checks",
          logRegisterError: "Erros de Cadastro",
        },
        settings: {
          system: {
            system: "Sistema",
            generalSettings: {
              generalSettings: "Configurações globais",
              correios: "Correios",
              centralBank: "Banco central",
              geolocalization: "Geolocalização",
              certillion: "Certillion",
            },
            cache: "Cache",
            paramsDefaultEstablishment: "Parâmetros padrão do estabelecimento",
            executeSql: "Executar SQL",
          },
          generalSettings: {
            generalSettings: "Configurações gerais",
            holidays: "Feriados",
            establishmentParams: "Parâmetros do estabelecimento",
            healthChecks: "Health Checks de Microsserviços 4uHub",
          },
          applications: {
            applications: "Aplicações",
            cardsTemplates: "Templates de carteirinha",
          },
          crm: {
            crm: "CRM",
            chatBot: {
              chatBot: "ChatBot",
              ticketServiceArea: "Áreas de atendimento",
              ticketServiceMessage: "Mensagens",
            },
            ticketProtocolRule: "Regras de geração de protocolo",
            ticketService: "Serviço de tickets(cards)",
            ticketAutoFinishRule: "Regras de finalização de ticket(card)",
            ticketDefaultDocs: "Protocolos de Orientações Padrão",
            events: "Eventos",
            externalProcessType: "Tipos de Processos Externos",
          },
          chatBot: {
            chatBot: "ChatBot",
            ticketServiceArea: "Áreas de atendimento",
            ticketServiceMessage: "Mensagens",
            ticketServiceDay: "Horário de atendimento",
          },
          hcp: {
            hcp: "4uHcP",
            healthProgramTypes: "Tipos de programas de saúde",
            workGroupTypes: "Tipos de grupos de trabalho",
          },
          "4uBus": {
            "4uBus": "4uBus",
            ipm: {
              ipm: "IPM",
              generalSettings: "Configurações gerais",
            },
            globals: {
              globals: "Configurações gerais",
              sync: "Sincronização",
            },
            zscan: {
              zscan: "ZScan",
              examSendRule: "Regra de envio de exame",
            },
            externalExamPrescription: {
              externalExamPrescription:
                "Geração de Prescrição do Pedido de Exame Externo",
              rules: "Regras de Geração de Prescrição",
            },
          },
          "4uSched": {
            "4uSched": "4uSched",
            tasyCalendar: {
              tasyCalendar: "Agenda Tasy",
              checkIn: "Check in",
              confirmation: "Confirmação de Agenda",
            },
          },
          "4uSiteApp": {
            "4uSiteApp": "4uSiteApp",
            digitalTriage: {
              digitalTriage: "Check in da Triagem PA Digital",
              checkIn: "Check in",
            },
          },
        },

        "4uSiteApp": {
          applications: {
            applications: "Aplicações",
            register: "Cadastro",
            myApplications: "Minhas aplicações",
          },
          communication: {
            communication: "Comunicação",
            banners: "Banners",
            notifications: "Notificações",
            news: "Notícias",
          },
          privacyAndSecurity: {
            privacyAndSecurity: "Segurança e Privacidade",
            termsAndPolicies: "Termos e políticas",
          },
          sac: {
            sac: "SAC e Ouvidoria",
            faq: "FAQ",
          },
        },
        "4uHcP": {
          structure: {
            structure: "Estrutura de programas",
            healthPrograms: "Programas de saúde",
            workGroups: "Grupos de Trabalho",
          },
          content: {
            content: "Conteúdo",
            contentRules: "Regras de Conteúdo",
          },
          captation: {
            captation: "Captação",
            captations: "Captações",
            imports: "Importações",
          },
        },
        "4ubus": {
          register: {
            register: "Cadastro",
            accessTokens: "Tokens de acesso",
            profileTasy: "Perfil - Barramento Tasy",
          },
          setRelationships: {
            setRelationships: "Relacionamentos",
            ipm: "IPM x TASY",
            "4uHub": "Tasy - Call Center",
            zscanEvo: "ZScan Evo",
          },
          restriction: {
            restriction: "Restrições",
            rule: "Agenda",
          },
          logs: {
            logs: "Logs",
            ipmxtasyinternal: "IPM - Receber - IPM x Tasy (Interno)",
            tasyxipminternal: "IPM - Enviar - Tasy x IPM (Interno)",
            tasyCalendar: "Agendas Tasy",
            ipmtasy: "IPM - Receber - IPM x Tasy",
            tasyipm: "IPM - Enviar - Tasy x IPM",
            monitoring: "Monitoramento OnPremise",
          },
          robots: {
            robots: "Robôs",
            list: "Lista",
          },
        },
        "4uSalesContract": {
          register: {
            register: "Cadastros",
            salesGrantors: "Outorgantes",
            salesContractAttachmentTypes: "Tipo de Anexo de Contrato",
            salesContractClassifications: "Classificação de Contrato",
            salesProductCtrClassifications: "Classificação de Produtos",
            salesChannelClassifications: "Classificações de Canal de Vendas",
            salesChannels: "Canais de Vendas",
            salesProductCtrs: "Produto",
            salesContractTerminationReason: "Motivo de Cancelamento Contrato",
            beneficiaryContractReasonOfTerminations:
              "Motivo de Termino Beneficiário",
            salesContractBeneficiaryClassifications:
              "Classificação de Beneficiário",
            beneficiaryContractReasonOfCancellations:
              "Motivo de Cancelamento Beneficiário",
            beneficiaryContractReasonOfSuspensions:
              "Motivo Suspensão Beneficiário",
          },
          salesContract: {
            salesContract: "Contratos",
            salesContractClubAbs: "Clube de Vantagens e Benefícios",
          },
        },
        "4uESign": {
          createAutomations: {
            createAutomations: "Automações",
            automationRules: "Regras de automação",
          },
        },
      },
      logs_accordions: {
        user: "Usuário:",
        duration: "Duração:",
      },
      logs_table: {
        name: "Nome",
        original_value: "Dados originais",
        new_value: "Dados novos",
        button_see_more: "Ver mais",
      },
      logs_no_data: {
        message: "Dados não encontrados!",
      },
      roles: {
        title: "Perfil",
        name: "Nome",
        establishment: "Estabelecimento",
        search_name: "Procure por um nome",
        new_role: "Novo perfil",
      },
      roles_basic_data: {
        name: "Nome",
        description: "Descrição",
      },
      roles_establishments: {
        title: "Estabelecimentos",
        add_establishments: "Adicionar novo estabelecimento",
        name: "O nome é obrigatório",
        label: "Estabelecimento(s) que poderão utilizar este perfil",
        establishmentsTypeId: "Selecione um estabelecimento",
        establishments_tooltip: "Ver estabelecimentos",
      },
      roles_privileges: {
        title: "Privilégios",
        add_privileges: "Adicionar novo privilégio",
      },
      forgetPasswordForm: {
        title: "Esqueceu a senha?",
        subtitle:
          "Insira seu CPF abaixo e enviaremos um código no seu e-mail para a recuperação de senha.",
        button: "Enviar",
        sending: "Enviando",
        login: "CPF",
        login_required: "O campo CPF é obrigatório!",
        notification_email_sent: "Email enviado com sucesso!",
        back_login: "Voltar para o login",
      },
      forgetPasswordModal: {
        title: "Instruções enviadas!",
        message:
          "Nós enviamos instruções de alteração da senha para o seu email, por favor cheque todas as caixas de entrada e a pasta de spam.",
        close: "Fechar",
      },
      resetPasswordForm: {
        title: "Nova senha",
        subtitle:
          "Digite  o código enviado para seu email. Se não encontrá-lo verifique a área de spam.",
        password: "Senha",
        confirm_password: "Confirmar senha",
        confirmation_code: "Código de confirmação",
        sending: "Enviando",
        send: "Enviar",
        new_password_required: "O campo senha é obrigatório.",
        confirm_new_password_required:
          "O campo de confirmar senha é obrigatório.",
        passwords_match: "As senhas não coincidem.",
        confirmation_code_required: "O código de confirmação é obrigatório",
        notification_password_success: "Senha alterada com sucesso!",
      },
      noRowsMessage: {
        no_permission: "Você não possui permissão para ver as informações",
        no_data: "Você não possui informações cadastradas",
      },
      onPremiseModal: {
        establishment: {
          establishment_on_premise: "Estabelecimento on premise",
          no_data: "Nenhum estabelecimento encontrado",
        },
        params: {
          params_on_premise: "Parâmetros on premise",
          no_data: "Nenhum parâmetro encontrado",
        },
        generics: {
          generics_on_premise: "Genéricos on premise",
          no_data: "Nenhum genérico encontrado",
        },
        health: {
          health_on_premise: "Planos de Saúde sincronizados",
          no_data: "Nenhum plano de saúde encontrado",
        },
        medicSpecialties: {
          no_data: "Nenhuma especialidade médica encontrada",
        },
        providerTypes: {
          no_data: "Nenhum tipo de prestador encontrado",
        },
        professionalCouncils: {
          no_data: "Nenhum conselho profissional encontrado",
        },
        medicalPracticeAreas: {
          no_data: "Nenhuma área de atuação médica encontrada",
        },
      },
      inputFileValidations: {
        invalid_type: "Tipo de arquivo não suportado",
        invalid_size:
          "Tamanho de arquivo não suportado. (Max {{maxFileSize}} MB)",
      },
      cardMenu: {
        button: "Ver mais",
      },
      extensionsStoreHeader: {
        title: "Loja de extensões",
        search: "Pesquisar por uma extensão ou tag",
        add_extensions: "Adicionar extensões",
        my_extensions: "Minhas extensões",
        back: "Voltar",
      },
      extensionsCard: {
        details: "Detalhes",
        install: "Instalar",
        request_install: "Solicitar instalação",
        requested: "Instalação solicitada",
        installing: "Instalando",
        installed: "Instalado",
        free: "Grátis",
        select: "Selecione um site",
        tooltip_install: "Instalação",
        tooltip_month: "Mensalidade",
        site_app: "Sites ou Apps",
        errors: {
          site_app: "Selecione ao menos um site ou app para instalar",
        },
      },
      extensionsStoreDetail: {
        title: "Detalhes da extensão",
        free: "Grátis",
        installed: "Instalado",
        about: "Sobre a extensão",
        available_for: "Disponível para",
        install: "Instalar",
        request_install: "Solicitar instalação",
        requested: "Instalação solicitada",
        settings: "Configurar em",
        configure: "Configurar",
        tooltip_install: "Instalar",
        tooltip_uninstall: "Desinstalar",
        tooltip_settings: "Configurações",
        tooltip_month: "Mensalidade",
        tags: "Tags",
        dependencies: "Dependências",
      },
      extensionInstallPopper: {
        title: "Deseja realmente instalar a extensão?",
        request_title: "Deseja realmente solicitar a instalação da extensão?",
        cancel: "Cancelar",
        install: "Instalar",
        request: "Solicitar",
      },
      extensionUninstallPopper: {
        title: "Deseja realmente desinstalar a extensão?",
        cancel: "Cancelar",
        uninstall: "Desinstalar",
        uninstalling: "Desinstalando",
      },
      extensionSettings: {
        title: "Configurar extensão",
        identifier: "Identificador",
        property: "Propriedade",
        value: "Valor",
        description: "Descrição",
      },
      settingsCorreios: {
        url_token: "Token da URL",
        user_token: "Token do Usuário",
        access_token: "Token do Código de Acesso",
        post_card: "Cartão de Postagem",
      },
      settingsGeneral: {
        user_token: "correiosTokenUser",
        access_token: "correiosTokenAccessCode",
        post_card: "correiosTokenPostCard",
        correios_url: "URL dos Correios",
        correios_token_user: "correiosTokenUser",
        correios_authentication: "correiosAuthenticationUrl",
        correios_locations: "correiosLocationsUrl",
        correios_addresses: "correiosAddressesUrl",
        cep_cache_time: "cepCacheTime",
        correios_page_size: "correiosPageSize",
        bank_base: "bankBaseUrl",
        bank_cooperativas: "bankCooperativasUrl",
        bank_bancos: "bankBancosUrl",
        geolocation: "Geolocalização",
        api_geocoding_key: "apiGeocodingKey",
        save: "Salvar",
        saving: "Salvando",
        success: "Configurações gerais atualizadas com sucesso",
        errors: {
          user_token: "O correiosTokenUser é obrigatório",
          access_token: "O correiosTokenAccessCode é obrigatório",
          post_card: "O correiosTokenPostCard é obrigatório",
          correios_url: "A URL dos Correios é obrigatória",
          correios_token_user: "O correiosTokenUser é obrigatório",
          correios_authentication: "O correiosAuthenticationUrl é obrigatório",
          correios_locations: "O correiosLocationsUrl é obrigatório",
          correios_addresses: "O correiosAddressesUrl é obrigatório",
          cep_cache_time: "O cepCacheTime é obrigatório",
          bank_base: "O bankBaseUrl é obrigatório",
          bank_cooperativas: "O bankCooperativasUrl é obrigatório",
          bank_bancos: "O bankBancosUrl é obrigatório",
          correios_page_size: "O correiosPageSize é obrigatório",
          api_geocoding_key: "O apiGeocodingKey é obrigatório",
        },
      },
      certillionSettings: {
        title: "Configurações do Certillion",
        base_url: "URL base",
        client_secret: "Segredo do cliente",
        seconds_to_find_signed_files:
          "Segundos para encontrar arquivos assinados",
        token_path: "Caminho do token",
        upload_file_path: "Caminho do arquivo para upload",
        pscs_accounts_path: "Caminho das contas PSCs",
        client_id: "ID do cliente",
        default_minutes_signature_session:
          "Minutos padrão para a sessão de assinatura",
        authorize_path: "Caminho de autorização",
        signature_path: "Caminho da assinatura",
        client_token_path: "Caminho do token do cliente",
        save: "Salvar",
        saving: "Salvando",
        success: "Configuração atualizada",
        paths: "Paths",
        errors: {
          base_url: "URL base é obrigatória",
          client_secret: "Segredo do cliente é obrigatório",
          seconds_to_find_signed_files:
            "Segundos para encontrar arquivos assinados são obrigatórios",
          token_path: "Caminho do token é obrigatório",
          upload_file_path: "Caminho do arquivo para upload é obrigatório",
          pscs_accounts_path: "Caminho das contas PSCs é obrigatório",
          client_id: "ID do cliente é obrigatório",
          default_minutes_signature_session:
            "Minutos padrão para a sessão de assinatura são obrigatórios",
          authorize_path: "Caminho de autorização é obrigatório",
          signature_path: "Caminho da assinatura é obrigatório",
          client_token_path: "Caminho do token do cliente é obrigatório",
        },
      },
      settingsCache: {
        settingsCacheTotal: {
          clean_all_cache: "Limpeza de Cache Total (exceto Hangfire)",
          clean_all_description:
            "Faz a limpeza de todo o cache da plataforma com exceção dos que são gerenciados pelo HangFire, nosso gerenciador de jobs.",
          clean_all_button: "Limpar",
          clean_all_success: "Cache limpo com sucesso",
        },
        settingsCacheKey: {
          clean_key_cache: "Limpeza de Cache por Chave (aceita chave parcial)",
          clean_key_description:
            "Faz a limpez do cache de acordo com a chave informada. Aceita também informar uma chave parcial. Exemplo: Se for informado EF_ limpará todo o cache do Entity Framework da plataforma. Se for informado hangfire* limpará todo o cache relacionado ao nosso gerenciador de jobs.",
          clean_key: "Chave",
          clean_key_button: "Limpar",
          clean_key_success: "Cache da chave '{{key}}' limpo com sucesso",
          errors: {
            cache_key: "A chave é obrigatória",
          },
        },
        searchCacheKey: {
          search_cache_key: "Pesquisa de Cache por Chave",
          search_cache_description:
            "Faz a pesquisa do cache de acordo com a chave informada. Aceita também informar uma chave parcial. Exemplo: Se for informado *EF_* irá buscar todo o cache do Entity Framework da plataforma. Se for informado *HANGFIRE* irá buscar todo o cache relacionado ao nosso gerenciador de jobs.",
          search: "Pesquisar",
          search_key: "Chave",
          results: "Resultados de busca para a chave",
          no_data: "Nenhuma informação encontrada",
          errors: {
            cache_key: "A chave é obrigatória",
          },
        },
      },
      neighborhood: {
        neighborhood: "Bairro",
        other: "Não encontrei meu bairro e quero digitar um novo",
        cep: "Digite um CEP primeiro",
      },
      addressContent: {
        cep_first: "Digite um CEP primeiro",
      },
      appDomain: {
        domain: "Domínio",
        title: "Copiar domínio",
      },
      nationalHolidays: {
        import_holidays: "Importar feriados nacionais",
        select_all: "Selecionar todos",
      },
      cepInput: {
        zip_code: "CEP",
        helper_text: "Digite um CEP para pesquisar",
        city: "Cidade",
        province: "Estado",
      },
      daysOfWeek: {
        sunday: "Domingo",
        monday: "Segunda-feira",
        tuesday: "Terça-feira",
        wednesday: "Quarta-feira",
        thursday: "Quinta-feira",
        friday: "Sexta-feira",
        saturday: "Sábado",
      },
      notificationInformations: {
        status: "Status",
        is_published: "Status da publicação",
        start_publish: "Início da publicação",
        finish_publish: "Fim da publicação",
        published: "Publicado",
        not_published: "Não publicado",
      },
      notificationBatchActions: {
        generate: "Gerar lote",
        cancel: "Cancelar lote",
        batch_generated: "Lote gerado com sucesso",
        batch_canceled: "Lote cancelado com sucesso",
        cancel_popover_title:
          "Ao cancelar o lote não será mais possível gerar lotes para essa notificação.",
        users: "Usuários notificados",
      },
      searchResults: {
        no_data: "Nenhum resultado encontrado",
      },
      yearSelect: {
        label: "Filtrar por ano",
      },
      syncSection: {
        sync: "Sincronizar",
        view_data: "Visualizar dados integrados",
      },
      syncEstablishment: {
        title: "Estabelecimento",
        description:
          "Sincronizar os dados do Estabelecimento com o ambiente do 4uBus",
        synced: "Dados do Estabelecimento sincronizados com sucesso",
        modal_title: "Dados integrados do estabelecimento",
      },
      syncGeneric: {
        title: "Genérico",
        description: "Sincronizar os dados do Genérico com o ambiente do 4uBus",
        synced: "Dados do Genérico sincronizados com sucesso",
        modal_title: "Dados integrados do genérico",
      },
      syncUsers: {
        title: "Usuário",
        description: "Sincronizar os dados do Usuário com o ambiente do 4uBus",
        synced: "Dados do Usuário sincronizados com sucesso",
        modal_title: "Dados integrados do usuário",
      },
      syncPrivilege: {
        title: "Privilégio",
        description:
          "Sincronizar os dados do Privilégio com o ambiente do 4uBus",
        synced: "Dados do Privilégio sincronizados com sucesso",
        modal_title: "Dados integrados do privilégio",
      },
      syncEstablishmentParam: {
        title: "Parâmetro do estabelecimento",
        description:
          "Sincronizar os dados do Parâmetro do estabelecimento com o ambiente do 4uBus",
        synced:
          "Dados do Parâmetro do estabelecimento sincronizados com sucesso",
        modal_title: "Dados integrados do parâmetro do estabelecimento",
      },
      syncRelationshipType: {
        title: "Tipo de relacionamento",
        description:
          "Sincronizar os dados do Tipo de relacionamento com o ambiente do 4uBus",
        synced: "Dados do Tipo de relacionamento sincronizados com sucesso",
        modal_title: "Dados integrados do tipo de relacionamento",
      },
      syncRelationship: {
        title: "Relacionamento",
        description:
          "Sincronizar os dados do Relacionamento com o ambiente do 4uBus",
        synced: "Dados do Relacionamento sincronizados com sucesso",
        modal_title: "Dados integrados do relacionamento",
      },
      syncExtension: {
        title: "Extensão",
        description: "Sincronizar os dados da Extensão com o ambiente do 4uBus",
        synced: "Dados da Extensão sincronizados com sucesso",
        modal_title: "Dados integrados da extensão",
      },
      integratedData: {
        no_data: "Nenhum dado integrado encontrado",
      },
      filtersActions: {
        filter: "Filtrar",
        clean: "Limpar filtros",
        refresh: "Atualizar",
      },
      callToActionsButtons: {
        call_to_action: "Chamada para ação",
        change_buttons_order: "Trocar a ordem de agrupamento dos botões",
      },
      quickAnswerButtons: {
        quick_aswer: "Resposta rápida",
        change_buttons_order: "Trocar a ordem de agrupamento dos botões",
      },
      buttonSetting: {
        action_type: "Tipo de ação",
      },
      accessSiteAction: {
        static: "Estática",
        dynamic: "Dinâmica",
        text: "Texto do botão",
        url_type: "Tipo de URL",
        payload: "URL do site",
        url_placeholder: "https://www.exemplo.com",
        url_tooltip:
          "A adição de uma variável cria um link personalizado para o cliente ver as informações dele. Somente uma variável pode ser adicionada ao final de um URL.",
      },
      callAction: {
        text: "Texto do botão",
        country: "País",
        payload: "Telefone",
      },
      copyOfferCodeAction: {
        text: "Texto do botão",
        info: {
          title: "Adicionar amostra de código da oferta",
          subtitle:
            "Para nos ajudar a analisar seu modelo de mensagem, adicione um exemplo do código de oferta.",
        },
        payload: "Código da oferta",
      },
      selectWhatsButtons: {
        tooltip_limit:
          "Limite de botões atingido, para adicionar outro tipo de botão, exclua um botão primeiro",
        generate_automatically:
          "Os botões são determinados automaticamente de acordo com a automação selecionada",
        add_button: "Adicione um botão",
        quick_answer_buttons: "Botões de resposta rápida",
        call_to_action_buttons: "Botões de chamada para ação",
        help: "Se você adicionar mais de 3 botões, eles aparecerão em uma lista.",
      },
      whatsButtonsOptions: {
        personalized: {
          name: "Personalizado",
        },
        access_site: {
          name: "Acessar o site",
          description: "2 botões no máximo",
        },
        call: {
          name: "Ligar",
          description: "1 botão no máximo",
        },
        call_whats: {
          name: "Ligar no WhatsApp",
          description: "1 botão no máximo",
        },
        copy_offer_code: {
          name: "Copiar código da oferta",
          description: "1 botão no máximo",
        },
      },
      customButton: {
        text: "Texto do botão",
      },
      addVariablesButton: {
        variables: "Variáveis",
        variable: "Variável",
        cancel: "Cancelar",
        add: "Adicionar",
        add_variables: "Adicionar variáveis",
      },
      variablesContainer: {
        title: "Exemplo de conteúdo do corpo",
        description:
          "Para nos ajudar a analisar seu modelo de mensagem, inclua um exemplo de cada variável no corpo do texto. Não use informações reais de clientes. A API de Nuvem hospedada pela Meta analisa modelos e parâmetros variáveis para proteger a segurança e a integridade dos nossos serviços.",
      },
      variableField: {
        label: "Insira conteúdo para ",
      },
      bodyInput: {
        error: "Todas as variáveis devem ser válidas",
      },
      timeRules: {
        time_rule: "Regra de Tempo",
        new_time_rule: "Nova regra de tempo",
        add_time_rule: "Adicionar regra de tempo",
        time_classification: "Classificação do Tempo",
        type: "Tipo",
        value: "Valor",
        search: "Pesquise por uma regra de tempo",
        confirm: {
          title: "Deletar regra de tempo?",
          description: "Isso vai permanentemente deletar a regra de tempo",
        },
        errors: {
          type: "O tipo é obrigatório",
          time_classification: "A classificação do tempo é obrigatória",
          value: "O valor é obrigatório",
        },
      },
      automationMessages: {
        automation_message: "Mensagem da Automação",
        new_automation_message: "Nova mensagem da automação",
        add_automation_message: "Adicionar mensagem da automação",
        search: "Pesquise por uma mensagem da automação",
        automation_message_type: "Tipo de mensagem da automação",
        message: "Mensagem",
        confirm: {
          title: "Deletar mensagem da automação?",
          description:
            "Isso vai permanentemente deletar a mensagem da automação",
        },
        errors: {
          automation_message_type:
            "O tipo de mensagem da automação é obrigatório",
          message: "A mensagem é obrigatória",
        },
      },
      messages: {
        message: "Mensagem",
        new_message: "Nova mensagem",
        add_message: "Adicionar mensagem",
        search: "Pesquise por uma mensagem",
        name: "Nome",
        enable: "Habilitar",
        exception: "Exceção",
        send: "Enviar",
        initial_date: "Data de início",
        end_date: "Data de fim",
        evaluation_form: "Formulário",
        rounded_schedules_date: "Arredondamento de Datas",
        establishment: "Estabelecimento",
        schedule_records: "Agendas",
        schedule_classifications: "Classificações da agenda",
        schedule_types: "Tipo de agenda",
        schedule_health_insurances: "Convênio",
        actions: "Ações",
        move_ticket:
          "Mover ticket(card) se a pessoa responder diferente de SIM",
        if: "Se",
        then: "Então",
        qty_pacient_sent: "Quantidade de pacientes a enviar",
        warning:
          "Ao habilitar a exceção, ao menos um dos campos abaixo são obrigatórios.",
        confirm: {
          title: "Deletar mensagem?",
          description: "Isso vai permanentemente deletar a mensagem",
        },
        errors: {
          name: "O nome é obrigatório",
          evaluation_form: "O formulário é obrigatório",
          initial_date: "A data de início é obrigatória",
          end_date: "A data de fim é obrigatória",
          initial_date_bigger:
            "A data de início não pode ser maior que a data de fim",
          ticket_channel_when_not: "O quadro é obrigatório",
          ticket_channel_step_when_not: "A lista é obrigatória",
          qty_pacient_sent: "A quantidade de pacientes a enviar é obrigatória",
        },
      },
      boardList: {
        board: "Quadro",
        list: "Lista",
      },
      ticketServiceAreaDays: {
        ticket_service_area_days: "Dias de atendimento",
        ticket_service_area_day: "Dia de atendimento",
        add_ticket_service_area_day: "Adicionar dia de atendimento",
        new_ticket_service_area_day: "Novo dia de atendimento",
        edit_ticket_service_area_day: "Editar dia de atendimento",
        day_of_week: "Dia da semana",
        initial_time: "Horário de início",
        end_time: "Horário de fim",
        enable: "Habilitar",
        search: "Pesquise por um dia de atendimento",
        confirm: {
          title: "Deletar dia de atendimento?",
          description: "Isso vai permanentemente deletar o dia de atendimento",
        },
        errors: {
          day_of_week: "O dia da semana é obrigatório",
          initial_time: "O horário de início é obrigatório",
          end_time: "O horário de fim é obrigatório",
          initial_time_bigger:
            "O horário de início não pode ser maior que o horário de fim",
        },
      },
      selectCategories: {
        label: "Selecionar categorias",
        no_options_text: "Nenhuma categoria selecionada",
        options_button_label: "Pesquisar categorias",
        drawer_props: {
          title: "Selecionar Categorias",
          create_button_label: "Criar nova categoria",
          input_label: "Nome da categoria",
          apply_button_label: "Selecionar categorias",
        },
      },
    },
    private: {
      permission: "Ooops! Você não tem permissão!",
    },
    chatBot: {
      page: {
        register: {
          ticketService: {
            id: "Id do serviço",
            ticket_service: "Serviço de tickets(cards)",
            new_ticket_service: "Novo serviço de tickets(cards)",
            name: "Nome",
            enable: "Habilitar",
            ticket_service_type: "Tipo de serviço de tickets(cards)",
            search: "Pesquise por um serviço de tickets(cards)",
            add_ticket_service: "Adicionar serviço de tickets(cards)",
            site_app_extensions: "Sites ou Apps",
            ticket_channel_initial: "Quadro inicial",
            show_message_disable_area:
              "Apresentar mensagem informando que algumas opções estão indisponíveis por estar fora do horário comercial e necessitar de um atendente presencial",
            config_title: "Configurações",
            show_badge_name_color:
              "Mostrar balão com nome do serviço no ticket(card)?",
            badge_background_name_color: "Selecione a cor de fundo do balão",
            badge_name_color: "Selecione a cor de texto do balão",
            whats_settings: "Configurações do Whatsapp",
            typebot_settings: "Configurações do TypeBot",
            ticket_message_received_rvent: "Lista para mensagens",
            confirm: {
              title: "Deletar serviço de tickets(cards)?",
              description:
                "Isso vai permanentemente deletar o serviço de tickets(cards)",
            },
            copied_successfully: "Copiado com sucesso",
            errors: {
              name: "O nome é obrigatório",
              ticket_service_type:
                "O tipo de serviço de tickets(cards) é obrigatório",
              site_app_extensions: "Ao menos um site ou app é obrigatório",
              ticket_channel_initial: "O quadro inicial é obrigatório",
              badge_background_name_color:
                "A cor do fundo do balão é obrigatória",
              badge_name_color: "A cor do texto do balão é obrigatória",
            },
          },
          ticketServiceWhats: {
            ticket_service_whats: "Configuração do Whatsapp",
            new_ticket_service_whats: "Nova configuração do Whatsapp",
            business_phone_number:
              "Id do Número de Telefone do Whatsapp Business",
            business_account: "Id da Conta do Whatsapp Business",
            whatsapp_business: "Id do App Whatsapp Business",
            access_token: "Token de Acesso",
            webhook_link: "Link do Webhook",
            webhook_link_tooltip: "Copiar link",
            verify_token: "Token de Verificação",
            verify_token_tooltip: "Copiar token de verificação",
            errors: {
              business_phone_number:
                "O id do número de telefone do Whatsapp Business é obrigatório",
              business_account:
                "O id da conta do Whatsapp Business é obirigatório",
              whatsapp_business: "O id do App Whatsapp Business é obrigatório",
              access_token: "O token de acesso é obrigatório",
            },
          },
          ticketServiceTypebot: {
            ticket_service_typebot: "Configurações do TypeBot",
            new_ticket_service_typebot: "Nova configuração do TypeBot",
            typebot_viewer_host: "Visualizador do Host do TypeBot",
            errors: {
              viewer_host: "O visualizador do host do typeBot é obrigatório",
            },
          },
        },
        service: {
          protocolGenerateRule: {
            protocol_generate_rules: "Regras de geração de protocolo",
            protocol_generate_rule: "Regra de geração de protocolo",
            new_protocol_generate_rule: "Nova regra de geração de protocolo",
            name: "Nome da regra",
            enable: "Habilitar",
            initial_date: "Início vigência",
            end_date: "Fim vigência",
            search: "Pesquise por uma regra de geração de protocolo",
            add_protocol_generate_rule:
              "Adicionar regra de geração de protocolo",
            protocol_rule_details: "Detalhes da regra de geração de protocolo",
            add_protocol_rule_details:
              "Adicionar detalhe da regra de geração de protocolo",
            order: "Ordem",
            field_type: "Tipo",
            fixed_code: "Valor fixo",
            mask: "Máscara",
            mask_information:
              "A máscara serve para formatar o valor do campo '{{type}}' no protocolo, nos casos em que o comprimento for menor que o limite aceito.",
            mask_no_information:
              "A máscara serve para formatar o valor do campo 'tipo' no protocolo, nos casos em que o comprimento for menor que o limite aceito.",
            confirm: {
              title: "Deletar regra de geração de protocolo?",
              description:
                "Isso vai permanentemente deletar a regra de geração de protocolo",
            },
            errors: {
              name: "O nome da regra é obrigatório",
              initial_date: "O início vigência é obrigatório",
              end_date: "O fim vigência é obrigatório",
              fixed_code: "O valor fixo é obrigatório",
              protocol_rule_details:
                "Ao menos um detalhe da regra de geração de protocolo é obrigatório",
            },
          },
          ticketServiceArea: {
            ticket_service_areas: "Áreas de atendimento",
            ticket_service_area: "Área de atendimento",
            search: "Pesquise por uma área de atendimento",
            add_ticket_service_area: "Adicionar área de atendimento",
            new_ticket_service_area: "Nova área de atendimento",
            ticket_service_area_parent: "Área de Atendimento Pai",
            ticket_default_doc: "Protocolo Padrão",
            ticket_event: "Disparar Evento",
            ticket_event_type: "Tipo de Evento",
            name: "Nome",
            code: "Código",
            enable: "Habilitar",
            identification: "Exige identificação",
            enable_all_the_time: "Atendimento 24/7",
            enable_special_time: "Horário especial de atendimento",
            display_order: "Ordem de Exibição",
            summary: "Resumo",
            ticket_services: "Serviço de Tickets(Cards)",
            ticket_channel: "Quadro para Transferir",
            ticket_channel_step: "Lista para transferir",
            service_type: "Tipo de Atendimento",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            yes: "Sim",
            no: "Não",
            actions: "Ações",
            time: "Horário",
            confirm: {
              title: "Deletar área de atendimento?",
              description:
                "Isso vai permanentemente deletar a área de atendimento",
            },
            errors: {
              name: "O nome é obrigatório",
              display_order: "A ordem de exibição é obrigatória",
              code: "O código é obrigatório",
              ticket_services: "O serviço de tickets(cards) é obrigatório",
              summary: "O resumo é obrigatório",
              summary_characteres:
                "O resumo não pode ter mais que 72 caracteres",
              name_characteres: "O nome não pode ter mais de 24 caracteres",
            },
          },
          ticketServiceMessage: {
            ticket_service_messages: "Mensagens",
            ticket_service_message: "Mensagem",
            search: "Pesquise por uma mensagem",
            message: "Mensagem",
            message_type: "Tipo de mensagem",
            ticket_service: "Serviço de ticket(card)",
            target: "Target",
            errors: {
              message: "A mensagem é obrigatória",
              message_type: "O tipo da mensagem é obrigatório",
              ticket_service: "O serviço de ticket(card) é obrigatório",
            },
          },
          serviceDay: {
            service_days: "Horários de atendimento",
            service_day: "Horário de atendimento",
            new_service_day: "Novo horário de atendimento",
            search: "Pesquise por um horário de atendimento",
            add_service_day: "Adicionar horário de atendimento",
            day_of_week: "Dia da semana",
            start_time: "Horário de início",
            end_time: "Horário de encerramento",
            ticket_service: "Serviço de ticket(card)",
            enable: "Habilitar",
            confirm: {
              title: "Deletar horário de atendimento?",
              description:
                "Isso vai permanentemente deletar o horário de atendimento",
            },
            errors: {
              day_of_week: "O dia da semana é obrigatório",
              ticket_service: "O serviço de ticket(card) é obrigatório",
              start_time: "O horário de início é obrigatório",
              end_time: "O horário de encerramento é obrigatório",
            },
          },
        },
        templates: {
          whatsapp: {
            whatsapp_templates: "Templates do Whatsapp",
            whatsapp_template: "Template do Whatsapp",
            new_whatsapp_template: "Novo template do Whatsapp",
            search: "Pesquise por um template do Whatsapp",
            add_whatsapp_template: "Adicionar template do Whatsapp",
            name: "Nome",
            enable: "Habilitar",
            automation: "Automação",
            ticket_service: "Serviço de ticket(card)",
            no_ticket_service:
              "É necessário ter ao menos um serviço de ticket(card) cadastrado, para poder cadastrar um template do Whatsapp",
            template_meta_name: "Nome do template na Meta",
            automation_status: "Status da automação",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            internal_template: "Template Interno CRM",
            message_preview: "Preview da mensagem",
            preview_button: "Visualizar preview do template",
            optional: "Opcional",
            header: "Cabeçalho",
            header_sub:
              "Adicione um título ou escolha qual tipo de mídia você usará para esse cabeçalho.",
            title: "Título",
            body: "Corpo",
            body_sub: "Insira o texto da sua mensagem.",
            body_placeholder: "Digite o corpo da sua mensagem...",
            footer: "Rodapé",
            footer_sub:
              "Adicione uma pequena linha de texto na parte inferior do seu modelo de mensagem.",
            buttons: "Botões",
            buttons_sub:
              "Crie botões para os clientes responderem à sua mensagem ou executarem alguma ação.",
            resend: "Reenviar",
            resend_success: "Template reenviado com sucesso",
            whatsappTemplateAutomation: {
              title: "Alterar automação?",
              description:
                "Alterar a automação fará com que todos os campos sejam limpos",
              cancel: "Cancelar",
              change: "Alterar",
            },
            confirm: {
              title: "Deletar Template do Whatsapp?",
              description:
                "Isso vai permanentemente deletar o Template do Whatsapp",
            },
            errors: {
              name: "O nome é obrigatório",
              ticket_service: "O serviço de ticket(card) é obrigatório",
              ticket_automation: "A automação é obrigatória",
              body: "O corpo deve ter ao menos 1 caractere",
              body_max: "O corpo deve ter no máximo 1024 caracteres",
              custom_button_text:
                "O texto do botão deve ter ao menos 1 caractere",
              custom_button_text_max:
                "O texto do botão deve ter no máximo 25 caracteres",
              url_type: "O tipo de URL é obrigatório",
              country: "O país é obrigatório",
              text: "O texto do botão deve ter ao menos 1 caractere",
              text_max: "O texto do botão deve ter no máximo 25 caracteres",
              payload: "Campo obrigatório",
              example: "O exemplo é obrigatório",
            },
          },
          internalCrm: {
            internal_crm: "Interno CRM",
            new_variable: "Nova Variável",
            new_category: "Nova Categoria",
            new_template: "Novo Template",
            search_category: "Pesquise por uma categoria",
            search_variable: "Pesquise por uma variável",
            search_template: "Pesquise por um template",
            add_automation_rule: "Adicionar regra de automação",
            categories: "Categorias",
            variables: "Variáveis",
            templates: "Templates",
            fields: "Campos",
            label: "Label",
            screen_order: "Sequencia Tela",
            is_required: "Obrigatório",
            add_new_item: "Adicionar novo campo",
            active: "Ativo",
            inactive: "Inativo",
            name: "Nome",
            status: "Status",
            category: "Categoria",
            data_type: "Tipo de dado",
            enable: "Ativar",
            code: "Código",
            confirm: {
              title_variable: "Excluir variável?",
              description_variable:
                "Isso irá excluir permanentemente a variável",
              title_category: "Excluir categoria?",
              description_category:
                "Isso irá excluir permanentemente a categoria",
              title_template: "Excluir template?",
              description_template:
                "Isso irá excluir permanentemente o template",
            },
            errors: {
              name: "O nome é obrigatório",
              label: "O label é obrigatório",
              screen_order: "A Sequencia de Tela é obrigatória",
              categories: "A categoria é obrigatória",
              data_type: "O tipo de dado é obrigatório",
              variable: "A variável é obrigatória",
              fields: "Ao menos um campo é obrigatório",
            },
          },
        },
        automations: {
          automationMessageTicketDefaulDoc: {
            title: "Protocolo Padrão",
            new: "Novo protocolo padrão",
            add: "Adicionar protocolo padrão",
            send_when: "Enviar Quando",
            ticket_default_doc: "Protocolo Padrão",
            schedules: "Agendamentos",
            confirm: {
              title: "Deletar o protocolo padrão?",
              description:
                "Isso vai permanentemente deletar o protocolo padrão",
            },
            errors: {
              ticket_default_doc: "O protocolo padrão é obrigatório",
              send_when: "O enviar quando é obrigatória",
            },
          },
          automationRules: {
            automation_rules: "Regras de Automação",
            automation_rule: "Regra de Automação",
            new_automation_rule: "Nova regra de automação",
            search: "Pesquise por uma regra de automação",
            add_automation_rule: "Adicionar regra de automação",
            name: "Nome",
            automation: "Automação",
            created_by: "Criado por",
            status: "Status",
            active: "Ativo",
            inactive: "Inativo",
            enable: "Habilitar",
            event: "Evento",
            ticket_service: "Serviço de ticket(card)",
            initial_date: "Data de início",
            end_date: "Data de fim",
            whatsapp_template: "Template do WhatsApp",
            robot: "Robô",
            attendant: "Atendente",
            pendent: "Pendência de Envio",
            when_error: "Quando der algum erro ao disparar o template",
            automation_message_ticket_defaul_doc: "Protocolo Padrão",
            board: "Quadro",
            list: "Lista",
            time_rule: "Regra de tempo",
            automation_message: "Mensagem de automação",
            messages: "Mensagens",
            created_successfully: "Regra de automação criada com sucesso",
            confirm: {
              title: "Deletar regra de automação?",
              description:
                "Isso vai permanentemente deletar a regra de automação",
            },
            errors: {
              name: "O nome é obrigatório",
              ticket_automation: "A automação é obrigatória",
              ticket_event: "O evento é obrigatório",
              ticket_service: "O serviço de ticket(card) é obrigatório",
              initial_date: "A data de início é obrigatória",
              end_date: "A data de fim é obrigatória",
              ticket_automation_template:
                "O template do WhatsApp é obrigatório",
              ticket_channel_step_robot: "A lista para o robô é obrigatória",
              ticket_channel_step_attendant:
                "A lista para o atendente é obrigatória",
              ticket_channel_step_pendent:
                "A lista para a pendência de envio é obrigatória",
              initial_date_bigger:
                "A data de início deve ser menor que a data de fim",
            },
          },
        },
        flows: {
          serviceWhatsapp: {
            title: "Você foi redirecionado para o gerenciador de fluxos",
            subtitle: "Para acessar novamente clique no botão abaixo",
            button: "Acessar",
          },
        },
      },
    },
    "4uESign": {
      page: {
        automations: {
          automationRules: {
            automation_rules: "Regras de automação",
            automation_rule: "Regra de automação",
            search: "Procure por uma regra de automação",
            name: "Nome",
            created_by: "Criado por",
            status: "Status",
            add_automation_rule: "Adicionar regra de automação",
            new_automation_rule: "Nova regra de automação",
            active: "Ativo",
            inactive: "Inativo",
            initial_date: "Data de início",
            end_date: "Data de fim",
            esign_automation: "Automação",
            enable: "Habilitar",
            observation: "Observação",
            file_type: "Tipo de arquivo",
            header_pdf: "Cabeçalhos do PDF",
            footer_pdf: "Rodapé do PDF",
            file_generation: "Da geração do arquivo e integração",
            created_successfully: "Regra de automação criada com sucesso",
            automation: "Automação",
            confirm: {
              title: "Deletar regra de automação?",
              description:
                "Isso vai permanentemente deletar a regra de automação",
            },
            errors: {
              name: "O nome é obrigatório",
              automation: "A automação é obrigatória",
              initial_date: "A data de início é obrigatória",
              end_date: "A data de fim é obrigatória",
              initial_date_bigger:
                "A data de início deve ser menor que a data de fim",
            },
          },
        },
      },
    },
  },
};

export default pt_BR;
