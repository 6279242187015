import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "@4uhub/lib4uhub";
import MainApi from "../../../../../services/mainApi.service";
import { ISiteAppCheckIn } from "../../../../../models/site-app-check-in";
import { IHealthChecks } from "../../../../../models/health-checks";

const HEALTH_CHECK_ROUTE =
  process.env.REACT_APP_CALENDAR + "/api/v1/TasyTriageCheckInRule/";

const healthCheckService = new MainApi<ISiteAppCheckIn>(HEALTH_CHECK_ROUTE);

const translationPath = "page.settings.siteApp.siteAppCheckIn.";

const SiteAppCheckInPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const [data, setData] = useState<ISiteAppCheckIn[]>([]);

  const [deletedData, setDeletedData] = useState<ISiteAppCheckIn | null>(null);

  const columns: GridColDef<ISiteAppCheckIn>[] = useMemo(
    () => [
      {
        field: "time",
        headerName: t(translationPath + "time") || "Time",
      },
      {
        field: "metersApproximation",
        headerName:
          t(translationPath + "meters_approximation") ||
          "Approximation in meters",
        renderCell: (params) => {
          return params.value ? params.value.name : "-";
        },
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IHealthChecks>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
    setDeletedData(null);
  };

  return (
    <Table<ISiteAppCheckIn>
      showDefaultMacroFilters={false}
      service={healthCheckService}
      columns={columns}
      title={t(translationPath + "site_app_check_in") || "Check In"}
      onAdd={handleAdd}
      onFetchData={setData}
      onFetchDelete={setDeletedData}
      onRowClick={handleRowClick}
      defaultPageSize={20}
      searchInput={false}
      backButton
      showButton={data.length === 0 || !!deletedData}
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      pageSizeOptions={[5, 10, 20]}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a health check"
      }
      addButtonLabel={
        t(translationPath + "add_site_app_check_in") || "Add Check In"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default SiteAppCheckInPage;
