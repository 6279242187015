import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApiService from "../../../services/mainApi.service";
import { IGetCity } from "../../../models/cities";

const TRANSLATION_PATH = "page.register.cities.";

const COUNTRIES_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/City";
const genericService = new MainApiService<IGetCity>(COUNTRIES_ROUTE);

const CitiesPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const columns: GridColDef<IGetCity>[] = [
    {
      field: "ibgeCode",
      headerName: t(TRANSLATION_PATH + "ibge_code") || "IBGE Code",
      maxWidth: 150,
    },
    {
      field: "name",
      headerName: t(TRANSLATION_PATH + "name") || "Name",
    },
    {
      field: "provincy",
      headerName: t(TRANSLATION_PATH + "provincy") || "State",
      renderCell: (params) => params.value.name,
    },
  ];

  const handleRowClick = (params: GridRowParams<IGetCity>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IGetCity>
      showDefaultMacroFilters={false}
      service={genericService}
      columns={columns}
      title={t(TRANSLATION_PATH + "cities")}
      searchInput
      onAdd={handleAdd}
      showDelete={false}
      defaultActions={false}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_city") || "Searh for a city"
      }
      addButtonLabel={t(TRANSLATION_PATH + "add_city") || "Add City"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default CitiesPage;
