import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import MainApi from "../../../../services/mainApi.service";
import { ITicketAutomationRules } from "../../../../models/ticket-automation-rules";

const TICKET_AUTOMATION_RULES_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationRules/";
const ticketAutomationRulesService = new MainApi<ITicketAutomationRules>(
  TICKET_AUTOMATION_RULES_ROUTE
);

const translationPath = "chatBot.page.automations.automationRules.";

const AutomationRulesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<ITicketAutomationRules>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
    {
      field: "ticketAutomation",
      headerName: t(translationPath + "automation") || "Automation",
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value.name}
            tooltip={params.value.name}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "createdByUserName",
      headerName: t(translationPath + "created_by") || "Created By",
      maxWidth: 350,
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "enable",
      headerName: t(translationPath + "status") || "Status",
      maxWidth: 120,
      renderCell: (params) => (
        <Chip
          size="small"
          label={
            params.value
              ? t(translationPath + "active")
              : t(translationPath + "inactive")
          }
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<ITicketAutomationRules>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<ITicketAutomationRules>
      showDefaultMacroFilters={false}
      service={ticketAutomationRulesService}
      columns={columns}
      title={t(translationPath + "automation_rules")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Searh for Whatsapp template"
      }
      addButtonLabel={
        t(translationPath + "add_automation_rule") || "Add Automation Rule"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default AutomationRulesPage;
