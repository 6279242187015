import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";
import MainApi from "../../../services/mainApi.service";
import { memo } from "react";
import { Box, Chip } from "@mui/material";
import { IEventTypesModalProps } from "./models";
import { IEventType } from "../../../models/event-type";

const EVENT_TYPES_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventType/";
const protocolGenerateService = new MainApi<IEventType>(EVENT_TYPES_ROUTE);

const translationPath = "page.settings.crm.eventTypes.";

export const EventTypesModal = memo(
  ({ changeToFormMode }: IEventTypesModalProps) => {
    const [, setSearchParams] = useSearchParams();

    const { t } = useTranslation();

    const columns: GridColDef<IEventType>[] = [
      {
        field: "name",
        headerName: t(translationPath + "name") || "Name",
      },
      {
        field: "code",
        headerName: t(translationPath + "code") || "Code",
        renderCell: (params) => params.value ?? "-",
      },
      {
        field: "enable",
        headerName: t(translationPath + "status") || "Status",
        maxWidth: 150,
        renderCell: (params) => (
          <Chip
            size="small"
            label={
              params.value
                ? t(translationPath + "active")
                : t(translationPath + "inactive")
            }
            color={params.value ? "success" : "error"}
          />
        ),
      },
    ];

    const handleRowClick = (params: GridRowParams<IEventType>) => {
      if (!params.id) return;
      changeToFormMode();
      setSearchParams({ id: params.id.toString() });
    };

    return (
      <Box sx={{ height: "80vh" }}>
        <Table<IEventType>
          showDefaultMacroFilters={false}
          service={protocolGenerateService}
          columns={columns}
          title={""}
          searchInput
          onAdd={changeToFormMode}
          onRowClick={handleRowClick}
          searchInputPlaceHolder={
            t(translationPath + "search") ||
            "Search for a protocol generate rule"
          }
          addButtonLabel={
            t(translationPath + "add_event_type") || "Add event type"
          }
          confirmOptions={{
            title: t(translationPath + "confirm.title"),
            description: t(translationPath + "confirm.description"),
            confirmationButtonProps: {
              variant: "contained",
            },
          }}
        />
      </Box>
    );
  }
);
