import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import {
  AutoCompleteUncontrolled,
  DateRangeUncontrolled,
  ISelectType,
  ModalFilters,
  OverflowTooltip,
  Table,
} from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";

import MainApiService, { MainApi } from "../../services/mainApi.service";
import { IHealthChecksLogs } from "../../models/health-checks-logs";
import useDate from "../../hooks/useDate";

const BANK_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/HealthCheckEvent";
const bankService = new MainApiService<IHealthChecksLogs>(BANK_ROUTE);

const HEALTH_CHECKS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/HealthCheck/";
const healthCheckService = new MainApi<ISelectType>(HEALTH_CHECKS_ROUTE);

const translationPath = "page.logs.healthChecks.";

const HealthChecksLogsPage = () => {
  const { t } = useTranslation();

  const { localeFormat } = useDate();

  const [selectedHealthCheck, setSelectedHealthCheck] =
    useState<ISelectType | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);

  const [endDate, setEndDate] = useState<Date | null>(null);

  const [filterParams, setFilterParams] = useState<
    Record<string, any> | undefined
  >();

  const columns: GridColDef<IHealthChecksLogs>[] = [
    {
      field: "healthCheck",
      headerName: t(translationPath + "name") || "Name",
      flex: 1,
      maxWidth: 350,
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value.name}
            tooltip={params.value.name}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "data",
      headerName: t(translationPath + "data") || "Data",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "created",
      headerName: t(translationPath + "created") || "Created",
      flex: 1,
      maxWidth: 150,
      renderCell: (params) =>
        params.value ? localeFormat(new Date(params.value), "Pp") : "-",
    },
  ];

  const filterHandler = useCallback(() => {
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setStartDate(null);
      setEndDate(null);
    }

    setFilterParams({
      HealthCheckId: selectedHealthCheck ? selectedHealthCheck.id : undefined,
      DateInitial:
        startDate && endDate
          ? startDate.toISOString().substring(0, 10)
          : undefined,
      DateEnd:
        endDate && startDate
          ? endDate.toISOString().substring(0, 10)
          : undefined,
    });
  }, [selectedHealthCheck, startDate, endDate]);

  const cleanFileterHandler = useCallback(() => {
    setFilterParams(undefined);
    setSelectedHealthCheck(null);
    setStartDate(null);
    setEndDate(null);
  }, []);

  const hasFilter = selectedHealthCheck || (startDate && endDate);

  return (
    <Table<IHealthChecksLogs>
      showDefaultMacroFilters={false}
      service={bankService}
      columns={columns}
      title={t(translationPath + "health_checks")}
      searchInput
      showButton={false}
      showDelete={false}
      showDeleteButton={false}
      serviceProps={filterParams}
      defaultActions={false}
      macroFilters={() => (
        <ModalFilters
          onFilter={filterHandler}
          actionsDisabled={!hasFilter}
          badgeCount={
            filterParams
              ? Object.keys(filterParams).reduce((count, key) => {
                  if (filterParams[key] !== undefined) {
                    return count + 1;
                  } else {
                    return count;
                  }
                }, 0) -
                (filterParams["DateInitial"] && filterParams["DateEnd"] ? 1 : 0)
              : 0
          }
          onCleanFilter={cleanFileterHandler}
        >
          <>
            <AutoCompleteUncontrolled
              label={t(translationPath + "health_checks") || "Health Checks"}
              service={healthCheckService.list}
              onValueSelected={setSelectedHealthCheck}
              value={selectedHealthCheck}
              size="small"
              fullWidth
            />
            <DateRangeUncontrolled
              onChangeEndDate={setEndDate}
              onChangeStartDate={setStartDate}
              startDateValue={startDate}
              endDateValue={endDate}
              disableFuture
            />
          </>
        </ModalFilters>
      )}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for health checks log"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default HealthChecksLogsPage;
