import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table, useFetch } from "@4uhub/lib4uhub";

import { Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { ISalesContract } from "../../../models/sales-contract";
import MainApi from "../../../services/mainApi.service";
import SituationChip from "./SituationChip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import CancelIcon from "@mui/icons-material/Cancel";
import { useCallback, useState } from "react";
import {
  approveSalesContract,
  cancelSalesContract,
  reactivateSalesContract,
  rescendSalesContract,
} from "../../../services/salesContract.service";

export const translationPath = "page.4uSalesContract.registers.baseContracts.";

const x = process.env.REACT_APP_MAIN + "/api/v1/salesContract/";

const service = new MainApi(x);

const AdvantagesAndBenefitsClubPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(0);

  const { sendRequest: approve } = useFetch(approveSalesContract);
  const { sendRequest: cancel } = useFetch(cancelSalesContract);
  const { sendRequest: reactivate } = useFetch(reactivateSalesContract);
  const { sendRequest: rescend } = useFetch(rescendSalesContract);

  const formatDate = (date: string) => {
    if (!date) return "-";

    const [year, month, day] = date.split("-");

    return `${day}/${month}/${year}`;
  };

  const columns: GridColDef<ISalesContract>[] = [
    {
      field: "code",
      headerName: t(translationPath + "code")!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "contractTitle",
      headerName: t(translationPath + "name")!,
    },
    {
      field: "contractDate",
      headerName: t(translationPath + "contratation_date")!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{formatDate(value)}</Typography>
      ),
    },
    {
      field: "terminationDate",
      headerName: t(translationPath + "termination_date")!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{formatDate(value)}</Typography>
      ),
    },
    {
      field: "salesContractClassification",
      headerName: t(translationPath + "contract_classification")!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{value?.name ?? "-"}</Typography>
      ),
    },
    {
      field: "integrationId",
      headerName: t(translationPath + "integration_code")!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{value ?? "-"}</Typography>
      ),
    },
    {
      field: "situation",
      headerName: t(translationPath + "situation_status")!,
      renderCell: ({ value }) => <SituationChip code={value?.code} />,
    },
  ];
  const onCancelSalesContract = useCallback(
    async (id: string) => {
      const { success } = await cancel(id);
      if (success) {
        setRefresh((p) => (p = +1));
      }
    },
    [cancel]
  );

  const onApproveSalesContract = useCallback(
    async (id: string) => {
      const { success } = await approve(id);
      if (success) {
        setRefresh((p) => (p = +1));
      }
    },
    [approve]
  );

  const onReactivationSalesContract = useCallback(
    async (id: string) => {
      const { success } = await reactivate(id);
      if (success) {
        setRefresh((p) => (p = +1));
      }
    },
    [reactivate]
  );

  const onRescendSalesContract = useCallback(
    async (id: string) => {
      const { success } = await rescend(id);
      if (success) {
        setRefresh((p) => (p = +1));
      }
    },
    [rescend]
  );

  const handleRowClick = ({ id }: GridRowParams<any>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<any>
      showDefaultMacroFilters={false}
      service={service}
      columns={columns}
      title={t(translationPath + "title")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      serviceProps={{
        refresh,
      }}
      searchInputPlaceHolder={t(translationPath + "search")}
      addButtonLabel={t(translationPath + "add")!}
      rowActions={(params) => [
        params.row.situation.code === "1" && params.row.approvalDate == null
          ? {
              action: (params) => {
                onApproveSalesContract(params.row.id);
              },
              icon: <CheckCircleIcon fontSize="small" />,
              text: `${t(translationPath + "approve")}`,
            }
          : null,
        params.row.cancelationDate === null
          ? {
              action: (params) => {
                onCancelSalesContract(params.row.id);
              },
              icon: <CancelIcon fontSize="small" />,
              text: `${t(translationPath + "cancel")}`,
            }
          : null,
        (params.row.situation.code === "4" &&
          params.row.terminationDate != null) ||
        (params.row.situation.code === "3" &&
          params.row.cancelationDate != null)
          ? {
              action: (params) => {
                onReactivationSalesContract(params.row.id);
              },
              icon: <PlayCircleFilledIcon fontSize="small" />,
              text: `${t(translationPath + "reactivation")}`,
            }
          : null,
        params.row.terminationDate === null
          ? {
              action: (params) => {
                onRescendSalesContract(params.row.id);
              },
              icon: <PauseCircleFilledIcon fontSize="small" />,
              text: `${t(translationPath + "terminate")}`,
            }
          : null,
      ]}
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default AdvantagesAndBenefitsClubPage;
