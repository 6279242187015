import { GridColDef, GridRowIdGetter } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table } from "@4uhub/lib4uhub";

import MainApi from "../../../../../../services/mainApi.service";
import { useCallback, useMemo } from "react";
import { IHealthProgramsParticipants } from "../../../../../../models/health-programs-participantes";
import { documentFormat } from "../../../../../../utils/conversions";
import MemberHistory from "./MemberHistory";

const HEALTH_PROGRAMS_ROUTE =
  process.env.REACT_APP_MAIN +
  "/api/v1/HealthProgram/GetHealthProgramParticipants";

const healthProgramsService = new MainApi<IHealthProgramsParticipants>(
  HEALTH_PROGRAMS_ROUTE
);

const translationPath = "page.4uHcp.hcp.participants.";

const MembersPage = () => {
  const { healthProgramId } = useParams();

  const { t } = useTranslation();

  const columns: GridColDef<IHealthProgramsParticipants>[] = [
    {
      field: "individual.name",
      headerName: t(translationPath + "name") || "Name",
      valueGetter: (v) => v.row.individual.name,
    },
    {
      field: "individual.document",
      headerName: t(translationPath + "document") || "Document",
      valueGetter: (v) =>
        v.row.individual.document
          ? documentFormat(v.row.individual.document)
          : "-",
    },
    {
      field: "isActive",
      headerName: t(translationPath + "status") || "Status",
      valueGetter: (v) =>
        v.value
          ? t(translationPath + "active")
          : t(translationPath + "formerMember"),
    },
    {
      field: "healthProgramHistory",
      headerName: t(translationPath + "history") || "History",
      renderCell: (v) => <MemberHistory data={v.value} />,
    },
  ];

  const props = useMemo(() => {
    return {
      HealthProgramId: healthProgramId,
    };
  }, [healthProgramId]);

  const getRowId: GridRowIdGetter<IHealthProgramsParticipants> = useCallback(
    (r) => r.individual.id,
    []
  );
  return (
    <Table<IHealthProgramsParticipants>
      showDefaultMacroFilters={false}
      service={healthProgramsService}
      columns={columns}
      title={t(translationPath + "participants")}
      searchInput
      showButton={false}
      defaultActions={false}
      showDelete={false}
      showDeleteButton={false}
      getRowId={getRowId}
      hideLog
      backButton
      serviceProps={props}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for a participant"
      }
      addButtonLabel={
        t(translationPath + "add_participant") || "Add Participant"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default MembersPage;
