import { Box, Card } from "@mui/material";
import GaugeChart from "./GaugeChart";

interface GaugeChartBoxProps {
  title: string;
  value: number | undefined;
}

// Componente principal do painel de métricas
const GaugeChartBox: React.FC<GaugeChartBoxProps> = ({ title, value }) => {
  return (
    <Box
      sx={{
        width: "100%",
        minWidth: 140,
      }}
    >
      <Card
        variant="outlined"
        sx={(theme) => ({
          p: 2,
          height: 180,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          backgroundColor:
            theme.palette.mode === "light" ? "#fff" : theme.palette.grey[900],
          transition: "all 0.2s ease-out",
          "&:hover": {
            borderColor: theme.palette.info.main,
          },
        })}
      >
        <GaugeChart title={title} value={value || 0} />
      </Card>
    </Box>
  );
};

export default GaugeChartBox;
