import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Chip, Typography } from "@mui/material";
import { Table } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";

import MainApi from "../../../../services/mainApi.service";
import { buildTPath } from "./beneficiary-termination-reason.utils";
import { IBeneficiaryTerminationReason } from "../../../../models/beneficiary-termination-reason";

const serviceURL =
  process.env.REACT_APP_MAIN +
  "/api/v1/BeneficiaryContractReasonOfTermination/";
const service = new MainApi<IBeneficiaryTerminationReason>(serviceURL);

const BeneficiaryTerminationReasonPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns: GridColDef<IBeneficiaryTerminationReason>[] = [
    {
      field: "code",
      headerName: t(buildTPath("code"))!,
      renderCell: ({ value }) => (
        <Typography variant="body2">{value}</Typography>
      ),
    },
    {
      field: "name",
      headerName: t(buildTPath("name"))!,
    },
    {
      field: "enable",
      maxWidth: 100,
      headerName: t(buildTPath("status"))!,
      renderCell: ({ value }) => (
        <Chip
          size="small"
          color={value ? "success" : "error"}
          label={value ? t(buildTPath("active")) : t(buildTPath("inactive"))}
        />
      ),
    },
  ];

  const handleRowClick = ({
    id,
  }: GridRowParams<IBeneficiaryTerminationReason>) => {
    navigate(`${id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IBeneficiaryTerminationReason>
      showDefaultMacroFilters={false}
      service={service}
      columns={columns}
      title={t(buildTPath("title"))}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={t(buildTPath("search"))}
      addButtonLabel={t(buildTPath("add"))!}
      confirmOptions={{
        title: t(buildTPath("confirm.title")),
        description: t(buildTPath("confirm.description")),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default BeneficiaryTerminationReasonPage;
