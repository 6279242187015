import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  AutoComplete,
  FormCheckBox,
  FormContainer,
  FormInput,
  ISelectType,
  QPFQuickRegisterForm,
  QPJQuickRegisterForm,
  Treturn,
  UsePrivileges,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import {
  createAccessToken,
  getAccessTokenById,
  updateAccessTokenById,
} from "../../../../../services/accessToken.service";
import { TAccessTokenForm, accessTokenSchema } from "./AccessTokenSchema";
import MainApi from "../../../../../services/mainApi.service";
import { useCallback, useLayoutEffect, useState } from "react";

import AccessTokenInformations from "./AccessTokenInformations";
import { listExtensionPrivileges } from "../../../../../services/busPrivileges.service";
import AccessTokenProfiles from "./AccessTokenProfiles";
import AccessTokenPrivileges from "./AccessTokenPrivileges";

const translationPath = "page.4uBus.register.accessTokens.";

const SOFTWARE_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Software/All/";

const softwareList = new MainApi<ISelectType>(SOFTWARE_ROUTE);

const AccessToken = () => {
  const { t } = useTranslation();

  const { privileges } = UsePrivileges();

  const hasIntegrationPrivilege = privileges.find(
    (privilege) =>
      privilege.name === "platform-all" ||
      privilege.name === "4ubususersintegration-read"
  );

  const [token, setToken] = useState("-");

  const [createdDate, setCreatedDate] = useState("-");

  const [name, setName] = useState("-");

  const { setMessage } = useNotificationContext();

  const [extensionsOptions, setExtensionsOptions] = useState<ISelectType[]>([]);

  const [selectedExtension, setSelectedExtension] =
    useState<ISelectType | null>();

  const { sendRequest, loading: pLoading } = useFetch(listExtensionPrivileges);

  const fetchExtensionsOptions = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      if (data.length === 0) {
        setMessage({
          type: "error",
          message: t(translationPath + "no_extensions_found"),
        });
      }
      setExtensionsOptions(data);
      setSelectedExtension(data[0]);
    }
  }, [sendRequest, setMessage, t]);

  useLayoutEffect(() => {
    fetchExtensionsOptions();
  }, [fetchExtensionsOptions]);

  const { sendRequest: save, loading: sLoading } = useFetch(createAccessToken);

  const { sendRequest: update, loading: uLoading } = useFetch(
    updateAccessTokenById
  );

  const { sendRequest: get, loading: gLoading } = useFetch(getAccessTokenById);

  const saveHandler = (v: TAccessTokenForm) =>
    save({
      ...v,
      privileges: v.privileges.map((priv) => priv.id),
      individualId: v.individual ? v.individual.id : undefined,
      legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
      softwareId: v.software ? v.software.id : undefined,
      description: v.description ?? undefined,
      justification: v.justification ?? undefined,
      isIntegration: hasIntegrationPrivilege ? v.isIntegration : false,
      profiles: v.restrictionRuleProfiles
        ? v.restrictionRuleProfiles.map((profile) => profile.id)
        : undefined,
    });

  const updateHandler = (v: TAccessTokenForm, id: string) =>
    update({
      item: {
        ...v,
        privileges: v.privileges.map((priv) => priv.id),
        individualId: v.individual ? v.individual.id : undefined,
        legalEntityId: v.legalEntity ? v.legalEntity.id : undefined,
        softwareId: v.software ? v.software.id : undefined,
        description: v.description ?? undefined,
        justification: v.justification ?? undefined,
        profiles: v.restrictionRuleProfiles
          ? v.restrictionRuleProfiles.map((profile) => profile.id)
          : undefined,
      },
      id: id,
    });

  const getHandler = useCallback(
    async (id: string): Promise<Treturn<TAccessTokenForm>> => {
      const { data, success } = await get(id);

      if (data) {
        setToken(data.tokenPublic);
        setCreatedDate(data.created);
        setName(data.name);

        const newData: TAccessTokenForm = {
          ...data,
          legalEntity: data.legalEntity
            ? {
                ...data.legalEntity,
                corporateName: data.legalEntity.name,
                document: data.legalEntity.code,
              }
            : null,
          individual: data.individual
            ? {
                ...data.individual,
                individualName: data.individual.name,
                document: data.individual.code,
              }
            : null,
        };
        return { data: newData, success: true };
      }
      return { data, success };
    },
    [get]
  );

  const loading = sLoading || uLoading || gLoading;

  return (
    <FormContainer<TAccessTokenForm>
      saveFunction={saveHandler}
      updateFunction={updateHandler}
      getFunction={getHandler}
      loading={loading}
      title={t(translationPath + "access_token")}
      schema={accessTokenSchema}
      subtitle={t(translationPath + "new_access_token")}
      defaultValues={{
        privileges: [],
      }}
      subtitleWatchField={"name"}
      fields={(type) => (
        <Grid container spacing={2}>
          {type === "update" && (
            <AccessTokenInformations
              token={token}
              createdDate={createdDate}
              name={name}
            />
          )}

          {hasIntegrationPrivilege && (
            <Grid item xs={12} sm={12}>
              <FormCheckBox
                name="isIntegration"
                label={
                  t(translationPath + "is_integration") || "Is integration"
                }
              />
            </Grid>
          )}

          {type === "create" && (
            <Grid item xs={12} sm={12}>
              <FormInput
                size="small"
                name="name"
                fullWidth
                label={t(translationPath + "name") || "Name"}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <QPFQuickRegisterForm
              name="individual"
              label={t(translationPath + "individual") || "Individual"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <QPJQuickRegisterForm
              name="legalEntity"
              label={t(translationPath + "legal_entity") || "Legal entity"}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AutoComplete
              name="software"
              label={t(translationPath + "software") || "Software"}
              getOptionLabel={(option) => option.name}
              size="small"
              fullWidth
              request={softwareList.list}
            />
          </Grid>

          <Grid item xs={12} sm={!hasIntegrationPrivilege ? 12 : 6}>
            <FormInput
              size="small"
              name="justification"
              fullWidth
              label={t(translationPath + "justification") || "Justification"}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormInput
              size="small"
              name="description"
              fullWidth
              label={t(translationPath + "description") || "Description"}
              multiline
              minRows={4}
            />
          </Grid>

          <AccessTokenPrivileges
            extensionsOptions={extensionsOptions}
            loading={pLoading}
          />

          <AccessTokenProfiles
            extensionsOptions={extensionsOptions}
            onSelectedExtension={setSelectedExtension}
            selectedExtensionCode={selectedExtension?.code ?? ""}
          />
        </Grid>
      )}
    />
  );
};

export default AccessToken;
