import { Box, Paper, Typography } from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";

interface ITableList {
  columns?: GridColDef<any>[];
  rows?: any[];
  pageSizeOptions?: number[];
  fileName: string;
  showSelects?: boolean;
  onRowSelectionModelChange:
    | ((
        rowSelectionModel: GridRowSelectionModel,
        details: GridCallbackDetails<any>
      ) => void)
    | undefined;
}

export interface ITableListRef {
  handleCsv: () => void;
}

const translationPath = "page.register.documents.";

const TableList = forwardRef<ITableListRef, ITableList>(
  (
    {
      onRowSelectionModelChange,
      columns,
      rows,
      pageSizeOptions,
      fileName,
      showSelects,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const apiRef = useGridApiRef();

    useImperativeHandle(ref, () => ({
      handleCsv() {
        apiRef.current?.exportDataAsCsv({
          fileName: fileName,
        });
      },
    }));

    return (
      <Box sx={{ height: "100%", width: "100%", marginTop: 3 }}>
        <Typography variant="subtitle1">
          {t(translationPath + "table_title") || "Version history"}:
        </Typography>
        <Paper
          sx={{
            overflow: "hidden",
            width: "100%",
            height: "90%",
          }}
          component={Box}
          elevation={0}
        >
          <DataGrid
            rows={rows || []}
            columns={columns || []}
            apiRef={apiRef}
            sx={(theme) => ({
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[900]
                    : theme.palette.grey[200],
                textTransform: "capitalize",
              },
            })}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            checkboxSelection={showSelects}
            disableRowSelectionOnClick
            onRowSelectionModelChange={onRowSelectionModelChange}
            pageSizeOptions={pageSizeOptions || [5, 10, 25, 50, 100]}
          />
        </Paper>
      </Box>
    );
  }
);

export default TableList;
