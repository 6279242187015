import { Table } from "@4uhub/lib4uhub";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IExternalProcessTypes } from "../../../../../models/external-process-type";
import MainApiService from "../../../../../services/mainApi.service";

const EVENT_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketExternalProcessType/";
const eventService = new MainApiService<IExternalProcessTypes>(EVENT_ROUTE);

const translationPath = "page.settings.crm.externalProcessType.";

const ExternalProcessTypePage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { eI } = useParams();

  const columns: GridColDef<IExternalProcessTypes>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
    },
  ];

  const handleAdd = () => {
    navigate("new");
  };

  const handleRowClick = (params: GridRowParams<IExternalProcessTypes>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  return (
    <Table<IExternalProcessTypes>
      showDefaultMacroFilters={false}
      service={eventService}
      columns={columns}
      title={t(translationPath + "external_process_types")}
      searchInput
      onAdd={handleAdd}
      onRowClick={handleRowClick}
      disableFilterParams
      backButton
      backButtonUrl={`/e/${eI}/dashboard/settings`}
      searchInputPlaceHolder={
        t(translationPath + "search") || "Search for an external process type"
      }
      addButtonLabel={
        t(translationPath + "add_external_process_type") ||
        "Add External Process Type"
      }
      confirmOptions={{
        title: t(translationPath + "confirm.title"),
        description: t(translationPath + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default ExternalProcessTypePage;
