import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ticketChannelStepsByTicketChannel } from "../../../../../../../services/ticketChannelStep.service";

const translationPath = "chatBot.page.service.ticketServiceArea.";

export const TicketChannelStepField = memo(() => {
  const { t } = useTranslation();

  const [listOptions, setListOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(ticketChannelStepsByTicketChannel);

  const { control } = useFormContext();

  const selectedBoard = useWatch({
    name: "ticketChannel",
    control,
  });

  const fetchListOptions = useCallback(async () => {
    if (!selectedBoard) {
      setListOptions([]);
      return;
    }
    const { data, success } = await sendRequest({
      id: selectedBoard.id,
      all: true,
      statusCode: "1",
    });
    if (data && success) {
      setListOptions(data.map((item) => ({ id: item.id, name: item.name })));
    }
  }, [sendRequest, selectedBoard]);

  useEffect(() => {
    fetchListOptions();
  }, [fetchListOptions]);

  return (
    <>
      <Grid item xs={12} sm={4}>
        <AutoComplete
          name="ticketChannelStep"
          label={t(translationPath + "ticket_channel_step")}
          getOptionLabel={(option) => option.name}
          size="small"
          fullWidth
          options={listOptions}
          disable={!selectedBoard}
        />
      </Grid>
    </>
  );
});
