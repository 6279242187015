import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";
import { useMemo } from "react";
import { IRoles } from "../../../models/roles";

import { MainApiService, Table, useRole } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../../../axios/axios-config";

const ROLE_ESTABLISHMENTS_ROUTE =
  process.env.REACT_APP_IDENTIY + "/api/v1/RoleEstablishments/";

const TRANSLATION_PATH = "page.register.roles.";

const roleService = new MainApiService<IRoles>(
  ROLE_ESTABLISHMENTS_ROUTE,
  GlobalAxios
);

const RolesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const createPermission = useRole(["Create"]);

  const columns: GridColDef<IRoles>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t(TRANSLATION_PATH + "name") || "Name",
      },
      {
        field: "establishments",
        headerName: t(TRANSLATION_PATH + "establishments") || "Establishment",
        renderCell: (params) => {
          const concataned = params.formattedValue.map((v: any) => (
            <Chip sx={{ marginRight: 1 }} key={v.id} label={v.name} />
          ));
          return concataned;
        },
      },
      {
        field: "profiles",
        headerName: t(TRANSLATION_PATH + "profiles") || "Roles",
        renderCell: (params) => {
          const concataned = params.formattedValue.map((v: any) => (
            <Chip sx={{ marginRight: 1 }} key={v.id} label={v.name} />
          ));
          return concataned;
        },
      },
    ],
    [t]
  );

  const handleRowClick = (params: GridRowParams<IRoles>) => {
    navigate(`${params.id.toString()}/edit`);
  };

  const handleAdd = () => {
    navigate("new");
  };

  return (
    <Table<IRoles>
      showDefaultMacroFilters={false}
      service={roleService}
      columns={columns}
      title={t(TRANSLATION_PATH + "roles")}
      searchInput={false}
      onAdd={handleAdd}
      showButton={createPermission}
      defaultActions={false}
      onRowClick={handleRowClick}
      searchInputPlaceHolder={
        t(TRANSLATION_PATH + "search_role") || "Searh for a name"
      }
      addButtonLabel={t(TRANSLATION_PATH + "new_role") || "Add role"}
      confirmOptions={{
        title: t(TRANSLATION_PATH + "confirm.title"),
        description: t(TRANSLATION_PATH + "confirm.description"),
        confirmationButtonProps: {
          variant: "contained",
        },
      }}
    />
  );
};

export default RolesPage;
