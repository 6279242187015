import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormInput,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import SettingsPageContainer from "../../../../../components/Settings/SettingsPageContainer";
import {
  settingsCertillionConfigSchema,
  TSettingsCertillionConfigForm,
} from "./SettingsCertillionSchema";
import { CertillionService } from "../../../../../services/certillion.service";
import { ICertillionSettings } from "../../../../../models/certillion-setting";

const translationPath = "components.certillionSettings.";

const service = new CertillionService();

const SettingsCertillion = () => {
  const { t } = useTranslation();
  const [userSettings, setUserSettings] = useState<ICertillionSettings>();
  const methods = useForm<TSettingsCertillionConfigForm>({
    resolver: zodResolver(settingsCertillionConfigSchema),
  });
  const { setMessage } = useNotificationContext();
  const { sendRequest } = useFetch(service.getCertillionSettings);
  const { sendRequest: update, loading } = useFetch(
    service.updateCertillionSettings
  );

  const onLoadSettingsGeneral = useCallback(async () => {
    const { data } = await sendRequest(undefined);
    if (data) {
      setUserSettings(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    onLoadSettingsGeneral();
  }, [onLoadSettingsGeneral]);

  useEffect(() => {
    if (userSettings) {
      methods.reset({
        ...userSettings,
        defaulMinutesSignatureSession:
          userSettings.defaulMinutesSignatureSession.toString(),
        secondsToFindSignedFiles:
          userSettings.secondsToFindSignedFiles.toString(),
      });
    }
  }, [userSettings, methods]);

  const onSubmitHandler = useCallback(
    async (values: TSettingsCertillionConfigForm) => {
      const { success } = await update({
        item: {
          ...values,
          secondsToFindSignedFiles: Number(values.secondsToFindSignedFiles),
          defaulMinutesSignatureSession: Number(
            values.defaulMinutesSignatureSession
          ),
        },
      });
      if (success) {
        setMessage({
          message: `${t(translationPath + "success")}`,
          type: "success",
          notificationKey: new Date().getMilliseconds(),
        });
      }
    },
    [update, setMessage, t]
  );

  const { handleSubmit } = methods;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <SettingsPageContainer title={t(translationPath + "title")}>
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: matches ? "100%" : "70%",
            mt: 2,
          }}
        >
          <Grid container columnSpacing={2} mt={2}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="baseUrl"
                autoFocus
                fullWidth
                label={t(translationPath + "base_url")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="clientId"
                fullWidth
                label={t(translationPath + "client_id")}
              />
            </Grid>
            <Grid item xs={12} sm={3} mb={2}>
              <FormInput
                size="small"
                name="secondsToFindSignedFiles"
                type="number"
                fullWidth
                label={t(translationPath + "seconds_to_find_signed_files")}
              />
            </Grid>
            <Grid item xs={12} sm={3} mb={2}>
              <FormInput
                size="small"
                name="defaulMinutesSignatureSession"
                type="number"
                fullWidth
                label={t(translationPath + "default_minutes_signature_session")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="clientSecret"
                fullWidth
                label={t(translationPath + "client_secret")}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={2} sx={{ marginBottom: 2 }}>
              <Divider sx={{ width: "100%", marginBottom: 2 }} />
              <Typography variant="h6">
                {t(translationPath + "paths")}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="authorizePath"
                fullWidth
                label={t(translationPath + "authorize_path")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="tokenPath"
                fullWidth
                label={t(translationPath + "token_path")}
              />
            </Grid>

            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="signaturePath"
                fullWidth
                label={t(translationPath + "signature_path")}
              />
            </Grid>

            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="uploadFilePath"
                fullWidth
                label={t(translationPath + "upload_file_path")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="clientTokenPath"
                fullWidth
                label={t(translationPath + "client_token_path")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormInput
                size="small"
                name="pscsAccountsPath"
                fullWidth
                label={t(translationPath + "pscs_accounts_path")}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Button
                variant="contained"
                disabled={loading ? true : false}
                type="submit"
                size="small"
              >
                {loading && (
                  <Stack
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <CircularProgress size={18} />
                    {t(translationPath + "saving")}
                  </Stack>
                )}
                {!loading && t(translationPath + "save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Form>
    </SettingsPageContainer>
  );
};

export default SettingsCertillion;
