import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MainApi from "../../../../../../../services/mainApi.service";

const translationPath = "chatBot.page.service.ticketServiceArea.";

const TICKET_CHANNELS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannels/";
const ticketChannelsService = new MainApi(TICKET_CHANNELS_ROUTE);

export const TicketChannelField = memo(() => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();

  const valueChangeHandler = useCallback(() => {
    setValue("ticketChannelStep", null);
  }, [setValue]);

  return (
    <>
      <Grid item xs={12} sm={4}>
        <AutoComplete
          getOptionLabel={(option) => {
            return option.name;
          }}
          size="small"
          label={t(translationPath + "ticket_channel")}
          name="ticketChannel"
          onValueChange={valueChangeHandler}
          request={ticketChannelsService.list}
        />
      </Grid>
    </>
  );
});
