import { OverflowTooltip, Table } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ITicketAutomationMessageRules } from "../../../../../../models/automation-message-rules";
import MainApi from "../../../../../../services/mainApi.service";
import Message from "./MessageForm/Message";

const TICKET_AUTOMATION_RULE_MESSAGE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationMessageRules/";
const automationRuleMessageService = new MainApi<ITicketAutomationMessageRules>(
  TICKET_AUTOMATION_RULE_MESSAGE_ROUTE
);

const translationPath = "components.messages.";

export const Messages = memo(() => {
  const [timeRuleForm, setTimeRuleForm] = useState(false);

  const { id } = useParams();

  const [selectedId, setSelectedId] = useState<string>();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const columns: GridColDef<ITicketAutomationMessageRules>[] = [
    {
      field: "name",
      headerName: t(translationPath + "name") || "Name",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <OverflowTooltip
            text={params.value}
            tooltip={params.value}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        ),
    },
    {
      field: "initialDate",
      headerName: t(translationPath + "initial_date") || "Initial Date",
      maxWidth: 200,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(`${params.value}T12:00:00Z`);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
    {
      field: "endDate",
      headerName: t(translationPath + "end_date") || "End Date",
      maxWidth: 200,
      renderCell: (params) => {
        if (!params.value) return "-";

        const initialDate = new Date(`${params.value}T12:00:00Z`);
        return format(initialDate, "P", {
          locale:
            language === "pt-BR" ? ptBR : language === "en-US" ? enUS : es,
        }).replaceAll(",", "");
      },
    },
  ];

  const handleAdd = () => {
    setTimeRuleForm(true);
  };

  const handleRowClick = (
    params: GridRowParams<ITicketAutomationMessageRules>
  ) => {
    if (!params.row) return;
    setTimeRuleForm(true);
    setSelectedId(params.row.id);
  };

  const handleBack = useCallback(() => {
    setSelectedId(undefined);
    setTimeRuleForm(false);
  }, []);

  const props = useMemo(() => {
    return {
      TicketAutomationRuleId: id,
    };
  }, [id]);

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      {timeRuleForm && (
        <Box
          sx={(t) => ({
            p: 1,
            px: 10,
            height: "100%",
            overflow: "auto",
            backgroundColor: t.palette.background.default,
            borderRadius: 1,
          })}
        >
          <Message
            id={selectedId}
            ticketAutomationRuleId={id}
            onBackButton={handleBack}
          />
        </Box>
      )}
      {!timeRuleForm && (
        <Box sx={{ height: 600 }}>
          <Table<ITicketAutomationMessageRules>
            showDefaultMacroFilters={false}
            service={automationRuleMessageService}
            columns={columns}
            title={""}
            searchInput
            onAdd={handleAdd}
            onRowClick={handleRowClick}
            searchInputAutoFocus={false}
            serviceProps={props}
            searchInputPlaceHolder={
              t(translationPath + "search") || "Searh for a message"
            }
            addButtonLabel={t(translationPath + "add_message") || "Add Message"}
            confirmOptions={{
              title: t(translationPath + "confirm.title"),
              description: t(translationPath + "confirm.description"),
              confirmationButtonProps: {
                variant: "contained",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
});
