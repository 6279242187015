import { GlobalAxios } from "../axios/axios-config";
import { ICertillionSettings } from "../models/certillion-setting";

const CERTILLION_ROUTE =
  process.env.REACT_APP_4UESIGN + "/api/v1/Certillion/ECertillionConfigs";

export class CertillionService {
  getCertillionSettings = () => {
    return GlobalAxios.get<ICertillionSettings>(CERTILLION_ROUTE);
  };

  updateCertillionSettings = ({ item }: { item: ICertillionSettings }) => {
    return GlobalAxios.put(CERTILLION_ROUTE, item);
  };
}
